import React, { useContext } from "react";
import { CartContext } from "../../context/cart";

export default function Footer({ prev, next }) {
    const { cart } = useContext(CartContext);

    const customItem = Object.values(cart).find(({ options = null }) => options && options?.size !== 'standard');

    return (
        <div className={customItem ? `shop-footer shop-footer--notice`: 'shop-footer'}>
            <div className="grid-x align-middle">
                <div className="cell small-4 medium-4">
                    {prev}
                </div>
                <div className="cell small-8 medium-8 flex-container align-right align-middle">
                    {customItem && <p className="shop-footer-notice"><i className="far fa-info-circle"></i> ACC Higgins will need to approve this item before arranging delivery</p>}
                    {next}
                </div>
            </div>
        </div>
    );
}
