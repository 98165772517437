import React, { useEffect, useState } from "react";
import { spinnerService } from "@chevtek/react-spinners";
import { notify } from "react-notify-toast";
import axios from "axios";
import Region from "./components/region";
import Modal from "../widgets/modal";
import { useFetch } from "../hooks";
import { getOptionLabel } from "../helpers";
import { acc_regions, apiUrl } from "../config/config";
import { isAccess } from "../utilities/constant/navbar.constant";

export default function Regions() {
  let [regions, setRegions] = useState(null);
  let [options, setOptions] = useState({});
  let [edit, setEdit] = useState(null);
  let [response, refresh] = useFetch(`${apiUrl}/${acc_regions}`);
  let [region, setRegion] = useState({
    name: "",
    connection: "",
    manager: "",
    email: {
      order_received: "",
      order_updated: "",
      order_approved: "",
      order_complete: "",
    },
  });

  useEffect(() => {
    if (response) {
      setRegions(response.regions);
      setOptions(response.options);
    }
  }, [response]);

  const handleUpdate = ({ target }) => {
    const value =
      target.name === "manager" ? Number(target.value) : target.value;

    setRegion({ ...region, [target.name]: value });
  };

  // Actually save changes
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isAccess('acc/v1/regions')) {
      return;
    }
    spinnerService.show("ug");
    axios
      .post(`${apiUrl}/acc/v1/regions`, region)
      .then(function (response) {
        if (response?.data?.status && response.data.status === "success") {
          notify.show("Region saved", "success", 3000);
          refresh();
          setRegion({
            name: "",
            connection: "",
            manager: "",
            email: {
              order_received: "",
              order_updated: "",
              order_approved: "",
              order_complete: "",
            },
          });
        } else {
          notify.show(
            response?.data?.error ?? "Something went wrong",
            "error",
            3000
          );
        }
      })
      .finally(() => {
        spinnerService.hide("ug");
      });
  };

  return (
    <div className="regions">
      <h2 className="heading-underline">Region settings</h2>

      {regions && (
        <>
          <table className="options-table">
            <thead>
              <tr>
                <th>Region name</th>
                <th>SAP Connection</th>
                <th>Manager</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {regions.map((region) => (
                <tr key={region.id}>
                  <td>{region.name}</td>
                  <td>
                    <span className="readonly">
                      {getOptionLabel(region.connection, options.connection)}
                    </span>
                  </td>
                  <td>{getOptionLabel(region.manager, options.manager)}</td>
                  <td className="text-right">
                    <button
                      type="button"
                      className="button button--reset"
                      aria-label="Edit region"
                      data-tip="Edit region"
                      onClick={() => setEdit(region)}
                    >
                      <i className="far fa-pencil"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="form-section grid-x grid-margin-x">
            <div className="cell small-12 medium-12 large-5">
              <label htmlFor="region_name">Region name</label>
              <input
                type="text"
                name="name"
                id="region_name"
                onChange={handleUpdate}
                placeholder="Region name"
                value={region.name}
                required
              />
            </div>
            <div className="cell small-12 medium-5 large-3">
              <label htmlFor="connection">SAP connection</label>
              <select
                name="connection"
                id="connection"
                onChange={handleUpdate}
                value={region.connection}
                required
              >
                <option value="">Select connection</option>
                {options.connection.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="cell small-12 medium-7 large-3">
              <label htmlFor="manager">Region manager</label>
              <select
                name="manager"
                id="manager"
                onChange={handleUpdate}
                value={region.manager}
                required
              >
                <option value="">Select manager</option>
                {options.manager.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="cell small-12 medium-6 large-1 align-end">
              <button
                type="button"
                className="button add-button-height button--ghost"
                disabled={
                  !(region?.name && region.connection && region.manager)
                }
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </>
      )}

      <Modal open={Boolean(edit)} classes="modal-option" width="780">
        <button onClick={() => setEdit(null)} className="btn-close-options">
          <i className="far fa-times"></i>
        </button>
        <Region
          item={edit}
          options={options}
          refresh={refresh}
          close={() => setEdit(null)}
        />
      </Modal>
    </div>
  );
}
