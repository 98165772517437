import React from 'react';
import styled from '@react-pdf/styled-components';
import OutputStyles from './styles.jsx';

import img_cover from '../../../images/bg-rear.png';
import img_logo from '../../../images/rear-page-logo.png';

class PDFRear extends React.Component {
    render() {  


  
        const ImageLogo = styled.Image`
            width:60%;
            height:auto;
            margin:0 auto;
            margin-top:10px;
            
        `;

        const Heading = styled.Text`
            font-size: 12px;
            
            
            margin-top:20px;
            font-family: 'Helvetica';
            color: white;
            text-align:center;
            
            
        `;

        const CenterBox = styled.View`
                
            flex-grow: 0;
            height:366px;
            
            margin: 0 auto;
            margin-top:-40px;
            
            
            text-align: center;
            width:300px;
            
            font-size: 8px;

            position:relative;
            z-index:10;
            
        `;
        

        
        
        return (
            <OutputStyles.ACCPage size="A4">
                <OutputStyles.ImageFillWrap>
                    <OutputStyles.ImageFill src={img_cover}  ></OutputStyles.ImageFill>
                </OutputStyles.ImageFillWrap>
                <OutputStyles.AllPageBottom >
                    <CenterBox>                        
                        <ImageLogo src={img_logo} ></ImageLogo>
                        <Heading>1300 588 480</Heading>                        
                    </CenterBox>                    
                </OutputStyles.AllPageBottom>
            </OutputStyles.ACCPage>
             
        );


    }


}
export default PDFRear;
