import React from "react";
import { Page } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import OutputStyles from "./styles.jsx";
import _ from "lodash";
import moment from "moment";

import img_cover from "../../../images/bg-a3.png";
import { decodeHTML } from "../../../utilities/constant/navbar.constant.js";

class PDFSales extends React.Component {
  render() {
    const CatalogueTable = styled.View`
      width: 100%;

      color: black;

      padding: 14px 36px;
    `;

    const CatRow = styled.View`
      display: flex;
      flex-direction: row;
    `;

    const CatRowHeader = styled.View`
      display: flex;
      flex-direction: row;
      font-weight: bold;
      margin-bottom: 10px;
    `;

    const CatColumn = styled.View`
      display: flex;
      flex-shrink: 0;
      flex-grow: 1;
      padding-left: 10px;
      padding-right: 10px;
      flex-wrap: wrap;
    `;

    const CatColumnNumber = styled.View`
      display: flex;
      flex-shrink: 0;
      flex-grow: 0;
      width: 60px;
      text-align: right;
      padding-left: 10px;
      padding-right: 10px;
    `;

    const CatEntry = styled.Text`
      width: 100%;
      white-space: nowrap;
      color: black;
      font-size: 9px;

      padding-top: 5px;
      padding-bottom: 4px;
    `;
    const CatEntrySalesStat = styled.Text`
      width: 100%;
      white-space: nowrap;
      color: #b89653;
      font-size: 9px;
      padding-top: 5px;
      padding-bottom: 4px;
    `;

    const CatEntrySalesStatHide = styled.Text`
      width: 100%;
      white-space: nowrap;
      color: white;
      font-size: 9px;
      padding-top: 5px;
      padding-bottom: 4px;
    `;

    const KeyStats = styled.View`
      width: 1164px;
      margin-left: 14px;

      height: 46px;

      padding: 0px 18px;

      display: flex;
      flex-direction: row;

      margin-top: 20px;
    `;

    const KeyStatsEntryRed = styled.View`
      background-color: #8a2726;

      color: white;
      width: 151px;
      height: 46px;

      margin: 0px 5px;
      padding: 8px 10px;
    `;
    const KeyStatsEntryBlue = styled.View`
      background-color: #203a66;
      color: white;
      width: 151px;
      height: 46px;

      margin: 0px 5px;
      padding: 8px 10px;
    `;
    const KeyStatsEntry = styled.View`
      background-color: #2a3338;
      color: white;
      width: 151px;
      height: 46px;

      margin: 0px 5px;
      padding: 8px 10px;
    `;

    const StatTitle = styled.Text`
      font-size: 9px;
      margin-bottom: 5=6px;
    `;
    const StatValue = styled.Text`
      font-size: 17px;
    `;
    const Header = styled.View`
      width: 1164px;
      height: 72px;
      margin-top: 14px;
      margin-left: 14px;
      display: flex;
      flex-direction: row;
    `;
    const HeaderLeft = styled.View`
      width: 50%;
      padding-left: 30px;
      text-align: left;
      color: white;
      align-items: flex-start;
      justify-content: center;
    `;

    const HeaderRight = styled.View`
      text-align: right;

      width: 50%;
      padding-right: 30px;

      color: white;

      justify-content: center;
    `;

    this.props.products.sort(function (a, b) {
      let x = parseFloat(b.atts.retail_price);
      let y = parseFloat(a.atts.retail_price);

      return x < y ? -1 : 1;
    });

    let chunked = _.chunk(this.props.products, 28);
    let maxSales = 0;
    let totalSales = 0;
    let totalCostValue = 0;
    let totalSalesValue = 0;
    let totalMargin = 0;
    let totalMargin2 = 0;

    // let totalCost = 0;
    // let totalRetail = 0;
    let totalMOF = 0;
    let averageMOF = 0;
    let averageMOFSales = 0;

    let discount = parseFloat(this.props.discount);

    //Find max number of sales
    for (var product of this.props.products) {
      if (
        !product.atts.stats_number_of_sales ||
        isNaN(product.atts.stats_number_of_sales)
      ) {
        product.atts.stats_number_of_sales = 0;
      }

      totalSales += parseInt(product.atts.stats_number_of_sales);

      let costDiscountAmount =
        parseFloat(product.atts.price || product.atts.wholesale_price) * (discount / 100);
      let cost = parseFloat(product.atts.price || product.atts.wholesale_price) - costDiscountAmount;
      let retail = parseFloat(product.atts.retail_price);

      // totalCost += cost;
      // totalRetail += retail;
      totalMOF += retail / cost;

      totalSalesValue += parseInt(product.atts.stats_number_of_sales) * retail;
      totalCostValue += parseInt(product.atts.stats_number_of_sales) * cost;

      let margin = parseFloat(product.atts.retail_price) - cost;
      let margin2 =
        parseInt(product.atts.stats_number_of_sales) * (retail - cost);

      totalMargin += margin;
      totalMargin2 += margin2;

      if (parseInt(product.atts.stats_number_of_sales) > maxSales) {
        maxSales = parseInt(product.atts.stats_number_of_sales);
      }
    }

    if (maxSales > 75) {
      maxSales = 75;
    }

    averageMOF = totalMOF / this.props.products.length;
    averageMOFSales = totalSalesValue / (totalSalesValue - totalMargin2);

    if (!chunked.length) {
      return (
        <Page size="A3" orientation="landscape" wrap={false}>
          <OutputStyles.ImageFillWrap>
            <OutputStyles.ImageFill src={img_cover} />
          </OutputStyles.ImageFillWrap>

          <Header>
            <HeaderLeft>
              <OutputStyles.Header1>
                No products in catalogs.
              </OutputStyles.Header1>
            </HeaderLeft>
          </Header>

          <OutputStyles.FooterDark>
            {this.props.funeral_home_name} | Private and Confidential
          </OutputStyles.FooterDark>
        </Page>
      );
    }

    let count = this.props.products.length + 1;

    console.log(product.atts);
    return (
      <React.Fragment>
        {chunked.map(function (products, indexPage) {
          let odd = true;

          return (
            <Page
              size="A3"
              orientation="landscape"
              wrap={false}
              key={indexPage}
            >
              <OutputStyles.ImageFillWrap>
                <OutputStyles.ImageFill
                  src={img_cover}
                ></OutputStyles.ImageFill>
              </OutputStyles.ImageFillWrap>

              <Header>
                <HeaderLeft>
                  {this.props.discount > 0 ? (
                    <OutputStyles.Header1>
                      Current Sales: Including discount{" "}
                    </OutputStyles.Header1>
                  ) : (
                    <OutputStyles.Header1>Current Sales </OutputStyles.Header1>
                  )}
                  <OutputStyles.Header2>
                    Your current inventory list, pricing and sales data
                  </OutputStyles.Header2>
                </HeaderLeft>
                <HeaderRight>
                  <OutputStyles.Header3>
                    Sheet {indexPage + 1} / {chunked.length} | Data taken
                    between:
                  </OutputStyles.Header3>
                  <OutputStyles.Header2>
                    {moment(
                      this.props.existingCatalogue.atts.start_date,
                      "YYYY-MM-DD"
                    ).format("DD/MM/Y")}{" "}
                    to{" "}
                    {moment(
                      this.props.existingCatalogue.atts.end_date,
                      "YYYY-MM-DD"
                    ).format("DD/MM/Y")}
                  </OutputStyles.Header2>
                </HeaderRight>
              </Header>

              <CatalogueTable>
                <CatRowHeader>
                  <CatColumnNumber style={{ width: "40px" }}>
                    <CatEntry></CatEntry>
                  </CatColumnNumber>
                  <CatColumn style={{ width: "50px" }}>
                    <CatEntry>
                      {this.props.funeral_home_name} Product Name
                    </CatEntry>
                  </CatColumn>
                  <CatColumn style={{ width: "50px" }}>
                    <CatEntry>ACC Product Name</CatEntry>
                  </CatColumn>

                  <CatColumnNumber>
                    <CatEntry>Cost</CatEntry>
                  </CatColumnNumber>
                  <CatColumnNumber>
                    <CatEntry>Retail</CatEntry>
                  </CatColumnNumber>
                  <CatColumnNumber>
                    <CatEntry>Margin</CatEntry>
                  </CatColumnNumber>
                  <CatColumnNumber>
                    <CatEntry>MOF</CatEntry>
                  </CatColumnNumber>
                  <CatColumn>
                    <CatEntrySalesStatHide>
                      {"|".repeat(maxSales)}
                    </CatEntrySalesStatHide>
                  </CatColumn>
                  <CatColumnNumber>
                    <CatEntry>Total</CatEntry>
                  </CatColumnNumber>
                  <CatColumnNumber>
                    <CatEntry>%</CatEntry>
                  </CatColumnNumber>
                  <CatColumnNumber>
                    <CatEntry>Gross</CatEntry>
                  </CatColumnNumber>
                  <CatColumnNumber>
                    <CatEntry>Margin</CatEntry>
                  </CatColumnNumber>
                  <CatColumnNumber>
                    <CatEntry>%</CatEntry>
                  </CatColumnNumber>
                </CatRowHeader>

                {products.map(function (product, indexProducts) {
                  if (
                    isNaN(product.atts.stats_number_of_sales) ||
                    product.atts.stats_number_of_sales < 0
                  ) {
                    product.atts.stats_number_of_sales = 0;
                  }

                  let salesCount = parseInt(product.atts.stats_number_of_sales);
                  if (salesCount > 75) {
                    salesCount = 75;
                  }
                  let salesText = "|".repeat(salesCount);

                  if (salesCount > 75) {
                    salesText += "+";
                  }

                  let costDiscountAmount =
                    parseFloat(product.atts.price || product.atts.wholesale_price) * (discount / 100);
                  let cost =
                    parseFloat(product.atts.price || product.atts.wholesale_price) - costDiscountAmount;
                  let retail = parseFloat(product.atts.retail_price);

                  let margin =
                    parseFloat(product.atts.retail_price) - parseFloat(cost);

                  let gross =
                    parseInt(product.atts.stats_number_of_sales) * retail;

                  let margin2 =
                    parseInt(product.atts.stats_number_of_sales) *
                    (retail - cost);

                  let margin2Perc = margin2 / totalMargin2;

                  odd = !odd;
                  count = count - 1;
                  return (
                    <CatRow
                      key={indexProducts}
                      style={{ backgroundColor: odd ? "#f1f8f9" : "" }}
                    >
                      <CatColumnNumber style={{ width: "40px" }}>
                        <CatEntry>{count}</CatEntry>
                      </CatColumnNumber>
                      <CatColumn style={{ width: "50px" }}>
                        <CatEntry>
                          {decodeHTML(product.atts.product_name_funeral_home)}
                        </CatEntry>
                      </CatColumn>
                      <CatColumn style={{ width: "50px" }}>
                        <CatEntry>{decodeHTML(product.atts.product_name_acc)}</CatEntry>
                      </CatColumn>

                      <CatColumnNumber>
                        <CatEntry>
                          $
                          {cost.toLocaleString("en-AU", {
                            maximumFractionDigits: 0,
                          })}
                        </CatEntry>
                      </CatColumnNumber>
                      <CatColumnNumber>
                        <CatEntry>
                          $
                          {retail.toLocaleString("en-AU", {
                            maximumFractionDigits: 0,
                          })}
                        </CatEntry>
                      </CatColumnNumber>
                      <CatColumnNumber>
                        <CatEntry>
                          $
                          {margin.toLocaleString("en-AU", {
                            maximumFractionDigits: 0,
                          })}{" "}
                        </CatEntry>
                      </CatColumnNumber>
                      <CatColumnNumber>
                        <CatEntry>
                          {(product.atts.retail_price / cost).toFixed(2)}
                        </CatEntry>
                      </CatColumnNumber>
                      <CatColumn>
                        <CatEntrySalesStat>{salesText}</CatEntrySalesStat>
                      </CatColumn>
                      <CatColumnNumber>
                        <CatEntry>
                          {product.atts.stats_number_of_sales}
                        </CatEntry>
                      </CatColumnNumber>
                      <CatColumnNumber>
                        <CatEntry>
                          {(
                            (product.atts.stats_number_of_sales / totalSales) *
                            100
                          ).toFixed(1)}
                          %
                        </CatEntry>
                      </CatColumnNumber>
                      <CatColumnNumber>
                        <CatEntry>
                          $
                          {gross.toLocaleString("en-AU", {
                            maximumFractionDigits: 0,
                          })}
                        </CatEntry>
                      </CatColumnNumber>
                      <CatColumnNumber>
                        <CatEntry>
                          $
                          {margin2.toLocaleString("en-AU", {
                            maximumFractionDigits: 0,
                          })}
                        </CatEntry>
                      </CatColumnNumber>
                      <CatColumnNumber>
                        <CatEntry>{(margin2Perc * 100).toFixed(1)}%</CatEntry>
                      </CatColumnNumber>
                    </CatRow>
                  );
                }, this)}

                {indexPage === chunked.length - 1 && (
                  <CatRow style={{ backgroundColor: "#a4cbd8" }}>
                    <CatColumnNumber style={{ width: "40px" }}>
                      <CatEntry></CatEntry>
                    </CatColumnNumber>
                    <CatColumn style={{ width: "50px" }}>
                      <CatEntry></CatEntry>
                    </CatColumn>
                    <CatColumn style={{ width: "50px" }}>
                      <CatEntry></CatEntry>
                    </CatColumn>

                    <CatColumnNumber>
                      <CatEntry></CatEntry>
                    </CatColumnNumber>
                    <CatColumnNumber>
                      <CatEntry></CatEntry>
                    </CatColumnNumber>
                    <CatColumnNumber>
                      <CatEntry></CatEntry>
                    </CatColumnNumber>
                    <CatColumnNumber>
                      <CatEntry>{averageMOF.toFixed(2)}</CatEntry>
                    </CatColumnNumber>
                    <CatColumn>
                      <CatEntrySalesStat></CatEntrySalesStat>
                    </CatColumn>
                    <CatColumnNumber>
                      <CatEntry>{totalSales}</CatEntry>
                    </CatColumnNumber>
                    <CatColumnNumber>
                      <CatEntry>100%</CatEntry>
                    </CatColumnNumber>
                    <CatColumnNumber>
                      <CatEntry>
                        ${" "}
                        {totalSalesValue.toLocaleString("en-AU", {
                          maximumFractionDigits: 0,
                        })}
                      </CatEntry>
                    </CatColumnNumber>
                    <CatColumnNumber>
                      <CatEntry>
                        $
                        {totalMargin2.toLocaleString("en-AU", {
                          maximumFractionDigits: 0,
                        })}
                      </CatEntry>
                    </CatColumnNumber>
                    <CatColumnNumber>
                      <CatEntry>100%</CatEntry>
                    </CatColumnNumber>
                  </CatRow>
                )}
              </CatalogueTable>

              {indexPage === chunked.length - 1 && (
                <KeyStats>
                  <KeyStatsEntryRed>
                    <StatTitle>Total Sales:</StatTitle>
                    <StatValue>
                      $
                      {totalSalesValue.toLocaleString("en-AU", {
                        maximumFractionDigits: 0,
                      })}
                    </StatValue>
                  </KeyStatsEntryRed>
                  <KeyStatsEntryRed>
                    <StatTitle>Gross Margin:</StatTitle>
                    <StatValue>
                      $
                      {totalMargin.toLocaleString("en-AU", {
                        maximumFractionDigits: 0,
                      })}
                    </StatValue>
                  </KeyStatsEntryRed>
                  <KeyStatsEntryBlue>
                    <StatTitle>Average Pricing MOF:</StatTitle>
                    <StatValue>{averageMOF.toFixed(2)}</StatValue>
                  </KeyStatsEntryBlue>
                  <KeyStatsEntryBlue>
                    <StatTitle>Average MOF of Actual Sales:</StatTitle>
                    <StatValue>{averageMOFSales.toFixed(2)}</StatValue>
                  </KeyStatsEntryBlue>
                  <KeyStatsEntry>
                    <StatTitle>Cost average:</StatTitle>
                    <StatValue>
                      $
                      {(totalCostValue / totalSales).toLocaleString("en-AU", {
                        maximumFractionDigits: 0,
                      })}
                    </StatValue>
                  </KeyStatsEntry>
                  <KeyStatsEntry>
                    <StatTitle>Retail sales average:</StatTitle>
                    <StatValue>
                      $
                      {(totalSalesValue / totalSales).toLocaleString("en-AU", {
                        maximumFractionDigits: 0,
                      })}
                    </StatValue>
                  </KeyStatsEntry>
                  <KeyStatsEntry>
                    <StatTitle>Average Unit margin:</StatTitle>
                    <StatValue>
                      $
                      {(totalMargin2 / totalSales).toLocaleString("en-AU", {
                        maximumFractionDigits: 0,
                      })}
                    </StatValue>
                  </KeyStatsEntry>
                </KeyStats>
              )}

              <OutputStyles.FooterDark>
                {this.props.funeral_home_name} | Private and Confidential
              </OutputStyles.FooterDark>
            </Page>
          );
        }, this)}
      </React.Fragment>
    );
  }
}
export default PDFSales;
