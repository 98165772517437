import React from "react";
import Shop from "../shop/shop"

export default function NewOrder() {
    return (
        <div className="page-content customer-new-order" id="main-scroller">
            <div className="grid-container">
                <div className="grid-x">
                    <div className="cell cell-component small-12">
                        <Shop path="/customer/new-order" />
                    </div>
                </div>
            </div>
        </div>
    );
}
