import React from "react";
import { Link, useLocation } from "react-router-dom";
import { decodeHTML } from "../utilities/constant/navbar.constant";

export default function Navigation({ navigator = [], heading, leftHeading = null, path, headingClassName = '' }) {
  let location = useLocation();

  const steps = path && [path, `${path}/accessories`, `${path}/review`];
  const activeStep =
    steps && steps.findIndex((step) => step === location.pathname);
  return (
    <div className="shop-header navigation">
      <Link className="navigation-home-icon" to="/">
        <i className="fas fa-home fa-fill" /> /
      </Link>
      {navigator?.length !== 0 &&
        navigator.map((data, index) => (
          <Link className="navigation-text-link" key={index} to={data.path}>
            <span className="navigator-text">{`${data.label}`}</span> /
          </Link>
        ))}
      <div className="grid-x align-middle">
        <div className="cell small-12 medium-8">
          <h1 className={`shop-header-title navigator-title ellipsis ${headingClassName}`} title={heading}>{decodeHTML(heading)}</h1>
        </div>
        {steps?.length > 0 && (
          <div
            className={`cell small-12 medium-4 flex-container align-right shop-header-step-${activeStep + 1
              }`}
          >
            {steps.map((step, i) => {
              return (
                <Link className="shop-header-step" to={step} key={i}>
                  {i + 1}
                </Link>
              );
            })}
          </div>
        )}
        {leftHeading && <div className="medium-4">
          {leftHeading}
        </div>}
      </div>
    </div>
  );
}
