import React, { useState } from "react";

export default function ApproveProposal({  cancel,approve }) {
  // State
  const [acceptCondition, setAcceptCondition] = useState(false);

  return (
    <>
      <h3 className="modal-confirm-fast-track-title">Confirm Approved Proposal</h3>
      <p>
      By setting this proposal as approved you will replacing the Funeral Homes catalogue with the one outlined in the proposal.
      </p>
            <div className="confirm-section">
        <input
          type="checkbox"
          onChange={(ev) => setAcceptCondition(ev.target.checked)}
          checked={acceptCondition}
        />{" "}
        <label className="modal-confirm-fast-checkbox">
          I understand
        </label>
      </div>
      <div className="grid-x grid-margin-x">
        <div className="cell small-6">
          <button
            type="submit"
            className="button button--ghost"
            onClick={cancel}
          >
            Cancel
          </button>
        </div>
        <div className="cell small-6 text-right">
          <button
            type="submit"
            className="button"
            onClick={approve}
            disabled={!acceptCondition}
          >
            Approve Proposal
          </button>
        </div>
      </div>
    </>
  );
}
