import React from 'react';
import {RIEInput, RIENumber} from '@attently/riek'
import InlineEditSelect from '../../widgets/inline-edit-select.jsx';


class TableRow extends React.Component {
    render() {  
        if (!this.props.atts.price){
            this.props.atts.price = "0";
        }
        if (!this.props.atts.retail_price){
            this.props.atts.retail_price = "0";
        }
        
        return (

            <tr>

                <td className="td-linked">                    
                    {(this.props.atts.product_atts) ? (
                        <i className="far fa-check"></i>
                    ) : (
                        <span></span>
                    )}
                </td>
                <td>

                <RIEInput
                    className="wrap-inline-edit text"
                    classEditing="editing"
                    value={<div dangerouslySetInnerHTML={{ __html: this.props.atts.product_name_acc }} />}
                    change={this.props.onChange}
                    propName='product_name_acc'
                    isDisabled
                />  


                    {/* <RIEInput
                        className="wrap-inline-edit wrap-product_name_funeral_home text "
                        classEditing="editing"
                        value={this.props.atts.product_name_funeral_home}
                        change={this.props.onChange}  
                        propName='product_name_funeral_home'
                        
                    /> */}

                    
                </td>
                <td>
                    <RIEInput
                        className="wrap-inline-edit text"
                        classEditing="editing"
                        value={<div dangerouslySetInnerHTML={{ __html: this.props.atts.product_name_funeral_home }} />}
                        change={this.props.onChange}  
                        propName='product_name_funeral_home'                     
                    />
                </td>
                <td>
                    <RIEInput
                            className="wrap-inline-edit text"
                            classEditing="editing"
                            defaultValue=""
                            value={<div dangerouslySetInnerHTML={{ __html: this.props.atts.sku }} />}
                            change={this.props.onChange}  
                            propName='sku'  
                            isDisabled                   
                        />

                </td>
                <td>
                    <InlineEditSelect
                        value={this.props.atts.category}
                        propName='category'
                        options={this.props.product_cats}
                        change={this.props.onChange}  
                    />
                    
                </td>
                <td>
                    <RIENumber
                        className="wrap-inline-edit number "
                        classEditing="editing"
                        value={this.props.atts.price}
                        change={this.props.onChange}   
                        propName='price'
                        isDisabled                        
                    />                                    
                </td>
                <td>
                    <RIENumber
                        className="wrap-inline-edit number "
                        classEditing="editing"
                        value={this.props.atts.retail_price}
                        change={this.props.onChange}    
                        propName='retail_price'
                        
                    />                                    
                </td>
                <td>{ (this.props.atts.retail_price - this.props.atts.price).toFixed(2) }</td>
                {(this.props.catalogue_type === 'existing' || this.props.catalogue_type === 'data')  &&
                    <td>
                        <RIENumber
                            className="wrap-inline-edit number "
                            classEditing="editing"
                            value={this.props.atts.stats_number_of_sales}
                            change={this.props.onChange}  
                            propName='stats_number_of_sales'
                            editProps={ {
                                min: 0
                            } }                         
                            
                        />                                    
                    </td>
                }
                <td>
                    <button className="a black" onClick={this.props.onDelete}>
                        <i className="far fa-times"></i>
                    </button>
                </td>
                
                
            </tr>
        );
    }

   

  }

  export default TableRow;
