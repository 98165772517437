import React from 'react';
//import ReactTooltip from 'react-tooltip'

class PricePointEntry extends React.Component {
    render() {  
      let _this = this;
      
      return (
        <div className="price-point-entry">                  

          {this.props.matching_products.map(function(product, index){
            let colour = '';
            let categoryName = '';
            
            for (let cat of _this.props.product_cats){
              if (cat.id === product.atts.category){
                  //product_cat = cat;
                  colour = cat.colour;
                  categoryName = cat.text;
              }
            }

            return (
              <div
                style={{'color': colour}}
                key={index}
                className="product-entry" 
                data-html={true} 
                data-tip={`<p><b>${product.atts.product_name_acc}</b></p><p><b>(${product.atts.product_name_funeral_home})</b></p><p>${categoryName}</p><p>Whole Sale: $${product.atts.price}</p><p>Retail: $${product.atts.retail_price}</p>`}                 
                onClick={ () => this.props.onPricePointClick(product) }  
              >
                ${parseInt(product.atts.retail_price).toLocaleString('en-AU', {  maximumFractionDigits: 0 })}

                {product.atts.pinningIndex &&
                <div className="product-pin">{product.atts.pinningIndex}</div>
                
                }
              </div>
            );
          }, this)}

        </div>
            
      );
    }
    
    //  componentDidUpdate() {       
    //    ReactTooltip.rebuild();
    //  }
    //  componentDidMount() {       
    //    ReactTooltip.rebuild();
    //  }
 
  }

  export default PricePointEntry;
