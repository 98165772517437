import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth";
import { spinnerService } from "@chevtek/react-spinners";

const StaffDashboard = () => {
  const { auth, logout } = useContext(AuthContext);
  spinnerService.hide("ug");
  const dashboardSections = [
    {
      label: "Funeral Homes",
      subLabel: "View and edit funeral home details",
      icon: "fal fa-home-alt",
      to: "/funeral-homes",
    },
    {
      label: "Customer Orders",
      subLabel: "View and review orders",
      icon: "fal fa-truck",
      to: "/orders",
    },
    {
      label: "Products",
      subLabel: "View and edit product details",
      icon: "fal fa-coffin",
      to: "/products",
    },
    {
      label: "Customer Mailouts",
      subLabel: "View and edit customer mail out information",
      icon: "fal fa-envelope",
      to: "/customer-mailouts",
    },
    {
      label: "My Profile",
      subLabel: "Edit your profile information",
      icon: "fal fa-user",
      to: "/account",
    },
    {
      label: "Global Settings",
      subLabel: "Edit portal settings",
      icon: "fal fa-cog",
      to: "/options/routes",
    },
  ];

  return (
    <div className="cell auto funeral-home-body body-white page-content">
      <div className="grid-container dashboard-section">
        <div className="title-section">
          <div className="title-text">
            Hi {auth?.user_display_name}, welcome to your dashboard.
          </div>
          <button
            className="button button--reset logout-button"
            onClick={logout}
          >
            Logout
          </button>
        </div>
        <div className="dashboard-grid">
          <div className="grid-x grid-margin-x">
            {dashboardSections.map((section) => {
              return (
                <Link
                  className="customer-dashboard-tile cell cell-component small-12 medium-6 large-4 dashboard-card"
                  to={section.to}
                >
                  <i className={section.icon}></i>
                  <h2 className="tile-title">{section.label}</h2>
                  <p className="tile-subtitle">{section.subLabel}</p>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffDashboard;
