import React from "react";
import axios from "axios";
import { spinnerService } from "@chevtek/react-spinners";
import { Redirect } from "react-router-dom";
import Navigation from "./dashboard/navigation.jsx";
import ReactPaginate from "react-paginate";
import FuneralHomeList from "./funeral-home/funeral-home-list.jsx";
import { isAccess } from "./utilities/constant/navbar.constant.js";
import { acc_funeral_homes, acc_shop_order_unapproved } from "./config/config.js";

const itemsPerPage = 12;
class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.state.funeralHomeRedirect && (
          <Redirect to={this.state.funeralHomeRedirect} />
        )}
        <div
          className="body-white funeral-home-body funeral-home-list"
          id="main-scroller"
        >
          <div className="grid-container">
            <Navigation heading="Funeral Homes" />
            {this.state.homes && (
              <div className="cell small-12">
                <div className="funeral-home-filters filters grid-x">
                  <div className="medium-6">
                    <label htmlFor="search-keywords">Keywords</label>
                    <input
                      name="search-keywords"
                      id="search-keywords"
                      onChange={this.filterByName}
                      type="search"
                      placeholder="Enter keywords"
                    />
                  </div>
                  <div className="funeral-home-filter-group medium-2">
                    <label htmlFor="filter-group">Funeral Home Group</label>
                    <select
                      name="filter-group"
                      id="filter-group"
                      value={this.state.filterByGroup}
                      onChange={({ target }) =>
                        this.filterByGroup(target.value)
                      }
                    >
                      <option value="">All</option>
                      {this.state.groups.map((group, index) => (
                        <option key={index} value={group.term_id}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="medium-2">
                    <label htmlFor="filter-state">State</label>
                    <select
                      name="filter-state"
                      id="filter-state"
                      value={this.state.filterByState}
                      onChange={({ target }) =>
                        this.filterByState(target.value)
                      }
                    >
                      <option value="">All</option>
                      {this.state.states.map((group, index) => (
                        <option key={index} value={group.code}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {this.state.hasArchived && (
                    <div className="cell-toggle align-right">
                      <div className="fancy-checkbox">
                        <input
                          name="filter-archived"
                          id="filter-archived"
                          onChange={this.toggleArchived}
                          type="checkbox"
                        />
                        <label htmlFor="filter-archived">Show archived</label>
                      </div>
                    </div>
                  )}
                </div>

                {this.state.filteredHomes?.length > 0 && (
                  <>
                    {this.state.filteredHomes
                      .slice(
                        (this.state.currentPage - 1) * itemsPerPage,
                        this.state.currentPage * itemsPerPage
                      )
                      .map((home, index) => {
                        return (
                          <FuneralHomeList
                            key={index}
                            home={home}
                            config={this.props.config}
                          />
                        );
                      })}

                    <ReactPaginate
                      previousLabel={<i className="fal fa-chevron-left" />}
                      breakLabel="..."
                      nextLabel={<i className="fal fa-chevron-right" />}
                      breakClassName={"break-me"}
                      forcePage={this.state.currentPage - 1}
                      pageCount={this.state.totalpages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(selectedPage) => {
                        this.setState({
                          currentPage: selectedPage.selected + 1,
                        });
                      }}
                      containerClassName={"pagination"}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  filter() {
    let homes = Object.assign([], this.state.homes);
    let records = [];

    let filterText = [];
    if (this.state.filterByName) {
      filterText = this.state.filterByName.toUpperCase().split(" ");
    }

    for (let home of homes) {
      let okGroup = false;
      let okState = false;
      let okName = false;
      let okArchived = false;

      let thisName = home.funeral_home_name.toUpperCase();

      //Match group
      if (
        !this.state.filterByGroup ||
        (home.group && home.group.term_id === Number(this.state.filterByGroup))
      ) {
        okGroup = true;
      }
      //Match state
      if (
        !this.state.filterByState ||
        (home.address_state && home.address_state.toLowerCase() === this.state.filterByState.toLowerCase())
      ) {
        okState = true;
      }
      //Match archived
      if (this.state.showArchived || !home.archived) {
        okArchived = true;
      }

      if (filterText === "") {
        okName = true;
      } else {
        okName = true;
        //Match text
        for (var f = 0; f < filterText.length; f++) {
          if (filterText[f] !== "" && thisName.indexOf(filterText[f]) > -1) {
            // okName = true;
            //break;
          } else {
            okName = false;
            break;
          }
        }
      }

      home.show = okGroup && okName && okState && okArchived;
      if (home.show) {
        records.push(home);
      }
    }

    this.setState({
      homes,
      filteredHomes: records,
      currentPage: 1,
      totalpages: Math.ceil(records.length / itemsPerPage),
    });
  }
  filterByName(event) {
    const value = event.target.value;

    this.setState(
      (state) => ({
        filterByName: value,
      }),
      () => this.filter()
    );
  }
  filterByGroup(group_id) {
    this.setState(
      (state) => ({
        filterByGroup: group_id,
      }),
      () => this.filter()
    );
  }
  filterByState(state_code) {
    this.setState(
      (state) => ({
        filterByState: state_code,
      }),
      () => this.filter()
    );
  }

  toggleArchived() {
    this.setState(
      (state) => ({
        showArchived: !state.showArchived,
      }),
      () => this.filter()
    );
  }

  addHome() {
    let _this = this;
    if (!isAccess('"/acc/v1/funeral-home/create"')) {
      return;
    }
    spinnerService.show("ug");
    axios
      .get(this.props.config.apiUrl + "/acc/v1/funeral-home/create", {})
      .then(function (response) {
        spinnerService.hide("ug");

        if (response && response.data) {
          _this.setState((state) => ({
            funeralHomeRedirect:
              "/funeral-home/" + response.data.id + "/profile",
          }));
        }
      });
  }

  constructor(props) {
    super(props);

    let _this = this;
    this.state = {
      ready: false,
      unapprovedOrders: null,
      currentPage: 1,
      totalpages: 0,
      states: [
        { code: "act", name: "ACT" },
        { code: "nsw", name: "NSW" },
        { code: "nt", name: "NT" },
        { code: "sa", name: "SA" },
        { code: "qld", name: "QLD" },
        { code: "vic", name: "VIC" },
        { code: "wa", name: "WA" },
      ],
    };

    this.filterByGroup = this.filterByGroup.bind(this);
    this.filterByName = this.filterByName.bind(this);
    this.filterByState = this.filterByState.bind(this);
    this.toggleArchived = this.toggleArchived.bind(this);
    this.filter = this.filter.bind(this);
    this.addHome = this.addHome.bind(this);

    if (!isAccess(acc_funeral_homes)) {
      return;
    }
    spinnerService.show("ug");

    axios
      .get(this.props.config.apiUrl + `/${acc_funeral_homes}/`, {})
      .then(function (response) {
        spinnerService.hide("ug");

        if (response && response.data) {
          _this.setState(
            (state) => ({
              groups: response.data.groups,
              homes: response.data.homes,
              filteredHomes: response.data.homes,
              ready: true,
              filterByGroup: "",
              filterByName: "",
              filterByState: "",
              showArchived: false,
              hasArchived: response.data.homes.find((home) => home.archived),
              funeralHomeRedirect: null,
              totalpages: Math.ceil(response.data.homes.length / itemsPerPage),
            }),
            () => _this.filter()
          );
        }
      });

      if (!isAccess(acc_shop_order_unapproved)) {
        return;
      }
    // Get the total number of unapproved orders
    axios
      .get(this.props.config.apiUrl + `/${acc_shop_order_unapproved}`)
      .then((response) => {
        if (response?.data) {
          this.setState({ unapprovedOrders: response.data });
        }
      });
  }
}

export default Home;
