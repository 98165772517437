import React from 'react';

import { NavLink } from "react-router-dom";

import axios from 'axios';

import { spinnerService } from '@chevtek/react-spinners';
import {notify} from 'react-notify-toast';

import Headery  from "../auth/header.jsx";

import Select from 'react-select';
import FileBase64 from 'react-file-base64';
import { isAccess } from '../utilities/constant/navbar.constant.js';
import Navigation from '../dashboard/navigation.jsx';

const states = [
    { "value" : "ACT", "label": "ACT"},
    { "value" : "NSW", "label": "NSW"},
    { "value" : "NT", "label": "NT"},
    { "value" : "QLD", "label": "QLD"},
    { "value" : "SA", "label": "SA"},
    { "value" : "TAS", "label": "TAS"},
    { "value" : "VIC", "label": "VIC"},
    { "value" : "WA", "label": "WA"},
]
class CustomerMailoutAudience extends React.Component {
    render() {
      
        return (
            <React.Fragment>
                <Headery briefTitle="Mailout Audience" title="Mailout Audience" handleScroll={this.handleScroll}></Headery>
                <div className="body-white body-customer-mailouts customer-mailout-audiences" id="main-scroller">
                    <div className="grid-container">
                    <Navigation
                        navigator={[{ label: "Customer Mailouts", path: "/customer-mailouts" },{ label: "Manage Audiences", path: "/customer-mailout-audiences" }]}
                        heading={this.state.post_title || "Create New Audiences"}
                    />
                        <div className="cell small-12 large-12">
                            <NavLink className="button nav-new-mailout" to={`/customer-mailout`}><button>Create New Mailout</button></NavLink>
                        </div>        
                        <div className="cell large-12">
                            <input type="hidden" name="id" value={this.state.id} />
                            <label htmlFor="post_title">Audience Name</label>
                            <input id="post_title" onChange={this.handleName} type="text" value={this.state.post_title} placeholder="Audience Name" name="post_title" />
                            <label htmlFor="audience_state">State</label>
                                    <Select
                                        id="audience_state"
                                        options={this.state.states}
                                        defaultValue='VIC'
                                        value={this.state.state}
                                        onChange={e =>{
                                            
                                            this.setState({
                                                state: {'label': e.label, 'value': e.value}
                                            });
                                        }}
                                        name="state" />
                            <label>Audience members</label>
                            <table cellSpacing="0" className="audience">
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.members && this.state.members.map(function(member, index){
                                            return (
                                                <tr key={index}>
                                                    <td>{member.email_address}</td>
                                                    <td>{member.merge_fields.FNAME}</td>
                                                    <td>{member.merge_fields.LNAME}</td>
                                                    <td><i onClick={() => this.removeMember(member.email_address)} className="fa fa-times"></i></td>
                                                </tr>
                                            );
                                            
                                        }, this)}
                                </tbody>

                            </table>
                            <label>Add single recipient to list</label>
                            <div className="field-group">
                                <input id="single_email" type="text" placeholder="Email" name="email"  onChange={this.handleSingle} />
                                <input id="single_first_name" type="text" placeholder="First name" name="first_name"  onChange={this.handleSingle} />
                                <input id="single_last_name" type="text" placeholder="Last name" name="last_name"  onChange={this.handleSingle} />
                                <input className="btn-save-mailout" type="submit" value="Add recipient" onClick={this.handleSubmit} />
                            </div>
                            <br />
                            <label>Select from existing members</label>
                            <Select
                                
                                options={this.state.list_members}
                                //onInputChange={this.handleInputChange}
                                onChange={this.selectMember}
                            />
                            
                            <br />
                            <label>Bulk upload recipients</label>
                            <FileBase64
                                multiple={ false }
                                onDone={ (file) => { 
                                    this.setState({
                                        csv: file
                                    }) }} />
                            
                            
                            <p>
                                <input className="btn-save-mailout" type="submit" value={this.state.submitLabel} onClick={this.handleSubmit} />
                            </p>
                        </div>
                       
                    </div>
                </div>
            </React.Fragment>
        )
    }

    constructor(props) {
        super(props)
        let audience_id = this.props.match.params.id;
        
        this.state = {
            id: 0,
            members: [],
            customer_emails: [],
            post_title: "",
            csv: null,
            single_recipient: {},
            states: states,
            state: {'label':'VIC','value': 'VIC'}
        }

        var self = this;
        this.handleChange = this.handleChange.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleSingle = this.handleSingle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.removeMember = this.removeMember.bind(this);
        this.selectMember = this.selectMember.bind(this);
        
        if(audience_id){
            if (!isAccess('acc/v1/customer-mailouts/audience/')) {
              return;
            }
            
            spinnerService.show('ug');
            axios.get(this.props.config.apiUrl + '/acc/v1/customer-mailouts/audience/' + audience_id , {     
     
            }).then((response) => {

                if(response.data){
                    
                    this.setState({
                        id: audience_id,
                        members: response.data.mc.members,
                        post_title: response.data.mc.tag.name,
                        state: {'label': response.data.state, 'value': response.data.state}

                    });

                    axios.get(this.props.config.apiUrl + '/acc/v1/customer-mailouts/whole-list' , {     
     
                    }).then((response) => {
                        spinnerService.hide('ug');
                        if(response.data){
                            var emails = response.data.select;
                            for(var i=0; i < self.state.members.length; i++){
                                // eslint-disable-next-line no-loop-func
                                emails = emails.filter((emp) => {
                                    if (emp.value === self.state.members[i].email_address) {
                                        return false;
                                    }
                                    return true;
                                });
                            }
                            
                            this.setState({
                                list_members: emails,
                                mc_raw_members: response.data.members
                            })
                        }
                    });
                    
                }
            });

        }else {
            if (!isAccess('acc/v1/customer-mailouts/whole-list')) {
                return;
              }
              spinnerService.show('ug');
            axios.get(this.props.config.apiUrl + '/acc/v1/customer-mailouts/whole-list' , {     
     
            }).then((response) => {
                spinnerService.hide('ug');
                if(response.data){
                    var emails = response.data.select;
                    for(var i=0; i < self.state.members.length; i++){
                        // eslint-disable-next-line no-loop-func
                        emails = emails.filter((emp) => {
                            if (emp.value === self.state.members[i].email_address) {
                                return false;
                            }
                            return true;
                        });
                    }
                    
                    this.setState({
                        list_members: emails,
                        mc_raw_members: response.data.members
                    })
                }
            });
        }        
    }

    selectMember(e){
        var emails = this.state.list_members.filter(function(emp) {
            if (emp.value === e.value) {
                return false;
            }
            return true;
        });

        for(var i=0;i< this.state.mc_raw_members.length;i++){
            if( this.state.mc_raw_members[i].email_address === e.value){
                var members = this.state.members.slice();
                members.push(this.state.mc_raw_members[i]);

                this.setState({
                    members: members
                });
                
            }
        }

        this.setState({
            list_members: emails
        });
    }

    handleName(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSingle(e){
        let single_recipient = this.state.single_recipient
        single_recipient[e.target.name] = e.target.value
        this.setState({
            single_recipient
        })
    }

    handleInputChange(e){
        console.log('asyncChange', e);
    }

    handleChange(e){
        
        this.setState(state => {
            const members = [...state.members, {email: e.value, name: e.label}]
            return {
                members,
            }
        });
    }

    removeMember(email){

        this.setState({
            members: this.state.members.filter((member) => member.email_address !== email)
          });

    }

    loadOptions = (input, callback) => {
        callback(this.getListMembers);
    }

    handleSubmit(){
        var self = this;
        if (!isAccess('acc/v1/customer-mailouts/audiences')) {
            return;
          }
        spinnerService.show('ug');
        axios.post(this.props.config.apiUrl + '/acc/v1/customer-mailouts/audiences', {
            id: this.state.id,
            post_title: this.state.post_title,
            audience: this.state.members,
            csv: this.state.csv,
            single_recipient: this.state.single_recipient,
            state: this.state.state.value

        }).then(function (response) {

            if(response.data.status === 400){
                spinnerService.hide('ug');
                notify.show(response.data.detail.replace('tag', 'Audience') , 'error', 3000);
                return;
            }

            self.setState({
                id: response.data.audience.tag_id,
                members: response.data.audience.members,
                mc_response: response.data.mc_responses,
                csv: null
            });

            document.getElementById('single_email').value = "";
            document.getElementById('single_first_name').value = "";
            document.getElementById('single_last_name').value = "";

            spinnerService.hide('ug');
            notify.show('Updated list: ' + self.state.post_title , 'success', 3000);
        })
    }


    
   
}

export default CustomerMailoutAudience;
