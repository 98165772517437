import React from 'react';

import axios from 'axios';

import { spinnerService } from '@chevtek/react-spinners';
import {notify} from 'react-notify-toast';

import Headery  from "../auth/header.jsx";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Select from 'react-select'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DatePicker from "react-datepicker";

import { convertToRaw, ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import editorStyles from './editorStyles.css';
import { isAccess } from '../utilities/constant/navbar.constant.js';
import Navigation from '../dashboard/navigation.jsx';


const getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
    // TODO: catch an error
    reader.onerror = error => {};
  };
  
  const imageUploadCallback = file => new Promise(
    (resolve, reject) => getFileBase64(
      file,
      data => resolve({ data: { link: data } })
    )
  );
 
const times = [
    {"value": '9:00:00 am', "label": '9:00 am'},
    {"value": '10:00:00 am', label: '10:00 am'},
    {"value": '11:00:00 am', label: '11:00 am'},
    {"value": '12:00:00 pm', "label": '12:00 pm'},
    {"value": '1:00:00 pm', "label": '1:00 pm'},
    {"value": '2:00:00 pm', "label": '2:00 pm'},
    {"value": '3:00:00 pm', "label": '3:00 pm'},
    {"value": '4:00:00 pm', "label": '4:00 pm'},
    {"value": '5:00:00 pm', "label": '5:00 pm'}
]

const days = [
    { "value": "monday", "label": "monday" },
    { "value": "tuesday", "label": "tuesday" },
    { "value": "wednesday", "label": "wednesday" },
    { "value": "thursday", "label": "thursday" },
    { "value": "friday", "label": "friday" },
]

const frequency = [
    { "value": "once", "label": "once"},
    { "value": "daily", "label": "daily"},
    { "value": "weekly", "label": "weekly"},
    { "value": "fortnightly", "label": "fortnightly"},
    { "value": "monthly", "label": "monthly"}
]

class CustomerMailout extends React.Component {

    render(){

        return(
            
            <React.Fragment>
                <Headery briefTitle="New Customer Mailout" title="New Customer Mailout" handleScroll={this.handleScroll}></Headery>
                <div className="body-white body-customer-mailouts customer-mailouts-list" id="main-scroller">
                    <div className="grid-container">
                    <Navigation
                        navigator={[{ label: "Customer Mailouts", path: "/customer-mailouts" }]}
                        heading={this.state.post_title}
                    />
                    <Tabs>
                        <TabList>
                            <Tab>Mailout Content</Tab>
                            <Tab>Mailout Performance</Tab>
                        </TabList>
                        <TabPanel>
                    
                        <div className="grid-x">
                            <div className="cell large-12">
                                    <input type="hidden" name="id" value={this.state.id} />
                                    <label htmlFor="enabled">
                                        <input type="checkbox" id="enabled" onChange={(ev) => this.setState({enabled: ev.target.checked})} checked={this.state.enabled} /> 
                                        Enabled
                                    </label>
                                    <label htmlFor="title_text">Title</label>
                                    <input id="title_text" onChange={this.handleChange} type="text" value={this.state.post_title} placeholder="Mailout Name" name="post_title" />
                                    {this.state.missingFields.includes('post_title') && <p className="warning">Please give your mailout a name.</p>}
                                    <label htmlFor="audience_select">Audience</label>
                                    <Select
                                        id="audience_select"
                                        options={this.state.segments}
                                        defaultValue={this.state.audience}
                                        value={this.state.audience}
                                        onChange={e =>{
                                            
                                            this.setState({
                                                audience: {'label': e.label, 'value': e.value}
                                            });
                                        }}
                                        name="audience" />
                                    {this.state.missingFields.includes('audience') && <p className="warning">Please select an audience.</p>}
                                    <label htmlFor="subject_text">Subject</label>
                                    <input id="subject_text" onChange={this.handleChange} type="text" value={this.state.post_subject} placeholder="Email Subject" name="post_subject" />
                                    {this.state.missingFields.includes('post_subject') && <p className="warning">Please give your mailout an email subject.</p>}
                                    <label htmlFor="editor">Email Content</label>
                                    
                                    <div className={editorStyles.editor}>
                                        <Editor
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.onChange}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            toolbar={{
                                                //options: ['image'],
                                                image: {
                                                    uploadEnabled: true,
                                                    urlEnabled: false,
                                                    uploadCallback: imageUploadCallback
                                                }
                                            }}
                                        ></Editor>
                                        
                                    </div>
                                        <label htmlFor="send_now">
                                            <input type="checkbox" id="send_now" name="send-now" 
                                                onChange={e => {
                                                    this.setState({
                                                        send_now: e.target.checked
                                                    })
                                                }}
                                            /> Send now
                                        </label>
                                        { !this.state.send_now && ( <div className="schedule">
                                        <label htmlFor="time_select">Time</label>
                                        <Select
                                        id="time_select"
                                        options={times}
                                        onChange={e =>{
                                            this.setState({
                                                time_of_day: {'label': e.label, 'value': e.value}
                                            });
                                        }}
                                        value={this.state.time_of_day}
                                        name="time_of_day" />
                                    
                                    
                                    <label htmlFor="day_select">Day of week</label>
                                    <Select
                                        id="day_select"
                                        options={days}
                                        onChange={e =>{
                                            this.setState({
                                                day_of_week: {'label': e.label, 'value': e.value}
                                            });
                                        }}
                                        value={this.state.day_of_week}
                                        name="day_of_week" />
                                    
                                    <label htmlFor="send_frequency">Send frequency</label>
                                    <Select
                                        id="send_frequency"
                                        options={frequency}
                                        onChange={e =>{
                                            this.setState({
                                                send_frequency: {'label': e.label, 'value': e.value}
                                            });
                                        }}
                                        value={this.state.send_frequency}
                                        name="send_frequency" />
                                        
                                        <label htmlFor="starting_date">Starting Date</label>
                                        <DatePicker
                                            name="starting_date"
                                            dateFormat="DD/MM/YYYY"
                                            onChange={e =>{
                                                this.setState({
                                                    starting_date: e.format("DD/MM/YYYY")
                                                });
                                                
                                            }}
                                            value={this.state.starting_date}
                                        />
                                        </div> )}
                                    <p>
                                        <input className="btn-save-mailout" type="submit" value={this.state.submitLabel} onClick={this.handleSubmit} />
                                    </p>

                                    {this.state.missingFields.length > 0 && <p className="warning">Please fix form errors.</p>}
                                
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                                            <div className="grid-container stats">
                            <div className="grid-x grid-margin-x grid-padding-x">
                                <div className="cell large-3 top-stats">
                                    <span className="num">0</span>
                                    <span className="label">opened</span>
                                </div>
                                <div className="cell large-3 top-stats">
                                        <span className="num">0</span>
                                        <span className="label">clicked</span>
                                </div>
                                <div className="cell large-3 top-stats">
                                    <span className="num">0</span>
                                        <span className="label">bounced</span>
                                </div>
                                <div className="cell large-3 top-stats">
                                    <span className="num">0</span>
                                    <span className="label">unsubscribed</span>
                                </div>
                                <div className="cell large-12">
                                    <h2>Subscribers with most opens</h2>

                                </div>
                                <div className="cell large-12">
                                    <h2>Top links clicked</h2>
                                    
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    /*readImage = (file, callback) => {

        let reader = new FileReader(); 
        reader.onload = () => callback(reader.result);
    
        (resolve, reject) => getFileBase64(
          file,
          data => resolve({ data: { link: data } })
        )
      );

    handleImage = (file) => {
        
        return axios.post( this.props.config.apiUrl + '/acc/v1/customer-mailouts/upload_image' , {
            'image': file
        }).then((res) => {
            console.log('res', res);
        });
    }*/

    constructor(props) {
        super(props)
        let mailout_id = this.props.match.params.id;
        let date = '';
        this.state = {
            enabled: false,
            send_now: false,
            editorState: EditorState.createEmpty(), 
            id: 0,
            post_title: 'new mailout',
            audience: {'value': '', 'label': 'Select audience'},
            time_of_day: {'label':'9:00 am','value':'9:00 am'},
            day_of_week: {'label':'monday','value':'monday'},
            send_frequency: {'label': 'weekly', 'value': 'weekly'},
            starting_date: date,
            submitLabel: 'Create Mailout',
            post_subject: 'Email Subject',
            missingFields: []
        }
        
        if(mailout_id){
            if (!isAccess('acc/v1/customer-mailouts/audiences')) {
                return;
            }
            spinnerService.show('ug');
            axios.get(this.props.config.apiUrl + '/acc/v1/customer-mailouts/audiences', {    
            
            }).then((response) => {
                spinnerService.hide('ug');

                if(response.data){
                    
                    var segments = []
                    for(var i=0;i < response.data.length; i++){
                        segments.push({"value": response.data[i].id, "label": response.data[i].name})
                    }
                    
                    this.setState((state) => ({ 
                        segments: segments
                    }));

                    axios.get(this.props.config.apiUrl + '/acc/v1/customer-mailouts/mailout/' + mailout_id, {     
     
                    }).then((response) => {
                        spinnerService.hide('ug');
                        var audienceIndex = this.state.segments.map( audience => audience.value ).indexOf(Number(response.data.audience));

                        if(response.data){
                            const content = htmlToDraft(response.data.post_content);
                            const contentState = ContentState.createFromBlockArray(content.contentBlocks)
                            const editorState = EditorState.createWithContent(contentState);
                            this.setState({
                                editorState: editorState, 
                                post_title: response.data.post_title,
                                post_subject: response.data.post_subject,
                                audience: this.state.segments[audienceIndex],
                                id: response.data.ID,
                                submitLabel: "Update Mailout",
                                time_of_day: {'label': response.data.time_of_day, 'value': response.data.time_of_day},
                                day_of_week: {'label': response.data.day_of_week, 'value': response.data.day_of_week},
                                send_frequency: {'label': response.data.send_frequency, 'value': response.data.send_frequency},
                                starting_date: response.data.starting_date,
                                enabled: response.data.enabled
                            });
        
                            
                        }
                    });
                }
            });


            
        }else{
            if (!isAccess('acc/v1/customer-mailouts/audiences')) {
                return;
              }
              spinnerService.show('ug');
            axios.get(this.props.config.apiUrl + '/acc/v1/customer-mailouts/audiences', {    
            
            }).then( response => {
                spinnerService.hide('ug');
                if(response.data){
                    
                    var segments = []
                    for(var i=0;i < response.data.length; i++){
                        segments.push({"value": response.data[i].id, "label": response.data[i].name})
                    }

                    this.setState((state) => ({ 
                        segments: segments
                    }));
                }
            })
        }
    

        

        this.onChange = (editorState) => this.setState({editorState})
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(e){
        let target = e.target;
        this.setState({ [target.name]: target.value })

    }
    

    handleSubmit = () =>{
        var self = this;
        if (this.missingFields()) {
            return;
        }

        var message = "Created mailout: ";
        
        if(this.state.id > 0){
            message = "Updated mailout: ";
            
        }
        if (!isAccess('acc/v1/customer-mailouts')) {
            return;
          }
        spinnerService.show('ug');
        axios.post(this.props.config.apiUrl + '/acc/v1/customer-mailouts', {
            
            id: this.state.id,
            post_title: this.state.post_title,
            post_subject: this.state.post_subject,
            post_content: draftToHtml(convertToRaw( this.state.editorState.getCurrentContent())),
            time_of_day: this.state.time_of_day.value,
            day_of_week: this.state.day_of_week.value,
            send_frequency: this.state.send_frequency.value,
            starting_date: this.state.starting_date,
            audience: this.state.audience.value,
            send_now: this.state.send_now,
            enabled: this.state.enabled
    
        }).then(function (response) {
            
            self.setState({
                id: response.data.ID,
                submitLabel: 'Update Mailout'
            })

            spinnerService.hide('ug');
            notify.show( message + response.data.post_title , 'success', 3000);
            
        })
    }

    missingFields = () => {
        let missingFields = [];
        let requiredFields = ['audience', 'post_title', 'post_subject'];
        
        requiredFields.forEach(field => {
            if (field === 'audience' && ! this.state[field]?.value) {
                missingFields.push(field);
            } else if (! this.state[field]) {
                missingFields.push(field);
            }
        });

        this.setState({ missingFields });

        return missingFields.length;
    }


}

export default CustomerMailout;
