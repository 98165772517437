import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import { notify } from "react-notify-toast";
import moment from 'moment';
import axios from "axios";
import Modal from "../widgets/modal";
import Product from "../shop/components/product";
import ProductTable from "../shop/components/product-table";
import ProductSearch from "../shop/components/product-search";
import ProductCard from "../shop/components/product-card";
import FeesTable from "../shop/components/fees-table";
import AddressForm from "../shop/components/address-form";
import OrderCompletion from "./components/order-completion";
import { acc_shop_products, apiUrl, wc_orders } from "../config/config";
import { useFetch, useQuery } from "../hooks";
import { getMetaData, getMetaDate, getMatches, getNextDeliveryDate } from '../helpers'
import Navigation from "../dashboard/navigation";
import ConfirmFastTrack from "../shop/components/confirm-fast-track";

export default function Order() {
    const query = useQuery();
    let { id } = useParams();
    let history = useHistory();
    let [response] = useFetch(`${apiUrl}/${wc_orders}/${id}?home=${query.get('home')}`, false);
    let [productsResponse] = useFetch(`${apiUrl}/${acc_shop_products}?home=${query.get('home')}`, false);
    const [confirmFastTrack, setConfirmFastTrack] = useState(false);
    const [fullName, setFullName] = useState("");
    const [fastTrack, setFastTrack] = useState(false);
    let [order, setOrder] = useState(null);
    let [editable, setEditable] = useState(null);
    let [deliveryDate, setDeliveryDate] = useState(moment());
    let [selectDate, setSelectDate] = useState(false);
    let [items, setItems] = useState([]);
    let [editItem, setEditItem] = useState(null);
    let [address, setAddress] = useState({});
    let [editAddress, setEditAddress] = useState(false);
    let [notes, setNotes] = useState(null);
    let [meta, setMeta] = useState({ is_order_fast_track: null, order_reference: null, order_freight_id: null });
    
    let [search, setSearch] = useState("");
    let [products, setProducts] = useState([]);
    let [list, setList] = useState([]);

    // Update product search
    useEffect(() => {
        setList(search ? getMatches(products, search) : []);
    }, [search, products]);

    useEffect(() => {
        if (productsResponse) {
            setProducts([...productsResponse.products, ...productsResponse.accessories]);
        }
    }, [productsResponse]);
    
    // Set order data
    useEffect(() => {
        if (response) {
            setOrder(response);
            setItems(response.line_items);
            setEditable(response.status !== 'completed');
            setFastTrack(getMetaData('is_order_fast_track', response.meta_data))
            setFullName(getMetaData('order_fast_track_approved_by', response.meta_data))
            // Viewed orders move to processing
            if (response.status === 'new') {
                axios.post(apiUrl + `/${wc_orders}/${id}`, { status: 'processing' });
            }

            // Set default date for calendar
            const nextDeliveryDate = getNextDeliveryDate(response.delivery_route)

            setDeliveryDate(nextDeliveryDate);
        }
    }, [response, id]);

    // Get all order updates
    const getUpdates = () => {
        // Add updated data
        let updates = {};

        if (Object.values(address).length) {
            updates.billing = address;
            updates.shipping = address;
        }

        if (notes !== null) {
            updates.customer_note = notes;
        }

        // Add updated meta data
        updates.meta_data = [];

        Object.keys(meta).forEach(key => {
            if (meta[key] !== null) updates.meta_data.push({ key, value: meta[key] });
        });

        // Add updated line items
        if (items.length) {
            updates.line_items = items.map(({ id, product_id, quantity, meta_data }) => {
                let item = { quantity, meta_data };

                if (product_id) {
                    item = { ...item, product_id, id }
                } else {
                    item = { ...item, product_id: id } // dont pass id for new items
                }

                return item;
            });
        }

        return updates;
    }

    // Save updates to the order
    const handleSave = () => {
        spinnerService.show("ug");        

        const updates = getUpdates();
        
        // Create new order
        axios.post(`${apiUrl}/${wc_orders}/${order.id}`, updates).then(response => {
            if (response?.data) {
                setOrder(response.data);
                setItems(response.data.line_items);
                
                notify.show("Order updated", "success", 3000);
            } else {
                notify.show("Something went wrong updating the order", "error", 3000);
            }
        }).finally(() => {
            spinnerService.hide("ug");
        });
    };

    // Save and approve the order
    const handleApproval = () => {
        spinnerService.show("ug");

        let updates = getUpdates();

        axios.post(apiUrl + `/${wc_orders}/${order.id}`, {
            ...updates, status: 'approved'
        })
        .then(function (response) {
            if (response?.data) {
                history.push(`/order-approved/${response.data.id}?home=${response.data.funeral_home.funeral_home_name}`);
            } else {
                notify.show("Something went wrong approving the order", "error", 3000);
            }
        })
        .finally(() => {
            spinnerService.hide("ug");
        });
    }

    // Show the date picker or save and complete the order
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!selectDate) {
            setSelectDate(true);
        } else {
            spinnerService.show("ug");

            let updates = getUpdates();

            updates.meta_data.push({ key: "order_delivery_date", value: deliveryDate.format('YYYYMMDD') });

            axios.post(apiUrl + `/${wc_orders}/${order.id}`, {
                ...updates, status: 'completed'
            })
            .then(function (response) {
                if (response?.data) {
                    history.push(`/order-completed/${response.data.id}?home=${response.data.funeral_home.funeral_home_name}`);
                } else {
                    notify.show("Something went wrong completing the order", "error", 3000);
                }
            })
            .finally(() => {
                spinnerService.hide("ug");
            });
        }
    }

    // Update details
    const handleAddress = fields => {
        setAddress(fields);
        setEditAddress(false);
    };

    const handleMeta = ({ target }) => {
        setMeta({ ...meta, [target.name]: target.type === 'checkbox' ? target.checked : target.value });
    };

    // Add or update a product
    const handleProductUpdate = (item, quantity, data) => {
        setEditItem(null);

        let updatedItems = [...items];

        if (!item.product_id && !updatedItems.find(i => i.id === item.id)) {
            updatedItems.push(item);
            notify.show("Product added to order, save below to finalise changes", "info", 3000);
        }

        setItems(updatedItems.map(i => {
            if (i.id !== item.id) return i;

            const options = data?.options ? Object.keys(data.options).map(key => ({ key, value: data.options[key] })) : [];

            return { ...item, quantity, meta_data: options };
        }));
    };

    // Remove a product
    const handleProductRemoval = (item) => {
        setItems(items.map(i => {
            const removeNewItem = !i.product_id && (i.id === item.id);
            const removeOrderItem = i.product_id && (i.product_id === item.product_id);
            
            return removeNewItem || removeOrderItem ? {...i, quantity: 0} : i;
        }));
    };

    // Don't show items with zero qty in table
    const display = items.filter(i => i.quantity);
    
    const orderDeliveryDate = order ? getMetaDate('order_delivery_date', order.meta_data) : null;
    const orderFastTracked = order ? getMetaDate('is_order_fast_track', order.meta_data) : null;
    const fastTrackRequestName = order ? getMetaData('order_fast_track_approved_by', order.meta_data) : null;
    const orderFreightApproved = order ? getMetaDate('order_freight_approved', order.meta_data) : null;

    const handleFastTrack = () => {
        if (fastTrack) {
          setFastTrack(false);
          setMeta({ ...meta, 'is_order_fast_track': false,'order_fast_track_approved_by':"" });
        } else {
          setConfirmFastTrack(true);
        }
      };

    const saveFastTrack = () => {
        setFastTrack(true);
        setMeta({ ...meta, 'is_order_fast_track': true,'order_fast_track_approved_by':fullName });
        setConfirmFastTrack(false);
      };

    return (
        <div className="page-content body-white order">
            
            <div className="grid-container">
            <Navigation
            navigator={[{ label: "Funeral Homes", path: "/funeral-homes" },
          ...(order ? [{ label: `${order?.funeral_home?.funeral_home_name}`, path: `/funeral-home/${order?.funeral_home?.id}` }]:[])]}
            heading={`#${order?.id ? order?.id:''}, ${order?.date_created ? moment(order?.date_created, "YYYY-MM-DD hh:mm:ss").format('DD/MM/YYYY hh:mmA'):''}`}
          />
                {order && (
                    <form id="order-form" onSubmit={handleSubmit}>
                        <div className="order-details grid-x">
                            <div className="order-details-info flex-container flex-wrap small-12 large-8">
                                <span className="order-status capitalize" data-status={order.status}>
                                    <i className="fas fa-square"></i> {order.status}
                                </span>
                                {orderFastTracked ? <span><i className="fas fa-shipping-fast"></i> Fast track requested {fastTrackRequestName && `by ${fastTrackRequestName}`}</span> : ''}
                                {orderFreightApproved ? <span><i className="fas fa-file-signature"></i> Freight costs approved</span> : ''}
                                {orderDeliveryDate ? <span><i className="fas fa-truck"></i> {orderDeliveryDate}</span> : ''}
                            </div>
                            <div className="order-updated small-12 large-4">
                                <em>Last updated on {moment(order.date_modified, "YYYY-MM-DD hh:mm:ss").format('DD/MM/YYYY hh:mmA')} {order?.last_edit_by ? `by ${order.last_edit_by}` : ''}</em>
                            </div>
                        </div>

                        <ProductTable items={display} edit={editable ? setEditItem : null} remove={editable ? handleProductRemoval : null} />
                        <FeesTable items={order.fee_lines} />

                        {editable && <div className="order-product-search">
                            <h2 className="heading-underline">Add products to this order</h2>
                            {products.length ? <> 
                                <ProductSearch search={search} text="Type SAP or product name to search catalogue" handleSearch={value => setSearch(value)} />

                                <div className="grid-x grid-margin-x shop-products-list">
                                    {list.map((item) => <ProductCard item={item} key={item.id} add={setEditItem} />)}
                                    {search.length > 1 && !list.length && <p className="info cell small-12">No matching products</p>}
                                </div>
                            </> : <p className="info">Loading products...</p>}
                        </div>}

                        <div className="grid-x">
                            <div className="order-delivery-details small-12 medium-6">
                                <h2 className="heading-underline">Delivery details</h2>
                                <p className="text-large">
                                    {order?.delivery_route?.name && <span>Deliveries for <span className="bold-text">{order?.delivery_route?.name || ''}</span> are scheduled <span className="bold-text">{order?.delivery_route.frequency["value"] === "monthly" ? order?.delivery_route.date["label"] : order?.delivery_route.day["label"]}, {order?.delivery_route.frequency["label"]}</span>.</span>} Your delivery date will be confirmed via email. If you need all or
                                    part of the order for a specific date, please tick the box below
                                    to fast-track the order.
                                </p>
                                <div className="fancy-checkbox fancy-checkbox--large">
                                    <input type="checkbox" name="is_order_fast_track" id="is_order_fast_track" checked={fastTrack} 
                                    onChange={handleFastTrack} />
                                    <label htmlFor="is_order_fast_track">
                                        <h6>Fast-track this order <i className="shop-review-fast-track-icon fas fa-shipping-fast"></i></h6>
                                        <small>(the funeral home has agreed to additional freight charges)</small>
                                    </label>
                                </div>
                                <label className="order-fast-track">
                                    <span>Freight tracking number</span>
                                    <input type="text" id="order_freight_id" name="order_freight_id" value={meta.order_freight_id ?? getMetaData('order_freight_id', order.meta_data)} placeholder="Tracking number" onChange={handleMeta} />
                                </label>
                                {orderFastTracked && <div className="fancy-checkbox">
                                    <input type="checkbox" name="order_freight_approved" id="order_freight_approved" checked={meta.order_freight_approved ?? getMetaData('order_freight_approved', order.meta_data)} onChange={handleMeta} />
                                    <label htmlFor="order_freight_approved">Freight costs approved</label>
                                </div>}
                            </div>

                            <div className="order-delivery-details shop-review-address small-12 medium-5 medium-offset-1">
                                <h2 className="heading-underline">Delivery address</h2>
                                <p>
                                    {address?.company ?? order.billing.company}<br />
                                    {address?.address_1 ?? order.billing.address_1}<br />
                                    {address?.address_2 ?? order.billing.address_2}<br />
                                    {address?.city ?? order.billing.city} {address?.postcode ?? order.billing.postcode} <span className="shop-review-address-state">{address?.state ?? order.billing.state}</span>
                                </p>
                                {editable && <button type="button" className="button button--ghost" onClick={() => setEditAddress(true)}>Edit delivery address</button>}
                            </div>

                            <div className="order-additional small-12">
                                <h2 className="heading-underline">Additional information</h2>
                                <label>
                                    <span>Customer order reference name or number</span>
                                    <input type="text" id="order_reference" name="order_reference" value={meta.order_reference ?? getMetaData('order_reference', order.meta_data)} placeholder="Order reference" onChange={handleMeta} />
                                </label>
                                <label>
                                    <span>Additional customer notes for the ACC Higgins</span>
                                    <textarea name="notes" id="notes" rows="10" value={notes ?? order.customer_note} placeholder="Additional notes..." onChange={({ target }) => setNotes(target.value)}></textarea>
                                </label>
                            </div>

                            <div className="order-actions small-12 grid-x align-middle">
                                <div className="cell small-12 medium-4 small-order-2 medium-order-1">
                                    <Link to="/orders" className="button--reset"><i className="far fa-chevron-left"></i> Back</Link>
                                </div>
                                <div className="cell small-12 medium-8 small-order-1 medium-order-2 flex-container align-right">
                                    {editable && <button type="button" className="button button--ghost" onClick={handleSave}>Save changes</button>}
                                    {['new', 'processing'].includes(order.status) && <button type="button" className="button" onClick={handleApproval}><i className="fas fa-check"></i> Approve order</button>}
                                    {['approved'].includes(order.status) && <button type="submit" className="button"><i className="far fa-calendar-week"></i> Complete order</button>}
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </div>

            <Modal open={editAddress} classes="modal-address-form">
                <button onClick={() => setEditAddress(false)} className="btn-close"><i className="far fa-times"></i></button>

                {order && <AddressForm save={handleAddress} fields={Object.values(address).length ? address : order.billing} />}
            </Modal>

            <Modal open={Boolean(editItem)} classes="modal-product">
                <button onClick={() => setEditItem(null)} className="btn-close"><i className="far fa-times"></i></button>
                {editItem && <Product item={editItem} submit={handleProductUpdate} />}
            </Modal>

            <Modal open={selectDate} classes="modal-delivery-date" width="380">
                <button onClick={() => setSelectDate(false)} className="btn-close"><i className="far fa-times"></i></button>
                
                <OrderCompletion value={deliveryDate} update={setDeliveryDate} cancel={() => setSelectDate(false)} />
            </Modal>

            <Modal open={confirmFastTrack} classes="modal-confirm-fast-track" width="460">
                <button onClick={() => setConfirmFastTrack(false)} className="btn-close"><i className="far fa-times"></i></button>

                <ConfirmFastTrack  update={() => saveFastTrack()} setFullName={setFullName} cancel={() => setConfirmFastTrack(false)}/>
            </Modal>
        </div>
    );
}
