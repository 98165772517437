import React, { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/auth.jsx";
import img_logo from "../images/portal-logo2.svg";

export default function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { authed, login } = useContext(AuthContext);

  useEffect(() => {
    spinnerService.hide("ug");
  }, []);

  const handleLogin = (e) => {
    login(e, { username, password }, (error) => {
      setError(error);
    });
  };

  if (authed()) return <Redirect to={`/`} />;

  return (
    <div className="wrapper-cell-sign-in">
      <div className="cell cell-sign-in">
        <div>
          <div className="logo">
            <img alt="ACC Portal Logo" src={img_logo} />
          </div>

          <h5>Login</h5>

          <form onSubmit={handleLogin} autoComplete="off">
            <input
              name="username"
              type="text"
              placeholder="Username"
              onChange={({ target }) => setUsername(target.value)}
            />
            <input
              name="password"
              type="password"
              placeholder="Password"
              onChange={({ target }) => setPassword(target.value)}
            />
            <button type="submit" className="">
              Sign In
            </button>

            {error && (
              <div
                className="error error-login"
                dangerouslySetInnerHTML={{ __html: error }}
              ></div>
            )}
          </form>

          <Link to={"/password-reset"}>Forgot Password?</Link>
        </div>
      </div>
    </div>
  );
}
