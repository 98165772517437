import React, { useEffect } from "react";
import Shop from "../shop/shop"
import { useParams } from "react-router-dom";
import { useQuery } from "../hooks";

export default function NewOrder({ setBreadcrumbs }) {
    let { home } = useParams();
    const query = useQuery();
    const name = query.get("name");

    useEffect(() => {
        setBreadcrumbs({
            funeralHomeName: name,
            funeralHomeID: home,
            pageTitle: 'New Order'
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="page-content body-white new-order" id="main-scroller">
            <div className="grid-container">
                <div className="grid-x">
                    <div className="cell cell-component small-12">
                        <Shop path={`/new-order/${home}`} match={`/new-order/:home`} title={`Create new order`} home={home} />
                    </div>
                </div>
            </div>
        </div>
    );
}
