import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../config/config";
import { fetchCache } from "../hooks";

export const AuthContext = React.createContext();

const initailAuth = {
    token: null,
    id: "",
    user_display_name: "",
    user_nice_name: "",
    user_email: "",
    signature: "",
    role: "",
    roles: "",
    options: []
};

export const AuthProvider = (props) => {
    const [ready, setReady] = useState(false);
    const [auth, setAuth] = useState(initailAuth);

    useEffect(() => {
        update();
        setReady(true);
    }, []);

    const authed = () => auth.token;

    const isAdmin = (role) => auth.role === 'administrator' || auth.role === 'acch_sales';
    const isCustomer = (role) => auth.role === 'customer'||auth.role === 'editor'||auth.role === 'author'||auth.role === 'contributor'||auth.role === 'subscriber'||auth.role === 'shop_manager';
    const isManager = (role) => auth.role === 'manager';
    const isProductionStaff = (role) => auth.role === 'acch_production';

    const update = () => {
        let auth = localStorage.getItem("auth");

        if (auth) {
            try {
                auth = JSON.parse(auth);

                axios.defaults.headers.common["Authorization"] = "Bearer " + auth.token;

                document.body.classList.add(auth.role);

                setAuth(auth);
            } catch (e) {
                localStorage.removeItem("auth");

                axios.defaults.headers.common["Authorization"] = "";
            }
        }
    };

    const reset = () => {
        setAuth(initailAuth);

        axios.defaults.headers.common["Authorization"] = "";
    };

    const login = (e, { username, password }, callback) => {
        e.preventDefault();

        reset();

        axios
            .post(apiUrl + "/jwt-auth/v1/token", { username, password }, {
                errorHandle: false,
            })
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("auth", JSON.stringify(response.data));
                    localStorage.setItem("role", response.data.role);

                    update();
                } else {
                    callback("Something went wrong");
                }
            })
            .catch((error) => {
                callback(error?.response?.data?.message ? error.response.data.message : "Somwething went wrong");
            });
    };

    const logout = (e) => {
        if (e) e.preventDefault();

        reset();

        document.body.className = "";
        localStorage.removeItem("auth");
        localStorage.removeItem("role");
        localStorage.removeItem("selectedFuneralHome");
        fetchCache.clear();
    };

    const passwordResetRequest = (login, callback) => {
        axios
            .post(apiUrl + "/acc/v1/auth/password/reset", { login })
            .then(callback)
            .catch(() => {
                callback({ status: "error", message: "Something went wrong" });
            });
    };

    const passwordResetUpdate = (data, callback) => {
        axios
            .post(apiUrl + "/acc/v1/auth/password/update", data)
            .then(callback)
            .catch(() => {
                callback({ status: "error", message: "Something went wrong" });
            });
    };

    return <AuthContext.Provider value={{ ready, auth, authed, isAdmin, isManager, isCustomer,isProductionStaff, login, logout, passwordResetRequest, passwordResetUpdate }}>{props.children}</AuthContext.Provider>;
};
