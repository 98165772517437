import React, { useEffect, useContext } from "react";
import { spinnerService } from "@chevtek/react-spinners";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth.jsx";
import { useFetch } from "../hooks.js";
import {
  acc_contact_profile,
  acc_funeral_home,
  apiUrl,
} from "../config/config.js";
import { FuneralHome } from "../context/funeral-home.jsx";

export default function Dashboard() {
  const { auth, isManager, logout } = useContext(AuthContext);
  const { setSelectedFuneralHome } = useContext(FuneralHome);
  let [response] = useFetch(
    `${apiUrl}/${isManager() ? acc_funeral_home : acc_contact_profile}`
  );

  useEffect(() => {
    spinnerService.hide("ug");
    if (response) {
      if (isManager()) {
        setSelectedFuneralHome(
          response?.funeral_home?.atts?.main_contact?.atts
        );
      } else {
        setSelectedFuneralHome(response);
      }
    }
  }, [response]);

  const dashboardSections = [
    {
      label: "Create Order",
      subLabel: "Begin a new order now",
      icon: "fal fa-plus-circle",
      to: "/customer/new-order",
    },
    {
      label: "Orders",
      subLabel: "View previous and the order status",
      icon: "fal fa-truck",
      to: "/customer/orders",
    },
    {
      label: "Resources",
      subLabel: "View and download ACC Higgins resources",
      icon: "fal fa-books",
      to: "/customer/resources",
    },
    ...(isManager()
      ? [
          {
            label: "Funeral Home",
            subLabel: "Edit your Funeral Home and contact details",
            icon: "fal fa-users",
            to: "/customer/account",
          },
        ]
      : [
          {
            label: "My Details",
            subLabel: "Edit your Funeral Home and user details",
            icon: "fal fa-users",
            to: "/customer/profile",
          },
        ]),
  ];

  return (
    <div className="cell auto funeral-home-body body-white page-content">
      <div className="grid-container dashboard-section">
        <div className="title-section">
          <div className="title-text">
            Hi {auth.user_display_name ? ` ${auth.user_display_name}` : null},
            welcome to your dashboard.
          </div>
          <button
            className="button button--reset logout-button"
            onClick={logout}
          >
            Logout
          </button>
        </div>
        <div className="dashboard-grid">
          <div className="grid-x grid-margin-x">
            {dashboardSections.map((section) => {
              return (
                <Link
                  className="customer-dashboard-tile cell cell-component small-12 medium-6 large-4 dashboard-card"
                  to={section.to}
                >
                  <i className={section.icon}></i>
                  <h2 className="tile-title">{section.label}</h2>
                  <p className="tile-subtitle">{section.subLabel}</p>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
