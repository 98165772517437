import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import { spinnerService } from "@chevtek/react-spinners";
import { isAccess } from "./utilities/constant/navbar.constant";
import { apiUrl } from "./config/config";

// Get query params
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Make some noise
export function useAudioCue() {
  let audio = new AudioContext();
  let oscillator = audio.createOscillator();
  let gainNode = audio.createGain();

  oscillator.type = "sine";
  oscillator.frequency.value = 260;
  oscillator.connect(audio.destination);

  oscillator.connect(gainNode);
  gainNode.connect(audio.destination);
  gainNode.gain.setValueAtTime(0.025, audio.currentTime);

  const audioCue = () => {
    oscillator.start();

    setTimeout(() => oscillator.stop(), 250);
  };

  return [audioCue];
}

// Cache fetch response
export let fetchCache = new Map();

// Note: error catching is handled gloably, see App.js
export function useFetch(url, cache = true, data = {}, method = "GET") {
  const [response, setResponse] = useState(null);

  const handleFetch = (url) => {
    const baseUrl = apiUrl;
    const commonPart = url.substring(baseUrl.length);
    let filteredUrl = commonPart.replace(/^\/+/, "").split("?")[0];

    if (!!Number(filteredUrl.split("/")[filteredUrl.split("/").length - 1])) {
      let removedIdUrl = filteredUrl.split("/");
      removedIdUrl.pop();
      filteredUrl = removedIdUrl.join("/");
    }
    if (!isAccess(filteredUrl)) {
      return;
    }
    spinnerService.show("ug");

    if (cache && fetchCache.has(url)) {
      setResponse(fetchCache.get(url));
      spinnerService.hide("ug");
      setTimeout(() => ReactTooltip.rebuild(), 500);
    }

    axios({ url, method, data })
      .then((response) => {
        if (response) {
          setResponse(response.data);
          fetchCache.set(url, response.data);
        }
      })
      .finally(() => {
        spinnerService.hide("ug");
        setTimeout(() => ReactTooltip.rebuild(), 500);
      });
  };

  const refresh = (newUrl = false) => {
    handleFetch(newUrl || url);
  };

  useEffect(() => {
    handleFetch(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [response, refresh];
}

// Load more data on demand
export function useLoadMore(url, search, status, page = 1, limit = 50) {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalpages, setTotalPages] = useState(null);

  const handleFetch = (currentPage = page) => {
    const baseUrl = apiUrl;
    const commonPart = url.substring(baseUrl.length);
    let filteredUrl = commonPart.replace(/^\/+/, "").split("?")[0];

    if (!!Number(filteredUrl.split("/")[filteredUrl.split("/").length - 1])) {
      let removedIdUrl = filteredUrl.split("/");
      removedIdUrl.pop();
      filteredUrl = removedIdUrl.join("/");
    }
    if (!isAccess(filteredUrl)) {
      return;
    }

    axios({
      url: `${url}?page=${currentPage}&per_page=${limit}${
        search ? `&search=${search}` : ""
      }${status ? `&status=${status}` : ""}`,
      method: "GET",
    })
      .then((response) => {
        setTotalPages(response?.headers?.["x-wp-totalpages"]);
        if (response?.data) {
          setResponse(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => ReactTooltip.rebuild(), 500);
      });
  };

  const more = (currentPage) => {
    setLoading(true);
    handleFetch(currentPage);
  };

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [response, loading, more, totalpages];
}

// Fetch data in the background (every 2mins by default)
export function useBackgroundFetch(url, interval = 120000, limit = 100) {
  let [data, setData] = useState([]);

  // Refresh data every minute
  useEffect(() => {
    let updatedAt = new Date().toISOString();

    const refreshTimeout = setInterval(() => {
      axios
        .get(`${url}?per_page=${limit}&after=${updatedAt}`)
        .then((response) => {
          if (response.data) {
            setData(response.data);
          }

          updatedAt = new Date().toISOString();
        });
    }, interval);

    return () => clearInterval(refreshTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data];
}
