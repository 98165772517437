import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import axios from "axios";
import { notify } from "react-notify-toast";
import { useFetch } from "../hooks";
import { acc_contact_profile, acc_contact_update, apiUrl } from "../config/config";
import { getDiff } from "../helpers";
import { AuthContext } from "../context/auth.jsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import {  isAccess } from "../utilities/constant/navbar.constant";

export default function AccountForm() {
  let [user, setUser] = useState({});
  let [originalUser, setOriginalUser] = useState({});
  let [response] = useFetch(`${apiUrl}/${acc_contact_profile}`);
  const { isCustomer} = useContext(AuthContext);

  useEffect(() => {
    if (response) {
      setUser({ ...response });
      setOriginalUser({ ...response });
    }
  }, [response]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const changes = getDiff(formik.values, originalUser);

    if (!Object.keys(changes).length) {
      notify.show("No changes have been made", "info", 3000);
      return;
    }

    if(!isAccess(acc_contact_update)){
      return
    }

    spinnerService.show("ug");
    axios
      .post(apiUrl + `/${acc_contact_update}`, {
        id: user.ID,
        data: changes,
      })
      .then(function (response) {
        if (response?.data?.status && response.data.status === "success") {
          setOriginalUser({ ...user });
          notify.show("Details successfully updated ", "success", 3000);
        } else {
          notify.show(
            response?.data?.error ?? "Something went wrong",
            "error",
            3000
          );
        }
      })
      .finally(() => {
        spinnerService.hide("ug");
      });
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    user_login: Yup.string()
      .required("Username is required"),
    user_email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone_mobile: Yup.string().matches(/^[0-9]+$/, 'Phone - Mobile must be numeric value.').min(6, 'Phone - Mobile should be min 6 digits.').max(10, 'Phone - Mobile should be max 10 digits.'),
    phone_landline: Yup.string().matches(/^[0-9]+$/, 'Phone - Landline must be numeric value.').max(10, 'Phone - Landline should be max 10 digits.'),
    position: Yup.string(),
    "new-password": Yup.string().min(
      5,
      "Password must be at least 5 characters"
    ),
    "confirm-password": Yup.string().oneOf(
      [Yup.ref("new-password"), null],
      "Password don't match"
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      user_login: user.user_login,
      user_email: user.user_email,
      phone_mobile: user.phone_mobile,
      phone_landline: user.phone_landline,
      position: user.position,
      "new-password": user["new-password"],
      "confirm-password": user["confirm-password"],
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      {Object.values(user).length > 0 && (
        <form onSubmit={handleSubmit} className="form account-form">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="first_name">First Name*</label>
                <input
                  name="first_name"
                  type="text"
                  placeholder="First Name*"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={ formik.errors.first_name ? 'error-input' : ''}
                />
                { formik.errors.first_name && (
                  <span className="error-message">
                    {formik.errors.first_name}
                  </span>
                )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="last_name">Last Name*</label>
                <input
                  name="last_name"
                  type="text"
                  placeholder="Last Name*"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={ formik.errors.last_name ? 'error-input' : ''}
                />
                { formik.errors.last_name && (
                  <span className="error-message">
                    {formik.errors.last_name}
                  </span>
                )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="user_login">Username*</label>
                <input
                  name="user_login"
                  type="text"
                  placeholder="Email*"
                  value={formik.values.user_login}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={ formik.errors.user_login ? 'error-input' : ''}
                />
                { formik.errors.user_login && (
                  <span className="error-message">
                    {formik.errors.user_login}
                  </span>
                )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="user_email">Email*</label>
                <input
                  name="user_email"
                  type="text"
                  placeholder="Email*"
                  value={formik.values.user_email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={ formik.errors.user_email ? 'error-input' : ''}
                />
                { formik.errors.user_email && (
                  <span className="error-message">
                    {formik.errors.user_email}
                  </span>
                )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="phone_mobile">Phone - Mobile</label>
                <input
                  name="phone_mobile"
                  type="text"
                  placeholder="Phone - Mobile"
                  value={formik.values.phone_mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={ formik.errors.phone_mobile ? 'error-input' : ''}
                />
                { formik.errors.phone_mobile && (
                  <span className="error-message">
                    {formik.errors.phone_mobile}
                  </span>
                )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="phone_landline">Phone - Landline</label>
                <input
                  name="phone_landline"
                  type="text"
                  placeholder="Phone - Landline"
                  value={formik.values.phone_landline}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={ formik.errors.phone_landline ? 'error-input' : ''}
                />
                {
                  formik.errors.phone_landline && (
                    <span className="error-message">
                      {formik.errors.phone_landline}
                    </span>
                  )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="position">Position</label>
                <input
                  name="position"
                  type="text"
                  placeholder="Position"
                  value={formik.values.position}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={ formik.errors.position ? 'error-input' : ''}
                />
                { formik.errors.position && (
                  <span className="error-message">
                    {formik.errors.position}
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="account-form-password grid-x grid-margin-x">
            <div className="cell small-12">
              <h5 className="heading-underline">Update password</h5>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="new-password">New Password</label>
                <input
                  name="new-password"
                  type="password"
                  placeholder="Password"
                  value={formik.values["new-password"]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={ formik.errors["new-password"] ? 'error-input' : ''}
                />
                {
                  formik.errors["new-password"] && (
                    <span className="error-message">
                      {formik.errors["new-password"]}
                    </span>
                  )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="confirm-password">Confirm Password</label>
                <input
                  name="confirm-password"
                  type="password"
                  placeholder="Password"
                  value={formik.values["confirm-password"]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={ formik.errors["confirm-password"] ? 'error-input' : ''}
                />
                {
                  formik.errors["confirm-password"] && (
                    <span className="error-message">
                      {formik.errors["confirm-password"]}
                    </span>
                  )}
              </p>
            </div>
          </div>
          <div className="grid-x grid-margin-x align-middle">
            <div className="cell small-6">
              <Link
                to={isCustomer() ? "/customer" : "/"}
                className="button button--reset"
              >
                <i className="far fa-chevron-left"></i> Cancel
              </Link>
            </div>
            <div className="cell small-6 flex-container align-right">
              <button type="submit" disabled={!!Object.values(formik.errors)?.length} className="button no-margin">
                Save Changes
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
