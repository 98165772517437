import React, { useState } from "react";

export default function CatalogueTable({ items = [], edit, remove }) {
    let [editItem, setEditItem] = useState(null);
    let [title, setTitle] = useState(null);

    // Show the edit field
    const handleEdit = (item) => {
        setEditItem(item.id);
    };

    // Show the edit field
    const handleCancel = (item) => {
        setTitle(null);
        setEditItem(null);
    };

    // Clear and send edit updates
    const handleUpdate = (item) => {
        edit(item, title);

        setTitle(null);
        setEditItem(null);
    };

    return (
        <table className="shop-table product-table catalogue-table">
            <thead>
                <tr>
                    <th>Type/Image</th>
                    <th>SAP</th>
                    <th>ACC name</th>
                    <th>Funeral home name</th>
                    <th className="text-center">Remove</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item) => {
                    return (
                        <tr key={item?.key || item.id}>
                            <td className="product-table-thumb">
                                <div className="product-table-media" style={{ backgroundColor: item.category?.color ?? '#eee' }}>
                                    {item.image.medium && <img src={item.image.medium} alt={item.name} className="product-table-image" />}
                                </div>
                            </td>
                            <td className="product-table-sap" data-title="Product SAP">
                                <span>{item.sku}</span>
                            </td>
                            <td className="product-table-title" data-title="ACC name">
                                <strong>{item.name}</strong>
                            </td>
                            <td className="product-table-title catalogue-table-custom-title" data-title="Funeral home name">
                                {editItem === item.id ? <div className="catalogue-table-edit-title">
                                    <input type="text" name="custom_name" defaultValue={item?.custom_name ?? item.name} onChange={({ target }) => setTitle(target.value)} />
                                    <button type="button" className="button button--ghost right-pad" aria-label="Cancel editing title" onClick={handleCancel}>Cancel</button>
                                    <button type="button" className="button" onClick={() => handleUpdate(item)}>Update</button>
                                </div> : <>
                                    <strong>{item?.custom_name || item.name}</strong>  
                                    <button type="button" className="button button--reset" aria-label="Edit product title for funeral home" onClick={() => handleEdit(item)}><i className="fas fa-pencil-alt"></i></button>
                                </>}
                            </td>
                            <td className="product-table-actions text-center">
                                <button type="button" className="button button--reset button-remove" aria-label="Remove product from catalogue" onClick={() => remove(item)}><i className="fas fa-trash-alt"></i></button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
}
