import React from 'react';
import PricePointEntry from './price-point-entry.jsx';
import Outline from './outline.jsx';
import ReactTooltip from 'react-tooltip'

class DiamondView extends React.Component {
    render() {  

      

      let row_count = 1;



      let retailSalesTotal = 0;
      let retailSalesNumber = 0;

      for (let product of this.props.catalogue.atts.catalogue_products){
        let retailPrice = parseInt(product.atts.retail_price);
        if (isNaN(retailPrice)){
          retailPrice = 0;   
        }

        let numberOfSales = parseInt(product.atts.stats_number_of_sales);

        retailSalesTotal += (retailPrice * numberOfSales);
        retailSalesNumber += numberOfSales;
        
        product.atts.pinningIndex = null;

        if (this.props.notes){
          for (let note of this.props.notes){
            if (note.linked_product && note.linked_product.ID === product.post.ID){
              
              product.atts.pinningIndex = note.linked_product_index;
              break;
            }
          }
        }

      }
      //let retailAverage = retailTotal / this.props.catalogue.atts.catalogue_products.length;

      const matching_products = this.props.catalogue.atts.catalogue_products.filter(product => {                      
        let lowerValue =  parseInt(this.props.price_points_new[0].price) || 0;
        return product.atts.retail_price > lowerValue;
      });

      return (        
        
        <div>

          {!this.props.product_cats ? (
            <div>Please create at least one product category first.</div>

          ) : (
            <React.Fragment>
              <div className="diamond-view-header">

                <h2 onClick={() => this.props.onHeadingClick(this.props.catalogue_type) }>{this.props.catalogue_type.charAt(0).toUpperCase() + this.props.catalogue_type.slice(1)} Catalogue</h2>
                {this.props.catalogue_type === 'existing' &&
                  <p className="stat stat-average-retail-price">Retail Sales Average: 
                    {retailSalesNumber > 0 ? (
                      <strong>${(retailSalesTotal / retailSalesNumber).toLocaleString('en-AU', {  maximumFractionDigits: 0 })}</strong>
                    ) : (
                      <strong> Unknown</strong>
                    )}
                  </p>
                }

              </div>
              <div className={`diamond-view ${this.props.catalogue_type}`}> 
                
                <PricePointEntry
                  product_cats={this.props.product_cats}
                  matching_products={matching_products}
                  onPricePointClick={this.props.onPricePointClick}
                />

                {this.props.price_points_new &&
                  <React.Fragment>
            
                    {this.props.price_points_new.map(function(price_point, index){

                      const this_price_point = parseInt(price_point.price);

                      const matching_products = this.props.catalogue.atts.catalogue_products.filter(product => {              
                        
                        let lowerValue = 0;
                        if (this.props.price_points_new[index+1]){
                          
                          lowerValue =  parseInt(this.props.price_points_new[index+1].price) || 0;
                        
                        }

                        return product.atts.retail_price > lowerValue && product.atts.retail_price <= this_price_point;
                      });

                      let ar_cats = [];
                      for (let [, cat] of this.props.product_cats.entries()) {
                          ar_cats.push(cat.id);
                          

                      }

                      //Sort matching_products by category order
                      matching_products.sort(function(a, b){

                          let A = a.atts.category;
                          let B = b.atts.category;

                          if (ar_cats.indexOf(A) > ar_cats.indexOf(B)){
                              return 1
                          } else if (ar_cats.indexOf(A) < ar_cats.indexOf(B)) {
                              return -1;
                          }
                          return 0;

                         

                      });





                      row_count++;


                      return (
                        <PricePointEntry key={index} 
                          product_cats={this.props.product_cats}
                          matching_products={matching_products}
                          onPricePointClick={this.props.onPricePointClick}
                        />
                      );
                    }, this)}
          

                    <Outline
                      row_count={row_count}
                      catalogue_type = {this.props.catalogue_type}
                      price_points_new={this.props.price_points_new}
                    />

                  </React.Fragment>

                }

              </div>
            </React.Fragment>

          )}

          
        </div>
        
      );
    }  


        
     componentDidUpdate() {       
       ReactTooltip.rebuild();
     }
     componentDidMount() {       
       ReactTooltip.rebuild();
     }
   
  }

  export default DiamondView;
