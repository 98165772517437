import React from "react";

export default function ProductSearch({ search, text, handleSearch }) {
    return (
        <div className="shop-products-search">
            <input
                type="search"
                value={search}
                placeholder={text}
                onChange={({ target }) => handleSearch(target.value)}
            />
        </div>
    );
}
