import React from "react";

import axios from "axios";

import { NavLink } from "react-router-dom";

import { spinnerService } from "@chevtek/react-spinners";
import { isAccess } from "../utilities/constant/navbar.constant";

class Mailouts extends React.Component {
  render() {
    return (
      <>
        <div className="funeral-home-filters filters grid-x">
          <div className="medium-12">
            <label htmlFor="search-keywords">Keywords</label>
            <input
              name="search-keywords"
              id="search-keywords"
              onChange={(e) => this.filterByName(e.target.value)}
              type="search"
              placeholder="Enter keywords"
            />
          </div>
        </div>
        <div className="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-3 large-up-4 grid-homes">
          {this.state.filteredMailouts &&
            this.state.filteredMailouts.map(function (mailout, index) {
              return (
                <div key={index} className="cell mailout">
                  <div className="cell-home show" key={index}>
                    <div className="cell-home-inner" key={index}>
                      <NavLink to={`/customer-mailout/${mailout.ID}`}>
                        <div className="cell-home-inner-inner">
                          <div className="title">{mailout.post_title}</div>
                          {mailout.enabled && (
                            <div className="icon">
                              <i className="fal fa-paper-plane"></i>
                            </div>
                          )}
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              );
            }, this)}
        </div>
      </>
    );
  }

  filterByName(search) {
    let mailouts = Object.assign([], this.state.mailouts);
    let filteredMailouts = [];
    let filterText = [];
    if (search) {
      filterText = search.toUpperCase().split(" ");

      for (let item of mailouts) {
        let thisName = item.post_title.toUpperCase();
        if (filterText.length) {
          for (var f = 0; f < filterText.length; f++) {
            if (filterText[f] !== "" && thisName.indexOf(filterText[f]) > -1) {
              filteredMailouts.push(item);
            }
          }
        }
      }
    } else {
      filteredMailouts = mailouts;
    }
    this.setState({ filteredMailouts });
  }

  constructor(props) {
    super(props);

    this.state = {};
    if (!isAccess('acc/v1/customer-mailouts')) {
      return;
    }
    spinnerService.show("ug");
    axios
      .get(this.props.config.apiUrl + "/acc/v1/customer-mailouts", {})
      .then((response) => {
        spinnerService.hide("ug");
        if (response?.data) {
          this.setState((state) => ({
            mailouts: response.data,
            filteredMailouts: response.data,
          }));
        }
      });
  }
}

export default Mailouts;
