import React, { useState, useEffect } from "react";
import moment from 'moment';
import { useLoadMore } from "../hooks";
import { apiUrl } from "../config/config";
import spinImage from "../images/spin.svg";

export default function ChangeLog({ home, name }) {
    let [error, setError] = useState(null);
    let [entries, setEntries] = useState([]);
    let [response, loading, more, done] = useLoadMore(`${apiUrl}/acc/v1/log/${home}`);

    useEffect(() => {
        if (!response) return;

        if (response?.error) {
            setError(response.error);
        } else {
            setEntries(prev => [ ...prev, ...response ]);
        }
    }, [response]);

    return (
        <div className="modal-content-wrapper">
            <h4 className="heading-underline">Change log for {name}</h4>
            <table className="change-log">
                <tbody>
                    {entries.map((item) => (
                        <tr key={item.id}>
                            <td className="change-log-date"><strong>{moment(item.created_on, "YYYY-MM-DD hh:mm:ss").format('DD/MM/YYYY hh:mma')}</strong></td>
                            <td className="change-log-log">{item.event_info}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <p className="flex-container flex-dir-column align-center-middle">
                {loading && <img alt="Loading..." src={spinImage} />}
                {(! error && ! done) && <button type="button" onClick={more} className="button button--ghost" data-hidden={loading}>Load more</button>}
            </p>
            {error && <p className="error">{error}</p>}
        </div>
    );
}
