import React, { useState, useEffect } from "react";
import { spinnerService } from "@chevtek/react-spinners";
import { notify } from "react-notify-toast";
import axios from "axios";
import Email from "./email";
import Modal from "../../widgets/modal";
import { getDiff } from "../../helpers";
import { acc_regions, apiUrl } from "../../config/config";
import { isAccess } from "../../utilities/constant/navbar.constant";

export default function Region({ item, options, refresh, close }) {
  let [edit, setEdit] = useState(null);
  let [region, setRegion] = useState({
    name: "",
    connection: "",
    manager: "",
    email: {
      order_received: "",
      order_updated: "",
      order_approved: "",
      order_complete: "",
    },
  });

  useEffect(() => {
    if (item) {
      setRegion(item);
    }
  }, [item]);

  const handleUpdate = ({ target }) => {
    const value =
      target.name === "manager" ? Number(target.value) : target.value;

    setRegion({ ...region, [target.name]: value });
  };

  // Actually save changes
  const handleSubmit = (e) => {
    e.preventDefault();

    const changes = getDiff(region, item);

    if (!Object.keys(changes).length) {
      notify.show("No changes have been made", "info", 3000);
      return;
    }
    if (!isAccess('acc/v1/regions')) {
      return;
    }
    spinnerService.show("ug");

    axios
      .post(`${apiUrl}/acc/v1/regions/${item.id}`, region)
      .then(function (response) {
        if (response?.data?.status && response.data.status === "success") {
          notify.show("Region saved", "success", 3000);
          refresh();
          setTimeout(() => close(), 500);
        } else {
          notify.show(
            response?.data?.error ?? "Something went wrong",
            "error",
            3000
          );
        }
      })
      .finally(() => {
        spinnerService.hide("ug");
      });
  };

  // Delete the region
  const handleDelete = () => {
        if (!isAccess(acc_regions)) {
          return;
        }
    spinnerService.show("ug");

    axios
      .delete(`${apiUrl}/${acc_regions}/${item.id}`)
      .then(function (response) {
        if (response?.data?.status && response.data.status === "success") {
          notify.show("Region deleted", "success", 3000);
          refresh();
          setTimeout(() => close(), 500);
        } else {
          notify.show(
            response?.data?.error ?? "Something went wrong",
            "error",
            3000
          );
        }
      })
      .finally(() => {
        spinnerService.hide("ug");
      });
  };

  // Set email to edit
  const handleEdit = (key) => {
    setEdit({ key, content: region.email[key] });
  };

  // Update email overrides
  const handleEmailUpdate = (email) => {
    setRegion({
      ...region,
      email: { ...region.email, [email.key]: email.content },
    });

    setEdit(null);
  };

  return (
    <>
      <form
        className="form options-form cell small-12 flex-container flex-dir-column"
        onSubmit={handleSubmit}
      >
        <div className="modal-header">Editing region</div>
        <div className="modal-div">
          <div className="resources-label">Region settings</div>

          <div className="grid-x grid-margin-x align-center">
            <div className="cell small-12 medium-12 large-4">
              <label htmlFor="region_name">Region name</label>
              <input
                type="text"
                name="name"
                id="region_name"
                onChange={handleUpdate}
                placeholder="Region name"
                value={region.name}
                required
              />
            </div>
            <div className="cell small-12 medium-5 large-3">
              <div className="disabled-input">
                <label>SAP connection</label>
                <input
                  name="sku"
                  type="text"
                  placeholder="SAP Code"
                  value={region.connection}
                />
              </div>
            </div>
            <div className="cell small-12 medium-7 large-5">
              <label htmlFor="manager">Region manager</label>
              <select
                name="manager"
                id="manager"
                onChange={handleUpdate}
                value={region.manager}
                required
              >
                <option value="">Select manager</option>
                {options.manager.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-section form-scroll-x">
            <h5 className="heading-underline">Override email settings</h5>

            <table className="options-table">
              <thead>
                <tr>
                  <th>Email name</th>
                  <th>Email Preview</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(region.email).map((key) => (
                  <tr key={key}>
                    <td className="options-table-small capitalize">
                      {key.replace("_", " ")}
                    </td>
                    <td className="options-form-preview">
                      <span>
                        {region.email[key] || (
                          <button
                            type="button"
                            className="button button--reset"
                            onClick={() => handleEdit(key)}
                          >
                            Create email override
                          </button>
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      <button
                        type="button"
                        className="button button--reset"
                        aria-label="Edit email override"
                        data-tip="Edit email override"
                        onClick={() => handleEdit(key)}
                      >
                        <i className="far fa-pencil"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="grid-x grid-margin-x align-middle">
            <div className="cell small-6">
              <button
                type="button"
                className="button button--reset"
                onClick={close}
              >
                <i className="far fa-chevron-left"></i> Cancel
              </button>
            </div>
            <div className="cell small-6 flex-container align-right">
              <button
                type="button"
                className="button button--ghost right-pad"
                onClick={handleDelete}
              >
                <i className="fas fa-trash"></i> Delete region
              </button>
              <button type="submit" className="button">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </form>

      <Modal open={Boolean(edit)} classes="modal-option" width="780">
        <button onClick={() => setEdit(null)} className="btn-close-options">
          <i className="far fa-times"></i>
        </button>
        <Email
          item={edit}
          update={handleEmailUpdate}
          close={() => setEdit(null)}
        />
      </Modal>
    </>
  );
}
