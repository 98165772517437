import React from "react";
import styled from "@react-pdf/styled-components";
import { Page } from "@react-pdf/renderer";
import OutputStyles from "./styles.jsx";

import img_cover from "../../../images/bg-rear.png";
import img_cover_proposed from "../../../images/bg-a3-portrait-proposed.png";
import red_diamond from "../../../images/red.png";
import green_diamond from "../../../images/green.png";

class ExecutiveSummary extends React.Component {
  render() {
    let _this = this;
    let is_existing = this.props.catalogue_type === "existing";

    this.props.products.sort(function (a, b) {
      let x = parseFloat(b.atts.retail_price);
      let y = parseFloat(a.atts.retail_price);

      return x < y ? -1 : 1;
    });

    let totalSales = 0;

    let totalSalesValue = 0;
    let totalMargin2 = 0;

    // Existing catalogue variables
    let totalMOF_existing = 0;
    let averageMOF_existing = 0;
    let highestPrice_existing = 0;
    let lowestPrice_existing = 0;
    let pricePointsNonEmptyCount_existing = 0;
    let retailSalesTotal_existing = 0;
    let retailSalesNumber_existing = 0;
    let row_lengths_existing = [];

    let totalSales_existing = 0;
    let totalSalesValue_existing = 0;
    let totalMargin_existing = 0;
    let averageMOFSales_existing = 0;

    // Proposed catalogue variables
    let totalMOF_proposed = 0;
    let averageMOF_proposed = 0;
    let highestPrice_proposed = 0;
    let lowestPrice_proposed = 0;
    let pricePointsNonEmptyCount_proposed = 0;
    let retailSalesTotal_proposed = 0;
    let retailSalesNumber_proposed = 0;
    let row_lengths_proposed = [];

    let totalSalesValue_proposed = 0;
    let totalSales_proposed = 0;
    let totalMargin_proposed = 0;
    let averageMOFSales_proposed = 0;

    // Loop through existing catalogues
    for (let product of this.props.existingCatalogue.atts.catalogue_products) {
      if(parseInt(product.atts.stats_number_of_sales)){
        
      if (this.props.notes) {
        for (let note of this.props.notes) {
          if (
            note.linked_product &&
            note.linked_product.ID === product.post.ID
          ) {
            product.atts.pinningIndex = note.linked_product_index;
            break;
          }
        }
      }

      totalSales_existing += parseInt(product.atts.stats_number_of_sales);

      let retailPrice_existing = parseInt(product.atts.retail_price);
      let costPrice_existing = parseInt(product.atts.price || product.atts.wholesale_price);
      //retailTotal += retailPrice;
      if (retailPrice_existing > highestPrice_existing) {
        highestPrice_existing = retailPrice_existing;
      }
      if (!lowestPrice_existing || retailPrice_existing < lowestPrice_existing) {
        lowestPrice_existing = retailPrice_existing;
      }

      // Watch this
      let margin_existing =
        parseInt(product.atts.stats_number_of_sales) *
        (retailPrice_existing - costPrice_existing);

      totalMargin_existing += margin_existing;

      totalMOF_existing += retailPrice_existing / costPrice_existing;

      let numberOfSales_existing = parseInt(product.atts.stats_number_of_sales);

      retailSalesTotal_existing +=
        retailPrice_existing * numberOfSales_existing;
      retailSalesNumber_existing += numberOfSales_existing;

      totalSalesValue_existing +=
        parseInt(product.atts.stats_number_of_sales) * retailPrice_existing;
    
      }
    }

    // Loop through proposed catalogues
    for (let product of this.props.catalogue.atts.catalogue_products) {
      if(parseInt(product.atts.stats_number_of_sales)){
        
      if (this.props.notes) {
        for (let note of this.props.notes) {
          if (
            note.linked_product &&
            note.linked_product.ID === product.post.ID
          ) {
            product.atts.pinningIndex = note.linked_product_index;
            break;
          }
        }
      }

      totalSales_proposed += parseInt(product.atts.stats_number_of_sales);

      let retailPrice_proposed = parseInt(product.atts.retail_price);
      let costPrice_proposed = parseInt(product.atts.price || product.atts.wholesale_price);
      //retailTotal += retailPrice;
      if (retailPrice_proposed > highestPrice_proposed) {
        highestPrice_proposed = retailPrice_proposed;
      }
      if (!lowestPrice_proposed || retailPrice_proposed < lowestPrice_proposed) {
        lowestPrice_proposed = retailPrice_proposed;
      }

      totalMargin_proposed += parseInt(product.atts.stats_number_of_sales) *
      (retailPrice_proposed - costPrice_proposed);

      totalMOF_proposed += retailPrice_proposed / costPrice_proposed;
      
      let numberOfSales_proposed = parseInt(product.atts.stats_number_of_sales);
      
      retailSalesTotal_proposed +=
        retailPrice_proposed * numberOfSales_proposed;
      retailSalesNumber_proposed += numberOfSales_proposed;

      totalSalesValue_proposed += parseInt(product.atts.stats_number_of_sales) * retailPrice_proposed;
      
      }
    }

    averageMOF_existing =
      totalMOF_existing /
      this.props.existingCatalogue.atts.catalogue_products.length;

    averageMOF_proposed =
      totalMOF_proposed / this.props.catalogue.atts.catalogue_products.length;

    averageMOFSales_existing =
      totalSalesValue_existing /
      (totalSalesValue_existing - totalMargin_existing);

    let lowerValue = parseInt(this.props.price_points_new[0].price) || 0;
    const matching_products_existing =
      this.props.catalogue.atts.catalogue_products.filter((product) => {
        return product.atts.retail_price > lowerValue;
      });

    const matching_products_proposed =
      this.props.catalogue.atts.catalogue_products.filter((product) => {
        return product.atts.retail_price > lowerValue;
      });

    row_lengths_existing.push(matching_products_existing.length);
    row_lengths_proposed.push(matching_products_proposed.length);

    this.props.price_points_new.map(function (price_point, pricePointIndex) {
      let this_price_point = parseInt(price_point.price);

      // For existing products
      const matching_products_existing =
        _this.props.existingCatalogue.atts.catalogue_products.filter(
          (product) => {
            let lowerValue = 0;
            if (_this.props.price_points_new[pricePointIndex + 1]) {
              lowerValue =
                parseInt(
                  _this.props.price_points_new[pricePointIndex + 1].price
                ) || 0;
            }

            return (
              product.atts.retail_price > lowerValue &&
              product.atts.retail_price <= this_price_point
            );
          }
        );

      // For proposed products
      const matching_products_proposed =
        _this.props.catalogue.atts.catalogue_products.filter((product) => {
          let lowerValue = 0;
          if (_this.props.price_points_new[pricePointIndex + 1]) {
            lowerValue =
              parseInt(
                _this.props.price_points_new[pricePointIndex + 1].price
              ) || 0;
          }

          return (
            product.atts.retail_price > lowerValue &&
            product.atts.retail_price <= this_price_point
          );
        });

      row_lengths_existing.push(matching_products_existing.length);
      row_lengths_proposed.push(matching_products_proposed.length);

      if (matching_products_existing.length > 0) {
        pricePointsNonEmptyCount_existing++;
      }

      if (matching_products_proposed.length > 0) {
        pricePointsNonEmptyCount_proposed++;
      }

      return null;
    });

    const Header = styled.View`
      width: 816px;
      height: 72px;
      margin-top: 14px;
      margin-left: 14px;
      display: flex;
      flex-direction: row;
    `;

    const HeaderLeft = styled.View`
      width: 50%;
      padding-left: 30px;
      text-align: left;
      color: white;
      align-items: flex-start;
      justify-content: center;
    `;

    const HeaderRight = styled.View`
      text-align: right;

      width: 50%;
      padding-right: 30px;

      color: white;

      justify-content: center;
    `;

    const CurrentCatalogueBox = styled.View`
      background-color: #edf0f2;
      padding: 8px;
      width: 816px;
      margin-left: 14px;
    `;

    const CurrentCatalogueBox2 = styled.View`
      background-color: #fff;
      padding: 8px;
      width: 816px;
      margin-left: 14px;
    `;

    const SingleBoxHeading = styled.Text`
      margin-top: 12px;
      margin-left: 16px;
      font-size: 18px;
      font-weight: 400;
      color: #2e3a41;
    `;

    const TopSingleColumnViewHolder = styled.View`
      display: flex;
      flex-direction: row;
      margin-top: 12px;
      flex-wrap: wrap;
    `;

    const BottomSingleColumnViewHolder = styled.View`
      display: flex;
      flex-direction: row;
      margin-top: 12px;
      flex-wrap: wrap;
      background-color: #fff;
    `;

    const SingleColumnView = styled.View`
      display: flex;
      flex-direction: column;
      width: 33%;
    `;

    const RedBox = styled.View`
      margin: 12px 16px;
      background-color: #8a2726;
      color: white;
      padding: 18px 12px;
      width: 200px;
    `;

    const GreenBox = styled.View`
      margin: 12px 16px;
      background-color: #66805c;
      color: white;
      padding: 18px 12px;
      width: 200px;
    `;

    const TotalSalesText = styled.Text`
      font-size: 10px;
    `;

    const TotalSales = styled.Text`
      margin-top: 6px;
      font-size: 18px;
    `;

    const Stats = styled.View`
      font-size: 11px;
      margin-top: 12px;
      margin-left: 16px;
    `;

    const StatsWrap = styled.View`
      margin-bottom: 12px;
      flex-direction: row;
      font-size: 14px;
      align-items: center;
    `;

    const StatsText = styled.Text`
      font-size: 10px;
      padding-top: 3px;
    `;

    const FaIconWrap = styled.View`
      width: 26px;

      margin-right: 5px;

      //align-items:center;
      //justify-content:center;
      text-align: center;
    `;
    const FaIcon = styled.Text`
      font-family: "Font Awesome 5 Pro";
      text-align: center;
      text-align: center;
    `;

    return (
      <Page size="A3" wrap={false}>
        <OutputStyles.ImageFillWrap>
          {is_existing ? (
            <OutputStyles.ImageFill src={img_cover}></OutputStyles.ImageFill>
          ) : (
            <OutputStyles.ImageFill
              src={img_cover_proposed}
            ></OutputStyles.ImageFill>
          )}
        </OutputStyles.ImageFillWrap>

        <Header>
          {is_existing ? (
            <HeaderLeft>
              <OutputStyles.Header1>
                Current Catalogue Diamond
              </OutputStyles.Header1>
              <OutputStyles.Header2>
                Your current inventory list, organised by category{" "}
              </OutputStyles.Header2>
            </HeaderLeft>
          ) : (
            <HeaderLeft>
              <OutputStyles.Header1>Executive Summary </OutputStyles.Header1>
              {/* <OutputStyles.Header2>{ moment(this.props.catalogue.atts.start_date, "YYYY-MM-DD").format('DD/MM/Y') } - {moment(this.props.catalogue.atts.end_date, "YYYY-MM-DD").format('DD/MM/Y') }</OutputStyles.Header2> */}
            </HeaderLeft>
          )}

          <HeaderRight></HeaderRight>
        </Header>

        <CurrentCatalogueBox>
          <SingleBoxHeading>Current Catalogue</SingleBoxHeading>

          <TopSingleColumnViewHolder>
          <SingleColumnView>
              <RedBox>
                <TotalSalesText>Total Unit Sales: </TotalSalesText>
                <TotalSales>
                  $
                  {totalSalesValue_existing.toLocaleString("en-AU", {
                    maximumFractionDigits: 0,
                  })}
                </TotalSales>
              </RedBox>

              <RedBox>
                <TotalSalesText>Retail Sales Average inc GST: </TotalSalesText>
                <TotalSales>
                  $
                  {isNaN(totalSalesValue_existing / totalSales_existing)
                    ? '0'
                    : (totalSalesValue_existing / totalSales_existing).toLocaleString("en-AU", {
                        maximumFractionDigits: 0,
                    })}
                </TotalSales>
              </RedBox>

              <RedBox>
                <TotalSalesText>Average Unit Margin inc GST: </TotalSalesText>
                <TotalSales>
                  $
                  {isNaN(totalMargin_existing / totalSales_existing)
                    ? '0'
                    : (totalMargin_existing / totalSales_existing).toLocaleString(
                      "en-AU",
                      {
                        maximumFractionDigits: 0,
                      }
                    )}
                </TotalSales>
              </RedBox>
            </SingleColumnView>

            <SingleColumnView>
              <Stats style={{ color: "#8a2726" }}>
                <StatsWrap>
                  <FaIconWrap>
                    <FaIcon></FaIcon>
                  </FaIconWrap>
                  <StatsText>
                    {
                      this.props.existingCatalogue.atts.catalogue_products
                        .length
                    }{" "}
                    Units
                  </StatsText>
                </StatsWrap>
                <StatsWrap>
                  <FaIconWrap>
                    <FaIcon></FaIcon>
                  </FaIconWrap>
                  <StatsText>
                    {pricePointsNonEmptyCount_existing} Price Points
                  </StatsText>
                </StatsWrap>
                <StatsWrap>
                  <FaIconWrap>
                    <FaIcon>|</FaIcon>
                  </FaIconWrap>
                  <StatsText>
                    Highest Price $
                    {highestPrice_existing.toLocaleString("en-AU", {
                      maximumFractionDigits: 0,
                    })}
                  </StatsText>
                </StatsWrap>
                <StatsWrap>
                  <FaIconWrap>
                    <FaIcon>|</FaIcon>
                  </FaIconWrap>
                  <StatsText>
                    Lowest Price $
                    {lowestPrice_existing.toLocaleString("en-AU", {
                      maximumFractionDigits: 0,
                    })}
                  </StatsText>
                </StatsWrap>

                {retailSalesNumber_existing > 0 && (
                  <StatsWrap>
                    <FaIconWrap>
                      <FaIcon></FaIcon>
                    </FaIconWrap>
                    <StatsText>
                      $
                      {isNaN(retailSalesTotal_existing / retailSalesNumber_existing)
                    ? '0'
                    : (
                      retailSalesTotal_existing / retailSalesNumber_existing
                    ).toLocaleString("en-AU", {
                      maximumFractionDigits: 0,
                    })}{" "}
                      Retail Sales Average
                    </StatsText>
                  </StatsWrap>
                )}

                <StatsWrap>
                  <FaIconWrap>
                    <FaIcon></FaIcon>
                  </FaIconWrap>
                  <StatsText>
                    {isNaN(averageMOF_existing) ? '0' : averageMOF_existing.toFixed(2)} Average MOF on pricing
                  </StatsText>
                </StatsWrap>
              </Stats>
            </SingleColumnView>

            <SingleColumnView>
              <OutputStyles.ImageFill
                src={red_diamond}
                style={{ width: 160 }}
              ></OutputStyles.ImageFill>
            </SingleColumnView>
          </TopSingleColumnViewHolder>
        </CurrentCatalogueBox>

        <CurrentCatalogueBox2>
          <SingleBoxHeading>ACC Higgins Proposed Ranges</SingleBoxHeading>
          <BottomSingleColumnViewHolder>
          <SingleColumnView>
              <GreenBox>
                <TotalSalesText>Total Unit Sales: </TotalSalesText>
                <TotalSales>
                $
                  {isNaN(totalSalesValue_proposed) ? '0' : totalSalesValue_proposed.toLocaleString("en-AU", {
                    maximumFractionDigits: 0,
                  })}
                </TotalSales>
              </GreenBox>

              <GreenBox>
                <TotalSalesText>Retail Sales Average inc GST: </TotalSalesText>
                <TotalSales>               $
                  {isNaN(totalSalesValue_proposed / totalSales_proposed) ? '0' : (
                    totalSalesValue_proposed / totalSales_proposed
                  ).toLocaleString("en-AU", {
                    maximumFractionDigits: 0,
                  })}</TotalSales>
              </GreenBox>

              <GreenBox>
                <TotalSalesText>Average Unit Margin inc GST: </TotalSalesText>
                <TotalSales>                  $
                  {isNaN(totalMargin_proposed / totalSales_proposed) ? '0' : (totalMargin_proposed / totalSales_proposed).toLocaleString(
                    "en-AU",
                    {
                      maximumFractionDigits: 0,
                    }
                  )}</TotalSales>
              </GreenBox>
            </SingleColumnView>

            <SingleColumnView
              style={{ backgroundColor: "#fff", marginTop: 20 }}
            >
              <Stats style={{ color: "#66805c" }}>
                <StatsWrap>
                  <FaIconWrap>
                    <FaIcon></FaIcon>
                  </FaIconWrap>
                  <StatsText>
                    {this.props.catalogue.atts.catalogue_products.length} Units
                  </StatsText>
                </StatsWrap>
                <StatsWrap>
                  <FaIconWrap>
                    <FaIcon></FaIcon>
                  </FaIconWrap>
                  <StatsText>
                    {pricePointsNonEmptyCount_proposed} Price Points
                  </StatsText>
                </StatsWrap>
                <StatsWrap>
                  <FaIconWrap>
                    <FaIcon>|</FaIcon>
                  </FaIconWrap>
                  <StatsText>
                    Highest Price $
                    {highestPrice_proposed.toLocaleString("en-AU", {
                      maximumFractionDigits: 0,
                    })}
                  </StatsText>
                </StatsWrap>
                <StatsWrap>
                  <FaIconWrap>
                    <FaIcon>|</FaIcon>
                  </FaIconWrap>
                  <StatsText>
                    Lowest Price $
                    {lowestPrice_proposed.toLocaleString("en-AU", {
                      maximumFractionDigits: 0,
                    })}
                  </StatsText>
                </StatsWrap>

                {retailSalesNumber_proposed > 0 && (
                  <StatsWrap>
                    <FaIconWrap>
                      <FaIcon></FaIcon>
                    </FaIconWrap>
                    <StatsText>
                      $
                      {(
                        retailSalesTotal_proposed / retailSalesNumber_proposed
                      ).toLocaleString("en-AU", {
                        maximumFractionDigits: 0,
                      })}{" "}
                      Retail Sales Average
                    </StatsText>
                  </StatsWrap>
                )}

                <StatsWrap>
                  <FaIconWrap>
                    <FaIcon></FaIcon>
                  </FaIconWrap>
                  <StatsText>
                    {isNaN(averageMOF_proposed) ? '0' : averageMOF_proposed.toFixed(2)} Average MOF on pricing
                  </StatsText>
                </StatsWrap>
              </Stats>
            </SingleColumnView>

            <SingleColumnView>
              <OutputStyles.ImageFill
                src={green_diamond}
                style={{ width: 160 }}
              ></OutputStyles.ImageFill>
            </SingleColumnView>
          </BottomSingleColumnViewHolder>
        </CurrentCatalogueBox2>
      </Page>
    );
  }
}
export default ExecutiveSummary;
