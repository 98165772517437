import { spinnerService } from "@chevtek/react-spinners";
import Axios from "axios";
import Select from "react-select";
import React, { useContext,  useEffect, useState } from "react";
import { acc_funeral_homes, apiUrl } from "../../config/config";
import { useHistory } from "react-router-dom";
import { FuneralHome } from "../../context/funeral-home";
import { isAccess } from "../../utilities/constant/navbar.constant";

export default function CreatenewOrder({ cancel }) {
  const { setSelectedFuneralHome: setFuneralHome } = useContext(FuneralHome);
  // State
  const [funeralHomes, setFuneralHomes] = useState([]);
  const [selectedFuneralHome, setSelectedFuneralHome] = useState(null);
  const navigate = useHistory();

  const fetchFuneralHome = () => {
    if (!isAccess(acc_funeral_homes)) {
      return;
    }
    spinnerService.show("ug");
    Axios.get(apiUrl + `/${acc_funeral_homes}/`, {}).then(function (response) {
      spinnerService.hide("ug");
      if (response && response.data) {
        setFuneralHomes(response.data.homes);
      }
    });
  };

  useEffect(() => fetchFuneralHome(), []);

  return (
    <>
      <h3 className="modal-create-new-order-title">Create new order</h3>
      <div className="select-funeral-home-dropdown">
        <label className="dropdown-title">
          Select a funeral home to create an order for
        </label>
        <Select
          name="filter-state"
          id="filter-state"
          placeholder="Type and Select"
          value={selectedFuneralHome}
          onChange={(target) => setSelectedFuneralHome(target)}
          options={funeralHomes.map((home) => ({
            value: home.ID,
            label: `${home.funeral_home_name}`,
          }))}
        />
      </div>
      <div className="grid-x grid-margin-x">
        <div className="cell small-6">
          <button
            type="submit"
            className="button button--ghost"
            onClick={cancel}
          >
            Cancel
          </button>
        </div>
        <div className="cell small-6 text-right">
          <button
            type="submit"
            className="button"
            onClick={() => {
              setFuneralHome(
                funeralHomes.filter(
                  (home) => home.ID === selectedFuneralHome.value
                )[0]?.atts?.main_contact?.atts
              );
              navigate.push(
                `/new-order/${selectedFuneralHome.value}?name=${selectedFuneralHome.label}`
              );
            }}
          >
            Create order
          </button>
        </div>
      </div>
    </>
  );
}
