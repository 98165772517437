import React from "react";
import { NavLink } from "react-router-dom";

export default function FuneralHomeList(props) {
  return (
    <div
      className={`funeral-home-list-view ${props.home.show ? "show" : "hide"}`}
    >
      <NavLink to={`/funeral-home/${props.home.ID}`} className="flex" exact>
        <div className="grid-x cell-home-inner-inner">
          <div className="funeral-home-list-view-name medium-6">
            {props.home.funeral_home_name}
          </div>
          <div className="funeral-home-list-view-contact-name medium-4">
            <span dangerouslySetInnerHTML={{ __html: (props?.home?.atts?.main_contact?.atts?.first_name || '') + " " + (props?.home?.atts?.main_contact?.atts?.last_name || '') }} />
          </div>
          <div className="funeral-home-list-view-group-name medium-2">
            {props?.home?.group?.name ? `(${props?.home?.group?.name})` : ""}
          </div>
          {props.home.archived && <div className="status">Archived</div>}
        </div>
        <i className="far fa-pencil" style={{ color: "#B89653" }}></i>
      </NavLink>
    </div>
  );
}
