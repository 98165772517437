import React from 'react';

import PricePoints from './price-points.jsx';
import DiamondView from './diamond-view.jsx';
import Headery  from "../../auth/header.jsx";
import Navigation from '../../dashboard/navigation.jsx';

class DiamondEditor extends React.Component {
    render() {  
        
      return (
        <div className="holder-prop-catalogue holder-diamond-editor" id="main-scroller">
            <Headery briefTitle='Define Price Points' title={`Define Price Points | Proposal ${this.props.catalogues.existing.atts.proposal.ID}`} handleScroll={this.handleScroll}></Headery>

            <div className="grid-x">

            <Navigation
            navigator={[{ label: "Funeral Homes", path: "/funeral-homes" },
          { label: `${this.props.funeralHome.atts.funeral_home_name}`, path: `/funeral-home/${this.props.funeralHome.ID}` }
        ]}
            heading={`${this.props.proposal.ID}`}
            headingClassName={`navigator-heading-font-size`}
          />
                {!this.props.product_cats  ? (
                    <div>Please create at least one product first.</div>

                ) : (                
                    <React.Fragment>

                        <div className="cell cell-side-panel cell-catalogue-side-panel">
                            {/* <DiamondConfig 
                                number_price_points={this.props.proposal_atts.number_price_points}
                                price_points={this.props.proposal_atts.price_points}
                                onChangeValue={this.props.onChangeConfig}
                            /> */}
                            <PricePoints
                                number_price_points={this.props.proposal_atts.number_price_points}                        
                                price_points_new={this.props.proposal_atts.price_points_new}
                                onChangePricePoint={this.props.onChangePricePoint}
                                onChangePricePointShapeEdge={this.props.onChangePricePointShapeEdge}
                                onAddPricePointBefore={this.props.onAddPricePointBefore}
                                onDeletePricePoint={this.props.onDeletePricePoint}
                            />          


                        </div>
                        <div className="cell auto cell-main-panel cell-diamond-view ">
                            <div className="grid-x grid-margin-x">
                                <div className="cell small-12 large-6">            

                                
                                    {this.props.catalogues.existing &&
                                        <DiamondView
                                            catalogue_type='existing'
                                            price_points_new={this.props.proposal_atts.price_points_new}
                                            catalogue={this.props.catalogues.existing}
                                            product_cats={this.props.product_cats}
                                        />
                                    }

                                </div>
                                <div className="cell small-12 large-6">                            
                                    {this.props.catalogues.proposed &&
                                        <DiamondView
                                            catalogue_type='proposed'
                                            price_points_new={this.props.proposal_atts.price_points_new}
                                            catalogue={this.props.catalogues.proposed}
                                            product_cats={this.props.product_cats}
                                        />
                                    }
                                </div>
                            </div>
                            
                        


                        
                        </div>
                    </React.Fragment>
                )}

            </div>
        </div>
      );
    }  

  

   
  }

  export default DiamondEditor;
