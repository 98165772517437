import React from 'react';
import { NavLink   } from "react-router-dom";
import axios from "axios";
import { notify } from 'react-notify-toast';
import { spinnerService } from '@chevtek/react-spinners';
import Modal from '../widgets/modal';
import ApproveProposal from './components/approve-proposal';
import { AuthContext } from '../context/auth';
import { isAccess } from '../utilities/constant/navbar.constant';
class ProposalTile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      archiving: false,
      openModal: false,
    }; 
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event){      
      event.preventDefault();
      event.stopPropagation();

      if (!isAccess('acc/v1/proposal/archive')) {
        return;
      }

      if (!window.confirm('Are you sure you wish to archive this proposal? It will no longer appear against this funeral home.')){
        return;
      }
      this.setState({
        archiving: true
      });
      
      let _this = this;
      
      axios.post(this.props.config.apiUrl + '/acc/v1/proposal/archive', {
        id: this.props.prop.ID,        
      }).then(function (response) {
        window.location.href = `/funeral-home/${_this.props.prop.funeral_home_id}`
        notify.show('Proposal archived', 'success', 3000);
        _this.setState((state) => ({ 
          show: false,
          archiving: false
        })); 
  
      });

      return false;
    }

  handleApprove(checked){  
    if (!isAccess('acc/v1/proposal/approve')) {
      return;
    }
      spinnerService.show('ug');
      axios.post(this.props.config.apiUrl + '/acc/v1/proposal/approve', {
        id: this.props.prop.ID,
        funeral_home_id: this.props.prop.funeral_home_id,
      }).then( response => {
        notify.show(`Proposal ${checked ? 'approved' : 'unapproved'}`, 'success', 3000);
        window.location.href = `/funeral-home/${this.props.prop.funeral_home_id}`
        this.setState({
          openModal: false,
        });        
        spinnerService.hide('ug');
      });

    
  }

  render() {
      let post_date = this.props.prop.post_date_gmt.replace(/-/g,'/');
      let date = new Date(post_date + ' GMT');

      return (
        <>
        {!this.props.listView ? (
        <div className={`cell cell-proposal cell-home ${(this.props.prop.show) ? 'show' : 'hide'} ${(this.props.prop.archived) ? 'is-archived' : ''} ${(this.state.archiving) ? 'archiving' : ''}`} >
            <NavLink to={`/proposals/${this.props.prop.ID}`} exact>
                <div className="cell cell-proposal-inner cell-home-inner">
                    <div className="cell-home-inner-inner">
                        
                        <p><i className="far fa-file-alt"></i></p>
                        <div className="title">PROPOSAL {this.props.prop.ID}</div>
                        <p className="created">Created {this.props.prop.date}</p>
                        <div className="status">{this.props.prop.status}</div>
                        
                    </div>
                </div>

            </NavLink>
        </div>
        ) : (
          <tr className={`${(this.props.prop.show) ? 'show' : 'hide'} ${(this.props.prop.archived) ? 'is-archived' : ''} ${(this.state.archiving) ? ' archiving' : ''} ${
            (this.props.prop?.approved) ? 'approved-row' : ''
          }`}>
            <td><NavLink to={`/proposals/${this.props.prop.ID}`} exact><i className="far fa-file-alt"></i></NavLink></td>
            <td className="title"><NavLink to={`/proposals/${this.props.prop.ID}`} exact>PROPOSAL {this.props.prop.ID}</NavLink></td>
            <td><NavLink to={`/proposals/${this.props.prop.ID}`} exact>{this.props.prop.date}</NavLink></td>
            <td><NavLink to={`/proposals/${this.props.prop.ID}`} exact>{this.props.prop.author && ( this.props.prop.author.data.display_name)}</NavLink></td>
            <td><NavLink to={`/proposals/${this.props.prop.ID}`} exact>{date.toLocaleString('en-AU', { timeZone: 'Australia/Melbourne' })}</NavLink></td>
            <td className="centre" valign="center"><input type="checkbox" name="approved"    onClick={() => this.setState({openModal:true})} checked={this.props.prop.approved} disabled={this.props.prop.approved || !this.context.isAdmin()}/></td>
            <td className={`${(this.state.archiving) ? ' archiving' : ''}`}>{ !this.props.prop.archived && <NavLink to={`/proposals/${this.props.prop.ID}`} exact><button className="dataset-archive" onClick={this.handleSubmit}><i className="fal fa-archive"></i></button></NavLink> }</td>
          </tr>
        )}

        
      <Modal
        open={this.state.openModal}
        classes="modal-confirm-fast-track"
        width="460"
      >
        <button
          onClick={() => this.setState({openModal:false})}
          className="btn-close"
        >
          <i className="far fa-times"></i>
        </button>

        <ApproveProposal
        approve={()=> this.handleApprove(!this.props.prop.approved)}
          cancel={() => this.setState({openModal:false})}
        />
      </Modal>
        </>
        
      );
    }  
  }

  ProposalTile.contextType = AuthContext;
  export default ProposalTile;
