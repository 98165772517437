import React from "react";
import { Page, View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import OutputStyles from "./styles.jsx";

import img_bg_grey from "../../../images/bg-frame-grey.png";
import img_bg_blue from "../../../images/bg-frame-blue.png";
import { decodeHTML } from "../../../utilities/constant/navbar.constant.js";

const styles = StyleSheet.create({
  catalogueTable: {
    width: "100%",
    color: "black",
    paddingTop: 14,
    paddingbottom: 14,
    paddingLeft: 26,
    paddingRight: 26,
  },
  catalogueSection: {
    marginBottom: 12,
  },

  catalogueRow: {
    display: "flex",
    flexDirection: "row",
  },
  catalogueColumn: {
    display: "flex",
    flexShrink: 0,
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 10,
    flexWrap: "wrap",
  },
  catalogueColumnNumber: {
    display: "flex",
    flexShrink: 0,
    flexGrow: 0,
    width: 70,
    textAlign: "right",
    paddingLeft: 10,
    paddingRight: 10,
  },
  catalogueEntryHeader: {
    width: "100%",
    whiteSpace: "nowrap",
    color: "white",
    fontSize: 9,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 6,
    paddingBottom: 1,
    marginBottom: 2,
  },

  catalogueEntry: {
    width: "100%",
    whiteSpace: "nowrap",
    color: "#00386C",
    fontSize: 9,
    paddingBottom: 2,
    paddingTop: 6,
  },
  catalogueEntryTH: {
    width: "100%",
    whiteSpace: "nowrap",
    color: "#00386C",
    fontSize: 9,
  },
  pageHeader: {
    width: "100%",
    height: 100,
    backgroundColor: "black",
    color: "white",
  },
  imageFill: {
    width: "100%",
    height: "100%",
    left: 0,
    right: 0,
    margin: 0,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 0,
  },
  headerText: {
    color: "white",
    width: "100%",
    paddingLeft: 40,
    paddingTop: 30,
    height: 90,
  },
  header1: {
    fontSize: 22,
    marginBottom: 3,
  },
  header2: {
    fontSize: 11,
  },
});

class PDFCatalogue extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.cataloguePages.map(function (page, indexPages) {
          return (
            <Page size="A4" key={indexPages}>
              {this.props.type === "existing" ? (
                <React.Fragment>
                  <OutputStyles.ImageFillWrap>
                    <Image style={styles.imageFill} src={img_bg_grey} />
                  </OutputStyles.ImageFillWrap>
                  <View style={styles.headerText}>
                    <Text style={styles.header1}>Current catalogue </Text>
                    <Text style={styles.header2}>
                      Your current inventory list, organised by category{" "}
                    </Text>
                  </View>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <OutputStyles.ImageFillWrap>
                    <Image style={styles.imageFill} src={img_bg_blue} />
                  </OutputStyles.ImageFillWrap>
                  <View style={styles.headerText}>
                    <Text style={styles.header1}>Proposed catalogue </Text>
                    <Text style={styles.header2}>
                      Your proposed catalogue, organised by category{" "}
                    </Text>
                  </View>
                </React.Fragment>
              )}

              <View style={styles.catalogueTable}>
                <View style={styles.catalogueRow}>
                  <View style={styles.catalogueColumn}>
                    <Text style={styles.catalogueEntryTH}>
                      Product Name (ACCH)
                    </Text>
                  </View>
                  <View style={styles.catalogueColumn}>
                    <Text style={styles.catalogueEntryTH}>
                      Customer Product Name
                    </Text>
                  </View>
                  <View style={styles.catalogueColumnNumber}>
                    <Text style={styles.catalogueEntryTH}>Wholesale</Text>
                    <Text style={styles.catalogueEntryTH}>(inc GST)</Text>
                  </View>
                  <View style={styles.catalogueColumnNumber}>
                    <Text style={styles.catalogueEntryTH}>Retail</Text>
                    <Text style={styles.catalogueEntryTH}>(inc GST)</Text>
                  </View>
                  <View style={styles.catalogueColumnNumber}>
                    <Text style={styles.catalogueEntryTH}>Margin</Text>
                    <Text style={styles.catalogueEntryTH}>(inc GST)</Text>
                  </View>
                </View>

                {page.categories.map(function (category, indexCategories) {
                  let odd = true;

                  if (category.products && category.products.length > 0) {
                    category.products.sort(function (a, b) {
                      let y = parseFloat(a.atts.retail_price);
                      let x = parseFloat(b.atts.retail_price);

                      return x < y ? -1 : 1;
                    });

                    return (
                      <View key={indexCategories}>
                        <View style={styles.catalogueSection}>
                          <View style={styles.catalogueRow}>
                            <Text
                              style={[
                                styles.catalogueEntryHeader,
                                { backgroundColor: category.colour },
                              ]}
                            >
                              {category.text.toUpperCase()}
                            </Text>
                          </View>

                          {category.products && (
                            <React.Fragment>
                              {category.products.map(function (
                                product,
                                indexProducts
                              ) {
                                odd = !odd;

                                return (
                                  <View
                                    style={[
                                      styles.catalogueRow,
                                      {
                                        backgroundColor: odd
                                          ? category.colour_light
                                          : "",
                                      },
                                    ]}
                                    key={indexProducts}
                                  >
                                    <View style={styles.catalogueColumn}>
                                      <Text
                                        style={[
                                          styles.catalogueEntry,
                                          { width: "32%" },
                                        ]}
                                      >
                                        {decodeHTML(product.atts.product_name_acc)}
                                      </Text>
                                    </View>
                                    <View style={styles.catalogueColumn}>
                                      <Text
                                        style={[
                                          styles.catalogueEntry,
                                          { width: "32%" },
                                        ]}
                                      >
                                        {decodeHTML(product.atts.product_name_funeral_home)}
                                      </Text>
                                    </View>
                                    <View
                                      style={[
                                        styles.catalogueColumnNumber,
                                        { width: "12%" },
                                      ]}
                                    >
                                      <Text style={styles.catalogueEntry}>
                                        $
                                        {parseFloat(
                                          product.atts.price
                                        ).toLocaleString("en-AU", {
                                          maximumFractionDigits: 0,
                                        })}
                                      </Text>
                                    </View>
                                    <View
                                      style={[
                                        styles.catalogueColumnNumber,
                                        { width: "12%" },
                                      ]}
                                    >
                                      <Text style={styles.catalogueEntry}>
                                        $
                                        {parseFloat(
                                          product.atts.retail_price
                                        ).toLocaleString("en-AU", {
                                          maximumFractionDigits: 0,
                                        })}
                                      </Text>
                                    </View>
                                    <View
                                      style={[
                                        styles.catalogueColumnNumber,
                                        { width: "12%" },
                                      ]}
                                    >
                                      <Text style={styles.catalogueEntry}>
                                        $
                                        {(
                                          parseFloat(
                                            product.atts.retail_price
                                          ) - parseFloat(product.atts.price)
                                        ).toLocaleString("en-AU", {
                                          maximumFractionDigits: 0,
                                        })}
                                      </Text>
                                    </View>
                                  </View>
                                );
                              },
                              this)}
                            </React.Fragment>
                          )}
                        </View>
                      </View>
                    );
                  } else {
                    return null;
                  }
                }, this)}
              </View>
            </Page>
          );
        }, this)}
      </React.Fragment>
    );
  }
}
export default PDFCatalogue;
