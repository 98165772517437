import React from "react";
import Headery from "../auth/header.jsx";
import Navigation from "../dashboard/navigation.jsx";
class ProposalOverview extends React.Component {
  render() {
    return (
      <div className="holder-prop-catalogue">
        <Headery
          briefTitle={`Overview`}
          title={`Overview | Proposal ${this.props.proposal.ID}`}
          handleScroll={this.handleScroll}
        ></Headery>
        <div className="grid-x">
          <div className="cell">
            <Navigation
              navigator={[
                { label: "Funeral Homes", path: "/funeral-homes" },
                {
                  label: `${this.props.funeralHome.atts.funeral_home_name}`,
                  path: `/funeral-home/${this.props.funeralHome.ID}`,
                },
              ]}
              headingClassName={`navigator-heading-font-size`}
              heading={`${this.props.proposal.ID}`}
              leftHeading={
                <div className="flex-end">
                  <button
                    className={`button ${
                      !this.props.proposal.archived ? "button--ghost" : ""
                    }`}
                    disabled={this.props.proposal.archived}
                    onClick={this.props.archive}
                  >
                    {this.props.proposal.archived
                      ? "Archived Proposal"
                      : "Archive Proposal"}
                  </button>
                </div>
              }
            />
            <h2>Proposal Introductory Text</h2>
            <p>
              <cite>
                Enter text here to replace default PDF introductory text. This
                sits after 'Dear Client' and before 'Kind Regards'
              </cite>
            </p>
            <textarea
              rows="5"
              name="output_introductory_text"
              placeholder="Enter output introductory text"
              value={this.state.output_introductory_text}
              onBlur={this.props.onChangeConfig}
              onChange={this.onChangeValue}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }

  onChangeValue(e) {
    let target = e.target;
    let name = target.name;

    this.setState((state) => ({
      [name]: target.value,
    }));
  }

  constructor(props) {
    super(props);

    this.onChangeValue = this.onChangeValue.bind(this);

    this.state = {
      output_introductory_text: this.props.output_introductory_text,
    };
  }
}

export default ProposalOverview;
