import React, { useContext } from "react";
import { CartContext } from "../../context/cart";

export default function ProductAdded() {
    const { setShowAdded, setShowCart } = useContext(CartContext);

    const handleViewOrder = () => {
        setShowAdded(false);
        setShowCart(true);
    }

    return (
        <div className="shop-products-added cell small-12 flex-container flex-dir-column">
            <i className="shop-products-added-icon fal fa-shopping-cart"></i>
            <h4 className="shop-products-added-title">Item added to cart</h4>
            <div className="grid-x grid-margin-x">
                <button type="button" className="button button--ghost cell small-6" onClick={() => setShowAdded(false)}>Add more</button>
                <button type="button" className="button cell small-6" onClick={handleViewOrder}>View order</button>
            </div>
        </div>
    );
}
