import React, { useState } from "react";
import { apiUrl } from "../config/config";
import axios from 'axios';
import { spinnerService } from "@chevtek/react-spinners";
import { notify } from "react-notify-toast";

export const CartContext = React.createContext();

export const CartProvider = (props) => {
    const [cart, setCart] = useState({});
    const [home, setHome] = useState(null);
    const [editItem, setEditItem] = useState(null);
    const [showCart, setShowCart] = useState(false);
    const [showAdded, setShowAdded] = useState(false);

    const getCart = () => {
        spinnerService.show("ug");
        axios.get(`${apiUrl}/cocart/v2/cart`)
        .then(response => {
            if (response) {
                setEditItem(null);
                setShowAdded(false);
                setCart(response.data);
            }
        }).finally(() => {
            spinnerService.hide("ug");
        });
    };

    const clearCart = () => {
        return new Promise((resolve, reject)=>{
            axios.post(`${apiUrl}/cocart/v2/cart/clear`)
            .then(response => {
                if (response) {
                    setEditItem(null);
                    setShowAdded(false);
                    setCart(response.data);
                    resolve()
                }
            }).finally(() => {
                reject()
            });
    })
    };

    const addItem = (id, quantity = 1, data = {}) => {
        spinnerService.show("ug");
         return new Promise((resolve, reject)=>{
            axios.post(`${apiUrl}/cocart/v2/cart/add-item?home=${home}`,  {
                "id" : `${id}`,
                "quantity" : quantity.toString(),
                "item_data": data
            }).then(response => {
                if (response) {
                    setEditItem(null);
                    setShowAdded(true);
                }
                resolve()
            }).catch(() => {
                spinnerService.hide("ug");
                reject()
            });
    })
    };

    const updateItem = (key, quantity, data = {}) => {
        spinnerService.show("ug");

        axios.post(`${apiUrl}/cocart/v2/cart/item/${key}`,  {
            "quantity" : quantity,
            "item_data": data
        }).then(response => {
            if (response) {
                setEditItem(null);
                getCart()
                notify.show('Item updated', "success", 3000);
            }
        }).finally(() => {
            spinnerService.hide("ug");
        });
    };

    const removeItem = (key) => {
        spinnerService.show("ug");

        axios({ method: 'delete', url: `${apiUrl}/cocart/v2/cart/item/${key}`, data: {
            "return_cart": true,
            "cart_item_key": key
        }}).then(response => {
            if (response) {
                getCart()
                notify.show('Item removed from cart', "success", 3000);
            }
        }).finally(() => {
            spinnerService.hide("ug");
        });
    };

    return (
        <CartContext.Provider value={{ cart, setCart,getCart,clearCart, setHome, addItem, updateItem, removeItem, editItem, setEditItem, showCart, setShowCart, showAdded, setShowAdded }}>{props.children}</CartContext.Provider>
    );
};
