import { spinnerService } from "@chevtek/react-spinners";
import React, { useEffect, useState } from "react";
import Headery from "../auth/header";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiUrl, wp_resources } from "../config/config";
import ReactPaginate from "react-paginate";
import Resource from "./components/resource";
import { isAccess } from "../utilities/constant/navbar.constant";

export default function Resources() {
  // Status
  let [selectedCategory, setSelectedCategory] = useState("");
  let [resources, setResources] = useState([]);
  let [resourcesCategories, setResourcesCategories] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  let [totalpages, setTotalpages] = useState(null);
  let [searchValue, setSearchValue] = useState("");
  const itemPerPage = 12;

  // Show spinner
  useEffect(() => {
    fetchResourcesCategory();
  }, []);

  useEffect(() => {
    fetchResources(searchValue, selectedCategory);
  }, [searchValue, selectedCategory]);

  useEffect(() => {
    fetchResources(searchValue, selectedCategory, currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const fetchResources = (
    searchValue = "",
    selectedCategory = "",
    page = 1
  ) => {

    if (!isAccess(wp_resources)) {
      return;
    }
    spinnerService.show("ug");
    axios
      .get(
        apiUrl +
          `/${wp_resources}?_embed=true&acf_format=standard&page=${page}&per_page=${itemPerPage}${
            searchValue ? `&search=${searchValue}` : ""
          }${selectedCategory ? `&resource-category=${selectedCategory}` : ""}`,
        {}
      )
      .then(function (response) {
        spinnerService.hide("ug");
        if (response && response.data) {
          setCurrentPage(page);
          setTotalpages(response.headers["x-wp-totalpages"]);
          setResources(response.data);
        }
      });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const fetchResourcesCategory = () => {
    spinnerService.show("ug");
    axios
      .get(apiUrl + "/wp/v2/resource-category", {})
      .then(function (response) {
        spinnerService.hide("ug");
        if (response && response.data) {
          setResourcesCategories(response.data);
        }
      });
  };

  const handleChange = (e) => {
    let text = e.target.value ? e.target.value.trim() : "";
    if (e.key === "Enter") {
      e.preventDefault();
      setSearchValue(text);
    }
  };

  return (
    <div className="page-content customer-orders" id="main-scroller">
      <Headery title="Orders" />
      <div className="grid-container">
        <Link to="/customer" className="button--reset">
          <i className="far fa-chevron-left"></i> Back to dashboard
        </Link>
        <h1>Customer Resources</h1>
        <div className="resources-label">Browse and download resources</div>
        <div className="cell-search">
          <label htmlFor="search-keywords">Keywords</label>
          <div className="flex-container">
            <input
              name="search-keywords"
              id="search-keywords"
              onKeyPress={handleChange}
              onChange={handleChange}
              type="search"
              placeholder="Enter keywords and press Enter key"
            />
          </div>
        </div>
        <div>
          <label htmlFor="filter-state">Type</label>
          <div className="flex-container">
            <button
              className={`button button--black funeral-home-type ${
                selectedCategory === "" && "button--black-active"
              }`}
              onClick={() => setSelectedCategory("")}
            >
              All
            </button>
            {resourcesCategories.map((category, index) => {
              return (
                <button
                  key={index}
                  className={`button button--black funeral-home-type ${
                    selectedCategory === category.id && "button--black-active"
                  }`}
                  onClick={() => setSelectedCategory(category.id)}
                >
                  {category.name}
                </button>
              );
            })}
          </div>
        </div>

        {resources?.length > 0 && (
          <div className="grid-x grid-margin-x">
            {resources.map((item, index) => {
              return (
                <Resource
                  key={index}
                  icon={item.acf.resource_icon}
                  title={item?.title.rendered}
                  category={item?._embedded?.["wp:term"][0][0]?.name}
                  featured_media={item.featured_media}
                  imgURL={
                    item.featured_media &&
                    item?._embedded["wp:featuredmedia"][0]?.media_details?.sizes
                      ?.full?.source_url
                  }
                  resourceURL={item.acf.resource_file.url}
                />
              );
            })}
          </div>
        )}
        {resources?.length > 0 && (
          <ReactPaginate
            previousLabel={<i className="fal fa-chevron-left" />}
            breakLabel="..."
            nextLabel={<i className="fal fa-chevron-right" />}
            breakClassName={"break-me"}
            forcePage={currentPage - 1}
            pageCount={totalpages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
          />
        )}
      </div>
    </div>
  );
}
