import React from "react";
import DatePicker from "react-datepicker";
import moment from 'moment';

export default function OrderCompletion({ value, update, cancel }) {
    return (
        <>
            <h4 className="order-date-heading">Select delivery date</h4>
            <DatePicker selected={value} onChange={date => update(date)} minDate={moment().toDate()} inline />

            <div className="order-date-actions grid-x grid-margin-x">
                <div className="cell small-6">
                    <button type="button" className="button button--ghost" onClick={cancel}>Cancel</button>
                </div>
                <div className="cell small-6">
                    <button type="submit" className="button" form="order-form">Complete order</button>
                </div>
            </div>
        </>
    );
}
