import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { notify } from "react-notify-toast";
import { spinnerService } from "@chevtek/react-spinners";
import axios from "axios";
import Footer from "./components/footer";
import Modal from "../widgets/modal";
import AddressForm from "./components/address-form";
import ConfirmFastTrack from "./components/confirm-fast-track";
import ProductTable from "./components/product-table";
import { CartContext } from "../context/cart";
import { AuthContext } from "../context/auth";
import { apiUrl, wc_orders } from "../config/config";
import {  useFetch } from "../hooks";
import { getRouteById, transformAddress } from "../helpers";
import Navigation from "../dashboard/navigation";
import { FuneralHome } from "../context/funeral-home";
import { isAccess } from "../utilities/constant/navbar.constant";

export default function Review({ path, items, title = null }) {
  const { selectedFuneralHome ,routes=[]} = useContext(FuneralHome);
  let [address, setAddress] = useState({});
  const selectedRoute = routes?.length > 0 ? getRouteById(
    selectedFuneralHome?.funeral_home?.funeral_home_route,
    routes
  ) : null;
  let [profile, setProfile] = useState({});
  let [editAddress, setEditAddress] = useState(false);
  let [fastTrack, setFastTrack] = useState(false);
  const [fullName, setFullName] = useState("");
  let [confirmFastTrack, setConfirmFastTrack] = useState(false);
  let [additional, setAdditional] = useState({ reference: "", notes: "" });
  let { home } = useParams();
  let history = useHistory();
  const { auth } = useContext(AuthContext);
  const { cart, clearCart, setEditItem, removeItem } = useContext(CartContext);

  const endpoint = home ? `home/${home}` : "profile";
  let [response] = useFetch(`${apiUrl}/acc/v1/contact/${endpoint}`);

  useEffect(() => {
    if (response) {
      setProfile({ ...response });
      setAddress(transformAddress(response));
    }
  }, [response]);

  const handleFastTrack = () => {
    if (fastTrack) {
      setFastTrack(false);
    } else {
      setConfirmFastTrack(true);
    }
  };

  const saveFastTrack = (fields) => {
    setFastTrack(true);
    setConfirmFastTrack(false);
  };

  const handleAddress = (fields) => {
    setAddress(fields);
    setEditAddress(false);
  };

  const handleAdditional = ({ target }) =>
    setAdditional({ ...additional, [target.name]: target.value });

  const handleSubmit = () => {
    if (!cart?.item_count) {
      notify.show("You have no items in your cart", "info", 3000);

      return;
    }

    
    // Setup data for new order
    const cartItems = items.map(
      ({ id, quantity, options }) => {
        let cartItem = { product_id:id, quantity : quantity.value };

        if (options) {
          cartItem.meta_data = Object.keys(options).map((key) => ({
            key,
            value: options[key],
          }));
        }
        
        return cartItem;
      }
      );
      
      if (!isAccess(wc_orders)) {
        return;
      }
      spinnerService.show("ug");
    // Create new order
    axios
      .post(apiUrl + `/${wc_orders}`, {
        status: "pending",
        customer_id: home ? profile.ID : auth.id,
        customer_note: additional.notes,
        line_items: cartItems,
        meta_data: [
          { key: "order_reference", value: additional.reference },
          { key: "is_order_fast_track", value: fastTrack },
          { key: "order_fast_track_approved_by", value: fullName },
        ],
        billing: address,
        shipping: address,
      })
      .then((response) => {
        if (response) {
          clearCart();
          if (home) {
            history.push(`/orders?created=${response.data.id}`);
          } else {
            history.push(`/customer/order-confirmation/${response.data.id}`);
          }
        }
      })
      .finally(() => {
        spinnerService.hide("ug");
      });
  };

  return (
    <div className="shop-review">
      <Navigation
        navigator={[
          { label: "Funeral Home", path: "/funeral-homes" },
          { label: "Orders", path: "/orders" },
          {
            label: `New order for ${
              selectedFuneralHome?.funeral_home?.funeral_home_name ||
              ''
            }`,
            path: `/funeral-home/${
              selectedFuneralHome?.funeral_home?.id
            }`,
          },
        ]}
        heading="Review & submit"
        path={path}
      />

      {items.length ? (
        <ProductTable
          items={items}
          edit={setEditItem}
          remove={(item) => removeItem(item.item_key)}
        />
      ) : (
        <p>You don't have any products in your cart</p>
      )}

      <form className="shop-review-form" onSubmit={(e) => e.preventDefault()}>
        <div className="grid-x">
          <div>
            <div className="resources-label">Delivery details</div>
            <p className="text-large">
            {selectedRoute?.id && <span>Deliveries for <span className="bold-text">{selectedRoute.name}</span> are scheduled <span className="bold-text">{selectedRoute.frequency["value"] === "monthly" ? selectedRoute.date["label"] : selectedRoute.day["label"]}, {selectedRoute.frequency["label"]}</span>.</span>} Your delivery date will be confirmed via email. If you need all or
              part of the order for a specific date, please tick the box below
              to fast-track the order.
            </p>
            <div className="fancy-checkbox fancy-checkbox--large">
              <input
                type="checkbox"
                name="fast-track"
                id="fast-track"
                checked={fastTrack}
                onChange={handleFastTrack}
              />
              <label htmlFor="fast-track">
                <div className="fast-track-text">
                  <i className="shop-review-fast-track-icon fas fa-shipping-fast"></i>{" "}
                  Fast-track this order{" "}
                </div>
                <span className="fast-track-additional-charge-text">
                  (by ticking this box you accept additional freight charges
                  will be applied in order to fulfill the delivery date)
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="grid-x">
          {address && (
            <div className="shop-review-address small-12 medium-6">
              <div className="resources-label address-title-text">
                Delivery Address
              </div>
              <p>
                {address.company}
                <br />
                {address.address_1}
                <br />
                {address.address_2}
                <br />
                {address.city} {address.postcode}{" "}
                <span className="shop-review-address-state">
                  {address.state}
                </span>
              </p>
              <button
                type="button"
                className="button button--ghost"
                onClick={() => setEditAddress(true)}
              >
                Edit delivery address
              </button>
            </div>
          )}
          {selectedFuneralHome && (
            <div className="shop-review-address small-12 medium-6">
              <div className="resources-label address-title-text">
                Billing Address
              </div>
              <p>
                {selectedFuneralHome?.funeral_home?.funeral_home_name || ""}
                <br />
                {selectedFuneralHome?.funeral_home?.address_line_1 || ""}
                <br />
                {selectedFuneralHome?.funeral_home?.address_line_2 || ""}
                <br />
                {selectedFuneralHome?.funeral_home?.address_city || ""}{" "}
                {selectedFuneralHome?.funeral_home?.address_postcode || ""}{" "}
                <span className="shop-review-address-state">
                  {selectedFuneralHome?.funeral_home?.address_state || ""}
                </span>
              </p>
            </div>
          )}
        </div>

        <div className="resources-label">Add additional information</div>
        <label>
          <input
            type="text"
            id="reference"
            name="reference"
            placeholder="Customer order reference name or number"
            onChange={handleAdditional}
          />
        </label>
        <label>
          <textarea
            name="notes"
            id="notes"
            rows="10"
            placeholder="Enter any additional notes here for the ACC Higgins team…"
            onChange={handleAdditional}
          ></textarea>
        </label>
      </form>

      <Footer
        prev={
          <Link to={`${path}/accessories`} className="button button--reset">
            <i className="far fa-chevron-left"></i> Back
          </Link>
        }
        next={
          <button type="button" className="button" onClick={handleSubmit}>
            Confirm and submit order
          </button>
        }
      />

      <Modal
        open={confirmFastTrack}
        classes="modal-confirm-fast-track"
        width="460"
      >
        <button
          onClick={() => setConfirmFastTrack(false)}
          className="btn-close"
        >
          <i className="far fa-times"></i>
        </button>

        <ConfirmFastTrack
          update={() => saveFastTrack()}
          setFullName={setFullName}
          cancel={() => setConfirmFastTrack(false)}
        />
      </Modal>

      <Modal open={editAddress} classes="modal-address-form">
        <button onClick={() => setEditAddress(false)} className="btn-close">
          <i className="far fa-times"></i>
        </button>

        <AddressForm save={handleAddress} fields={address} />
      </Modal>
    </div>
  );
}
