import React, { useState, useEffect } from "react";
import { spinnerService } from "@chevtek/react-spinners";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import axios from "axios";
import CatalogueTable from "./catalogue-table";
import ProductSearch from "../../shop/components/product-search";
import ProductCard from "../../shop/components/product-card";
import { acc_shop_products, apiUrl } from "../../config/config";
import { useFetch } from "../../hooks";
import { getMatches } from '../../helpers'
import Navigation from "../../dashboard/navigation.jsx";
import { isAccess } from "../../utilities/constant/navbar.constant";

export default function FuneralHomeCatalogue({ home, setBreadcrumbs = null }) {
    let [response] = useFetch(`${apiUrl}/${acc_shop_products}/?home=${home.ID}`);
    let [ready, setReady] = useState(false);
    let [completeCatalogue, setCompleteCatalogue] = useState(false);
    let [catalogue, setCatalogue] = useState([]);
    let [products, setProducts] = useState([]);
    let [search, setSearch] = useState("");
    let [list, setList] = useState([]);

    useEffect(() => {
        if (setBreadcrumbs) {
            setBreadcrumbs({
                funeralHomeName: home.name,
                funeralHomeID: home.ID,
                pageTitle: "Catalogue",
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update product search
    useEffect(() => {
        setList(search ? getMatches(products, search) : []);
    }, [search, products]);

    useEffect(() => {
        if (response) {
            setReady(true);
            setProducts(response.products);
            setCatalogue(response.catalogue);
            setCompleteCatalogue(response.complete);
        }
    }, [response]);

    // Add item to catalogue
    const addItem = (item) => {
        const inCatalogue = catalogue.find(({ id }) => id === item.id);

        if (inCatalogue) {
            notify.show("Product already in catalogue", "info", 3000);
        } else {
            setCatalogue([...catalogue, item]);
            notify.show("Product added to catalogue, save below to finalise changes", "info", 3000);
        }
    }

    // Edit item in catalogue
    const editItem = (item, title) => {
        setCatalogue(catalogue.map((product) => {
            return item.id !== product.id ? product : { ...product, custom_name: title };
        }));
    }

    // Remove item from catalogue
    const removeItem = (item) => {
        setCatalogue(catalogue.filter(({ id }) => id !== item.id));
    }

    // Save catalogue changes
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isAccess('acc/v1/funeral-home/catalogue')) {
            return;
          }
        spinnerService.show("ug");

        axios
            .post(`${apiUrl}/acc/v1/funeral-home/catalogue/${home.ID}`, { 
                catalogue: catalogue.map(({ id, custom_name }) => ({ id, name: custom_name })),
                complete: completeCatalogue
            })
            .then((response) => {
                if (response?.data?.status && response.data.status === "success") {
                    notify.show("Catalogue updated", "success", 3000);
                } else {
                    notify.show(response?.data?.error ?? "Something went wrong", "error", 3000);
                }
            })
            .finally(() => {
                spinnerService.hide("ug");
            });
    }

    return (
        <div className="catalogue">
            <Navigation
            navigator={[{ label: "Funeral Homes", path: "/funeral-homes" },
          { label: `${home.name}`, path: `/funeral-home/${home.ID}` }]}
            heading={`Edit Catalogue`}
          />
            <div className="grid-container">
                {ready && <form className="grid-x" onSubmit={handleSubmit}>
                    <div className="cell cell-component small-12">
                        <div className="catalogue-complete fancy-checkbox">
                            <input type="checkbox" name="full_catalogue" id="full_catalogue" checked={completeCatalogue} onChange={() => setCompleteCatalogue(!completeCatalogue)} />
                            <label htmlFor="full_catalogue">
                                <h6>Enable full catalogue selection?</h6>
                                <small>(by ticking this box you give access to allow the funeral home to select and customise new products from the entire range available)</small>
                            </label>
                        </div>

                        <h2 className="heading-underline">Add and customise products within this catalogue</h2>
                        {catalogue.length ? <CatalogueTable items={catalogue} edit={editItem} remove={removeItem} /> : <p>There are no products assigned to this catalogue.</p>}

                        <div className="catalogue-save-secondary" data-show={list.length > 0}><button type="submit" className="button">Save Changes</button></div>

                        <div className="catalogue-product-search">
                            <h2 className="heading-underline">Browse products to add to the catalogue</h2>
                            {products.length > 0 ? <>
                                <ProductSearch search={search} text="Type SAP or product name to search catalogue" handleSearch={value => setSearch(value)} />

                                <div className="grid-x grid-margin-x shop-products-list">
                                    {list.map((item) => <ProductCard item={item} key={item.id} add={addItem} button="Add to catalogue" />)}
                                    {search.length > 1 && !list.length && <p className="info cell small-12">No matching products</p>}
                                </div>
                            </> : <p className="info">
                                {ready ? 'No products found' : 'Loading products...'}
                            </p>}
                        </div>
                    </div>
                    <div className="catalogue-actions small-12 grid-x align-middle">
                        <div className="cell small-6">
                            <Link to={`/funeral-home/${home.ID}`} className="button--reset"><i className="far fa-chevron-left"></i> Cancel</Link>
                        </div>
                        <div className="cell small-6 text-right">
                            <button type="submit" className="button">Save Changes</button>
                        </div>
                    </div>
                </form>}
            </div>
        </div>
    );
}
