import React, { useContext } from "react";
import { CartContext } from "../../context/cart";

export default function ProductCard({ item, add = null, button = null }) {
  const { cart, setEditItem } = useContext(CartContext);
  const { name, custom_name, image, category } = item;
  
  const cartQuantity =()=> {
    let product = null;
    if(cart?.items?.length){
      for (const cartItem of cart?.items) {
        if(cartItem.id === item.id){
            product = cartItem
            break; // This will exit the loop when the condition is met.
        }
      } 
    }
    return product
  }
  const inCart = cartQuantity();

  // Call the supplied add function or let the cart handle it
  const handleAdd = () => {
    if (add) {
      add(item);
    } else {
      setEditItem(item);
    }
  };

  const buttonText = button
    ? button
    : inCart
    ? `${inCart?.quantity?.value} ADDED`
    : "Add";

  return (
    <div className="shop-products-card cell small-12 medium-4 large-3 flex-container flex-dir-column">
      <div
        className="shop-products-card-media"
        style={{ backgroundColor: category?.color ?? "#eee" }}
      >
        {image.medium ? (
          <img
            src={image.medium}
            alt={custom_name || name}
            className="shop-products-card-image"
          />
        ) : (
          <div className="shop-products-card-no-image" />
        )}
      </div>
      <div className="shop-products-card-content flex-child-grow flex-container flex-dir-column align-justify">
        <h4 className="shop-products-card-title">{custom_name || name}</h4>
        <button
          type="button"
          className={inCart ? "button button--ghost" : "button"}
          onClick={handleAdd}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}
