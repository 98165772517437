import React, { useState, useContext, useEffect } from "react";
import ProductOptions from './product-options';
import { CartContext } from "../../context/cart";
import { getProductOptions } from "../../helpers";

export default function Product({ item, submit = null }) {
    const [sap, setSap] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const { updateItem, addItem,getCart, cart } = useContext(CartContext);
    let [options, setOptions] = useState({ size: 'standard', type: 'internal', length: '', width: '', height: '' });
    const productFromCart =()=> {
        let product = null;
        if(cart?.items?.length){
          for (const cartItem of cart?.items) {
            if(cartItem.id === item.id){
                product = cartItem
                break; // This will exit the loop when the condition is met.
            }
          } 
        }
        return product
      }

    const product = productFromCart()
    
    console.log(item,"item");
    useEffect(() => {
        setSap(item.sku);
        
        if (product?.quantity?.value || item.quantity) {
            setQuantity(product?.quantity?.value || item.quantity);
        }
        
        const itemOptions =  getProductOptions(item, options);

        setOptions({ ...options, ...itemOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOptions = ({ target }) => setOptions({ ...options, [target.name]: target.value })

    // Call the supplied submit function or let the cart handle it
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = options.size === 'specialty' ? { options } : {};
        
        if (submit) {
            submit(item, quantity, data);
        } else {
            if (product?.item_key) {
                updateItem(product.item_key, quantity, data);
            } else {
                await addItem(item.id, quantity, data);
                getCart()
            }
        }
    }

    return (
        <div className="shop-products-item grid-x" key={item.id}>
            <div className="shop-products-item-media small-12 medium-5" style={{ backgroundColor: item?.category?.color ?? '#eee' }}>
                {item.image.large && <img src={item.image.large} alt={item.custom_name || item.name} className="shop-products-item-image" />}
            </div>
            <form onSubmit={handleSubmit} className="shop-products-item-content small-12 medium-7 flex-container flex-dir-column">
                <p className="shop-products-item-sku">SAP: {sap}</p>
                <h4 className="shop-products-item-title">{item.custom_name || item.name}</h4>
                <div className="shop-products-item-details grid-x">
                    {Boolean(item.attributes.length) && item.attributes.map(i => (
                        <div className="cell small-6 shop-products-item-attr" key={i.id}>
                            <small>{i.label}:</small> <p>{i.value}</p>
                        </div>
                    ))}
                </div>
                {item.description && <div className="shop-products-item-description" dangerouslySetInnerHTML={{ __html: item.description }} />}

                {item.type === 'products' && <ProductOptions data={options} handleData={handleOptions} />}

                <div className="shop-products-item-submit grid-x">
                    <div className="cell small-6 flex-container align-middle">
                        <label htmlFor="quantity">QTY</label>
                        <input type="number" name="quantity" value={quantity} min={1} onChange={({ target }) => setQuantity(target.value)} required />
                    </div>
                    <div className="cell small-6 flex-container">
                        <button type="submit" className="button">
                            {product?.item_key || item.meta_data ? 'Update item' : 'Add to order'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
