import React from "react";

class InlineEditSelect extends React.Component {
  render() {
    return (
      <div className="bjm-inline-edit-wrap">
        {this.state.editing ? (
          <select
            className="wrap-inline-edit editing"
            onChange={this.finishEditing}
            onBlur={this.cancelEditing}
            value={this.props.value}
          >
            {this.props.options.map(function (option, index) {
              return (
                <option key={index} value={option.id}>
                  {option.text}
                </option>
              );
            }, this)}
          </select>
        ) : (
          <span className="wrap-inline-edit" onClick={this.start_edit}>
            {this.state.initialValue?.text && this.state.initialValue.text}
          </span>
        )}
      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value && props.options && !state.initialValue) {
      const initialValue = props.options.find(
        (option) => option.id === props.value
      );

      if (initialValue) {
        state = { ...state, initialValue };
      }
    }

    return state;
  }

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      initialValue: 0,
    };

    this.start_edit = this.start_edit.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.finishEditing = this.finishEditing.bind(this);
  }

  cancelEditing(e) {
    this.setState((state) => ({
      editing: false,
    }));
  }
  finishEditing(event) {
    let newValue = parseInt(event.target.value);
    const newFullValue = this.props.options.find(function (option) {
      return option.id === newValue;
    }, this);

    this.props.change({
      [this.props.propName]: newValue,
    });

    this.setState((state) => ({
      initialValue: newFullValue,
      editing: false,
    }));
  }
  start_edit(e) {
    this.setState((state) => ({
      editing: true,
    }));
  }
}

export default InlineEditSelect;
