import React from 'react';
import styled from '@react-pdf/styled-components';
import OutputStyles from './styles.jsx';

import img_product_test from '../../../images/no-image.jpg';
import { decodeHTML } from '../../../utilities/constant/navbar.constant.js';

class PDFProducts extends React.Component {
    render() {  
        const MarginPage = styled.Page`
            margin:14px;
        `;

        const Header = styled.View`

            background-color:black;
            width:814px;
            height:85px;
            display: flex;
            flex-direction: row;
            


        `;
        const HeaderLeft = styled.View`
            
            
            width: 50%;
            padding-left:30px;
            text-align:left;
            color: white;
            align-items: flex-start;
            justify-content: center;

        `;
 
        const HeaderRight = styled.View`
            
            text-align:right;
            
            width: 50%;
            padding-right:30px; 
            
            color: white;
            
            justify-content: center;
        `;
 
        const StatsBox = styled.View`
            background-color: white;
            position:absolute;
            bottom:28px;
            left:-1px;
            
            
            width:250px;
            height: 100px;

            padding-left:40px;
            padding-top:22px;
            color: red;
            font-size: 11px;
            
            

        `;
        const StatsBoxText = styled.Text`
            margin-bottom:4px;
        `;
        


        // eslint-disable-next-line no-unused-vars
        const StatsBoxDecoration = styled.View`
            background-color:white;
            
            position:absolute;
            left:243px;
            height:12px;
            width: 12px;
            bottom:113px;
            
        `;
        
        // eslint-disable-next-line no-unused-vars
        const StatsBoxDecoration2 = styled.View`
            background-color:white;
            
            position:absolute;
            left:248px;
            height:91px;
            width: 10px;
            bottom:28px;
            
        `;
        const StatsBoxDecoration3 = styled.View`
            width: 1px;
            background-color: black;
            bottom: 30px;
            height: 52px;
            left:16px;
            
            position:absolute;
    
            
        `;


        const ProductImageWrap = styled.View`                        
            width: 814px;
            height: 484px;
            position: absolute;
            top: 82px;
        `;

        const ProductImage = styled.Image`
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        `;
 
        const FaIconWrap = styled.View`                        
            width:26px;
            height:26px;
            text-align:center;

            position:absolute;
            left:12px;
            
            width: 10px;
            bottom: 86px;
            

        `;
        const FaIcon = styled.Text`                        
            font-family: 'Font Awesome 5 Pro';
            text-align:center;
            text-align:center;
            
      
        `;

        
        
        return (
            <React.Fragment>

                {this.props.categories.map(function(category, indexCategories){

                    return (
                        <React.Fragment key={indexCategories}>
                            
                            {category.products.map(function(product, indexProducts){



                                let specs = ['','','','',''];


                                if (product.atts.product_atts && product.atts.product_atts.description){
                                    let specHTML = document.createElement('div');
                                    specHTML.innerHTML = decodeHTML(product.atts.product_atts.description);
                                    let parts = specHTML.children;
                                    if (parts[0]?.tagName === 'UL'){
                                        let lis = parts[0].children;
                                        
                                        let i = 0;
                                        for (let li of lis){
                                            specs[i] = (li.innerText);
                                            
                                            i++;
                                        }
                                    }
                                    
                                }

                                return (

                                    <MarginPage key={indexProducts} size="A4" orientation="landscape">
                                        <Header style={{backgroundColor: category.colour}}>
                                            <HeaderLeft>                        
                                                <OutputStyles.Header1>{decodeHTML(product.atts.product_name_funeral_home)}</OutputStyles.Header1>
                                                <OutputStyles.Header2>{category.text}</OutputStyles.Header2>                                                
                                            </HeaderLeft>
                                            <HeaderRight>                        
                                                <OutputStyles.Header1>${product.atts.retail_price}</OutputStyles.Header1>
                                                <OutputStyles.Header2>SAP: {decodeHTML(product.atts.sku.toUpperCase())}</OutputStyles.Header2>
                                            </HeaderRight>
                                        </Header>
                                        <ProductImageWrap>
                                            {product.atts.product_atts && product.atts.product_atts.image ? (
                                                <ProductImage src={product.atts.product_atts.image[0]}></ProductImage>
                                            ) : (
                                                <ProductImage src={img_product_test}></ProductImage>
                                            )}
                                        </ProductImageWrap>
                                        <StatsBox style={{color: category.colour}}>
                                            {specs.map(function(spec, indexSpec){

                                                return (
                                                    <StatsBoxText key={indexSpec}>{spec}</StatsBoxText>
                                                );
                                            }, this)}
                                            
                                            
                                        </StatsBox>
                                        {/* <StatsBoxDecoration style={{transform: 'rotate(45deg)'}}></StatsBoxDecoration> */}
                                        {/* <StatsBoxDecoration2></StatsBoxDecoration2> */}
                                        <FaIconWrap><FaIcon style={{color: category.colour}}></FaIcon></FaIconWrap>
                                        <StatsBoxDecoration3 style={{backgroundColor: category.colour}}></StatsBoxDecoration3>


                                    
                                    </MarginPage>


                                );

                            }, this)}


                        </React.Fragment>

                    );

                }, this)}

            </React.Fragment>
             
        );


    }


}
export default PDFProducts;
