import React from "react";
import DateRange from "./date-range.jsx";
import AddCatalogueProduct from "./add-catalogue-product.jsx";
import TableRow from "./table-row.jsx";
import Sorter from "../../widgets/sorter.jsx";
import Navigation from "../../dashboard/navigation.jsx";
import { notify } from "react-notify-toast";

class Catalogue extends React.Component {
  render() {
    let _this = this;

    let products = this.props.catalogue.atts.catalogue_products;

    if (
      this.state.sorting.column !== "default" &&
      this.state.sorting.sort !== "none"
    ) {
      products.sort(function (a, b) {
        let x = a.atts[_this.state.sorting.column];
        let y = b.atts[_this.state.sorting.column];
        if (_this.state.sorting.isNumber) {
          x = parseInt(x);
          y = parseInt(y);
        }

        if (_this.state.sorting.sort === "asc") {
          return x < y ? -1 : 1;
        } else {
          return y < x ? -1 : 1;
        }
      });
    } else {
    }

    return (
      <div className="holder-prop-catalogue">
        <div className="grid-x">
          <Navigation
            navigator={[
              { label: "Funeral Homes", path: "/funeral-homes" },
              {
                label: `${this.props.funeralHome.atts.funeral_home_name}`,
                path: `/funeral-home/${this.props.funeralHome.ID}`,
              },
            ]}
            headingClassName={`navigator-heading-font-size`}
            heading={`${this.props.proposal.ID}`}
          />
          <div className="cell cell-side-panel cell-catalogue-side-panel">
            {(this.props.catalogue_type === "existing" ||
              this.props.catalogue_type === "data") && (
              <DateRange
                start_date={this.props.catalogue.atts.start_date}
                end_date={this.props.catalogue.atts.end_date}
                onSubmit={(e) => {
                  this.props.onSubmitEditCatalogue(
                    this.props.catalogue_type,
                    e
                  );
                }}
                onChangeValue={(m, name) => {
                  this.props.onChangeValue(this.props.catalogue_type, m, name);
                }}
              />
            )}

            <h2>Add {this.props.catalogue_type} product range</h2>
            <AddCatalogueProduct
              add={this.props.add}
              catalogue_type={this.props.catalogue_type}
              product_cats={this.props.product_cats}
              products={this.props.products}
              onProductSelect={(product) =>
                this.props.onProductSelect(this.props.catalogue_type, product)
              }
              onChangeValue={(e) => {
                this.props.onChangeValueAddLine(this.props.catalogue_type, e);
              }}
              onSubmit={(e) => {
                this.props.onSubmitAddLine(this.props.catalogue_type, e);
              }}
            />
          </div>
          <div
            className="cell auto cell-main-panel cell-catalogue-list"
            id="main-scroller"
          >
            <table>
              <thead>
                <tr>
                  <th width="6%">Linked? </th>
                  <th
                    onClick={() => this.changeSorting("product_name_acc")}
                    width="22%"
                  >
                    Product Name (ACCH){" "}
                    <Sorter
                      sorting={this.state.sorting}
                      column="product_name_acc"
                    />{" "}
                  </th>
                  <th
                    onClick={() =>
                      this.changeSorting("product_name_funeral_home")
                    }
                    width="22%"
                  >
                    Product Name (Home){" "}
                    <Sorter
                      sorting={this.state.sorting}
                      column="product_name_funeral_home"
                    />{" "}
                  </th>
                  <th onClick={() => this.changeSorting("sku")} width="13%">
                    SAP Code{" "}
                    <Sorter sorting={this.state.sorting} column="sku" />{" "}
                  </th>
                  <th
                    onClick={() => this.changeSorting("category", true)}
                    width="13%"
                  >
                    Category{" "}
                    <Sorter sorting={this.state.sorting} column="category" />
                  </th>
                  <th
                    onClick={() => this.changeSorting("price", true)}
                    width="8%"
                  >
                    Whole Sale{" "}
                    <Sorter sorting={this.state.sorting} column="price" />
                  </th>
                  <th
                    onClick={() => this.changeSorting("retail_price", true)}
                    width="8%"
                  >
                    Retail{" "}
                    <Sorter
                      sorting={this.state.sorting}
                      column="retail_price"
                    />
                  </th>
                  <th width="8%">Margin</th>
                  {(this.props.catalogue_type === "existing" ||
                    this.props.catalogue_type === "data") && (
                    <th
                      onClick={() =>
                        this.changeSorting("stats_number_of_sales", true)
                      }
                      width="8%"
                    >
                      No. of sales{" "}
                      <Sorter
                        sorting={this.state.sorting}
                        column="stats_number_of_sales"
                      />
                    </th>
                  )}
                  <th width="5%">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {products.map(function (product, index) {
                  return (
                    <TableRow
                      key={index}
                      atts={product.atts}
                      product_cats={this.props.product_cats}
                      catalogue_type={this.props.catalogue_type}
                      onChange={(name_value) => {
                        if (
                          Object.keys(name_value)?.length &&
                          Object.keys(name_value)[0] === "retail_price" &&
                          Number(name_value?.retail_price) <
                            Number(product.atts.price)
                        ) {
                          notify.show(
                            "Retail price should be greater than Wholesale price.",
                            "error",
                            3000
                          );
                        } else {
                          this.props.onChangeValueLine(
                            index,
                            this.props.catalogue_type,
                            name_value
                          );
                        }
                      }}
                      onDelete={(name_value) => {
                        this.props.onDeleteLine(
                          index,
                          this.props.catalogue_type
                        );
                      }}
                    />
                  );
                }, this)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  changeSorting(columnName, isNumber) {
    let sort = "asc";

    //If currently sorted by same column, reverse sort
    if (this.state.sorting.column === columnName) {
      //asc = !this.state.sorting.asc;
      if (this.state.sorting.sort === "asc") {
        sort = "desc";
      } else if (this.state.sorting.sort === "desc") {
        columnName = "default";
        sort = "none";
      }
    }

    this.setState((state) => ({
      sorting: {
        column: columnName,
        sort: sort,
        isNumber: isNumber,
      },
    }));
  }

  constructor(props) {
    super(props);
    let briefTitle = "Data Entry";
    let title = "Data Entry";

    if (this.props.catalogue_type !== "data") {
      briefTitle =
        this.props.catalogue.atts.type.charAt(0).toUpperCase() +
        this.props.catalogue.atts.type.slice(1) +
        " Catalogue";
      title =
        this.props.catalogue.atts.type.charAt(0).toUpperCase() +
        this.props.catalogue.atts.type.slice(1) +
        " Catalogue | Proposal " +
        this.props.catalogue.atts.proposal.ID;
    }

    this.state = {
      sorting: {
        column: "retail_price",
        sort: "desc",
        isNumber: true,
      },
      briefTitle: briefTitle,
      title: title,
    };

    this.changeSorting = this.changeSorting.bind(this);
  }
}

export default Catalogue;
