import React, { useState } from "react";

export default function ConfirmFastTrack({ update, setFullName, cancel }) {
  // State
  const [acceptCondition, setAcceptCondition] = useState(false);

  return (
    <>
      <h3 className="modal-confirm-fast-track-title">Confirm Fast-track?</h3>
      <p>
        By confirming Fast-track for this order you accept additional freight
        charges will be applied in order to fulfill the delivery date.
      </p>
      <label>
        <input
          type="text"
          placeholder="Full name"
          onChange={(e) => setFullName(e.target.value)}
        />
      </label>
      <div className="confirm-section">
        <input
          type="checkbox"
          onChange={(ev) => setAcceptCondition(ev.target.checked)}
          checked={acceptCondition}
        />{" "}
        <label className="modal-confirm-fast-checkbox">
          I understand that filling my name and ticking the checkbox acts as a
          digital signature, and I accept the additional charges.
        </label>
      </div>
      <div className="grid-x grid-margin-x">
        <div className="cell small-6">
          <button
            type="submit"
            className="button button--ghost"
            onClick={cancel}
          >
            Cancel
          </button>
        </div>
        <div className="cell small-6 text-right">
          <button
            type="submit"
            className="button"
            onClick={update}
            disabled={!acceptCondition}
          >
            Add Fast-track
          </button>
        </div>
      </div>
    </>
  );
}
