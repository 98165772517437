import React from "react";
import HomeImage from "./home-image.jsx";
import Contacts from "./contacts.jsx";
import axios from "axios";
import Select from "react-select";
import { notify } from "react-notify-toast";
import { spinnerService } from "@chevtek/react-spinners";
import { AuthContext } from "../../context/auth";
import { getRouteById } from "../../helpers";
import {
  isAccess,
  validationRegex,
} from "../../utilities/constant/navbar.constant.js";
import Navigation from "../../dashboard/navigation.jsx";
import {
  acc_funeral_home_logo_upload,
  acc_funeral_home_lookup_lga,
} from "../../config/config.js";

class ProfileFuneralHome extends React.Component {
  static contextType = AuthContext;

  render() {
    return (
      <div className="holder-prop-funeral-home-details">
        {!this.props.customer && (
          <Navigation
            navigator={[
              { label: "Funeral Homes", path: "/funeral-homes" },
              ...(this.state.atts.funeral_home_name !== "New Funeral Home"
                ? [
                    {
                      label: `${this.props.home.atts.funeral_home_name}`,
                      path: `/funeral-home/${this.props.home.ID}`,
                    },
                  ]
                : []),
            ]}
            heading={`${
              this.state.atts.funeral_home_name !== "New Funeral Home"
                ? "Edit Funeral Home Details"
                : "Add a new funeral home"
            }`}
            leftHeading={
              <div className="flex-end">
                <button
                  className="button btn-add-contact"
                  type="button"
                  onClick={() => this.saveFuneralHome(this.state.payload)}
                >
                  Save changes
                </button>
              </div>
            }
          />
        )}

        <div className="grid-x">
          <div className="cell profile-panel-left cell-side-panel">
            <HomeImage
              logo={this.state.atts.logo}
              config={this.props.config}
              onDrop={this.handleDrop}
              onRemove={this.handleBlur}
            />
          </div>
          <div
            className="cell auto profile-panel-right cell-main-panel"
            id="main-scroller"
          >
            <div className="grid-x">
              <div className="cell small-12">
                <h2 className="heading-underline">Funeral Home Details</h2>

                <form
                  className="form profile-form form-section"
                  autoComplete="off"
                >
                  <div className="grid-x grid-margin-x">
                    <div className="cell small-12 large-6 cell-form">
                      <p>
                        <label htmlFor="funeral_home_name">
                          Funeral Home Name
                        </label>
                        <input
                          name="funeral_home_name"
                          type="text"
                          placeholder="Funeral Home Name"
                          value={this.state.atts.funeral_home_name}
                          onBlur={this.handleBlur}
                          onChange={this.handleChange}
                        />
                      </p>
                      {this.context.isAdmin() && (
                        <p>
                          <label htmlFor="funeral_home_group">
                            Funeral Home Group
                          </label>
                          <select
                            name="funeral_home_group"
                            value={this.state.atts.funeral_home_group}
                            onChange={this.handleBlur}
                          >
                            <option value="">Choose Group...</option>
                            {this.props.funeral_home_groups.map(function (
                              group,
                              index
                            ) {
                              return (
                                <option key={index} value={group.term_id}>
                                  {group.name}
                                </option>
                              );
                            },
                            this)}
                          </select>
                        </p>
                      )}
                      {this.context.isAdmin() && (
                        <p>
                          <label htmlFor="discount_percent">
                            Discount Percentage
                          </label>
                          <input
                            name="discount_percent"
                            type="number"
                            placeholder="Discount Percentage"
                            value={this.state.atts.discount_percent}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                          />
                        </p>
                      )}
                      <div className="grid-x grid-margin-x">
                        <div className="cell small-12 large-6 cell-form">
                          <p>
                            <label htmlFor="address_state">State</label>
                            <select
                              name="address_state"
                              value={this.state.atts.address_state.toLowerCase()}
                              onChange={this.handleBlur}
                            >
                              <option value="act">ACT</option>
                              <option value="nsw">NSW</option>
                              <option value="nt">NT</option>
                              <option value="sa">SA</option>
                              <option value="qld">QLD</option>
                              <option value="vic">VIC</option>
                              <option value="wa">WA</option>
                            </select>
                          </p>
                        </div>
                        <div className="cell small-12 large-6 cell-form">
                          <p>
                            <label htmlFor="address_postcode">Postcode</label>
                            <input
                              name="address_postcode"
                              type="text"
                              placeholder="Postcode"
                              value={this.state.atts.address_postcode}
                              onBlur={this.handleBlur}
                              onChange={this.handleChange}
                            />
                            {this.state.error &&
                              this.state.errorField === "address_postcode" && (
                                <span className="error-message">
                                  {this.state.errorMessage}
                                </span>
                              )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="cell small-12 large-6 cell-form">
                      <p>
                        <label htmlFor="address_line_1">Address Line 1</label>
                        <input
                          name="address_line_1"
                          type="text"
                          placeholder="Address Line 1"
                          value={this.state.atts.address_line_1}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                        />
                      </p>
                      <p>
                        <label htmlFor="address_line_2">Address Line 2</label>
                        <input
                          name="address_line_2"
                          type="text"
                          placeholder="Address Line 2"
                          value={this.state.atts.address_line_2}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                        />
                      </p>
                      <p>
                        <label htmlFor="address_city">City</label>
                        <input
                          name="address_city"
                          type="text"
                          placeholder="City"
                          value={this.state.atts.address_city}
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                        />
                        {this.state.error &&
                          this.state.errorField === "address_city" && (
                            <span className="error-message">
                              {this.state.errorMessage}
                            </span>
                          )}
                      </p>
                      <div className="grid-x grid-margin-x">
                        <div className="cell small-12 large-6 cell-form">
                          <p>
                            <label htmlFor="lga_id">LGA</label>
                            <Select
                              name="lga_id"
                              onChange={(e) => {
                                this.setState({ selectedLga: e });
                                this.handleBlur({
                                  target: { value: e.value, name: "lga_id" },
                                });
                              }}
                              value={this.state.selectedLga}
                              options={this.state.lgaOptions}
                              placeholder="Select LGA..."
                              isSearchable={true}
                              required
                              isOptionDisabled={(option) => this.state.atts.address_state.toLowerCase() !== option.label?.split("-")[0]?.trim()?.toLowerCase()}
                            />
                          </p>
                        </div>
                        <div className="cell small-12 large-6 cell-form">
                          <button
                            type="button"
                            className="button profile-form-button-lga"
                            disabled={
                              !(
                                this.state.atts.address_postcode &&
                                this.state.atts.address_state
                              )
                            }
                            onClick={this.findLGAByPostcode}
                          >
                            Find LGA by Postcode
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {this.context.isAdmin() && (
                <form
                  className="form cell small-12"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="form-section">
                    <h2 className="heading-underline">Product selection</h2>
                    <div className="grid-x align-items-end">
                      <div className="medium-4">
                        <label>Customer Code</label>
                        {this.state.atts.customer_code}
                      </div>
                      <div className="medium-3">
                        <label>Database Connection</label>
                        {this.props?.home?.atts?.main_contact?.atts
                          ?.funeral_home?.funeral_home_connection || ""}
                      </div>
                      <div className="medium-5">
                        <span className="product-selection-checkbox">
                          <span
                            data-tip="Allow access to full region catalogue"
                            className="allow-access-full-region-catalogue"
                            onClick={() =>
                              this.setState({
                                checkAllowFullRegion:
                                  !this.state.checkAllowFullRegion,
                              })
                            }
                          >
                            {this.state.checkAllowFullRegion ? (
                              <i className="fas fa-check-square"></i>
                            ) : (
                              <i className="fal fa-square"></i>
                            )}
                          </span>
                          Allow access to full region catalogue?
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-scroll-x">
                    <h2 className="heading-underline">Ordering details</h2>
                    <table className="options-table">
                      <thead>
                        <tr className="options-table-tight">
                          <th>Region</th>
                          <th>Delivery route name</th>
                          <th>Delivery frequency</th>
                          <th>Deliver day/date</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <select
                              name="funeral_home_region"
                              id="funeral_home_region"
                              className="no-margin"
                              onChange={this.handleBlur}
                              value={this.state.atts.funeral_home_region}
                            >
                              <option value="">Select route</option>
                              {this.props?.home?.regions.map(({ id, name }) => (
                                <option value={id} key={id}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <select
                              name="funeral_home_route"
                              id="funeral_home_route"
                              className="no-margin"
                              onChange={this.handleBlur}
                              value={this.state.atts.funeral_home_route}
                            >
                              <option value="">Select route</option>
                              {this.state.routes.map(({ id, name }) => (
                                <option value={id} key={id}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>{this.state.selectedRoute.frequency["label"]}</td>
                          <td>
                            {this.state.selectedRoute.frequency["value"] ===
                            "monthly"
                              ? this.state.selectedRoute.date["label"]
                              : this.state.selectedRoute.day["label"]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              )}

              {(this.context.isCustomer() || this.context.isManager()) && (
                <form
                  className="form cell small-12"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="form-section form-scroll-x">
                    <div className="flex-end">
                      <button
                        className="button btn-add-contact"
                        type="button"
                        onClick={() => this.saveFuneralHome(this.state.payload)}
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </form>
              )}
              <div className="cell small-12 ">
                <h2 className="heading-underline">Contact Details</h2>

                <Contacts
                  config={this.props.config}
                  contacts={this.state.contacts}
                  defaultContact={this.state.atts.main_contact}
                  home_id={this.state.ID}
                  contactAdded={this.handleContactAdded}
                  contactDeleted={this.handleContactDeleted}
                  contactSetDefault={this.handleContactSetDefault}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.findLGAByPostcode = this.findLGAByPostcode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleContactAdded = this.handleContactAdded.bind(this);
    this.handleContactDeleted = this.handleContactDeleted.bind(this);
    this.handleContactSetDefault = this.handleContactSetDefault.bind(this);
    this.saveFuneralHome = this.saveFuneralHome.bind(this);
    this.getSelectedLGA = this.getSelectedLGA.bind(this);

    this.state = {
      ...this.props.home,
      originalDetails: { ...this.props.home.atts },
      selectedRoute: { frequency: "", day: "", date: "" },
      error: false,
      errorField: "",
      errorMessage: "",
      payload: null,
      checkAllowFullRegion: this.props.home.atts.product_catalogue_complete,
      selectedLga: this.props.home.lga_postcode_maps
        .map(function (option) {
          return {
            value: option.id,
            label: `${option.state} - ${option.lga}`,
          };
        })
        .filter((option) => option.value === this.props.home?.atts?.lga_id),
      lgaOptions: this.props.home.lga_postcode_maps.map(function (option) {
        return {
          value: option.id,
          label: `${option.state} - ${option.lga}`,
        };
      }),
    };

    if (this.props?.setBreadcrumbs) {
      this.props.setBreadcrumbs({
        funeralHomeName: this.props.home.atts.funeral_home_name,
        funeralHomeID: this.props.home.ID,
        pageTitle: "Profile",
      });
    }
  }

  componentDidMount() {
    this.setSelectedRoute();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.atts.funeral_home_route !== prevState.atts.funeral_home_route
    ) {
      this.setSelectedRoute();
    }
  }

  setSelectedRoute() {
    const selectedRoute = getRouteById(
      this.state.atts?.funeral_home_route,
      this.state.routes
    );

    this.setState({ selectedRoute });
  }

  handleDrop(files) {
    let _this = this;
    var formData = new FormData();
    formData.append("file", files[0]);
    formData.append("id", this.state.ID);
    if (!isAccess(acc_funeral_home_logo_upload)) {
      return;
    }
    spinnerService.show("ug");
    axios
      .post(
        this.props.config.apiUrl + `/${acc_funeral_home_logo_upload}`,
        formData,
        {}
      )
      .then(function (response) {
        spinnerService.hide("ug");

        notify.show("Uploaded image", "success", 3000);

        let atts = Object.assign({}, _this.state.atts);
        atts.logo = response.data.url;
        _this.setState({ atts });
      });
  }

  validation(data) {
    let error = false;
    let errorMessage = "";
    let errorField = "";
    if (
      data.address_city &&
      !validationRegex.onlyAlphabetWithWhiteSpace.test(data.address_city)
    ) {
      error = true;
      errorMessage = "City must be Alphabet chars value";
      errorField = "address_city";
    } else if (
      data.address_postcode &&
      !validationRegex.onlyNumer.test(data.address_postcode)
    ) {
      error = true;
      errorMessage = "Postcode must be numeric value";
      errorField = "address_postcode";
    }

    this.setState({
      error,
      errorMessage,
      errorField,
    });

    return error;
  }

  getSelectedLGA(id) {
    return this.generateOptions().filter((option) => option.value === id);
  }

  generateOptions() {
    return this.state.lga_postcode_maps.map(function (option) {
      return {
        value: option.id,
        label: `${option.state} - ${option.lga}`,
      };
    }, this);
  }

  handleBlur(event) {
    const target = event.target;
    const name = target.name;

    let atts = Object.assign({}, this.state.atts);
    atts[name] = target.value;

    if (this.validation(atts)) {
      return;
    }

    this.setState({ atts, payload: atts });

    if (this.props.setHomeAttrs) this.props.setHomeAttrs(atts);
  }

  saveFuneralHome(change) {
    if (!isAccess("acc/v1/funeral-home/update")) {
      return;
    }
    axios
      .post(this.props.config.apiUrl + "/acc/v1/funeral-home/update", {
        funeral_home: {
          ID: this.state.ID,
          atts: {
            ...this.state.atts,
            product_catalogue_complete: this.state.checkAllowFullRegion,
          },
        },
      })
      .then((response) => {
        notify.show(`Save Changes`, "success", 3000);

        this.setState({
          originalDetails: {
            ...this.state.originalDetails,
            change,
          },
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;

    let atts = Object.assign({}, this.state.atts);
    atts[name] = target.value;

    this.setState({ atts, payload: atts });
  }

  handleContactSetDefault(defaultContact) {
    let atts = Object.assign({}, this.state.atts);
    atts.main_contact = defaultContact;

    this.setState({ atts }, () =>
      this.saveFuneralHome({
        name: "Default Contact",
        value: defaultContact.atts.first_name,
      })
    );

    if (this.props.setHomeAttrs)
      this.props.setHomeAttrs({ main_contact: defaultContact });
  }

  handleContactAdded(new_contact) {
    let contacts = Object.assign([], this.state.contacts);
    contacts.push(new_contact);

    this.setState({ contacts }, () => {
      if (contacts.length === 1) {
        let atts = Object.assign({}, this.state.atts);
        atts.main_contact = new_contact;
        this.setState({ atts });
      }
    });
  }

  handleContactDeleted(index) {
    let contacts = Object.assign([], this.state.contacts);
    contacts.splice(index, 1);

    this.setState({ contacts });
  }

  findLGAByPostcode() {
    let _this = this;
    if (!isAccess(acc_funeral_home_lookup_lga)) {
      return;
    }
    spinnerService.show("ug");
    axios
      .post(this.props.config.apiUrl + `/${acc_funeral_home_lookup_lga}`, {
        postcode: this.state.atts.address_postcode,
        state: this.state.atts.address_state.toUpperCase(),
      })
      .then(function (response) {
        spinnerService.hide("ug");
        if (response?.data?.status === "success") {
          if (response?.data?.lgas?.length) {
            _this.setState({
              lgaOptions: response?.data?.lgas.map(function (option) {
                return {
                  value: option.id,
                  label: `${option.state_code} - ${option.lga}`,
                };
              }),
            });
          } else {
            notify.show(
              "Could not find matching LGA for state and postcode",
              "error",
              8000
            );
          }
        } else {
          _this.setState({
            lgaOptions: _this.props.home.lga_postcode_maps.map(function (
              option
            ) {
              return {
                value: option.id,
                label: `${option.state} - ${option.lga}`,
              };
            }),
          });
        }
      });
  }
}

export default ProfileFuneralHome;
