import React from 'react';
import NoteEntry from './note-entry.jsx';
import DiamondView from './diamond-view.jsx';
import Headery  from "../../auth/header.jsx";

import {notify} from 'react-notify-toast';
import Navigation from '../../dashboard/navigation.jsx';

class DiamondEditorNotes extends React.Component {
    render() {  
      return (
        <div className="holder-prop-catalogue">
            <Headery briefTitle='Notes' title={`Notes | Proposal ${this.props.catalogues.existing.atts.proposal.ID}`} handleScroll={this.handleScroll}></Headery>
            <div className="grid-x">
            <Navigation
            navigator={[{ label: "Funeral Homes", path: "/funeral-homes" },
          { label: `${this.props.funeralHome.atts.funeral_home_name}`, path: `/funeral-home/${this.props.funeralHome.ID}` }
        ]}
            heading={`${this.props.proposal.ID}`}
            headingClassName={`navigator-heading-font-size`}
          />
                <div className="cell cell-side-panel cell-catalogue-side-panel">

              

                    {this.props.notes &&
                        <div>
                            {this.props.notes.map(function(note, index){

                                return (
                                <NoteEntry
                                    key={note.ID}
                                    noteIndex={index}
                                    note={note}
                                    pinning={this.state.pinning}
                                    unpin={this.handeUnpin}
                                    pinningNoteIndex={this.state.pinningNoteIndex}
                                    onDeleteNote={this.props.onDeleteNote}
                                    saveNote={this.props.saveNote}                            
                                    
                                    onTogglePin={this.handeOnTogglePin}
                                />
                                );
                            }, this)}
                        </div>
                    }

                    { <NoteEntry
                        key={-1}
                        noteIndex={-1}
                        note={this.state.newNote}
                        pinning={this.state.pinning}
                        pinningNoteIndex={this.state.pinningNoteIndex}
                        onDeleteNote={this.props.onDeleteNote}
                        saveNoteNew={this.props.saveNoteNew}
                        onTogglePin={this.handeOnTogglePin}
                    /> }



                </div>
                <div id="main-scroller" className={`cell auto cell-main-panel cell-diamond-notes  cell-catalogue-list-pinning-${this.state.pinning}`}>
                    <div className="grid-x grid-margin-x">
                        <div className="cell small-12 large-6">                            
                            <DiamondView
                                catalogue_type='existing'
                                price_points_new={this.props.proposal_atts.price_points_new}
                                catalogue={this.props.catalogues.existing}
                                onPricePointClick={this.handleOnPricePointClick}
                                onHeadingClick={this.handleOnHeadingClick}
                                product_cats={this.props.product_cats}
                                notes={this.props.notes}
                            />
                        </div>
                        <div className="cell small-12 large-6">                            
                            <DiamondView
                                catalogue_type='proposed'
                                price_points_new={this.props.proposal_atts.price_points_new}
                                catalogue={this.props.catalogues.proposed}
                                onPricePointClick={this.handleOnPricePointClick}
                                onHeadingClick={this.handleOnHeadingClick}
                                product_cats={this.props.product_cats}
                                notes={this.props.notes}
                            />
                        </div>
                    </div>
                    
                 

 
                  
                </div>

            </div>
        </div>
      );


    }   


    handleOnChangeNoteNew(ignoreIndex, field, value) {
        
        let newNote = Object.assign({}, this.state.newNote);  
        newNote[field] = value;
        
        this.setState({newNote});
     
    }

    handeUnpin(index){
        let new_note = Object.assign(this.props.notes[index]);

        new_note.linked_product = false;
        new_note.linked_catalogue = '';

        this.props.saveNote(new_note, index);
    }


    handleOnPricePointClick(product) {

        if (!this.state.pinning){
            return false;
        }

        for (let [index, note] of this.props.notes.entries()){
            
            if (index === this.state.pinningNoteIndex){
                let new_note = Object.assign(note);  
                new_note.linked_product = product.post;     
                new_note.linked_catalogue = '';
                this.props.saveNote(new_note, index);
            }
        }

        notify.hide();
        this.setState((state) => ({
            pinning: false,
            pinningNoteIndex: null
        }));

    }
    handleOnHeadingClick(catalogue) {

        if (!this.state.pinning){
            return false;
        }

        for (let [index, note] of this.props.notes.entries()){
            
            if (index === this.state.pinningNoteIndex){
                let new_note = Object.assign(note);  
                new_note.linked_catalogue = catalogue;     
                new_note.linked_product = false;
                this.props.saveNote(new_note, index);
            }
        }

        notify.hide();
        this.setState((state) => ({
            pinning: false,
            pinningNoteIndex: null
        }));

    }

    handeOnTogglePin(pinningNoteIndex, note) {
        
        let newval;
        

        if (!this.state.pinning || (this.state.pinning && pinningNoteIndex !== this.state.pinningNoteIndex) ){

            if (note.linked_product){ //Note already pinned
                
                note.linked_product = null;

                newval = false;
            }else{

                newval = true;
                notify.show(`Choose a product to pin this note to...`, 'success', -1);
            }



        }else{
            newval = false
            notify.hide();
        }
        
        this.setState((state) => ({
            pinning: newval,
            pinningNoteIndex: pinningNoteIndex
        }));

    }

    resetNewNote(){
        
        this.setState((state) => ({
            
            newNote: {
                note: '',
                linked_product: null
            }
        }));
        
    }



    constructor(props) {
        super(props);
    
        this.state = {              
            pinning: false,
            pinningNoteIndex: null,
            newNote: {
                note: '',
                linked_product: null
            }
        }

        this.handleOnPricePointClick = this.handleOnPricePointClick.bind(this);
        this.handleOnHeadingClick = this.handleOnHeadingClick.bind(this);
        this.handeOnTogglePin = this.handeOnTogglePin.bind(this);
        this.handleOnChangeNoteNew = this.handleOnChangeNoteNew.bind(this);
        this.handeUnpin = this.handeUnpin.bind(this);
          
    }


    
 
   
  }

  export default DiamondEditorNotes;
