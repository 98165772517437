import moment from "moment";

// Get a unique list of categories
export function getCategories(items = []) {
  return items.reduce((acc, item) => {
    if (!item?.category?.id) return acc;

    const inCats = acc.find((a) => a.id === item.category.id);

    if (!inCats) acc.push(item.category);

    return acc;
  }, []);
}

// Get items matching search term and category
export function getMatches(items = [], search = null, category = null) {
  return items.filter((item) => {
    let matchesSearch = true;

    if (search) {
      matchesSearch =
        item.custom_name.toLowerCase().includes(search.toLowerCase()) ||
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.sku.toLowerCase().includes(search.toLowerCase());
    }

    const inCategory = item.category?.id
      ? item.category.id === category
      : false;

    return matchesSearch && (inCategory || !category);
  });
}

// Find and return difference between two objects
export function getDiff(one = {}, two = {}) {
  if (Array.isArray(one)) {
    return one.filter((o) => !two.find((t) => t.id === o.id));
  } else {
    let difference = {};

    for (const key in one) {
      if (!two.hasOwnProperty(key) || two[key] !== one[key]) {
        difference[key] = one[key];
      }
    }

    return difference;
  }
}

// Find and return the updated items
export function getUpdated(current = {}, updates = {}) {
  return current.map((curr) => {
    const update = updates.find((up) => up.id === curr.id);

    return update ? update : curr;
  });
}

// Get a meta value from an array of meta data
export function getMetaData(key, meta = []) {
  if (!meta.length) return "";

  const found = meta.find((m) => m.key === key);

  return found ? found.value : "";
}

// Check if some meta key/value exists in line items
export function hasLineMeta(key, value, items = []) {
  if (!items.length) return "";

  const found = items.filter((i) =>
    i.meta_data.find((m) => m.key === key && m.value === value)
  );

  return found.length > 0;
}

// Get a date from some meta
export function getMetaDate(key, meta = []) {
  const found = getMetaData(key, meta);

  return found ? moment(found, "YYYYMMDD").format("DD/MM/YYYY") : "";
}

// Get a product option
export function getProductOption(product, key) {
  return (
    getMetaData(key, product?.meta) ||
    (product?.options?.[key] ?? product.dimensions[key]) ||
    ""
  );
}

// Get a product options
export function getProductOptions(product, options) {
  return {
    size: getProductOption(product, "size") || options.size,
    type: getProductOption(product, "type") || options.type,
    length: getProductOption(product, "length") || options.length,
    width: getProductOption(product, "width") || options.width,
    height: getProductOption(product, "height") || options.height,
  };
}

// Transform an address for WooCommerce use
export function transformAddress(address) {
  return {
    country: "AU",
    first_name: address.first_name,
    last_name: address.last_name,
    company: address.funeral_home.funeral_home_name,
    address_1: address.funeral_home.address_line_1,
    address_2: address.funeral_home.address_line_2,
    city: address.funeral_home.address_city,
    state: address.funeral_home.address_state.toUpperCase(),
    postcode: address.funeral_home.address_postcode,
    email: address.user_email,
    phone: address.phone_landline,
  };
}

// Get the imperal conversion for sizes
export function getImperialUnit(value) {
  return Math.round((value / 2.54) * 100) / 100;
}

// Get the options label
export function getOptionLabel(value, options = []) {
  const found = options.find((option) => option.value === value);

  return found ? found.label : value;
}

// Get a route by its id
export function getRouteById(id = null, routes) {
  return id
    ? routes.find((route) => route.id === Number(id))
    : { frequency: "", day: "", date: "" };
}

// Get a route next delivery date
export function getNextDeliveryDate(route) {
  let deliveryDate = moment();
  const weekdays = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  if (!route.frequency?.["value"]) {
    return deliveryDate;
  }

  // Set date to the next monthly date occurance
  if (route.frequency["value"] === "monthly") {
    const date = route.date["value"];

    if (date <= deliveryDate.get("date")) {
      deliveryDate.add(1, "months").date(date);
    } else {
      deliveryDate.date(date);
    }
  }

  const day = route.day["value"];

  // Set date to the next fortnightly date occurance
  // This assumes all fortnightly deliveries happen in the first week
  if (route.frequency["value"] === "fortnightly") {
    const inFirstWeek = moment().week() % 2;

    if (inFirstWeek) {
      if (weekdays[day] <= deliveryDate.get("day")) {
        deliveryDate.add(2, "weeks").isoWeekday(day);
      } else {
        deliveryDate.isoWeekday(day);
      }
    } else {
      deliveryDate.add(1, "weeks").isoWeekday(day);
    }
  }

  // Set date to the next weekly date occurance
  if (route.frequency["value"] === "weekly") {
    if (weekdays[day] <= deliveryDate.get("day")) {
      deliveryDate.add(1, "weeks").isoWeekday(day);
    } else {
      deliveryDate.isoWeekday(day);
    }
  }

  return deliveryDate;
}
