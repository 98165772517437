import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import Routes from "./routes";
import Regions from "./regions";
import Emails from "./emails";
import Display from "./display";
import Navigation from "../dashboard/navigation";

export default function Options() {
  return (
    <div className="page-content body-white funeral-home-body">
      <div className="grid-container">
        <Navigation
          navigator={[
            { label: "Dashboard", path: "/" },
            { label: "Settings", path: "/" },
          ]}
          heading="Global Settings"
        />
        <div className="grid-x">
          <nav className="options-nav cell small-12">
            <NavLink className="button right-pad" to="/options/routes">
              <i className="fas fa-arrows"></i> Delivery routes
            </NavLink>
            <NavLink className="button right-pad" to="/options/regions">
              <i className="fas fa-map-marker-alt"></i> Region settings
            </NavLink>
            <NavLink className="button right-pad" to="/options/emails">
              <i className="fas fa-envelope"></i> Email templates
            </NavLink>
            <NavLink className="button" to="/options/display">
              <i className="fas fa-eye"></i> Display options
            </NavLink>
          </nav>
          <div className="cell small-12">
            <Switch>
              <Route exact path="/options/routes">
                <Routes />
              </Route>
              <Route exact path="/options/regions">
                <Regions />
              </Route>
              <Route exact path="/options/emails">
                <Emails />
              </Route>
              <Route exact path="/options/display">
                <Display />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
