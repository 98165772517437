import React from "react";
import Select from "react-select";
import { decodeHTML } from "../../utilities/constant/navbar.constant";

class AddCatalogueProduct extends React.Component {
  render() {
    let emptyProduct = {
      label: "(No product)",
      value: 0,
    };

    let productOptions = Object.assign([], this.props.products); //Clone

    productOptions.unshift(emptyProduct);

    return (
      <form
        autoComplete="off"
        onSubmit={(e) => {
          this.props.onSubmit(e);
          this.firstInput.focus();
        }}
      >
        <Select
          value={this.props.add.sel}
          onChange={this.props.onProductSelect}
          options={productOptions}
          className="react-select-container"
          filterOption={this.filterOptions}
        />

        <p>
          <label className="product-label">Product Name (ACCH)</label>
          <input
            name="product_name_acc"
            type="text"
            placeholder="Product Name (ACCH)"
            required
            disabled
            title="Product Name (ACCH)"
            ref={(input) => {
              this.firstInput = input;
            }}
            value={decodeHTML(this.props.add.product_name_acc)}
          // onChange={this.props.onChangeValue}
          />
        </p>
        <p>
          <label className="product-label">Product Name (Home)</label>
          <input
            name="product_name_funeral_home"
            type="text"
            placeholder="Product Name (Home)"
            title="Product Name (Home)"
            value={decodeHTML(this.props.add.product_name_funeral_home)}
            required
            onChange={this.props.onChangeValue}
          />
        </p>
        <p>
          <label className="product-label">SAP Code</label>
          <input
            name="sku"
            type="text"
            placeholder="SAP Code"
            title="SAP Code"
            value={decodeHTML(this.props.add.sku)}
            disabled
          // onChange={this.props.onChangeValue}
          />
        </p>
        <p>
          <label className="product-label">Category</label>
          <select
            name="category"
            value={this.props.add.category}
            required
            disabled={!this.props.add.sel}
            onChange={this.props.onChangeValue}
          >
            <option value="">Choose Category...</option>
            {this.props.product_cats.map(function (option, index) {
              return (
                <option key={index} value={option.id}>
                  {option.text}
                </option>
              );
            }, this)}
          </select>
        </p>
        <p>
          <label className="product-label">Whole Sale Price</label>
          <input
            name="price"
            type="number"
            step="0.01"
            min="0"
            placeholder="Whole Sale Price"
            title="Whole Sale Price"
            value={this.props.add.price}
            required
            disabled
          // onChange={this.props.onChangeValue}
          />
        </p>
        <p>
          <label className="product-label">Retail Price</label>
          <input
            name="retail_price"
            type="number"
            step="0.01"
            min="0"
            placeholder="Retail Price"
            title="Retail Price"
            value={this.props.add.retail_price}
            required
            onChange={this.props.onChangeValue}
          />
        </p>

        {(this.props.catalogue_type === "existing" ||
          this.props.catalogue_type === "data") && (
            <p>
              <label className="product-label">Number of sales</label>
              <input
                name="stats_number_of_sales"
                type="number"
                step="1"
                min="0"
                placeholder="Number of sales"
                title="Number of sales"
                value={this.props.add.stats_number_of_sales}
                onChange={this.props.onChangeValue}
              />
            </p>
          )}
        <p>
          <input type="submit" value="Add" disabled={!this.props.add.sel}></input>
        </p>
      </form>
    );
  }

  componentDidMount() {
    this.firstInput.focus();
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedProduct: null,
    };
  }

  filterOptions(option, inputValue) {
    // Customize this logic based on your specific requirements
    const labelMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
    const skuMatch = option?.data?.sku ? option?.data?.sku.toLowerCase().includes(inputValue.toLowerCase()) : false
    return labelMatch || skuMatch;
  };
}

export default AddCatalogueProduct;
