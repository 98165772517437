import React from "react";

//import XLSX from '../../vendor/tempa-xlsx/dist/xlsx.js';
 
import {saveAs} from "file-saver";


class ExcelDownload extends React.Component {
    render() {
        return (
            <i onClick={this.exportFile} className="far fa-file-export"></i>
            
        );
    }

    constructor(props) {
        super(props);
        this.exportFile = this.exportFile.bind(this)
    }

    exportFile() {
        


        let _this = this;
        
        var styleBody = {
            font: {                
                sz: '28'
            },            
        };
        var styleBodyPercent = {
            font: {                
                sz: '28'
            },         
            numFmt:"0.0%"   
        };
        var styleBodyCurrency = {
            font: {                
                sz: '28'
            },         
            numFmt:"$#,##0"   
        };
        var styleBody2dp = {
            font: {                
                sz: '28'
            },         
            numFmt:"0.00"   
        };
        var styleHeader = {
            font: {
                bold: true,
                sz: '48'

            },            
        };
        var styleHeader2 = {
            font: {
                color: {rgb:"FFFFFF"},
                sz: '28'
            },
            fill:{fgColor: {rgb:"93895b"}}
        };
        var styleFooter = {
            font: {
                bold: true,
                sz: '28'
            },
            numFmt:"0.00",
            fill:{fgColor: {rgb:"9ccdda"}}
        };
        var styleFooterNone = {
            font: {
                bold: true,
                sz: '28'
            },
            
            fill:{fgColor: {rgb:"9ccdda"}}
        };
        var styleFooterCurrency = {
            font: {
                bold: true,
                sz: '28'
            },
            numFmt:"$#,##0",
            fill:{fgColor: {rgb:"9ccdda"}}
        };
        var styleFooterPercent = {
            font: {
                bold: true,
                sz: '28'
            },
            numFmt:"0.0%",
            fill:{fgColor: {rgb:"9ccdda"}}
        };
        var styleFooter2 = {
            font: {
                bold: true,
                color: {rgb:"FFFFFF"},
                sz: '36'
            },
            numFmt:"$#,##0",
            fill:{fgColor: {rgb:"892111"}}
        };
        var styleFooter3 = {
            font: {
                bold: true,
                color: {rgb:"FFFFFF"},
                sz: '36'
            },
            numFmt:"$#,##0",
            fill:{fgColor: {rgb:"93895b"}},
            
        };
        
        
        this.props.catalogue.atts.catalogue_products.sort(function(a, b){
            
            let y = parseFloat(a.atts.retail_price);
            let x = parseFloat(b.atts.retail_price);            

            return (x < y) ? -1 : 1;

        });

        
        let mainData = [];

        let max_sales = 12; //Minimum 12 cols
        this.props.catalogue.atts.catalogue_products.forEach(function(catalogue_product, index) {
            
            if (parseInt(catalogue_product.atts.stats_number_of_sales) > max_sales){
                max_sales = parseInt(catalogue_product.atts.stats_number_of_sales);
            }

        });
        
        


        let footerRow = this.props.catalogue.atts.catalogue_products.length + 2;

        this.props.catalogue.atts.catalogue_products.forEach(function(catalogue_product, index) {
            let this_data = [catalogue_product.atts.product_name_funeral_home, catalogue_product.atts.product_name_acc, parseInt(catalogue_product.atts.price), parseInt(catalogue_product.atts.retail_price), '=D' + (index+3) + '-C' + (index+3),  '=D' + (index+3) + '/C' + (index+3)];
            let sales = parseInt(catalogue_product.atts.stats_number_of_sales);
            if (!sales){
                sales = 0;
            }
            
            for (let i=0; i < max_sales; i++){

                if (i < sales){
                    this_data.push('X');
                }else{
                    this_data.push('');
                }
            }

            this_data.push(sales);
            
            this_data.push('=' + _this.toColumnName(max_sales+7) + (index+3) + '/' + _this.toColumnName(max_sales+7) + (footerRow+1));
            
            this_data.push('=' + _this.toColumnName(max_sales+7) + (index+3) + '*D' + (index+3));
            this_data.push('=' + _this.toColumnName(max_sales+7) + (index+3) + '*(D' + (index+3) + '-C' + (index+3) + ')' );
            
            this_data.push('=' + _this.toColumnName(max_sales+10) + (index+3) + '/' + _this.toColumnName(max_sales+10) + (footerRow+7));
            

            mainData.push(this_data);

        });



        let footerData = ['','','', 'Average MOF', ''];

        //MOF on sales
        //footerData.push('=' + _this.toColumnName(max_sales+10) + (footerRow+3) + '/(' + _this.toColumnName(max_sales+10) + (footerRow+3) + '-' + _this.toColumnName(max_sales+10) + (footerRow+7) + ')'  );
        
        //MOF
        footerData.push('=AVERAGE(F3:F' + (footerRow) + ')');
        for (let i=0; i < max_sales; i++){
            
            
            if (i === max_sales-12){
                footerData.push('Unit Sales:');
            }else{
                footerData.push('');
            }
        }
        footerData = footerData.concat('=SUM(' + _this.toColumnName(max_sales+7) + '3:' +  _this.toColumnName(max_sales+7) + (footerRow) + ')');
        footerData = footerData.concat('=SUM(' + _this.toColumnName(max_sales+8) + '3:' +  _this.toColumnName(max_sales+8) + (footerRow) + ')');
        footerData = footerData.concat('=SUM(' + _this.toColumnName(max_sales+9) + '3:' +  _this.toColumnName(max_sales+9) + (footerRow) + ')');
        footerData = footerData.concat('=SUM(' + _this.toColumnName(max_sales+10) + '3:' +  _this.toColumnName(max_sales+10) + (footerRow) + ')');
        footerData = footerData.concat('=SUM(' + _this.toColumnName(max_sales+11) + '3:' +  _this.toColumnName(max_sales+11) + (footerRow) + ')');
        
        


        let headerData1 = [this.props.funeralHome.atts.funeral_home_name, "", "DATE FROM " + this.props.catalogue.atts.start_date, "", "", "", "", "", "", "", "", "", "DATE TO " + this.props.catalogue.atts.end_date];
        let headerData2 = ["YOUR NAME", "ACC HIGGINS NAME", "Cost", "Retail", "Margin", "MOF"];
        for (let i=0; i < max_sales; i++){
            headerData2.push('');
        }
        headerData2.push("Total", "%", "Gross", "Margin", "%");

        let data = [headerData1, headerData2];
        data = data.concat(mainData, [footerData]);
        //data = data.concat(footerData);

        
        var ws = this.excelSheetFromAoA(data);
        
        
        ws[_this.toColumnName(max_sales+7) + (footerRow+3)] = {
            t: 's',
            v: 'Total Retail Sales:',
            s: styleFooter2
        }
        ws[_this.toColumnName(max_sales+10) + (footerRow+3)] = {
            t: 'n',
            f: _this.toColumnName(max_sales+9) + (footerRow+1),
            s: styleFooter2
        }

        ws[_this.toColumnName(max_sales+7) + (footerRow+5)] = {
            t: 's',
            v: 'Retail Sales Average:',
            s: styleFooter2
        }
        ws[_this.toColumnName(max_sales+10) + (footerRow+5)] = {
            t: 'n',
            f: _this.toColumnName(max_sales+10) + (footerRow+3) + '/' + _this.toColumnName(max_sales+7) + (footerRow+1),
            s: styleFooter2
        }


        ws[_this.toColumnName(max_sales+7) + (footerRow+7)] = {
            t: 's',
            v: 'Gross Margin:',
            s: styleFooter2
        }
        ws[_this.toColumnName(max_sales+10) + (footerRow+7)] = {
            t: 'n',
            f: _this.toColumnName(max_sales+10) + (footerRow+1),
            s: styleFooter2
        }

        ws[_this.toColumnName(max_sales+7) + (footerRow+9)] = {
            t: 's',
            v: 'Average Retail Margin:',
            s: styleFooter3
        }
        ws[_this.toColumnName(max_sales+10) + (footerRow+9)] = {
            t: 'n',
            f: _this.toColumnName(max_sales+10) + (footerRow+7) + '/' + _this.toColumnName(max_sales+7) + (footerRow+1),
            s: styleFooter3
        }
        
        
        
        ws["!merges"] = [
            {s:{r:0,c:0},e:{r:0,c:1}}, 
            {s:{r:0,c:2},e:{r:0,c:11}}, 
            {s:{r:0,c:12},e:{r:0,c:31}},
            {s:{r:(footerRow),c:3},e:{r:footerRow,c:4}},
            {s:{r:(footerRow),c:(max_sales-6) },e:{r:footerRow,c:max_sales+5}}, 

            {s:{r:(footerRow+2),c:(max_sales+6) },e:{r:footerRow+2,c:max_sales+8}}, 
            {s:{r:(footerRow+2),c:(max_sales+9) },e:{r:footerRow+2,c:max_sales+10}}, 
            {s:{r:(footerRow+4),c:(max_sales+6) },e:{r:footerRow+4,c:max_sales+8}}, 
            {s:{r:(footerRow+4),c:(max_sales+9) },e:{r:footerRow+4,c:max_sales+10}},
            {s:{r:(footerRow+6),c:(max_sales+6) },e:{r:footerRow+6,c:max_sales+8}}, 
            {s:{r:(footerRow+6),c:(max_sales+9) },e:{r:footerRow+6,c:max_sales+10}}, 
            {s:{r:(footerRow+8),c:(max_sales+6) },e:{r:footerRow+8,c:max_sales+8}}, 
            {s:{r:(footerRow+8),c:(max_sales+9) },e:{r:footerRow+8,c:max_sales+10}} 
        ]; 

        //Adjust sheet range
        ws['!ref'] = 
            'A1:' + _this.toColumnName(max_sales+11) + (footerRow+9)
        ;
        ws['!cols'] = [
            {wch:68},
            {wch:68},
            {wch:18},
            {wch:18},
            {wch:18},
            {wch:18},  
        ];
        for (let i=0; i < max_sales; i++){
            ws['!cols'].push(
                {wch:4},                
            )
        }
        ws['!cols'].push(
            {wch:18},
            {wch:18},
            {wch:22},
            {wch:22},
            {wch:68}
        );

        ws.A1.s = styleHeader;
        ws.C1.s = styleHeader;
        ws.M1.s = styleHeader;
        

        for (let i=0; i < max_sales+11; i++){
            let colName = this.toColumnName(i+1) + '2' ;
            ws[colName].s = styleHeader2;
        }
        this.props.catalogue.atts.catalogue_products.forEach(function(catalogue_product, index) {
            for (let i=0; i < max_sales+11; i++){
                let colName = _this.toColumnName(i+1) + (index+3) ;
                
                if (i === max_sales + 7 || i === max_sales + 10){
                    ws[colName].s = styleBodyPercent;
                }else if (i === 5){
                    ws[colName].s = styleBody2dp
                }else if (i === 2 || i === 3 || i === 4 || i === max_sales + 8 || i === max_sales + 9){
                    ws[colName].s = styleBodyCurrency;
                }else{
                    ws[colName].s = styleBody;
                }

                

            }
        });
        
        ws[ 'D' + (footerRow+1) ].s =styleFooter;
        ws[ 'F' + (footerRow+1) ].s =styleFooter;
        
        
        
        ws[ _this.toColumnName(max_sales-5) + (footerRow+1) ].s =styleFooterNone;
        
        ws[ _this.toColumnName(max_sales+7) + (footerRow+1) ].s =styleFooterNone;
        ws[ _this.toColumnName(max_sales+8) + (footerRow+1) ].s =styleFooterPercent;
        ws[ _this.toColumnName(max_sales+9) + (footerRow+1) ].s =styleFooterCurrency;
        ws[ _this.toColumnName(max_sales+10) + (footerRow+1) ].s =styleFooterCurrency;
        ws[ _this.toColumnName(max_sales+11) + (footerRow+1) ].s =styleFooterPercent;

     
        

        let wb = {            
            SheetNames: ['Main'],
            Sheets: {}
            
        };

        wb.Sheets['Main'] = ws;


    
        const fileExtension = 'xlsx'
        const fileName = 'ACC_Higgins_Worksheet.xlsx'
        
        const wbout = window.XLSX.write(wb, {bookType: fileExtension, bookSST: true, type: 'binary'});
        saveAs(new Blob([this.strToArrBuffer(wbout)], {type: "application/octet-stream"}), fileName);
            


          
    };

    toColumnName(num) {
        for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
          ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
        }
        return ret;
    }
      

    strToArrBuffer = (s) => {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
    
        for (var i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
    
        return buf;
    };
    
    
    dateToNumber = (v, date1904) => {
        if (date1904) {
            v += 1462;
        }
    
        var epoch = Date.parse(v);
    
        return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
    };

    excelSheetFromAoA = (data) => {
        
        var ws = {};
        var range = {s: {c: 10000000, r: 10000000}, e: {c: 0, r: 0}};

        for (var R = 0; R !== data.length; ++R) {
            for (var C = 0; C !== data[R].length; ++C) {
                if (range.s.r > R) {
                    range.s.r = R;
                }

                if (range.s.c > C) {
                    range.s.c = C;
                }

                if (range.e.r < R) {
                    range.e.r = R;
                }

                if (range.e.c < C) {
                    range.e.c = C;
                }

                var cell = {v: data[R][C]};

               

                if (cell.v === null) {
                    continue;
                }else{
                    if (typeof cell.v === 'string' && cell.v.substring(0,1) === '=' ){                    
                        cell = {f: cell.v.substring(1)};                        
                    }
                }

                var cellRef = window.XLSX.utils.encode_cell({c: C, r: R});
                if (cell.f) {
                    //Add nothing
                    cell.t = 'n';
                } else if (typeof cell.v === 'number') {
                    cell.t = 'n';
                } else if (typeof cell.v === 'boolean') {
                    cell.t = 'b';
                } else if (cell.v instanceof Date) {
                    cell.t = 'n';
                    cell.z = window.XLSX.SSF._table[14];
                    cell.v = this.dateToNumber(cell.v);
                } else {
                    cell.t = 's';
                }

                ws[cellRef] = cell;
            }
        }

        if (range.s.c < 10000000) {
            ws['!ref'] = window.XLSX.utils.encode_range(range);
        }

        
        return ws;

        
        
    };

} 
export default ExcelDownload;
