import React, { useContext, useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Products from "./products";
import Accessories from "./accessories";
import Review from "./review";
import Product from "./components/product";
import ProductAdded from "./components/product-added";
import ProductCart from "./components/product-cart";
import Modal from "../widgets/modal";
import { CartContext } from "../context/cart";
import { useFetch } from "../hooks";
import { acc_shop_products, apiUrl } from "../config/config";

export default function Shop({ path, match = false, title = null, home = null }) {
    const location = useLocation();
    const { cart, setCart, setHome, editItem, setEditItem, showAdded, setShowAdded, showCart, setShowCart } = useContext(CartContext);
    const productEndpoint = home ? `${apiUrl}/${acc_shop_products}?home=${home}` : `${apiUrl}/${acc_shop_products}`;
    let [products, setProducts] = useState(null);
    let [catalogue, setCatalogue] = useState([]);
    let [accessories, setAccessories] = useState([]);
    let [cartResponse] = useFetch(`${apiUrl}/cocart/v2/cart`, false);
    let [productsResponse] = useFetch(productEndpoint);

    const base = match || path;
    let items = cart?.items || []
      
    // Set the funeral home we're using the shop as
    useEffect(() => {
        if (home) setHome(home);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => window.scrollTo(0, 0), [location.pathname]);

    useEffect(() => {
        if (cartResponse) {
            setCart(cartResponse);
        }

        if (productsResponse) {
            setProducts(productsResponse.products);
            setCatalogue(productsResponse.catalogue);
            setAccessories(productsResponse.accessories);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartResponse, productsResponse]);

    return (
        <div className="shop">
            {products && <Switch>
                <Route exact path={`${base}`}>
                    <Products path={path} match={match} items={catalogue} products={products} title={title} />
                </Route>
                <Route exact path={`${base}/accessories`}>
                    <Accessories path={path} match={match} items={accessories} title={title} />
                </Route>
                <Route exact path={`${base}/review`}>
                    <Review path={path} match={match} items={items} title={title} />
                </Route>
            </Switch>}

            <Modal open={showCart} classes="modal-product-cart">
                <button onClick={() => setShowCart(false)} className="btn-close"><i className="far fa-times"></i></button>
                <ProductCart path={path} items={items} />
            </Modal>
            <Modal open={Boolean(editItem)} classes="modal-product">
                <button onClick={() => setEditItem(null)} className="btn-close"><i className="far fa-times"></i></button>
                {editItem && <Product item={editItem} />}
            </Modal>
            <Modal open={showAdded} classes="modal-product-added">
                <button onClick={() => setShowAdded(false)} className="btn-close"><i className="far fa-times"></i></button>
                <ProductAdded />
            </Modal>
        </div>
    );
}
