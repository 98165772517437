import React, { useContext } from "react";
import { AuthContext } from "../../context/auth";
import { getProductOption } from "../../helpers";
import { decodeHTML } from "../../utilities/constant/navbar.constant";

export default function ProductTable({ items = [], edit = null, remove = null, approved = false }) {
    const { isAdmin } = useContext(AuthContext);

    return (
        <table className="shop-table product-table">
            <thead>
                <tr>
                    <th>Type/Image</th>
                    <th>Product Name</th>
                    <th>Details</th>
                    <th>Size</th>
                    <th className="text-center">QTY</th>
                    {(edit || remove) && <th></th>}
                    <th className="no-padding"></th>
                </tr>
            </thead>
            <tbody>
                {items.map((item) => {
                    const type = getProductOption(item, 'type');
                    const size = getProductOption(item, 'size');
                    const notice = (isAdmin() || approved) ? 'Product contains custom dimensions' : 'ACC Higgins will need to approve this item before arranging delivery';

                    return (
                        <tr key={item?.key || item.id}>
                            <td className="product-table-thumb">
                                <div className="product-table-media" style={{ backgroundColor: item.category?.color ?? '#eee' }}>
                                    {item.image.medium && <img src={item.image.medium} alt={item.custom_name || item.name} className="product-table-image" />}
                                    { ( ! item.image.medium && item.image.src) && <img src={item.image.src} alt={item.custom_name || item.name} className="product-table-image" />}
                                </div>
                            </td>
                            <td className="product-table-title" data-title="Product Name">
                                <small className="product-table-sku">SAP: {decodeHTML(item.sku)}</small>
                                <h4 className="product-table-name">{decodeHTML(item.custom_name || item.name)}</h4>
                                <small className="product-table-higgin-name">{decodeHTML(item.name)}</small>
                            </td>
                            <td className="product-table-description" data-title="Details">
                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                            </td>
                            <td className="product-table-dimensions" data-title="Size">
                                <ul>
                                    {type && <li className="capitalize"><small>{type}</small></li>}
                                    <li>L: {getProductOption(item, 'length')}{item.dimensions.unit}</li>
                                    <li>W: {getProductOption(item, 'width')}{item.dimensions.unit}</li>
                                    <li>H: {getProductOption(item, 'height')}{item.dimensions.unit}</li>
                                </ul>
                            </td>
                            <td className="product-table-quantity" data-title="QTY">{item.quantity.value || item.quantity}</td>
                            {(edit || remove) && <td className="product-table-actions">
                                {edit && <button type="button" className="button button--reset button-edit" aria-label="Edit product" onClick={() => edit(item)}><i className="fas fa-pencil-alt"></i></button>}
                                {remove && <button type="button" className="button button--reset button-remove" aria-label="Remove product" onClick={() => remove(item)}><i className="fas fa-times"></i></button>}
                            </td>}
                            <td className="shop-table-notice">
                                {size === 'specialty' && <i className="fas fa-info-circle" data-tip={notice}></i>}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
}
