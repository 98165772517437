import React, { useContext } from "react";
import { CartContext } from "../../context/cart";

export default function CartWidget() {
  const { cart, setShowCart } = useContext(CartContext);

  const showCart = () => {
    if (cart?.items) {
      setShowCart(true);
    }
  };

  return (
    <button
      type="button"
      className="button cart-widget"
      data-items={Boolean(cart?.items?.length)}
      onClick={showCart}
    >
      <i className="cart-widget-icon fal fa-shopping-cart"></i>
      <span>
        {cart?.item_count || 0} items added
        {cart?.item_count > 0 && (
          <small className="cart-widget-view">view order</small>
        )}
      </span>
    </button>
  );
}
