import React, { useEffect, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import RequestReset from './request-reset'
import UpdatePassword from './update-password'
import { AuthContext } from "../../context/auth";
import { useQuery } from "../../hooks";
import logo from "../../images/portal-logo2.svg";

export default function PasswordReset() {
    const { authed } = useContext(AuthContext);
    const query = useQuery();
    const key = query.get("key");

    useEffect(() => {
        spinnerService.hide("ug");
    }, []);

    if (authed()) return <Redirect to={`/`} />;

    return (
        <div className="wrapper-cell-sign-in">
            <div className="cell cell-sign-in">
                <div className="logo">
                    <Link to="/sign-in" className="logo">
                        <img alt="ACC Portal Logo" src={logo} />
                    </Link>
                </div>

                <h5>Reset Password</h5>

                {key ? <UpdatePassword code={key} /> : <RequestReset /> }
            </div>
        </div>
    );
}
