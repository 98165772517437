import React from 'react';

import { NavLink, Link } from "react-router-dom";

import axios from 'axios';

import { spinnerService } from '@chevtek/react-spinners';


import Headery  from "../auth/header.jsx";
import { isAccess } from '../utilities/constant/navbar.constant.js';
import Navigation from '../dashboard/navigation.jsx';




class CustomerMailoutAudiences extends React.Component {
    render() {
      
        return (
            <React.Fragment>
                <Headery briefTitle="Customer Mailout Audiences" title="Customer Mailout Audiences" handleScroll={this.handleScroll}></Headery>
                <div className="body-white body-customer-mailouts customer-mailout-audiences" id="main-scroller">
                    <div className="grid-container">
                    <Navigation
                        navigator={[{ label: "Customer Mailouts", path: "/customer-mailouts" }]}
                        heading="Manage Audiences"
                    />
                        <div className="cell small-12 large-12">
                            
                            <NavLink className="button nav-new-mailout right-pad" to={`/customer-mailout-audience`}><button>Create New Audience</button></NavLink>
                            <NavLink className="button nav-new-mailout" to={`/customer-mailout`}><button>Create New Mailout</button></NavLink>
                            
                            
                        </div>
                        <div className="cell small-12">
                            <button onClick={() => this.filter(null)}  className={`audience-filter button ${this.state.filtered === null ? 'selected' : '' } `} >All</button>
                            <button onClick={() => this.filter('VIC')}  className={`audience-filter button ${this.state.filtered === 'VIC' ? 'selected' : '' } `} >VIC</button>
                            <button onClick={() => this.filter('QLD')}  className={`audience-filter button ${this.state.filtered === 'QLD' ? 'selected' : '' } `} >QLD</button>
                            <button onClick={() => this.filter('NSW')}  className={`audience-filter button ${this.state.filtered === 'NSW' ? 'selected' : '' } `} >NSW</button>
                            <button onClick={() => this.filter('WA')}  className={`audience-filter button ${this.state.filtered === 'WA' ? 'selected' : '' } `} >WA</button>
                            <button onClick={() => this.filter('SA')}  className={`audience-filter button ${this.state.filtered === 'SA' ? 'selected' : '' } `} >SA</button>
                            <button onClick={() => this.filter('NT')}  className={`audience-filter button ${this.state.filtered === 'NT' ? 'selected' : '' } `} >NT</button>
                            <button onClick={() => this.filter('TAS')}  className={`audience-filter button ${this.state.filtered === 'TAS' ? 'selected' : '' } `} >TAS</button>
                            <button onClick={() => this.filter('ACT')}  className={`audience-filter button ${this.state.filtered === 'ACT' ? 'selected' : '' } `} >ACT</button>
                        </div>     
                        <div className="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 large-up-6 grid-homes">
                        {this.state.audiences && this.state.audiences.map(function(audience, index){
                                            return (
                                                <div key={index} className={`cell audience ${this.state.filtered === audience.state ? 'show' : this.state.filtered === null ? '' : 'hide' }`}>
                                                    <div className="cell-home show" key={index}>
                                                        <div className="cell-home-inner" key={index}>
                                                            <div className="cell-home-inner-inner">
                                                                <Link to={`/customer-mailout-audience/${audience.id}`}>
                                                                    <div className="cell-home-inner-inner">
                                                                        <div className="title">{audience.name}</div>
                                                                    </div>
                                                                </Link>
                                                            </div>    
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                            
                                        }, this)}        
                            </div>
                    </div>

                    
                </div>
                
                
            </React.Fragment>
        )
    }

    constructor(props) {
        super(props)
        this.state = {
            filtered: null
        }
        if (!isAccess('acc/v1/customer-mailouts/audience')) {
            return;
          }
        spinnerService.show('ug');
        axios.get(this.props.config.apiUrl + '/acc/v1/customer-mailouts/audiences', {    
            
        }).then((response) => {
            spinnerService.hide('ug');
            if(response.data){
                this.setState((state) => ({ 
                    audiences: response.data
                }));

                
                
            }
        });
    }

    filter(state){
        if(state !== this.state.filtered){
            this.setState({
                filtered: state
            })
        }
        
    }


   
}

export default CustomerMailoutAudiences;