import React from "react";
import {
  Document,
  StyleSheet,
  PDFViewer,
  BlobProvider,
  Font,
} from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import PDFIntro from "./intro.jsx";
import PDFIntroExisting from "./intro-existing.jsx";
import PDFCoverCurrentSales from "./cover-current-sales.jsx";
import PDFCoverProposed from "./cover-proposed.jsx";
import PDFCatalogue from "./catalogue.jsx";
import PDFPricelist from "./public-pricelist.jsx";
import PDFRear from "./rear.jsx";
import PDFProducts from "./products.jsx";
import PDFSales from "./sales.jsx";
import PDFDiamond from "./diamond.jsx";
import moment from "moment";
import { spinnerService } from "@chevtek/react-spinners";
import ExecutiveSummary from "./executive-summary";

import Styles from "./styles.jsx";
import { AuthContext } from "../../../context/auth.jsx";
import img_cover from "../../../images/bg-cover-1.png";
import img_acc_logo from "../../../images/acc-logo@2x.png";

Font.register({
  family: "Font Awesome 5 Pro",
  src: `${
    window.location.protocol + "//" + window.location.host
  }/webfonts/fa-solid-900.ttf`,
});

class PDFOutput extends React.Component {
  static contextType = AuthContext;

  render() {
    const ACCPage = styled.Page`
      flex-direction: row;
      background-color: white;

      margin: 0;
      padding: 0;
    `;
    const Small = styled.Text`
      font-size: 9px;
      margin-top: 15px;
      margin-bottom: 5px;

      text-align: center;
    `;
    const CenterBox = styled.View`
      flex-grow: 0;
      height: 446px;

      text-align: center;
      width: 270px;
      margin: 0 auto;
      font-size: 8px;

      position: relative;
      z-index: 10;
      justify-content: center;
    `;
    const Heading = styled.Text`
      font-size: 14px;
      text-transform: uppercase;
      font-family: "Helvetica";
    `;
    const AllPage = styled.View`
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 0px;
      width: 100%;
      height: 100%;
      color: #00386c;
    `;
    const ImageLogo = styled.Image`
      width: 63%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      margin-bottom: 50px;
    `;

    const ImageFill = styled.Image`
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;

      margin: 0;
      position: absolute;
      top: 0px;

      bottom: 0px;
      z-index: 0;
    `;

    const stylesX = StyleSheet.create({
      page: {
        flexDirection: "row",
        /*backgroundColor: '#E4E4E4',*/
        backgroundColor: "pink",
        margin: 0,
        padding: 0,
      },
      section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
      },
    });

    let singleMode = this.props.mode === "single";

    let outputs = [];
    if (singleMode) {
      outputs.push(this.props.output.code);
    } else {
      for (let output of this.props.outputs) {
        if (output.include) {
          outputs.push(output.code);
        }
      }
    }

    // Create Document Component
    const MyDocument = () => (
      //const MyDocument = (
      <Document onRender={this.renderComplete}>
        {outputs.indexOf("cover") !== -1 && (
          <ACCPage size="A4">
            <Styles.ImageFillWrap>
              <ImageFill src={img_cover}></ImageFill>
            </Styles.ImageFillWrap>
            <AllPage>
              <CenterBox>
                <ImageLogo src={img_acc_logo}></ImageLogo>

                {this.props.funeralHome.logo ? (
                  <ImageLogo src={`${this.props.funeralHome.logo}`}></ImageLogo>
                ) : (
                  <Small>{this.props.funeralHome.atts.funeral_home_name}</Small>
                )}

                <Small>Presented by </Small>
                <Heading style={stylesX.h2}>
                  {this.context.auth.user_display_name}
                </Heading>
                <Small>{moment().format("DD/MM/YYYY")}</Small>
              </CenterBox>
            </AllPage>
          </ACCPage>
        )}
        {outputs.indexOf("executive-summary") !== -1 && (
          <ExecutiveSummary
            config={this.props.config}
            products={this.props.catalogues.existing.atts.catalogue_products}
            existingCatalogue={this.props.catalogues.existing}
            catalogue={this.props.catalogues.proposed}
            price_points_new={this.props.price_points_new}
          />
        )}
        {outputs.indexOf("intro") !== -1 && (
          <ACCPage size="A4">
            <PDFIntro
              proposal_prefs={this.props.proposal_prefs}
              proposal_atts={this.props.proposal_atts}
              funeralHome={this.props.funeralHome}
              config={this.props.config}
              lgaStats={this.props.lgaStats}
              user={this.context.auth}
            />
          </ACCPage>
        )}
        {outputs.indexOf("intro-existing") !== -1 && (
          <ACCPage size="A4">
            <PDFIntroExisting
              proposal_prefs={this.props.proposal_prefs}
              proposal_atts={this.props.proposal_atts}
              funeralHome={this.props.funeralHome}
              config={this.props.config}
              lgaStats={this.props.lgaStats}
              user={this.context.auth}
            />
          </ACCPage>
        )}
        {outputs.indexOf("current-sales-cover") !== -1 && (
          <PDFCoverCurrentSales
            config={this.props.config}
            funeralHome={this.props.funeralHome}
            existingCatalogue={this.props.catalogues.existing}
          />
        )}
        {outputs.indexOf("current-sales-table") !== -1 && (
          <PDFSales
            config={this.props.config}
            cataloguePages={this.props.catalogueAtts.existing.categories}
            funeral_home_name={this.props.funeralHome.atts.funeral_home_name}
            products={this.props.catalogues.existing.atts.catalogue_products}
            existingCatalogue={this.props.catalogues.existing}
            discount={0}
          />
        )}
        {outputs.indexOf("current-sales-table-discount") !== -1 &&
          parseFloat(this.props.funeralHome.atts.discount_percent) > 0 && (
            <PDFSales
              config={this.props.config}
              cataloguePages={this.props.catalogueAtts.existing.categories}
              funeral_home_name={this.props.funeralHome.atts.funeral_home_name}
              products={this.props.catalogues.existing.atts.catalogue_products}
              existingCatalogue={this.props.catalogues.existing}
              discount={this.props.funeralHome.atts.discount_percent}
            />
          )}
        {outputs.indexOf("catalogue-existing") !== -1 && (
          <PDFCatalogue
            type="existing"
            config={this.props.config}
            cataloguePages={this.props.catalogueAtts.existing.pages}
            funeral_home_name={this.props.funeralHome.atts.funeral_home_name}
          />
        )}
        {outputs.indexOf("diamond-existing-retail") !== -1 && (
          <PDFDiamond
            catalogue_type="existing"
            outputStyle="retail_price_only"
            config={this.props.config}
            product_cats={this.props.product_cats}
            funeral_home_name={this.props.funeralHome.atts.funeral_home_name}
            catalogue={this.props.catalogues.existing}
            price_points_new={this.props.price_points_new}
            notes={this.props.notes}
          />
        )}
        {outputs.indexOf("diamond-existing-full") !== -1 && (
          <PDFDiamond
            catalogue_type="existing"
            outputStyle="full"
            config={this.props.config}
            product_cats={this.props.product_cats}
            funeral_home_name={this.props.funeralHome.atts.funeral_home_name}
            catalogue={this.props.catalogues.existing}
            price_points_new={this.props.price_points_new}
            notes={this.props.notes}
          />
        )}
        {outputs.indexOf("proposed-cover") !== -1 && (
          <PDFCoverProposed funeralHome={this.props.funeralHome} />
        )}
        {outputs.indexOf("proposed-catalogue") !== -1 && (
          <PDFCatalogue
            type="proposed"
            config={this.props.config}
            cataloguePages={this.props.catalogueAtts.proposed.pages}
            funeral_home_name={this.props.funeralHome.atts.funeral_home_name}
          />
        )}
        {outputs.indexOf("diamond-proposed-retail") !== -1 && (
          <PDFDiamond
            catalogue_type="proposed"
            outputStyle="retail_price_only"
            config={this.props.config}
            product_cats={this.props.product_cats}
            funeral_home_name={this.props.funeralHome.atts.funeral_home_name}
            catalogue={this.props.catalogues.proposed}
            price_points_new={this.props.price_points_new}
            notes={this.props.notes}
          />
        )}
        {outputs.indexOf("diamond-proposed-full") !== -1 && (
          <PDFDiamond
            catalogue_type="proposed"
            outputStyle="full"
            config={this.props.config}
            product_cats={this.props.product_cats}
            funeral_home_name={this.props.funeralHome.atts.funeral_home_name}
            catalogue={this.props.catalogues.proposed}
            price_points_new={this.props.price_points_new}
            notes={this.props.notes}
          />
        )}
        {outputs.indexOf("proposed-price-list") !== -1 && (
          <PDFPricelist
            config={this.props.config}
            cataloguePages={this.props.catalogueAtts.proposed.pages_landscape}
            funeralHome={this.props.funeralHome}
          />
        )}
        {outputs.indexOf("proposed-products") !== -1 && (
          <PDFProducts
            config={this.props.config}
            categories={this.props.catalogueAtts.proposed.categories}
          />
        )}
        {outputs.indexOf("rear-cover") !== -1 && (
          <PDFRear config={this.props.config} />
        )}
      </Document>
    );

    if (this.props.outputDownload) {
      return (
        // <PDFDownloadLink document={<MyDocument />} fileName="acc-higgins-output.pdf">
        //     {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Create PDF')}
        // </PDFDownloadLink>
        <BlobProvider document={<MyDocument />}>
          {({ blob, url, loading, error }) => {
            if (!blob || loading) {
              //spinnerService.show('ug');
              return <div>...</div>;
            } else {
              //spinnerService.hide('ug');

              const data = window.URL.createObjectURL(blob);
              var link = document.createElement("a");
              link.href = data;
              link.download = "acc-portal-output.pdf";
              link.click();
              this.props.downloadComplete(this.props.outputIndex, false);

              return <div>ok</div>;
            }
            //(loading ? 'Loading document...' : 'Create PDF')}
          }}
        </BlobProvider>
      );
    } else {
      return (
        <PDFViewer>
          <MyDocument />
        </PDFViewer>
      );
    }
  }

  renderComplete(a) {
    spinnerService.hide("ug");
  }
}

export default PDFOutput;
