import React, { useEffect, useState } from "react";
import { spinnerService } from "@chevtek/react-spinners";
import { notify } from "react-notify-toast";
import axios from "axios";
import Modal from "../widgets/modal";
import Email from "./components/email";
import { useFetch } from "../hooks";
import { getDiff } from "../helpers";
import { acc_options_email, apiUrl } from "../config/config";
import { isAccess } from "../utilities/constant/navbar.constant";

export default function Emails() {
  let [emails, setEmails] = useState(null);
  let [edit, setEdit] = useState(null);
  let [response, refresh] = useFetch(`${apiUrl}/${acc_options_email}`);
    
  useEffect(() => {
    if (response) {
      setEmails(response);
    }
  }, [response]);

  // Set email to edit
  const handleEdit = (key) => {
    setEdit({ key, content: emails[key] });
  };

  const handleEmailUpdate = (data) => {
    const updated = { ...emails, [data.key]: data.content };
    const changes = getDiff(emails, updated);

    if (!Object.keys(changes).length) {
      notify.show("No changes have been made", "info", 3000);
      return;
    }
    
        if (!isAccess(acc_options_email)) {
          return;
        }
    
    spinnerService.show("ug");

    axios
      .post(`${apiUrl}/${acc_options_email}`, { email: updated })
      .then(function (response) {
        if (response?.data?.status && response.data.status === "success") {
          refresh();
          setEmails(updated);
          notify.show("Region saved", "success", 3000);
          setTimeout(() => setEdit(null), 500);
        } else {
          notify.show(
            response?.data?.error ?? "Something went wrong",
            "error",
            3000
          );
        }
      })
      .finally(() => {
        spinnerService.hide("ug");
      });
  };

  return (
    <div className="emails">
      <h2 className="heading-underline">Global emails</h2>

      {emails && (
        <>
          <table className="options-table">
            <thead>
              <tr>
                <th>Email name</th>
                <th>Email preview</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(emails).map((key) => (
                <tr key={key}>
                  <td className="options-table-small capitalize">
                    {key.replace("_", " ")}
                  </td>
                  <td className="options-form-preview">
                    <span>
                      {emails[key] || (
                        <button
                          type="button"
                          className="button button--reset"
                          onClick={() => handleEdit(key)}
                        >
                          Create email override
                        </button>
                      )}
                    </span>
                  </td>
                  <td className="text-right">
                    <button
                      type="button"
                      className="button button--reset"
                      aria-label="Edit email override"
                      data-tip="Edit email override"
                      onClick={() => handleEdit(key)}
                    >
                      <i className="far fa-pencil"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      <Modal open={Boolean(edit)} classes="modal-option" width="780">
        <button onClick={() => setEdit(null)} className="btn-close-options">
          <i className="far fa-times"></i>
        </button>
        <Email
          item={edit}
          update={handleEmailUpdate}
          close={() => setEdit(null)}
        />
      </Modal>
    </div>
  );
}
