import React from "react";
import { Link } from "react-router-dom";
import { getMetaDate, getMetaData } from '../../helpers'

export default function OrderTable({ items }) {
    return (
        <table className="shop-table customer-order-table">
            <tbody>
                {items.map((item) => {
                    const deliveryDate = getMetaDate('order_delivery_date', item.meta_data);
                    const fastTrack = getMetaData('is_order_fast_track', item.meta_data);
                    const freightID = getMetaData('order_freight_id', item.meta_data);
                    const fastTrackRequestName = getMetaData('order_fast_track_approved_by', item.meta_data);
                    const ref = getMetaData('order_reference', item.meta_data);

                    return (
                        <tr key={item.id}>
                            <td >
                                <small>Order number</small> <strong>#{item.id}</strong>
                            </td>
                            <td>
                                <small>Status</small> <strong className="capitalize">{item.status}</strong>
                            </td>
                            <td>
                                {ref && (<><small>REF</small> <strong>{ref}</strong></>)}
                            </td>
                            <td>
                                {deliveryDate && <><small>Delivery date</small> <strong>{deliveryDate}</strong></>}
                            </td>
                            <td>
                            {fastTrack && <><small>{freightID ? 'Fast track freight ID' : 'Fast track requested'}</small> <strong><i className="fas fa-shipping-fast"></i> {freightID ? freightID : fastTrackRequestName}</strong></>}
                            </td>
                            <td className="shop-table-actions">
                                <Link className="button" to={`/customer/orders/${item.id}`}>
                                    <i className="fas fa-file-alt"></i> View order
                                </Link>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
}
