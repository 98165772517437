import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Headery from "../auth/header.jsx";
import FuneralHomeProfile from "../funeral-home/profile/profile.jsx";
import { useFetch } from "../hooks";
import { config, apiUrl, acc_funeral_home } from "../config/config";

export default function Account() {
    let [home, setHome] = useState(null);
    let [response] = useFetch(`${apiUrl}/${acc_funeral_home}`);


    useEffect(() => {
        if (response) {
            setHome(response.funeral_home);
        }
    }, [response]);

    return (
        <div className="page-content customer-account" id="main-scroller">
            <Headery title="My Account" />
            <div className="grid-container">
                <Link to="/customer" className="button--reset"><i className="far fa-chevron-left"></i> Back to dashboard</Link>
                <h1>My Funeral Home</h1>

                {home && (
                    <FuneralHomeProfile
                        config={config}
                        customer
                        home={{
                            ID: home.post.ID,
                            atts: home.atts,
                            lga_postcode_maps: home.lga_postcode_maps,
                            contacts: home.contacts,
                            regions: home.regions,
                            routes: home.routes
                        }}
                        funeral_home_groups={home.funeral_home_groups}
                    />
                )}
            </div>
        </div>
    );
}
