import React from "react";
import { Link } from "react-router-dom";
import Headery from "../auth/header";
import AccountForm from "../auth/account-form";

export default function Profile() {
    return (
        <div className="page-content account customer-profile" id="main-scroller">
            <Headery title="My Profile" />
            <div className="grid-container black-color">
                <Link to="/customer" className="button--reset">
                    <i className="far fa-chevron-left"></i> Back to dashboard
                </Link>
                <h1>My profile</h1>

                <AccountForm />
            </div>
        </div>
    );
}
