import React, { useState, useEffect } from "react";
import { spinnerService } from "@chevtek/react-spinners";
import { notify } from "react-notify-toast";
import axios from "axios";
import { getDiff } from "../../helpers";
import { acc_routes, apiUrl } from "../../config/config";
import { isAccess } from "../../utilities/constant/navbar.constant";

export default function Route({ item, options, refresh, close }) {
  let [route, setRoute] = useState({
    name: "",
    frequency: "",
    day: "",
    date: "",
  });

  useEffect(() => {
    if (item) {
      setRoute({
        name: item.name,
        frequency: item.frequency["value"],
        day: item.day?.["value"],
        date: item.date?.["value"],
      });
    }
  }, [item]);

  const handleUpdate = ({ target }) => {
    setRoute({ ...route, [target.name]: target.value });
  };

  // Actually save changes
  const handleSubmit = (e) => {
    e.preventDefault();

    const changes = getDiff(route, item);

    if (!Object.keys(changes).length) {
      notify.show("No changes have been made", "info", 3000);
      return;
    }
    if (!isAccess(`${acc_routes}/update`)) {
      return;
    }
    spinnerService.show("ug");
    axios
      .post(`${apiUrl}/acc/v1/routes/${item.id}`, route)
      .then(function (response) {
        if (response?.data?.status && response.data.status === "success") {
          notify.show("Route saved", "success", 3000);
          refresh();
          setTimeout(() => close(), 500);
        } else {
          notify.show(
            response?.data?.error ?? "Something went wrong",
            "error",
            3000
          );
        }
      })
      .finally(() => {
        spinnerService.hide("ug");
      });
  };

  // Delete the route
  const handleDelete = () => {
        if (!isAccess(`${acc_routes}/delete`)) {
          return;
        }
    spinnerService.show("ug");

    axios
      .delete(`${apiUrl}/${acc_routes}/${item.id}`)
      .then(function (response) {
        if (response?.data?.status && response.data.status === "success") {
          notify.show("Route deleted", "success", 3000);
          refresh();
          setTimeout(() => close(), 500);
        } else {
          notify.show(
            response?.data?.error ?? "Something went wrong",
            "error",
            3000
          );
        }
      })
      .finally(() => {
        spinnerService.hide("ug");
      });
  };

  return (
    <form
      className="form options-form cell small-12 flex-container flex-dir-column"
      onSubmit={handleSubmit}
    >
      <div className="modal-header">Editing delivery route</div>
      <div className="modal-div">
        <div className="resources-label">Region settings</div>
        <div className="form-section grid-x grid-margin-x">
          <div className="cell small-12 medium-12 large-5">
            <label htmlFor="route_name">Route name</label>
            <input
              type="text"
              name="name"
              id="route_name"
              onChange={handleUpdate}
              placeholder="Delivery route name"
              value={route.name}
              required
            />
          </div>
          <div className="cell small-12 medium-6 large-4">
            <label htmlFor="frequency">Frequency</label>
            <select
              name="frequency"
              id="frequency"
              onChange={handleUpdate}
              value={route.frequency}
              required
            >
              <option value="">Select frequency</option>
              {options.frequency.map(({ value, label }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <div className="cell small-12 medium-6 large-3">
            <label htmlFor="day_date">Day or Date</label>
            {route.frequency !== "monthly" ? (
              <select
                name="day"
                id="day_date"
                disabled={!route.frequency}
                onChange={handleUpdate}
                value={route.day}
                required
              >
                <option value="">Select day</option>
                {options.day.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </select>
            ) : (
              <select
                name="date"
                id="day_date"
                disabled={!route.frequency}
                onChange={handleUpdate}
                value={route.date}
                required
              >
                <option value="">Select date</option>
                {options.date.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        <div className="grid-x grid-margin-x align-middle">
          <div className="cell small-6">
            <button
              type="button"
              className="button button--reset"
              onClick={close}
            >
              <i className="far fa-chevron-left"></i> Cancel
            </button>
          </div>
          <div className="cell small-6 flex-container align-right">
            <button
              type="button"
              className="button button--ghost right-pad"
              onClick={handleDelete}
            >
              <i className="fas fa-trash"></i> Delete delivery route
            </button>
            <button type="submit" className="button">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
