import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import ProductSearch from "./components/product-search";
import ProductCard from "./components/product-card";
import Footer from "./components/footer";
import { getCategories, getMatches } from "../helpers";
import Navigation from "../dashboard/navigation";
import { FuneralHome } from "../context/funeral-home";

export default function Accessories({ path, items, title = null }) {
  const { selectedFuneralHome } = useContext(FuneralHome);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(getCategories(items));
  }, [items]);

  useEffect(() => {
    setList(getMatches(items, search, category));
  }, [search, category, items]);

  return (
    <div className="shop-accessories">
      <Navigation
        navigator={[
          { label: "Funeral Home", path: "/funeral-homes" },
          { label: "Orders", path: "/orders" },
          {
            label: `New order for ${
              selectedFuneralHome?.funeral_home?.funeral_home_name ||
              ''
            }`,
            path: `/funeral-home/${
              selectedFuneralHome?.funeral_home?.id
            }`,
          },
        ]}
        heading="Add accessories"
        path={path}
      />
      <div className="resources-label">
        Browse our catalogue to add another product
      </div>
      {items.length > 0 && (
        <>
          <ProductSearch
            search={search}
            text="Type SAP or product name to search catalogue or select a category below"
            handleSearch={(value) => setSearch(value)}
          />

          <button
            className={`button button--ghost accessory-categories ${
              category === null && "active-button-color"
            }`}
            onClick={() => setCategory(null)}
          >
            All
          </button>
          {categories.map(({ id, name }) => (
            <button
              className={`button button--ghost accessory-categories ${
                id === category && "active-button-color"
              }`}
              onClick={() => setCategory(id)}
            >
              {name}
            </button>
          ))}

          <div className="grid-x grid-margin-x shop-products-list">
            {list.map((item) => (
              <ProductCard item={item} key={item.id} />
            ))}
            {search && !list.length && (
              <p className="info cell small-12">No matching accessories</p>
            )}
          </div>
        </>
      )}

      {!items.length && (
        <h4>There are no accessories available at this time.</h4>
      )}

      <Footer
        prev={
          <Link to={`${path}`} className="button button--reset">
            <i className="far fa-chevron-left"></i> Back
          </Link>
        }
        next={
          <Link to={`${path}/review`} className="button">
            Next
          </Link>
        }
      />
    </div>
  );
}
