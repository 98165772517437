import React from 'react';
import Contact from './contact.jsx';

class Contacts extends React.Component {
    render() {   
      return (
        <div className="table-contacts-wrap">
            <table className="table-contacts">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Position</th>
                        <th>Mobile</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>&nbsp;</th>
                    </tr>

                </thead>
                
                {this.props.contacts.map(function(contact, index){
                    return (
                        <tbody key={contact.ID} className="contact-entry">
                           
                            <Contact 
                                config={this.props.config}                                
                                contact={contact}
                                defaultContact={this.props.defaultContact}
                                index={index}
                                contactDeleted={this.props.contactDeleted}
                                contactSetDefault={this.props.contactSetDefault}
                            />
                            
                        </tbody>
                    );
                }, this)}

                {this.state.adding &&

                        <tbody className="contact-entry contact-entry-new">
                            
                            <Contact 
                                config={this.props.config}                                
                                contact={this.state.new_contact}
                                contactAdded={this.contactAdded}
                                
                            />
                            
                        </tbody>

                }


            

            </table>

            <button className="button btn-add-contact" type="button" onClick={this.toggleAdd}>
                <i className="fas fa-user-plus"></i> {this.state.adding ? 'Cancel Adding Contact' : 'Add New Contact'}
            </button>


        </div>
      );
    }  

   
    
 
    constructor(props) {
        super(props);        

        this.state = {
            adding: false,            
        }

        this.toggleAdd = this.toggleAdd.bind(this);
        this.contactAdded = this.contactAdded.bind(this);
    }


    contactAdded(new_contact){

        this.toggleAdd();

        this.props.contactAdded(new_contact);
    }

    toggleAdd(e){
        
        this.setState((state) => ({
            adding: !this.state.adding,
            new_contact:{
                ID: 'new',
                atts:{
                    user_email: "",
                    first_name: "",
                    funeral_home: this.props.home_id,
                    last_name: "",
                    notes: "",
                    phone_landline: "",
                    phone_mobile: "",
                    position: ""
                }
            }
        })); 

        
    }
    
  }

  export default Contacts;

