import React from 'react';


class DisplayError extends React.Component {
    render() {  
                
      return (
        <div className="grid-container">
          <div className="grid-x">
            <div className="cell small-12 display-error"> 
                <h2>A serious error has occured.</h2>
                <p>{this.props.error.message}</p>
                <p>Please contact support:</p>
                <p><a href="mailto:support@bjmdesign.com.au">support@bjmdesign.com.au</a></p>
            </div>
          </div>
        </div>
             
      );
    }   

  }

  export default DisplayError;
