import React from "react";

export default function Resource({
  icon,
  title,
  category,
  featured_media,
  imgURL,
  resourceURL,
}) {
  return (
    <div
      className="shop-products-card cell small-12 medium-4 large-4 flex-container flex-dir-column resource-div"
      onClick={() => window.open(resourceURL)}
    >
      <div className="shop-products-card-media">
        {featured_media ? (
          <img src={imgURL} alt={"..."} className="shop-products-card-image" />
        ) : (
          <div className="shop-products-card-icon">
            <i className={`fas ${icon}`}></i>
          </div>
        )}
      </div>
      <div className="shop-products-card-content flex-child-grow align-justify">
        <div className="details-section">
          <div className="resource-label">{category}</div>
          <div className="resource-title">{title}</div>
        </div>
      </div>
    </div>
  );
}
