import React from 'react';

import { NavLink, Redirect } from "react-router-dom";
import PrivateRoute from '../auth/private-route.jsx';
import axios from 'axios';
import Catalogue from './catalogue/catalogue.jsx';
import ioCatalogue from './catalogue/io-catalogue.jsx';
import DiamondEditor from './diamond-editor/diamond-editor.jsx';
import DiamondEditorNotes from './diamond-editor/diamond-editor-notes.jsx';
import Output from './output/output.jsx';
import { spinnerService } from '@chevtek/react-spinners';
import {notify} from 'react-notify-toast';
import Headery  from "../auth/header.jsx";
import ProposalOverview  from "./overview.jsx";
import { isAccess } from '../utilities/constant/navbar.constant.js';

class Proposal extends React.Component {

    render() {  
      

      return (
        <div className="react-main">
        
          <Headery  title={`Proposal ${this.props.match.params.id}`}></Headery>

            {this.state.error_go_back && 
              <Redirect to={`/`} /> 
            }
            {this.state.redirect_to_funeral_home && 
              <Redirect to={`/funeral-home/${this.state.atts.funeral_home.ID}`} /> 
            }

            {this.state.post &&

                <div className="grid-x">

                  <div className="cell left-nav">
                  
                    <ul>                  
                      <li>
                        <NavLink to={`/proposals/${this.state.post.ID}`} exact data-tip={`Proposal Overview`} data-place="right"><i className="far fa-info-circle"></i></NavLink>
                      </li>
                      <li>
                        <NavLink to={`/proposals/${this.state.post.ID}/content/existing-catalogue`} data-tip={`Enter Existing Catalogue & Sales`} data-place="right"><i className="far fa-tag"></i></NavLink>
                      </li>
                      <li>
                        <NavLink to={`/proposals/${this.state.post.ID}/content/proposed-catalogue`} data-tip={`Enter Proposed Catalogue`} data-place="right"><i className="far fa-thumbs-up"></i></NavLink>
                      </li>
                      <li>
                        <NavLink to={`/proposals/${this.state.post.ID}/content/diamond-editor`} data-tip={`Setup Diamond Price Points`} data-place="right"><i className="far fa-diamond"></i></NavLink>
                      </li>
                      <li>
                        <NavLink to={`/proposals/${this.state.post.ID}/content/diamond-editor-notes`} data-tip={`Add Diamond Notes`} data-place="right"><i className="far fa-comment"></i></NavLink>
                      </li>
                      <li>
                        <NavLink to={`/proposals/${this.state.post.ID}/content/output`} data-tip={`Output Documents`} data-place="right"><i className="far fa-arrow-alt-to-bottom"></i></NavLink>
                      </li> 
                    </ul>
                  </div>
                  <div className="cell auto proposal-body body-white">
                    
                    

                    <div className="proposal-right-body-content">
 

                        <PrivateRoute
                          exact
                          config={this.props.config}      
                          path={`/proposals/${this.state.post.ID}/content/existing-catalogue`}
                          
                          render={(props) => 
                            <Catalogue
                              onChangeValue={this.ioCatalogue.handleInputChangeCatalogue}                              
                              onChangeValueLine={this.ioCatalogue.handleInputChangeCatalogueProduct}
                              onChangeValueAddLine={this.ioCatalogue.handleInputChangeAddCatalogueProduct}
                              onSubmitAddLine={this.ioCatalogue.handleSubmitAddCatalogueProduct}
                              onDeleteLine={this.ioCatalogue.handleDeleteCatalogueProduct}
                              onProductSelect={this.ioCatalogue.handleProductSelect}
                              proposal={this.state.post}
                              funeralHome={this.state.atts.funeral_home}
                              add={this.state.atts.catalogues.existing.atts.new_product}
                              catalogue={this.state.atts.catalogues.existing}
                              product_cats={this.state.product_cats}
                              products={this.state.products}
                              catalogue_type="existing"

                              {...props}
                  
                            ></Catalogue>
                          }               
                        />
                        <PrivateRoute
                          exact                
                          config={this.props.config}      
                          path={`/proposals/${this.state.post.ID}/content/proposed-catalogue`}
                          render={() => 
                            <Catalogue 
                              onChangeValue={this.ioCatalogue.handleInputChangeCatalogue}
                              onChangeValueLine={this.ioCatalogue.handleInputChangeCatalogueProduct}
                              onChangeValueAddLine={this.ioCatalogue.handleInputChangeAddCatalogueProduct}                          
                              onSubmitAddLine={this.ioCatalogue.handleSubmitAddCatalogueProduct}            
                              onDeleteLine={this.ioCatalogue.handleDeleteCatalogueProduct}            
                              onProductSelect={this.ioCatalogue.handleProductSelect}
                              proposal={this.state.post}
                              funeralHome={this.state.atts.funeral_home}
                              add={this.state.atts.catalogues.proposed.atts.new_product}
                              catalogue={this.state.atts.catalogues.proposed}
                              product_cats={this.state.product_cats}
                              products={this.state.products}
                              catalogue_type="proposed"
                            ></Catalogue>
                          }               
                        />
                        <PrivateRoute
                          exact
                          config={this.props.config}
                          path={`/proposals/${this.state.post.ID}`}
                          render={() => 
                            <ProposalOverview 
                              proposal={this.state.post}
                              funeralHome={this.state.atts.funeral_home}
                              onChangeConfig={this.handleInputChangeProposalConfig}
                              output_introductory_text={this.state.atts.output_introductory_text}
                              archive={this.handleArchive}
                          ></ProposalOverview>
                          }
                        />
                        <PrivateRoute
                          exact
                          config={this.props.config}
                          path={`/proposals/${this.state.post.ID}/content/diamond-editor`}
                          render={() => 
                            <DiamondEditor
                            proposal={this.state.post}
                              funeralHome={this.state.atts.funeral_home}
                              product_cats={this.state.product_cats}
                              proposal_atts={this.state.atts}                          
                              catalogues={this.state.atts.catalogues}
                              onChangeConfig={this.handleInputChangeProposalConfig}
                              onChangePricePoint={this.handleInputChangePricePoint}
                              onChangePricePointShapeEdge={this.handleInputChangePricePointShapeEdge}
                              onAddPricePointBefore={this.handleAddPricePointBefore}
                              onDeletePricePoint={this.handleDeletePricePoint}
                            ></DiamondEditor>
                          }               
                        />
                        <PrivateRoute
                          exact
                          config={this.props.config}
                          path={`/proposals/${this.state.post.ID}/content/diamond-editor-notes`}
                          render={() => 
                            <DiamondEditorNotes
                            proposal={this.state.post}
                              funeralHome={this.state.atts.funeral_home}
                            product_cats={this.state.product_cats}
                              ref={this.childDiamondEditorNotes} 
                              notes={this.state.atts.notes}
                              proposal_atts={this.state.atts}
                              catalogues={this.state.atts.catalogues}         
                              onDeleteNote={this.handleDeleteNote}          
                              saveNote={this.handleSaveNote}                                       
                              saveNoteNew={this.handleSaveNoteNew}                                       
                              onChangeNote={this.handleOnChangeNote}           
                            ></DiamondEditorNotes>
                          }               
                        />
                        <PrivateRoute
                          exact
                          config={this.props.config}
                          path={`/proposals/${this.state.post.ID}/content/output`}
                          render={() => 
                            <Output 
                            proposal={this.state.post}
                              proposalID={this.state.atts.catalogues.existing.atts.proposal.ID}
                              funeralHome={this.state.atts.funeral_home}
                              catalogues={this.state.atts.catalogues}
                              proposal_prefs={this.state.prefs}
                              config={this.props.config}
                              proposal_atts={this.state.atts}
                              product_cats={this.state.product_cats}
                              user={this.props.user}
                              notes={this.state.atts.notes}
                              price_points_new={this.state.atts.price_points_new}
                            ></Output>
                          }               
                        />
          
                    </div>
                  

                  </div>

                </div>

            }
 
            

              
            
          
        </div>

      );
    }  

    constructor(props) {
      super(props);

      let _this = this;            

      _this.state = {};

      
      this.ioCatalogue = new ioCatalogue(this.props.config);
      
      
      let proposal_id = this.props.match.params.id;    
      
      this.childDiamondEditorNotes = React.createRef();
      
      this.ioCatalogue.handleInputChangeCatalogue = this.ioCatalogue.handleInputChangeCatalogue.bind(this);      
      this.ioCatalogue.handleInputChangeCatalogueProduct = this.ioCatalogue.handleInputChangeCatalogueProduct.bind(this);
      this.ioCatalogue.handleInputChangeAddCatalogueProduct = this.ioCatalogue.handleInputChangeAddCatalogueProduct.bind(this);      
      this.ioCatalogue.handleSubmitAddCatalogueProduct = this.ioCatalogue.handleSubmitAddCatalogueProduct.bind(this);
      this.ioCatalogue.handleDeleteCatalogueProduct = this.ioCatalogue.handleDeleteCatalogueProduct.bind(this);
      this.ioCatalogue.handleProductSelect = this.ioCatalogue.handleProductSelect.bind(this);
      this.handleInputChangeProposalConfig = this.handleInputChangeProposalConfig.bind(this);
      this.handleInputChangePricePoint = this.handleInputChangePricePoint.bind(this);
      this.handleInputChangePricePointShapeEdge = this.handleInputChangePricePointShapeEdge.bind(this);      
      this.handleAddPricePointBefore = this.handleAddPricePointBefore.bind(this);
      this.handleDeletePricePoint = this.handleDeletePricePoint.bind(this);
      this.handleDeleteNote = this.handleDeleteNote.bind(this);
      this.handleSaveNote = this.handleSaveNote.bind(this);
      this.handleSaveNoteNew = this.handleSaveNoteNew.bind(this);
      //this.handleOnChangeNote = this.handleOnChangeNote.bind(this);
      this.handleArchive = this.handleArchive.bind(this);

      if (!isAccess('acc/v1/proposal')) {
        return;
      }
      spinnerService.show('ug');
      axios.get(this.props.config.apiUrl + '/acc/v1/proposal/' + proposal_id, {
      }).then(function (response) {
        spinnerService.hide('ug');
        if (response){
          
          if (response.data.error || !response.data.proposal){
            alert('An error occured whilst retrieving this proposal. Returning you to homepage.')
            

            // alert(response.data.error);        

            _this.setState((state) => ({ 
              error_go_back: true,
              
            })); 

            return;
          }

          _this.setState((state) => ({ 
            prefs: response.data.proposal_prefs,
            post: response.data.proposal.post,
            atts: response.data.proposal.atts,
            product_cats: response.data.product_cats,          
            products: response.data.products,          
            redirect_to_funeral_home: false
            
          }));  

          _this.props.setBreadcrumbs({
            funeralHomeName: response.data.proposal.atts.funeral_home.atts.funeral_home_name,
            funeralHomeID: response.data.proposal.atts.funeral_home.ID,
            proposalID: response.data.proposal.post.ID,
          });

        }
      });
    }

    handleArchive(){
      let _this = this;
      if (!isAccess('acc/v1/proposal/archive')) {
        return;
      }
      if (!window.confirm('Are you sure you wish to archive this proposal? It will no longer appear in the list of proposals for ' + this.state.atts.funeral_home.atts.funeral_home_name)){
        return;

      }

      spinnerService.show('ug');      

      axios.post(this.props.config.apiUrl + '/acc/v1/proposal/archive', {
        id: this.state.post.ID,        
      }).then(function (response) {
        spinnerService.hide('ug');
        notify.show('Proposal archived - returning to funeral home', 'success', 3000);

        _this.setState((state) => ({ 
          redirect_to_funeral_home: true,
          
        })); 

      });

    }
    

    handleSaveNote(note, index){
      let notes = Object.assign(this.state.atts.notes);  

      if (note){
        notes[index].note = note.note;
        notes[index].linked_product = note.linked_product;
        notes[index].linked_catalogue = note.linked_catalogue;
      }
      let noteIndex = 0 ;
      for(let [, note] of notes.entries()){
        if (note.linked_product){
          note.linked_product_index = ++noteIndex;
          note.linked_catalogue = '';
        }else{
          note.linked_product_index = null;
        }
      }

      this.setState({notes: notes},() => {
        //this.handleSaveNote();
        if (!isAccess('acc/v1/proposal/notes/update')) {
          return;
        }
        spinnerService.show('ug');     

        axios.post(this.props.config.apiUrl + '/acc/v1/proposal/notes/update', {
          id: this.state.post.ID,
          notes: this.state.atts.notes,        
        }).then(function (response) {
          spinnerService.hide('ug');
          notify.show('Saved note', 'success', 3000);
        });

      });


      
    }
    handleSaveNoteNew(new_note){      

      let atts = Object.assign({}, this.state.atts);  

      if (!atts.notes){
        new_note.ID = 'note-0';
        atts.notes = [new_note];
      }else{
        new_note.ID = 'note-' + atts.notes.length;
        atts.notes.push(new_note);
      }
      
      this.setState(
        {atts},
        () => {
          this.handleSaveNote();
        }
      );

    this.childDiamondEditorNotes.current.resetNewNote();
   
    }
    
    handleDeleteNote(index){
      let notes = Object.assign(this.state.atts.notes);  
      notes.splice(index, 1);         
      
      let noteIndex = 0 ;
      for(let note in notes){
        if (note.linked_product){
          note.linked_product_index = ++noteIndex;
          note.linked_catalogue = '';
        }
      }
      
      this.setState(
        {notes: notes},
        () => {
          this.handleSaveNote();
        }
      );
    }


  savePricePoints(){
    if (!isAccess('acc/v1/proposal/price-points/update')) {
      return;
    }

    axios.post(this.props.config.apiUrl + '/acc/v1/proposal/price-points/update', {
      id: this.state.post.ID,
      price_points: this.state.atts.price_points_new
    }).then(function (response) {
      notify.show('Saved price points', 'success', 3000);
    });

  }


  handleDeletePricePoint(index){
    
    
    let atts = Object.assign({}, this.state.atts);  

    atts.price_points_new.splice(index, 1);

    this.setState(
      {atts},
      () => this.savePricePoints()
    );

    


  
  }
  handleAddPricePointBefore(index){
    
    let lowerValue = parseInt(this.state.atts.price_points_new[index].price);

    let higherValue = parseInt(this.state.atts.price_points_new[index].price) + 2000;

    if (this.state.atts.price_points_new[index-1]){
      higherValue = parseInt(this.state.atts.price_points_new[index-1].price);
    }

    let newValue = {
      price: lowerValue + ((higherValue - lowerValue) /2),
      shape_edge_point_existing: false,
      shape_edge_point_proposed: false
    }

    let atts = Object.assign({}, this.state.atts);  

    atts.price_points_new.splice(index, 0, newValue);

    this.setState(
      {atts},
      () => this.savePricePoints()
    );

  
  }

  handleInputChangePricePoint(newVal){

    let index = parseInt(Object.keys(newVal)[0]);
    let newValue = parseInt(Object.values(newVal)[0]);
    let atts = Object.assign({}, this.state.atts);  

    atts.price_points_new[index].price = newValue;
    
    this.setState(
      {atts},
      () => this.savePricePoints()
    );
    

  }
  handleInputChangePricePointShapeEdge(type, index){
    let atts = Object.assign({}, this.state.atts);  

    if (type === 'existing'){
      atts.price_points_new[index].shape_edge_point_existing = !atts.price_points_new[index].shape_edge_point_existing;
    }else{
      atts.price_points_new[index].shape_edge_point_proposed = ! atts.price_points_new[index].shape_edge_point_proposed;
    }
    
    this.setState(
      {atts},
      () => this.savePricePoints()
    );
    

  }
  handleInputChangeProposalConfig(event){
    
        
    const target = event.target;      
    const name = target.name;

    let atts = Object.assign({}, this.state.atts);  

    Object.assign(atts, {
      [name]: target.value
    });

    this.setState({atts},() => {
      if (!isAccess('acc/v1/proposal/update')) {
        return;
      }
      axios.post(this.props.config.apiUrl + '/acc/v1/proposal/update', {
        id: this.state.post.ID,
        data:{
          output_introductory_text: atts.output_introductory_text,
          number_price_points: atts.number_price_points,
        }
      }).then(function (response) {
        notify.show('Updated ' + name + '.', 'success', 3000);
      });
    });

  }

  


  }



       
export default Proposal
