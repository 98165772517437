import React from 'react';
import { RIENumber } from '@attently/riek'
import {notify} from 'react-notify-toast';

class PricePoints extends React.Component {
    render() {  
        

      
      
      return (
        <div className="price-point-entries">
            <div className="price-point-entry">
       
              <div className="div-edge-point-checkboxes"></div>
              <div className="price-to">
                100,000
              </div>
              <button className="a delete-line" >
              </button>
            </div>

            {this.props.price_points_new &&
              <React.Fragment>
                  {this.props.price_points_new.map(function(price_point, index){
                    
                    return (
                      <div key={index} className="price-point-entry">
                        


                        <div className="div-edge-point-checkboxes">
                          <div data-tip="Add edge point to existing diamond here" className={`chk-shape-edge chk-existing ${ price_point.shape_edge_point_existing ? 'on' : '' }`} onClick={(event) => this.props.onChangePricePointShapeEdge('existing', index)}>
                            <i className="far fa-check-square" ></i>
                          </div>
                          <div data-tip="Add edge point to proposed diamond here" className={`chk-shape-edge chk-proposed ${ price_point.shape_edge_point_proposed ? 'on' : '' }`} onClick={(event) => this.props.onChangePricePointShapeEdge('proposed', index)}>
                            <i className="far fa-check-square" ></i>
                          </div>
                        </div>
                        
            
                        <RIENumber
                          shouldRemainWhileInvalid={false}
                          className="price-to wrap-inline-edit number "
                          classEditing="editing"
                          value={price_point.price}
                          validate={(newVal) => {
                            return this.vali(index, newVal)
                          }}
                          
                          change={this.props.onChangePricePoint}
                          propName={`${index}A`}
                          
                        />   

                        
                        <button
                          
                          className="a delete-line"
                          onClick={() => {                        
                            this.props.onDeletePricePoint(index)
                          }}
                        >
                          {this.props.price_points_new[index+1] &&
                            <i className="far fa-times"></i>
                          }
                        </button>
                        
                        <button
                          
                          className="a insert-after"
                          onClick={() => {                      
                            this.props.onAddPricePointBefore(index)
                          }}
                        >
                          <i className="far fa-plus"></i>
                        </button>
                      </div>
                      
                          
                    
                    
                    );
                  }, this)}
                </React.Fragment>

              }
         
        </div>
      );
    }  

    vali(index, newValue){

      let higherValue = 99999999999;
      if (this.props.price_points_new[index-1]) {
        higherValue = this.props.price_points_new[index-1].price;
      }
      let lowerValue = 0;
      if (this.props.price_points_new[index+1]) {
        lowerValue =  this.props.price_points_new[index+1].price;
      }
  
      if (newValue >= higherValue){
        notify.show('ERROR - value must be less than the next highest price band', 'error', 6000);
        return false;
      }
      if (newValue <= lowerValue){
        notify.show('ERROR - value must be greater than the next lower price band', 'error', 6000);
        return false;
      }

      return true;
    }

   
  }

  export default PricePoints;
