import React, { useEffect, useState } from "react";
import { spinnerService } from "@chevtek/react-spinners";
import { notify } from "react-notify-toast";
import OrderTable from "./components/order-table";
import { useLocation } from "react-router-dom";
import {
  useQuery,
  useAudioCue,
  useLoadMore,
  useBackgroundFetch,
} from "../hooks";
import { getDiff, getUpdated } from "../helpers";
import { apiUrl, wc_orders } from "../config/config";
import Navigation from "../dashboard/navigation.jsx";
import ReactPaginate from "react-paginate";
import Modal from "../widgets/modal";
import CreateNewOrder from "./components/create-new-order";

export default function Orders() {
  const itemsPerPage = 8;
  const location = useLocation();
  let [orders, setOrders] = useState([]);
  let [searchValue, setSearchValue] = useState(
    location?.state?.searchValue || ""
  );
  let [status, setStatus] = useState("");
  let [currentPage, setCurrentPage] = useState(1);
  let [openCreateNewOrderModal, setOpenCreateNewOrder] = useState(false);
  let [response, loading, more, totalpages] = useLoadMore(
    `${apiUrl}/${wc_orders}`,
    searchValue,
    status,
    currentPage,
    itemsPerPage
  );
  let [data] = useBackgroundFetch(`${apiUrl}/${wc_orders}`);
  let [beep] = useAudioCue();
  const query = useQuery();
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  // Show spinner
  useEffect(() => {
    if (loading) {
      spinnerService.show("ug");
    } else {
      spinnerService.hide("ug");

      // New admin orders redirected here
      if (query.has("created")) {
        notify.show(
          `Order number #${query.get("created")} successfully created`,
          "success",
          3000
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // Display orders
  useEffect(() => {
    if (!response) return;

    setOrders(() => [...response]);
  }, [response]);

  // Add updated background data
  useEffect(() => {
    if (data.length) beep();

    let newOrders = getDiff(data, orders);
    let updatedOrders = getUpdated(orders, data);

    setOrders([...newOrders, ...updatedOrders]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Add updated background data
  useEffect(() => {
    more(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, currentPage]);

  // Filter the orders to display
  const display = orders
    ?.filter((o) => {
      return !["completed"].includes(o.status);
    })
    .sort((a, b) => b.id - a.id);

  const handleChange = (e) => {
    let text = e.target.value ? e.target.value.trim() : "";
    setSearchValue(text);
    if (e.key === "Enter") {
      e.preventDefault();
      more(currentPage);
    }
  };

  return (
    <div className="page-content body-white orders">
      <div className="grid-container">
        <Navigation
          navigator={
            location?.state?.searchValue
              ? [
                  {
                    label: location?.state?.funeralHomeName,
                    path: `/funeral-home/${location?.state?.funeralHomeId}`,
                  },
                ]
              : []
          }
          heading="Customer Orders"
        />
        <button
          onClick={() => setOpenCreateNewOrder(true)}
          className="button btn-create-product"
        >
          + Create a new Order
        </button>
        <div className="small-12">
          <div className="funeral-home-filters filters">
            <div className="">
              <label htmlFor="filter-state">Type</label>
              <div className="flex-container">
                <button
                  className={`button button--blue funeral-home-type ${
                    status === "" && "button--blue-active"
                  }`}
                  onClick={() => setStatus("")}
                >
                  All
                </button>
                <button
                  className={`button button--red funeral-home-type ${
                    status === "new" && "button--red-active"
                  }`}
                  onClick={() => setStatus("new")}
                >
                  New
                </button>
                <button
                  className={`button button--blue funeral-home-type ${
                    status === "processing" && "button--blue-active"
                  }`}
                  onClick={() => setStatus("processing")}
                >
                  In-progress
                </button>
                <button
                  className={`button button--black funeral-home-type ${
                    status === "approved" && "button--black-active"
                  }`}
                  onClick={() => setStatus("approved")}
                >
                  Approved
                </button>
              </div>
            </div>

            <div className="cell-search">
              <label htmlFor="search-keywords">Keywords</label>
              <div className="flex-container">
                <input
                  name="search-keywords"
                  id="search-keywords"
                  onKeyPress={handleChange}
                  onChange={handleChange}
                  type="search"
                  value={searchValue}
                  placeholder="Enter keywords and press Enter key"
                />
              </div>
            </div>
          </div>
        </div>
        {orders?.length > 0 && (
          <>
            <div className="cell small-12 filters">
              {display.length ? (
                <OrderTable items={display} />
              ) : (
                <h4>No orders to show</h4>
              )}
            </div>
            <ReactPaginate
              previousLabel={<i className="fal fa-chevron-left" />}
              breakLabel="..."
              nextLabel={<i className="fal fa-chevron-right" />}
              breakClassName={"break-me"}
              forcePage={currentPage - 1}
              pageCount={totalpages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
            />
          </>
        )}
        {!loading && !orders.length && (
          <p className="info">No orders to show</p>
        )}
      </div>

      <Modal
        open={openCreateNewOrderModal}
        classes="modal-confirm-fast-track"
        width="460"
      >
        <button
          onClick={() => setOpenCreateNewOrder(false)}
          className="btn-close"
        >
          <i className="far fa-times"></i>
        </button>
        <CreateNewOrder cancel={() => setOpenCreateNewOrder(false)} />
      </Modal>
    </div>
  );
}
