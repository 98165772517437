export const apiUrl = process.env.REACT_APP_CONFIG_APIURL;

export const config = { apiUrl };

export const acc_contact_profile = "acc/v1/contact/profile";
export const acc_contact_new = "acc/v1/contact/new";
export const acc_contact_update = "acc/v1/contact/update";
export const acc_contact_delete = "acc/v1/contact/delete";
export const acc_funeral_home = "acc/v1/funeral-home";
export const acc_funeral_home_logo_upload = "acc/v1/funeral-home/logo-upload";
export const acc_funeral_home_lookup_lga = "acc/v1/funeral-home/lookup-lga";
export const wp_resources = "wp/v2/resources";
export const acc_shop_products = "acc/v1/shop/products";
export const acc_shop_invoice = "acc/v1/shop/invoice";
export const acc_shop_order_cart = "acc/v1/shop/order/cart";
export const acc_shop_order_unapproved = "acc/v1/shop/order/unapproved";
export const wc_products = "wc/v3/products";
export const wc_orders = "wc/v3/orders";
export const wp_bjm_cat_product = "wp/v2/bjm_cat_product";
export const acc_funeral_homes = "acc/v1/funeral-homes";
export const acc_products = "acc/v1/products";
export const acc_regions = "acc/v1/regions";
export const acc_routes = "acc/v1/routes";
export const acc_note = "acc/v1/note";
export const acc_note_new = "acc/v1/note/new";
export const acc_options_email = "acc/v1/options/email";
export const cocart_cart = "cocart/v2/cart";
