import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import Headery from "../auth/header";

export default function OrderConfirmation() {
    let { id } = useParams();

    useEffect(() => spinnerService.hide("ug"), []);

    return (
        <div className="page-content customer-order-confirmation order-update" id="main-scroller">
            <Headery title="Order Confirmation" />
            <div className="grid-container">
                <div className="grid-x">
                    <div className="cell cell-component small-12 medium-offset-2 medium-8 large-offset-3 large-6">
                        <i className="order-update-icon fal fa-check-circle fadeUp"></i>
                        <p className="no-margin"><strong>Order number: {id}</strong></p>
                        <h1 className="order-update-title">Thanks, we have received your order for approval.</h1>
                        <p>You can view the order status by clicking the ‘Orders’ tile on your dashboard.</p>
                        <p>You will also reveive a confirmation email once your order has been approved.</p>
                        <Link to="/customer/orders" className="button button--ghost right-pad">View orders</Link>
                        <Link to="/customer" className="button">Go to dashboard</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
