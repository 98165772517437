import React from 'react';


class NoteEntry extends React.Component {
    render() {
        
      return (
        <div className={`note-entry ${this.props.noteIndex === -1 ? 'note-entry-new' : ''} note-entry-editing-${this.state.editing}`}>   

            {this.state.editing ? (       
                <div className="note-text note-text-open">       
                    <textarea
                        name="note"

                        placeholder="Notes"
                        value={this.state.note.note}
                        onBlur={(e) => {
                            if (this.props.noteIndex !== -1) {
                                this.props.saveNote(this.state.note, this.props.noteIndex);
                            }
                        }} 
                        onChange={this.onChangeNote}
                    
                    >
                    </textarea>
                   
                    
                </div>
            ) : (        
                <React.Fragment>
                    {this.props.noteIndex !== -1 &&
                    
                        <div className="note-text">
                            {this.props.note.linked_product_index &&
                                <div className="linked-product-index">{this.props.note.linked_product_index}</div>
                            }
                            {this.props.note.linked_catalogue &&
                                <div className={`linked-product-index linked-catalogue linked-catalogue-${this.props.note.linked_catalogue}`}>{this.props.note.linked_catalogue.slice(0,1)}</div>
                            }
                            {this.props.note.note}
                        </div>
                    }
                </React.Fragment>
            )}
        
            {this.state.editing ? (
                
            
                <div className="note-controls open">

                                      
                        
                    <button onClick={this.toggleEdit}><i className="far fa-times"></i></button>
                        
                    
                    {this.props.noteIndex !== -1 &&
                        <React.Fragment>
                            <button onClick={() => {                        
                                this.props.onDeleteNote(this.props.noteIndex)
                            }}>
                                <i className="far fa-trash-alt"></i>
                            </button>
                        


                            <div className="div-pinning">
                                <button className={`a-toggle-pinning pinning-${this.props.pinning && this.props.pinningNoteIndex === this.props.noteIndex}`} onClick={() => this.props.onTogglePin(this.props.noteIndex, this.props.note) }><i className="far fa-thumbtack"></i></button>
                                {this.props.note.linked_product_index &&
                                    <div className="linked-product-index">
                                        <span className="val">{this.props.note.linked_product_index}</span>
                                        <i onClick={() => this.props.unpin(this.props.noteIndex)} data-tip="Unpin note"  className="far fa-times"></i>
                                    </div>
                                }
                                {this.props.note.linked_catalogue &&
                                
                                    <div className={`linked-product-index linked-catalogue linked-catalogue-${this.props.note.linked_catalogue}`}>
                                        <span className="val">{this.props.note.linked_catalogue.slice(0,1)}</span>
                                        <i onClick={() => this.props.unpin(this.props.noteIndex)} data-tip="Unpin note"  className="far fa-times"></i>
                                    </div>
                                }


                            </div>
                        </React.Fragment>
                    }


                    {this.props.noteIndex === -1 &&
                        <button className={`a-save`}  onClick={ this.saveNoteNew }><i className="far fa-check"></i></button>
                    } 
                </div>
            ) : (
                <React.Fragment>
                    {this.props.noteIndex === -1 ? (
                        <div>
                            <button className="btn-add-new" onClick={this.toggleEdit}> Add New Note</button>
                        </div>
                    ) : (
                        <div className="note-controls">
                            <button onClick={this.toggleEdit}><i className="far fa-ellipsis-h"></i></button>
                        </div>
                    )}
                </React.Fragment>
            )} 

            


        </div>
            
      );
    }



    saveNoteNew(){
        this.setState((state) => ({
            editing: false
        })); 
        this.props.saveNoteNew(this.state.note);

    }
    toggleEdit(){

        if (this.props.noteIndex === -1){
            this.setState((state) => ({
                note: {
                    note: '',
                    linked_product: false
                }
            })); 
        }

        this.setState((state) => ({
            editing: !this.state.editing
        })); 
    }

    onChangeNote(e){
        
        const target = e.target;        
        let note = Object.assign({}, this.state.note);  
        note.note = target.value;

        this.setState({note});
        
    }

    

    constructor(props) {

        super(props);
    
        this.state = {
            editing: false,         
            pinning: false,
            note: this.props.note
        }

        this.toggleEdit = this.toggleEdit.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.saveNoteNew = this.saveNoteNew.bind(this);
          
    }
}
    


export default NoteEntry;
