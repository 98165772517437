import axios from 'axios';
import { spinnerService } from '@chevtek/react-spinners';
import {notify} from 'react-notify-toast';
import { isAccess } from '../../utilities/constant/navbar.constant';
 
class ioCatalogue{
   
  

    constructor(config) {
        this.props = {
            config: config
        };
    
    }

    handleInputChangeCatalogueProduct(index, catalogue_type, name_value){

        let atts = Object.assign({}, this.state.atts);  

        Object.assign(atts.catalogues[catalogue_type].atts.catalogue_products[index].atts, name_value);
    
        this.setState({atts});
    
        if (!isAccess('acc/v1/catalogue-product/update')) {
          return;
        }
    
        axios.post(this.props.config.apiUrl + '/acc/v1/catalogue-product/update', {
          data: atts.catalogues[catalogue_type].atts.catalogue_products[index]
        }).then(function (response) {
          notify.show('Saved changes to catalogue line', 'success', 3000);
        });
    
    }




  handleInputChangeCatalogue(catalogue_type, m, name){

    let atts = Object.assign({}, this.state.atts);  

    Object.assign(atts.catalogues[catalogue_type].atts, {
      [name]: m.format('YYYY-MM-DD')
    }); 

    this.setState({atts});
    if (!isAccess('acc/v1/catalogue/update')) {
      return;
    }

    axios.post(this.props.config.apiUrl + '/acc/v1/catalogue/update', {
      data: atts.catalogues[catalogue_type]
    }).then(function (response) {
      notify.show('Set ' + name + ' to ' + m.format('YYYY-MM-DD'), 'success', 3000);
    });

  }


  handleInputChangeAddCatalogueProduct(catalogue_type, event){
    
    const target = event.target;      
    const name = target.name;
    
    let atts = Object.assign({}, this.state.atts);  

    atts.catalogues[catalogue_type].atts.new_product[name] = target.value;
    if (name === 'product_name_acc' ){
      atts.catalogues[catalogue_type].atts.new_product.product_name_funeral_home = target.value;
    }

    this.setState({atts});

  }
  
  handleProductSelect(catalogue_type, product) {
    let atts = Object.assign({}, this.state.atts);

    let new_product = atts.catalogues[catalogue_type].atts.new_product;

    new_product.id = product.id;
    new_product.sel = {
      label: product.label,
      value: product.value,
    };
    
    if (product.value === 0){
      
      new_product.product_name_acc = '';
      new_product.product_name_funeral_home = '';
      new_product.sku = '';
      new_product.retail_price = '';
      new_product.price = '';
      new_product.stats_number_of_sales = '';
      new_product.category = '';
    }else{
      new_product.product_name_acc = product.name;
      new_product.product_name_funeral_home = product.name;
      new_product.sku = product.sku;
      new_product.retail_price = product.retail_price;
      new_product.price = product.price;
      new_product.stats_number_of_sales = 0;
      new_product.category = product.categories?.length ? product.categories[0] : '';
    }

    this.setState({atts});
  }

  handleSubmitAddCatalogueProduct(catalogue_type, event) {
    event.preventDefault();
    let _this = this;

    
    let previous_category = this.state.atts.catalogues[catalogue_type].atts.new_product.category;
    
    
    
    if (!isAccess('acc/v1/catalogue-product/add')) {
      return;
    }
    spinnerService.show('ug');
    
    axios.post(this.props.config.apiUrl + '/acc/v1/catalogue-product/add', {
      catalogue_id: this.state.atts.catalogues[catalogue_type].post.ID,
      new_product: this.state.atts.catalogues[catalogue_type].atts.new_product
    }).then(function (response) {
      let data = response.data;
      
      spinnerService.hide('ug');

      if (data.status === 'success'){

        let atts = Object.assign({}, _this.state.atts);  

        if (!atts.catalogues[catalogue_type].atts.new_product.stats_number_of_sales){
          atts.catalogues[catalogue_type].atts.new_product.stats_number_of_sales = 0;
        }

        atts.catalogues[catalogue_type].atts.new_product = data.empty_product;
        atts.catalogues[catalogue_type].atts.new_product.category = previous_category;
        atts.catalogues[catalogue_type].atts.catalogue_products.push(data.added_product);

        _this.setState({atts});


        notify.show('Saved!', 'success', 3000);
      }else{
        notify.show('Error adding product', 'error', 3000);
      }
    });



  }
  handleDeleteCatalogueProduct(entry_index, catalogue_type) {

    let _this = this;

    
    //event.preventDefault();
    
    
    let this_catalogue_product = this.state.atts.catalogues[catalogue_type].atts.catalogue_products[entry_index];
    
    if (!isAccess('acc/v1/catalogue-product/delete')) {
      return;
    }
    spinnerService.show('ug');
    axios.post(this.props.config.apiUrl + '/acc/v1/catalogue-product/delete', {
      id: this_catalogue_product.post.ID
    }).then(function (response) {
      let data = response.data;

      spinnerService.hide('ug');

      if (data.status === 'success'){        

        let atts = Object.assign({}, _this.state.atts);  

        atts.catalogues[catalogue_type].atts.catalogue_products.splice(entry_index, 1)

        _this.setState({atts});


        notify.show('Deleted entry', 'success', 3000);
      }else{
        notify.show('Error deleting line', 'error', 3000);
      }

    });

  }

   
  }

  export default ioCatalogue;
