import React from "react";
import { Link } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import ExcelDownload from "./excel.jsx";
import PDFOutput from "./pdf/pdf2.jsx";
import axios from "axios";
import Navigation from "../../dashboard/navigation.jsx";
import { isAccess } from "../../utilities/constant/navbar.constant.js";

class Output extends React.Component {
  render() {
    let outputCount = 0;
    for (let output of this.state.outputs) {
      if (output.include) {
        outputCount++;
      }
    }

    const defaultContact =
      this.props.funeralHome.defaultContact?.atts ??
      this.props.funeralHome.defaultContact;

    return (
      <div className="holder-prop-catalogue">
        <div className="grid-x">
        <Navigation
            navigator={[{ label: "Funeral Homes", path: "/funeral-homes" },
          { label: `${this.props.funeralHome.atts.funeral_home_name}`, path: `/funeral-home/${this.props.funeralHome.ID}` }
        ]}
            heading={`${this.props.proposal.ID}`}
            headingClassName={`navigator-heading-font-size`}
          />
          <div className="cell cell-side-panel cell-output-side-panel">
            <h2>Overview</h2>

            {!defaultContact ? (
              <div className="warning">
                <i className="fas fa-exclamation-triangle"></i>You must create a
                default funeral home contact before producing output.
              </div>
            ) : (
              <div className="proposal-overview">
                <div>
                  <i className="far fa-user-circle"></i>
                  <div>
                    {defaultContact.first_name} {defaultContact.last_name}
                  </div>
                </div>
                <div>
                  <i className="far fa-phone"></i>
                  <div>{defaultContact.phone_mobile}</div>
                </div>
                <div>
                  <i className="far fa-envelope"></i>
                  <div>{defaultContact.user_email}</div>
                </div>
                <div>
                  <i className="far fa-map-pin"></i>
                  <div>
                    {this.props.funeralHome.atts.address_line_1}
                    <br />
                    {this.props.funeralHome.atts.address_line_2 && (
                      <span>
                        {this.props.funeralHome.atts.address_line_2}
                        <br />
                      </span>
                    )}
                    {this.props.funeralHome.atts.address_city}
                    <br />
                    {this.props.funeralHome.atts.address_state.toUpperCase()}{" "}
                    {this.props.funeralHome.atts.address_postcode}
                  </div>
                </div>
              </div>
            )}

            <h2>
              {outputCount} / {this.state.outputs.length} Sections selected
            </h2>

            <input
              className="btn-download"
              type="button"
              onClick={this.producePDF}
              value="Generate PDF"
            />
            <div className="output-download-link">
              {this.state.produceOutput && (
                <React.Fragment>
                  <PDFOutput
                    mode="all"
                    outputs={this.state.outputs}
                    outputDownload={true}
                    proposal_prefs={this.props.proposal_prefs}
                    config={this.props.config}
                    proposal_atts={this.props.proposal_atts}
                    key={this.state.data.pdfVersion}
                    lgaStats={this.state.data.lgaStats}
                    product_cats={this.props.product_cats}
                    catalogueAtts={this.state.data.catalogueAtts}
                    user={this.props.user}
                    proposalID={this.props.proposalID}
                    funeralHome={this.props.funeralHome}
                    catalogues={this.props.catalogues}
                    notes={this.props.notes}
                    price_points_new={this.props.price_points_new}
                    outputIndex={null}
                    downloadComplete={this.hidePDF}
                  />
                  <button className="a a-hide-output" onClick={this.hidePDF}>
                    <i className="far fa-times"></i>
                  </button>
                </React.Fragment>
              )}
            </div>

            <canvas id="canvas" width="270px" height="10px"></canvas>
          </div>
          <div
            className="cell auto cell-main-panel cell-output-list"
            id="main-scroller"
          >
            {Array.isArray(this.state.outputs) && (
              <table>
                <tbody>
                  {this.state.outputs.map(function (output, index) {
                    return (
                      <tr key={index}>
                        <td>{output.name}</td>
                        <td>
                          {output.excel && (
                            <ExcelDownload
                              //proposal_atts={this.props.proposal_atts}
                              funeralHome={this.props.funeralHome}
                              catalogue={this.props.catalogues.existing}
                            />
                          )}
                        </td>
                        <td>
                          {output.edit && (
                            <Link
                              to={output.edit}
                              data-tip="Edit data for document"
                            >
                              <i className="far fa-pencil-alt"></i>
                            </Link>
                          )}
                        </td>
                        <td>
                          <div className="output-download-link">
                            {output.produceSingle && (
                              <React.Fragment>
                                <PDFOutput
                                  mode="single"
                                  output={output}
                                  outputDownload={this.state.outputDownload}
                                  proposal_prefs={this.props.proposal_prefs}
                                  config={this.props.config}
                                  proposal_atts={this.props.proposal_atts}
                                  key={this.state.data.pdfVersion}
                                  lgaStats={this.state.data.lgaStats}
                                  product_cats={this.props.product_cats}
                                  catalogueAtts={this.state.data.catalogueAtts}
                                  user={this.props.user}
                                  proposalID={this.props.proposalID}
                                  funeralHome={this.props.funeralHome}
                                  catalogues={this.props.catalogues}
                                  notes={this.props.notes}
                                  price_points_new={this.props.price_points_new}
                                  downloadComplete={this.toggleProduceSingle}
                                  outputIndex={index}
                                />
                                <button
                                  className="a a-hide-output"
                                  onClick={() =>
                                    this.toggleProduceSingle(index, false)
                                  }
                                >
                                  <i className="far fa-times"></i>
                                </button>
                              </React.Fragment>
                            )}
                            <i
                              data-tip="Produce and open this output page "
                              onClick={() =>
                                this.toggleProduceSingle(index, false)
                              }
                              className="far fa-eye"
                            ></i>
                          </div>
                        </td>

                        <td>
                          <i
                            data-tip="Produce and download this page "
                            onClick={() =>
                              this.toggleProduceSingle(index, true)
                            }
                            className="far fa-download"
                          ></i>
                        </td>
                        <td>
                          <div
                            data-tip="Add or remove this page from the output pack"
                            className={`chk-output ${
                              output.include ? "on" : ""
                            }`}
                            onClick={() => this.toggleInclude(index)}
                          >
                            <i className="far fa-check-square"></i>
                          </div>
                        </td>
                      </tr>
                    );
                  }, this)}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);

    let outputs = [];

    if (this.props.proposalID) {
      outputs.push(
        {
          code: "cover",
          name: "Cover",
          edit: `/proposals/${this.props.proposalID}`,
          include: true,
          produceSingle: false,
        },
        {
          code: "executive-summary",
          name: "Executive Summary",
          edit: `/proposals/${this.props.proposalID}`,
          include: true,
          produceSingle: false,
        },
        {
          code: "intro",
          name: "Introductory Letter",
          edit: `/proposals/${this.props.proposalID}`,
          include: true,
          produceSingle: false,
        },
        {
          code: "intro-existing",
          name: "Introductory Letter - Existing Catalogue Update Agreement",
          edit: `/proposals/${this.props.proposalID}`,
          include: true,
          produceSingle: false,
        },
        {
          code: "current-sales-cover",
          name: "Existing Catalogue - Cover",
          edit: null,
          include: true,
          produceSingle: false,
        }
      );
    }

    outputs.push({
      code: "current-sales-table",
      name: "Existing Catalogue - Profile Chart",
      edit: `/proposals/${this.props.proposalID}/content/existing-catalogue`,
      include: true,
      produceSingle: false,
      excel: {
        type: "existing_sales",
        discount: 0,
      },
    });

    if (
      this.props.funeralHome.atts.discount_percent &&
      !isNaN(parseFloat(this.props.funeralHome.atts.discount_percent))
    ) {
      outputs.push({
        code: "current-sales-table-discount",
        name:
          "Existing Catalogue - Profile Chart With " +
          parseFloat(this.props.funeralHome.atts.discount_percent) +
          "% Discount",
        edit: `/proposals/${this.props.proposalID}/content/existing-catalogue`,
        include: false,
        produceSingle: false,
      });
    }

    if (this.props.proposalID) {
      outputs.push(
        {
          code: "catalogue-existing",
          name: "Existing Catalogue",
          edit: `/proposals/${this.props.proposalID}/content/existing-catalogue`,
          include: true,
          produceSingle: false,
        },
        {
          code: "diamond-existing-retail",
          name: "Existing Catalogue - Diamond - Retail Only",
          edit: `/proposals/${this.props.proposalID}/content/diamond-editor`,
          include: false,
          produceSingle: false,
        },
        {
          code: "diamond-existing-full",
          name: "Existing Catalogue - Diamond - Full Details",
          edit: `/proposals/${this.props.proposalID}/content/diamond-editor`,
          include: true,
          produceSingle: false,
        },
        {
          code: "proposed-cover",
          name: "Proposed Catalogue - Cover",
          edit: null,
          include: true,
          produceSingle: false,
        },
        {
          code: "proposed-catalogue",
          name: "Proposed Catalogue",
          edit: `/proposals/${this.props.proposalID}/content/proposed-catalogue`,
          include: true,
          produceSingle: false,
        },
        {
          code: "diamond-proposed-retail",
          name: "Proposed Catalogue - Diamond - Retail Only",
          edit: `/proposals/${this.props.proposalID}/content/diamond-editor`,
          include: false,
          produceSingle: false,
        },
        {
          code: "diamond-proposed-full",
          name: "Proposed Catalogue - Diamond - Full Details",
          edit: `/proposals/${this.props.proposalID}/content/diamond-editor`,
          include: true,
          produceSingle: false,
        },
        {
          code: "proposed-price-list",
          name: "Proposed Catalogue - Retail Price List",
          edit: `/proposals/${this.props.proposalID}/content/proposed-catalogue`,
          include: true,
          produceSingle: false,
        },
        {
          code: "proposed-products",
          name: "Proposed Catalogue - Product Images",
          edit: `/proposals/${this.props.proposalID}/content/proposed-catalogue`,
          include: false,
          produceSingle: false,
        },
        {
          code: "rear-cover",
          name: "Back Cover",
          edit: null,
          include: true,
          produceSingle: false,
        }
      );
    }

    this.state = {
      produceOutput: false,
      data: {
        pdfVersion: 1,
        catalogueAtts: null,
        lgaStats: null,
      },
      outputs: outputs,
      outputDownload: false,
    };

    this.updateData();

    this.producePDF = this.producePDF.bind(this);
    this.hidePDF = this.hidePDF.bind(this);

    this.toggleProduceSingle = this.toggleProduceSingle.bind(this);
  }

  toggleProduceSingle(index, download = false) {
    let outputs = Object.assign([], this.state.outputs);
    outputs[index].produceSingle = !outputs[index].produceSingle;
    this.setState(
      {
        outputs: outputs,
        outputDownload: download,
      },
      () => {}
    );
  }
  toggleInclude(index) {
    let outputs = Object.assign([], this.state.outputs);
    outputs[index].include = !outputs[index].include;
    this.setState({ outputs });
  }

  setCategoriesAndPages(type) {
    let all_products;
    if (type === "existing") {
      all_products = this.props.catalogues.existing.atts.catalogue_products;
    } else if (type === "data") {
      all_products = this.props.catalogues.data.atts.catalogue_products;
    } else {
      all_products = this.props.catalogues.proposed.atts.catalogue_products;
    }

    //let categories = this.props.product_cats;
    let categories = [];

    //Put products into categories
    for (var product_cat of this.props.product_cats) {
      let new_cat = Object.assign({}, product_cat); //Clone category

      new_cat.products = [];

      for (var product of all_products) {
        if (product.atts.category === product_cat.id) {
          new_cat.products.push(product);
        }
      }

      categories.push(new_cat);
    }

    let pageHeightPortrait = 580;

    // eslint-disable-next-line no-unused-vars
    let entryHeight = 14;
    let headerHeight = 36;

    let headerHeightLandscape = 30 + 16;
    let entryHeightLandscape = 19;

    let pageHeightLandscape = 440;

    let pages_portrait = [
      {
        categories: [],
      },
    ];
    let pages_landscape = [
      {
        categories: [],
      },
    ];

    let pagePointerPortrait = 0;
    let pagePointerLandscape = 0;

    let runningHeightPortrait = 0;
    let runningHeightLandscape = 0;

    for (var product_cat2 of categories) {
      if (product_cat2.products.length === 0) {
        continue;
      }

      let product_cat_for_landscape = Object.assign({}, product_cat2); //Clone category
      let cat_height = 0;

      product_cat2.products.forEach((prod) => {
        if (prod.atts.product_name_acc.length > 38) {
          cat_height += 28;
        } else {
          cat_height += 14;
        }
      });

      runningHeightPortrait += cat_height + headerHeight;

      if (type === "proposed") {
        runningHeightLandscape +=
          product_cat_for_landscape.products.length * entryHeightLandscape +
          headerHeightLandscape;
      }

      if (runningHeightPortrait > pageHeightPortrait) {
        pagePointerPortrait++;
        runningHeightPortrait = 0;

        pages_portrait.push({
          categories: [],
        });
      }
      if (type === "proposed" && runningHeightLandscape > pageHeightLandscape) {
        pagePointerLandscape++;
        runningHeightLandscape = 0;

        pages_landscape.push({
          categories: [],
        });
      }

      pages_portrait[pagePointerPortrait].categories.push(product_cat2); //we can't cope with a single category being larger than a page

      if (type === "proposed") {
        pages_landscape[pagePointerLandscape].categories.push(
          product_cat_for_landscape
        ); //we can't cope with a single category being larger than a page
      }
    }

    return {
      pages: pages_portrait,
      pages_landscape: pages_landscape,
      categories: categories,
    };
  }

  hidePDF() {
    this.setState((state) => ({
      produceSingle: false,
      produceOutput: false,
      data: {
        pdfVersion: 0,
        lgaStats: null,
        catalogueAtts: null,
      },
    }));
  }

  updateData() {
    let _this = this;

    if (!this.props.funeralHome.atts.lga_id) {
      alert("You must set the LGA before producing output.");
      return;
    }

    if (!this.state.data.lgaStats) {
      if (!isAccess('acc/v1/funeral-home/get-stats')) {
        return;
      }
      spinnerService.show("ug");

      //Retrieve stats etc
      axios
        .get(
          this.props.config.apiUrl +
            "/acc/v1/funeral-home/get-stats/" +
            this.props.funeralHome.atts.lga_id,
          {}
        )
        .catch(function (error) {
          alert("An error occured retrieving LGA stats.");
        })
        .then(function (response) {
          spinnerService.hide("ug");

          let existing_atts = null;
          let proposed_atts = null;
          let data_atts = null;

          if (response) {
            if (_this.props.catalogues.existing) {
              existing_atts = _this.setCategoriesAndPages("existing");
            }
            if (_this.props.catalogues.proposed) {
              proposed_atts = _this.setCategoriesAndPages("proposed");
            }
            if (_this.props.catalogues.data) {
              data_atts = _this.setCategoriesAndPages("data");
            }

            let data = Object.assign({}, _this.state.data);

            data.pdfVersion = _this.state.data.pdfVersion + 1;
            data.lgaStats = response.data.stats;
            data.catalogueAtts = {
              existing: existing_atts,
              proposed: proposed_atts,
              data: data_atts,
            };

            _this.setState({ data });
          }
        });
    }
  }

  producePDF() {
    let _this = this;

    spinnerService.show("ug");

    _this.setState((state) => ({
      produceOutput: true,
    }));
  }
}
export default Output;
