import React from "react";
import { NavLink } from "react-router-dom";
import Mailouts from "./mailouts";
import Navigation from "../dashboard/navigation.jsx";

class CustomerMailouts extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="body-white body-customer-mailouts customer-mailouts-list"
          id="main-scroller"
        >
          <div className="grid-container">
            <Navigation heading="Customer Mailouts" />

            <div className="cell small-12 large-12">
              <NavLink
                className="button nav-new-mailout right-pad"
                to={`/customer-mailout`}
              >
                <button>
                  <i className="fas fa-plus"></i> Create New Mailout
                </button>
              </NavLink>
              <NavLink
                className="button nav-new-mailout"
                to={`/customer-mailout-audiences`}
              >
                <button>
                  <i className="fas fa-users"></i> Manage Audiences
                </button>
              </NavLink>
            </div>
            <Mailouts props={this.props} search config={this.props.config} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerMailouts;
