import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';


class DateRange extends React.Component {
    render() {  
      return (
        <div>
            <h2>Define sales period</h2>
            <form autoComplete="off" onSubmit={this.props.onSubmit}>
            
                <div className="bjm-input-wrap">
                    <label>Start:</label>
                    
                    <DatePicker
                        dateFormat="DD/MM/YYYY"
                        selected={this.state.start_date_obj}
                        onChange={(m) => {                                                                      

                            this.setState((state) => ({
                                start_date_obj: m,
                            })); 

                            this.props.onChangeValue(m, 'start_date');
                        }} 
                    />
                    <i className="far fa-calendar-alt"></i>
                    
                </div>
                <div className="bjm-input-wrap">
                    <label>End:</label>
                    
                    <DatePicker
                        dateFormat="DD/MM/YYYY"
                        selected={this.state.end_date_obj}
                        onChange={(m) => {                                                                      

                            this.setState((state) => ({
                                end_date_obj: m,
                            })); 

                            this.props.onChangeValue(m, 'end_date');
                        }} 
                    />
                    <i className="far fa-calendar-alt"></i>
                    
                </div>

            </form>
        </div>
            
      );
    }  

    constructor(props) {
        super(props);
        

        this.state = {
            start_date: this.props.start_date,
            start_date_obj: moment(this.props.start_date, "YYYY-MM-DD"),
            end_date: this.props.end_date,
            end_date_obj: moment(this.props.end_date, "YYYY-MM-DD")
        };       
    

    }

  }

  export default DateRange;
