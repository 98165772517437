import React from 'react';


class Outline extends React.Component {

    

    render() { 

        let is_existing = (this.props.catalogue_type === 'existing');
        

      //  let row_count = this.props.row_lengths.length;

        //Add top point
        let diamond_points = `200,5 `;
        //let diamond_points_grid = `20,0 `;

        let ar_y_points = [];
        
        
        //Calculate each shape edge y point
        for (let [index, price_point] of this.props.price_points_new.entries()) {
            if ((is_existing && price_point.shape_edge_point_existing) || (!is_existing && price_point.shape_edge_point_proposed) ){

                let perc = ( (index+1.5) / this.props.row_count);
                let y_half = Math.round(perc * 400) ;

                ar_y_points.push(y_half);

                

            }
/* TEMP*/
        /*    let perc = ( index / row_count);
            let y_half = Math.round(perc * 400);

            diamond_points_grid += ` 20,${y_half} `;
            diamond_points_grid += ` 50,${y_half} `;*/
/* TEMP */
        };

        //Add RHS y points
        for (let [, y] of ar_y_points.entries()) {
            diamond_points += ` 395,${y} `;
        }

        //Add bottom point
        diamond_points += ` 200,395 `
      //  diamond_points_grid += ` 20,400 `

        //Add LHS y points
        ar_y_points.reverse();        
        for (let [, y] of ar_y_points.entries()) {
            diamond_points += ` 5,${y} `;
        }



      return (
        <div> 
            
            <svg className="outline" height="400" width="400" viewBox="0 0 400 400"  strokeLinejoin="round" preserveAspectRatio="none">
              <polygon points={diamond_points} className="poly-outline" />
              
            </svg>
        </div>
            
      );
    }  
 
  }

  export default Outline;
