import React from "react";
import { Link, Redirect } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import { notify } from "react-notify-toast";
import axios from "axios";
import Headery from "../auth/header.jsx";
import ProductImage from "./product-image.jsx";
import Navigation from "../dashboard/navigation.jsx";
import { decodeHTML, isAccess } from "../utilities/constant/navbar.constant.js";
import { wc_products } from "../config/config.js";

class Product extends React.Component {
  render() {
    console.log("The product: ", this.state.product);
    return (
      <div className="cell auto products-body body-white">
        {this.state.redirectProduct && <Redirect to={`/products/`}></Redirect>}
        <div className="grid-container">
          <Navigation
            navigator={[{ label: "Manage Products", path: "/products" }]}
            heading={`Edit : ${this.state?.product?.name || ""}`}
          />
          <div className="grid-x">
            <div className="cell profile-panel-left cell-side-panel">
              {this.state.product && (
                <ProductImage
                  image={
                    this.state.product.images[0]
                      ? this.state.product.images[0].woocommerce_thumbnail
                      : null
                  }
                  config={this.props.config}
                  onDrop={this.handleOnDrop}
                />
              )}
            </div>
            <div
              className="cell auto profile-panel-right cell-main-panel"
              id="main-scroller"
            >
              <div className="grid-x">
                <div className="cell small-12">
                  <Headery
                    briefTitle={`Edit Product`}
                    title={`Edit Product`}
                  ></Headery>
                  {this.state.product && (
                    <div className="product-details">
                      <div className="product-details-title">
                        Product Details
                      </div>
                      <div className="div-division">
                        <div className="left-side-column">
                          <label>Product Name (ACCH)</label>
                          <input
                            name="name"
                            type="text"
                            placeholder="Product Name (ACCH)"
                            value={this.state.product.name}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="right-side-column disabled-input">
                          <label>Sap Code</label>
                          <input
                            name="sku"
                            type="text"
                            placeholder="SAP Code"
                            value={this.state.product.sku}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="div-division">
                        <div className="left-side-column">
                          <label>Retail Price</label>
                          <input
                            name="regular_price"
                            type="number"
                            step="0.01"
                            min="0"
                            placeholder="Retail Price"
                            value={this.state.product.regular_price}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="right-side-column disabled-input">
                          <label>Wholesale</label>
                          <input
                            name="price"
                            type="number"
                            step="0.01"
                            min="0"
                            placeholder="Wholesale Price"
                            value={this.state.product.price}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="div-division">
                        <div className="left-side-column">
                          <label>Category</label>
                          <select
                            name="category"
                            value={
                              this.state.product.categories[0]
                                ? this.state.product.categories[0].id
                                : ""
                            }
                            className="filter"
                            onChange={this.handleInputBlur}
                          >
                            {this.state.categories.map(function (
                              category,
                              index
                            ) {
                              return (
                                <option key={index} value={category.id}>
                                  {category.name}
                                </option>
                              );
                            },
                            this)}
                          </select>
                        </div>
                      </div>
                      <div className="product-details-title">Product Spec</div>
                      <div className="div-division">
                        <div className="left-side-column">
                          <label>Product spec line 1</label>
                          <input
                            name="spec"
                            type="text"
                            placeholder="Product Spec - Line 1"
                            value={this.state.product.spec[0]}
                            onBlur={(e) => this.handleInputBlur(e, 0)}
                            onChange={(e) => this.handleInputChange(e, 0)}
                          />
                        </div>
                        <div className="right-side-column">
                          <label>Product spec line 2</label>
                          <input
                            name="spec"
                            type="text"
                            placeholder="Product Spec - Line 2"
                            value={this.state.product.spec[1]}
                            onBlur={(e) => this.handleInputBlur(e, 1)}
                            onChange={(e) => this.handleInputChange(e, 1)}
                          />
                        </div>
                      </div>
                      <div className="div-division">
                        <div className="left-side-column">
                          <label>Product spec line 3</label>
                          <input
                            name="spec"
                            type="text"
                            placeholder="Product Spec - Line 3"
                            value={this.state.product.spec[2]}
                            onBlur={(e) => this.handleInputBlur(e, 2)}
                            onChange={(e) => this.handleInputChange(e, 2)}
                          />
                        </div>
                        <div className="right-side-column">
                          <label>Product spec line 4</label>
                          <input
                            name="spec"
                            type="text"
                            placeholder="Product Spec - Line 4"
                            value={this.state.product.spec[3]}
                            onBlur={(e) => this.handleInputBlur(e, 3)}
                            onChange={(e) => this.handleInputChange(e, 3)}
                          />
                        </div>
                      </div>
                      <div className="div-division">
                        <div className="left-side-column">
                          <label>Product spec line 5</label>
                          <input
                            name="spec"
                            type="text"
                            placeholder="Product Spec - Line 5"
                            value={this.state.product.spec[4]}
                            onBlur={(e) => this.handleInputBlur(e, 4)}
                            onChange={(e) => this.handleInputChange(e, 4)}
                          />
                        </div>
                        <div className="right-side-column">
                          <label>Product spec line 6</label>
                          <input
                            name="spec"
                            type="text"
                            placeholder="Product Spec - Line 6"
                            value={this.state.product.spec[5]}
                            onBlur={(e) => this.handleInputBlur(e, 5)}
                            onChange={(e) => this.handleInputChange(e, 5)}
                          />
                        </div>
                      </div>
                      <div className="div-division">
                        <div className="left-side-column">
                          <label>Product spec line 7</label>
                          <input
                            name="spec"
                            type="text"
                            placeholder="Product Spec - Line 7"
                            value={this.state.product.spec[6]}
                            onBlur={(e) => this.handleInputBlur(e, 6)}
                            onChange={(e) => this.handleInputChange(e, 6)}
                          />
                        </div>
                      </div>
                      <div className="product-details-title">
                        Product Dimensions
                      </div>
                      <div className="div-division disabled-input">
                        <div className="left-side-column">
                          <label>Length (external)</label>
                          <input
                            name="dimensions.length"
                            type="number"
                            value={this.state.product.dimensions.length}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="right-side-column">
                          <label>Length (internal)</label>
                          <input
                            name="product_internal_length"
                            type="number"
                            value={this.state.product.product_internal_length}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="div-division disabled-input">
                        <div className="left-side-column">
                          <label>Width (external)</label>
                          <input
                            name="dimensions.width"
                            type="number"
                            value={this.state.product.dimensions.width}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="right-side-column">
                          <label>Width (internal)</label>
                          <input
                            name="product_internal_width"
                            type="number"
                            value={this.state.product.product_internal_width}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="div-division disabled-input">
                        <div className="left-side-column">
                          <label>Height (external)</label>
                          <input
                            name="dimensions.height"
                            type="number"
                            value={this.state.product.dimensions.height}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="right-side-column">
                          <label>Height (internal)</label>
                          <input
                            name="product_internal_height"
                            type="number"
                            value={this.state.product.product_internal_height}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="space-between">
            <Link to="/products">
              <i className="fas fa-chevron-left" />
              <span className="cancel-button">Cancel</span>
            </Link>
            <button className="button archive" onClick={this.handleSaveChange}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    );
  }

  handleSaveChange() {
    if (
      !window.confirm(
        "Are you sure you wish to save the changes for this product?"
      )
    ) {
      return;
    }
    console.log(this.state.payload, this.state);
    spinnerService.show("ug");

    this.saveProduct(this.state.payload);
  }

  handleArchive() {
    let _this = this;
    if (
      !window.confirm(
        "Are you sure you wish to archive this product? It will no longer appear in the list of products."
      )
    ) {
      return;
    }

    if (!isAccess(`${wc_products}/delete`)) {
      return;
    }
    spinnerService.show("ug");

    axios
      .delete(
        this.props.config.apiUrl + `/${wc_products}/` + this.state.product.id,
        {}
      )
      .then(function (response) {
        spinnerService.hide("ug");
        notify.show(
          "Product archived - returning to products list",
          "success",
          3000
        );

        _this.setState({
          redirectProduct: true,
        });
      });
  }

  saveProduct(payload) {
    if (!isAccess(`${wc_products}/update`)) {
      return;
    }
    axios
      .put(
        this.props.config.apiUrl +
          `/${wc_products}/` +
          this.state.product.id +
          "",
        payload
      )
      .then(() => {
        spinnerService.hide("ug");
        notify.show("Save the changes successfully.", "success", 3000);
      });
  }

  handleInputChange(event, index = null) {
    const target = event.target;
    const name = target.name;

    let product = Object.assign({}, this.state.product);

    if (name === "spec") {
      product.spec[index] = target.value;
    } else if (name.includes("dimensions.")) {
      const [key, field] = name.split(".");

      product[key][field] = target.value;
    } else {
      product[name] = target.value;
    }

    this.setState({ product });
  }

  handleInputBlur(event, index = null) {
    const target = event.target;
    const name = target.name;

    let product = Object.assign({}, this.state.product);

    if (name === "category") {
      for (let cat of this.state.categories) {
        if (parseInt(cat.id) === parseInt(target.value)) {
          product["categories"][0] = cat;
          this.setState({
            product,
            payload: { ...this.state.payload, categories: product.categories },
          });
          break;
        }
      }
    } else if (name === "spec") {
      product.spec[index] = target.value;

      product.description = "<ul>";
      for (let spec of product.spec) {
        product.description += "<li>" + spec + "</li>";
      }
      product.description += "</ul>";

      this.setState({
        product,
        payload: { ...this.state.payload, description: product.description },
      });
    } else if (name.includes("dimensions.")) {
      const [key, field] = name.split(".");

      product[key][field] = target.value;
      console.log(key, field);
      this.setState({
        product,
        payload: {
          ...this.state.payload,
          [key]: { ...this.state.payload?.[key], [field]: target.value },
        },
      });
    } else if (name === "regular_price") {
      product[name] = target.value || 0;
      this.setState({
        product,
        payload: { ...this.state.payload, [name]: target.value || 0 },
      });
    } else {
      product[name] = target.value;
      this.setState({
        product,
        payload: { ...this.state.payload, [name]: target.value },
      });
    }
  }

  handleOnDrop(files) {
    let _this = this;

    var formData = new FormData();
    formData.append("file", files[0]);
    formData.append("id", this.state.product.id);
    if (!isAccess("acc/v1/product/image-upload")) {
      return;
    }
    spinnerService.show("ug");
    axios
      .post(
        this.props.config.apiUrl + "/acc/v1/product/image-upload",
        formData,
        {}
      )
      .then(function (response) {
        spinnerService.hide("ug");

        if (response) {
          notify.show("Uploaded image", "success", 3000);

          let product = Object.assign({}, _this.state.product);

          if (!product.images) {
            product.image = [];
          }
          if (!product.images[0]) {
            product.images[0] = {
              alt: "",
            };
          }
          product.images[0].woocommerce_thumbnail = response.data.url;
          product.images[0].id = response.data.attachment_id;

          _this.setState({ product });
        }
      });
  }

  constructor(props) {
    super(props);

    let _this = this;

    let product_id = this.props.match.params.id;

    this.state = {
      product: null,
      redirectProduct: false,
      payload: null,
    };

    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleArchive = this.handleArchive.bind(this);
    this.handleSaveChange = this.handleSaveChange.bind(this);
    this.saveProduct = this.saveProduct.bind(this);

    if (!isAccess(wc_products)) {
      return;
    }
    spinnerService.show("ug");

    axios
      .get(this.props.config.apiUrl + `/${wc_products}/` + product_id, {})
      .then(
        function (response) {
          axios
            .get(
              this.props.config.apiUrl +
                "/wc/v3/products/categories?per_page=100",
              {}
            )
            .then(
              function (responseCategories) {
                spinnerService.hide("ug");

                if (response && response?.data) {
                  response.data.spec = ["", "", "", "", ""];
                  if (response.data.description) {
                    let specHTML = document.createElement("div");
                    specHTML.innerHTML = response.data.description;
                    let parts = specHTML.children;
                    if (parts[0].tagName === "UL") {
                      let lis = parts[0].children;
                      let i = 0;
                      for (let li of lis) {
                        response.data.spec[i] = li.innerText;

                        i++;
                      }
                    }
                  }

                  response.data["name"] = decodeHTML(response.data["name"])
                  response.data["description"] = decodeHTML(response.data["description"])
                  response.data["sku"] = decodeHTML(response.data["sku"])
                  _this.setState({
                    product: response.data,
                    categories: responseCategories?.data || [],
                  });

                  this.props.setBreadcrumbs({
                    pageTitle: response.data.name,
                  });
                }
              }.bind(this)
            );
        }.bind(this)
      );
  }
}

export default Product;
