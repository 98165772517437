import React from "react";
import { Link } from "react-router-dom";

export default function MenuWidget() {
    return (
        <div className="menu-widget">
            <Link to="/customer" className="button button--reset"><span className="hide-for-small-only">View</span> Dashboard <i className="fas fa-th-large"></i></Link>
        </div>
    );
}
