import React from 'react';
import axios from 'axios';
import { spinnerService } from '@chevtek/react-spinners';
import {notify} from 'react-notify-toast';
import moment from 'moment';
import { isAccess } from '../../utilities/constant/navbar.constant';
import { acc_note_new } from '../../config/config';


class Note extends React.Component {
  render() {  

    if (this.state.editing){

      return (
        <tr>
          <td colSpan={5} className="cell-form td-fh-note-edit">
            <p>
              <textarea 
                rows="10"
                name="post_content"
                            
                placeholder="Notes"
                value={this.state.note.post_content}
                onBlur={this.onBlurValue}
                onChange={this.onChangeValue}
            
              ></textarea>
            </p>
            {this.state.note.ID !== 'new' &&
              <div className="td-editing-close" onClick={this.toggleEdit}><i className="far fa-times"></i></div>
            }            
            
            {this.state.note.ID === 'new' &&
              <p>
                <input className="btn-save-new-note" type="submit" value="Confirm new note" onClick={this.confirmNew} />
              </p>
            }
            {/* {this.state.note.ID !== 'new' &&
              <p>
                <input className="btn-delete-note" type="submit" value="Delete Note" onClick={this.delete} />
              </p>
            } */}
            

          </td>
          
        </tr>
      );
    }else{

      return (
        <tr>
            <td>
                {moment(this.state.note.post_date, "YYYY-MM-DD hh:mm:ss").format('DD/MM/YYYY h:mma')}
                {this.state.note.post_modified !== this.state.note.post_date &&
                    <div className="date-modified">Updated:<br/> {moment(this.state.note.post_modified, "YYYY-MM-DD hh:mm:ss").format('DD/MM/YYYY h:mma')}</div>
                }
            </td>   
          <td>{this.state.note.author_name}</td>
                           
          <td>
            {this.state.note.post_content.split('\n').map((item, key) => {
                return <React.Fragment key={key}>{item}<br/></React.Fragment>
            })}

          </td>
          <td>
            <div data-tip="Mark note as actioned" className={`chk-actioned ${ this.state.note.actioned ? 'on' : '' }`} onClick={() => this.toggleActioned()} >
                <i className="far fa-check-square" ></i>
            </div>
          </td>
          <td className="td-icons">            
            <div onClick={this.toggleEdit} data-tip="Edit Note">
              <i className="far fa-pencil"></i>
            </div>
          </td>
        </tr>
      );
    }

  }  


  toggleActioned(index){
    let note = Object.assign([], this.state.note);  
    note.actioned = !note.actioned
    this.setState({note}, () => this.saveNote('actioned flag'));
}



  truncate(mystring, n) {

    let str = mystring;
    if (mystring.length > n){
        str = mystring.replace(mystring.slice(n), "....");
    }
    return str;
}



  delete(e){
    let _this = this;
    if (!isAccess('acc/v1/note/delete')) {
      return;
    }
    
    if (!window.confirm('Are you sure you wish to delete this note?')){
      return false;
    }

    spinnerService.show('ug');
    axios.post(this.props.config.apiUrl + '/acc/v1/note/delete', {      
      id: this.state.note.ID
    }).then(function (response) {
      spinnerService.hide('ug');
      _this.setState((state) => ({
        editing: false
      })); 
      notify.show('Deleted note', 'success', 3000);
      _this.props.noteDeleted(_this.props.index);
    });
  }

  confirmNew(e){
    let _this = this;
    if (!isAccess(acc_note_new)) {
      return;
    }

    spinnerService.show('ug');
    axios.post(this.props.config.apiUrl + `/${acc_note_new}`, {      
      data: this.state.note,
      funeralHomeID: this.props.funeralHomeID
    }).then(function (response) {
      spinnerService.hide('ug');
      notify.show('Saved new note', 'success', 3000);
      _this.props.noteAdded(response.data.new_note);
    });
  }

  onBlurValue(e){
    
    let target = e.target;    
    let name = target.name;

    this.saveNote(name);

  }

  saveNote(name){
    let _this = this;
    if (this.state.note.ID === 'new'){
        return;
    }
    if (!isAccess("acc/v1/note/update")) {
      return;
    }


    axios.post(this.props.config.apiUrl + '/acc/v1/note/update', {
        id: this.state.note.ID,
        post_content: this.state.note.post_content,
        actioned: this.state.note.actioned,
        

    }).then(function (response) {

        _this.props.noteUpdated(_this.state.note.post_content, _this.props.index)
        notify.show('Updated note ' + name, 'success', 3000);
    });

  }


  onChangeValue(e){
    let target = e.target;
    let value = target.value;
    let name = target.name;
    

    let note = Object.assign({}, this.state.note);  
    note[name] = value;

    this.setState({note});



  }

  toggleEdit(){
    this.setState((state) => ({
      editing: !this.state.editing
    })); 
  }
  
 
  constructor(props) {
    super(props);
    
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onBlurValue = this.onBlurValue.bind(this);
    this.confirmNew = this.confirmNew.bind(this);
    this.delete = this.delete.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this)

    this.state = {
      editing: (this.props.note.ID === 'new'),
      note: this.props.note
    }
      
  }
  
}

export default Note;

