import React from "react";

export default function Categories({ items, active, handleSelect }) {
    const toggleCategory = (id) => {
        handleSelect(id !== active ? id : null);
    };

    return (
        <div className="shop-products-cats">
            {items.map(({ id, name, color }) => (
                <button
                    key={id}
                    type="button"
                    className="button"
                    data-active={id === active}
                    style={color ? { color } : null}
                    onClick={() => toggleCategory(id)}
                >
                    <span>{name}</span>
                </button>
            ))}
        </div>
    );
}
