import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Footer from "./components/footer";
import ProductSearch from "./components/product-search";
import ProductCard from "./components/product-card";
import Categories from "./components/categories";
import { getCategories, getMatches } from "../helpers";
import Navigation from "../dashboard/navigation";
import { FuneralHome } from "../context/funeral-home";

const perPage = 12;

export default function Products({ path, items, products = [], title = null }) {
  let history = useHistory();
  const { selectedFuneralHome } = useContext(FuneralHome);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [chosenList, setChosenList] = useState([]);
  const [chosenCategory, setChosenCategory] = useState(null);
  const [chosenCategories, setChosenCategories] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productSearch, setProductSearch] = useState("");
  const [productCategory, setProductCategory] = useState(null);
  const [productCategories, setProductCategories] = useState([]);

  useEffect(() => {
    setChosenCategories(getCategories(items));
    setProductCategories(getCategories(products));
  }, [items, products]);

  useEffect(() => {
    const matches = getMatches(items, null, chosenCategory);

    setChosenList(matches);
  }, [chosenCategory, items]);

  useEffect(() => {
    const matches = getMatches(products, productSearch, productCategory);
    const pages =
      matches.length > perPage ? Math.ceil(matches.length / perPage) : 1;

    setPage(0);
    setPages(pages);
    setProductList(matches);
  }, [productSearch, productCategory, products]);

  const handleCancel = () => {
    history.push("/");
  };

  const displayProducts = productList.slice(
    page * perPage,
    page * perPage + perPage
  );

  return (
    <div className="shop-products">
      <Navigation
        navigator={[
          { label: "Funeral Home", path: "/funeral-homes" },
          { label: "Orders", path: "/orders" },
          {
            label: `New order for ${
              selectedFuneralHome?.funeral_home?.funeral_home_name ||
              ''
            }`,
            path: `/funeral-home/${
              selectedFuneralHome?.funeral_home?.id
            }`,
          },
        ]}
        heading="Select products"
        path={path}
      />
      {items.length > 0 && (
        <>
          <div className="resources-label">Chosen for you</div>
          <Categories
            items={chosenCategories}
            active={chosenCategory}
            handleSelect={(id) => setChosenCategory(id)}
          />

          <div className="grid-x grid-margin-x shop-products-list">
            {chosenList.map((item) => (
              <ProductCard item={item} key={item.id} />
            ))}
          </div>
        </>
      )}

      {selectedFuneralHome?.funeral_home?.product_catalogue_complete && products.length > 0 && (
        <div>
          <div className="resources-label">
            Browse our catalogue to add another product
          </div>
          <ProductSearch
            search={productSearch}
            text="Type SAP or product name to search catalogue or select a category below"
            handleSearch={(value) => setProductSearch(value)}
          />

          <Categories
            items={productCategories}
            active={productCategory}
            handleSelect={(id) => setProductCategory(id)}
          />

          <div className="grid-x grid-margin-x shop-products-list">
            {displayProducts.map((item) => (
              <ProductCard item={item} key={item.id} />
            ))}
            {productSearch && !productList.length && (
              <p className="info cell small-12">No matching products</p>
            )}
          </div>

          <div className="text-center">
            <ReactPaginate
              pageCount={pages}
              forcePage={page}
              onPageChange={({ selected }) => setPage(selected)}
              containerClassName="pagination"
              activeClassName="current"
            />
          </div>
        </div>
      )}

      {!items.length && !products.length && (
        <h4>There are no products available at this time.</h4>
      )}

      <Footer
        prev={
          <button
            type="button"
            className="button button--reset"
            onClick={handleCancel}
          >
            <i className="far fa-chevron-left"></i> Cancel
          </button>
        }
        next={
          <Link to={`${path}/accessories`} className="button">
            Next
          </Link>
        }
      />
    </div>
  );
}
