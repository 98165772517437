import React from 'react';
import Dropzone from 'react-dropzone';

class ProductImage extends React.Component {
    render() {  
      return (
        <div>
            <div className="home-existing-logo">
                {this.props.image &&
                    <img alt="" src={this.props.image} />
                }
                {!this.props.image &&
                    <div>Product Image</div>
                }
            </div>
            <div className="dropzone-wrap">
                <Dropzone onDrop={this.onDrop}>
                    {({ getRootProps, isDragActive, getInputProps }) => (
                        <div {...getRootProps()} className={isDragActive ? 'drag-active' : ''}>
                            <input {...getInputProps()} />
                            <i className="fas fa-camera"></i>
                            <p>drop an image here to upload</p>
                        </div>
                    )}
                </Dropzone>
            </div> 



        </div>
      );
    }  

    onDrop(files) {
        this.setState({
            files
        });

        this.props.onDrop(files);        

    }
    
 
    constructor(props) {
        super(props);

        this.state = { files: [] };

        this.onDrop = this.onDrop.bind(this);
    }
    
  }

  export default ProductImage;
