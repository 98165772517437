import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import Headery from "../auth/header.jsx";
import OrderTable from "./components/order-table";
import { useLoadMore, useBackgroundFetch } from "../hooks";
import { getDiff, getUpdated } from "../helpers";
import { apiUrl, wc_orders } from "../config/config";

export default function Orders() {
    let [orders, setOrders] = useState([]);
    let [current, setCurrent] = useState([]);
    let [completed, setCompleted] = useState([]);
    let [response, loading, more, done] = useLoadMore(`${apiUrl}/${wc_orders}`);
    let [data] = useBackgroundFetch(`${apiUrl}/${wc_orders}`);

    // Show spinner
    useEffect(() => {
        if (loading) {
            spinnerService.show("ug");
        } else {
            spinnerService.hide("ug");
        }
    }, [loading]);

    // Set orders
    useEffect(() => {
        if (!response) return;

        setOrders(prev => [...prev, ...response]);
    }, [response]);

    // Add updated background data
    useEffect(() => {
        let newOrders = getDiff(data, orders);
        let updatedOrders = getUpdated(orders, data);

        setOrders([...newOrders, ...updatedOrders]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // Split orders into sections
    useEffect(() => {
        setCurrent([...orders.filter(({ status }) => status !== 'completed')]);
        setCompleted([...orders.filter(({ status }) =>  status === 'completed')]);
    }, [orders]);

    return (
        <div className="page-content customer-orders" id="main-scroller">
            <Headery title="Orders" />
            <div className="grid-container">
                <Link to="/customer" className="button--reset"><i className="far fa-chevron-left"></i> Back to dashboard</Link>
                <h1>Previous orders</h1>

                {orders.length > 0 && (
                    <>
                        <div className="customer-orders-current cell small-12">
                            <h2>Orders in progress</h2>

                            {! current.length && <p>No currently processing orders</p>}
                            
                            <OrderTable items={current} />
                        </div>

                        <div className="customer-orders-completed cell small-12">
                            <h2>Completed orders</h2>

                            {! completed.length && <p>No completed orders</p>}

                            <OrderTable items={completed} />
                        </div>

                        {! done && <p className="cell small-12 text-center" data-hidden={loading}><button type="button" onClick={more} className="button button--ghost">Load more</button></p>}
                    </>
                )}
            </div>
        </div>
    );
}
