import React from "react";

export default function FeesTable({ items = [] }) {
    if (! items.length) return null;

    return <>
        <h5 className="heading-underline">Additional Items</h5>
        <table className="shop-table fees-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th className="text-center">QTY</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item) => {
                    const [name, qty = 1] = item.name.split('**');

                    return (
                        <tr key={item.id}>
                            <td className="fees-table-name" data-title="Name">
                                {name}
                            </td>
                            <td className="fees-table-qty" data-title="Quantity">
                                {qty}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </>
}
