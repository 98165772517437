import React, { useContext } from "react";
import staffNavLogo from "../images/acc-logo-white@2x.png";
import { NavLink, useLocation } from "react-router-dom";
import {
  customerNavBarList,
  staffNavBarList,
} from "../utilities/constant/navbar.constant";
import { AuthContext } from "../context/auth";

export const Header = ({ pageName = "commonNavList" }) => {
  const { isCustomer, isManager } = useContext(AuthContext);
  const location = useLocation();
  const isUserCustomer = isCustomer() || isManager();
  const navLabelList = isUserCustomer
    ? location.pathname.split("/").indexOf("new-order") !== -1 ? customerNavBarList["createNewOrder"] : customerNavBarList[pageName] || customerNavBarList["commonNavList"]
    : location.pathname.split("/").indexOf("new-order") !== -1
    ? staffNavBarList["createNewOrder"]
    : staffNavBarList["commonNavList"];

  return (
    <nav className="header nav-header-wrapper">
      <div className="grid-container nav-header-wrapper-child">
        <div className="nav-image-logo">
          <NavLink exact to="/">
            <img src={staffNavLogo} alt="nav-staff-logo" />
          </NavLink>
        </div>
        <div className="nav-label-listing">
          <ul className="menu align-right">
            {navLabelList.map((navData) => {
              return (
                <li key={navData.name}>
                  {navData?.to ? (
                    <NavLink exact to={navData.to}>
                      {navData.label}
                    </NavLink>
                  ) : (
                    navData.label
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};
