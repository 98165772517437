import React from 'react';
import axios from 'axios';
import { spinnerService } from '@chevtek/react-spinners';
import {notify} from 'react-notify-toast';
import { AuthContext } from "../../context/auth"
import Modal from "../../widgets/modal";
import { isAccess } from '../../utilities/constant/navbar.constant';
import { acc_contact_delete, acc_contact_new, acc_contact_update } from '../../config/config';

class Contact extends React.Component {
  static contextType = AuthContext;

  render() {  

    if (this.state.editing){

      return (
        <tr>
          <td colSpan="6" className="cell-form">
            <div className="grid-x grid-margin-x">
              <div className="cell small-12 large-6">
                <p>
                  <input 
                      name="first_name"
                      type="text"             
                      placeholder="First Name*"
                      value={this.state.contact.atts.first_name}
                      onBlur={this.onBlurValue}
                      onChange={this.onChangeValue}
                  />
                </p>
              </div>
              <div className="cell small-12 large-6">
                <p>
                  <input 
                      name="last_name"
                      type="text"             
                      placeholder="Last Name*"
                      value={this.state.contact.atts.last_name}
                      onBlur={this.onBlurValue}
                      onChange={this.onChangeValue}
                  />
                </p>
              </div>
            </div>

            <div className="grid-x grid-margin-x">
              <div className="cell small-12 large-6">
                <p>
                <input 
                    name="position"
                    type="text"             
                    placeholder="Position"
                    value={this.state.contact.atts.position}
                    onBlur={this.onBlurValue}
                    onChange={this.onChangeValue}
                />
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                  <input 
                      name="user_email"
                      type="text"             
                      placeholder="Email*"
                      value={this.state.contact.atts.user_email}
                      onBlur={this.onBlurValue}
                      onChange={this.onChangeValue}
                  />
                </p>
              </div>
            </div>
            <div className="grid-x grid-margin-x">
              <div className="cell small-12 large-6">
                <p>
                  <input 
                      name="phone_mobile"
                      type="text"             
                      placeholder="Phone - Mobile"
                      value={this.state.contact.atts.phone_mobile}
                      onBlur={this.onBlurValue}
                      onChange={this.onChangeValue}
                  />
                </p>
              </div>
              <div className="cell small-12 large-6">
                <p>
                  <input 
                      name="phone_landline"
                      type="text"             
                      placeholder="Phone - Landline"
                      value={this.state.contact.atts.phone_landline}
                      onBlur={this.onBlurValue}
                      onChange={this.onChangeValue}
                  />
                </p>
              </div>
            </div>

            <p>
              <textarea 
                rows="5"
                name="contact_notes"
                placeholder="Notes"
                value={this.state.contact.atts.contact_notes}
                onBlur={this.onBlurValue}
                onChange={this.onChangeValue}
              ></textarea>
            </p>

            {this.state.contact.ID === 'new' &&
              <p>
                <input className="btn-save-new-contact button" type="submit" value="Confirm new Contact" onClick={this.confirmNew} />
              </p>
            }
            {this.state.contact.ID !== 'new' &&
              <p className="align-justify flex-container">
                <input className="btn-delete-contact pad-right button" type="submit" value="Delete Contact" onClick={this.delete} />
                <button type="button" className="button button--ghost no-margin" onClick={() => this.setState({ showResetModal: true })}>Reset Password</button>
              </p>
            }

            <Modal open={this.state.showResetModal} classes="contact-password-reset" width="600">
              <button onClick={() => this.setState({ showResetModal: false })} className="btn-close"><i className="far fa-times"></i></button>
              <form onSubmit={this.updatePassword} className="grid-x grid-margin-x">
                <div className="cell small-12">
                  <h5 className="heading-underline">Reset password</h5>
                </div>
                  <div className="cell small-12 large-6">
                    <p>
                        <label htmlFor="new-password">New Password</label>
                        <input
                            name="new-password"
                            type="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.onPasswordChange}
                        />
                    </p>
                  </div>
                  <div className="cell small-12 large-6">
                    <p>
                      <label htmlFor="confirm-password">Confirm Password</label>
                      <input
                          name="confirm-password"
                          type="password"
                          placeholder="Password"
                          value={this.state.passwordConfirmation}
                          onChange={this.onPasswordChange}
                      />
                    </p>
                  </div>

                  <div className="cell small-12 align-justify flex-container">
                    <button type="submit" className="button no-margin">Update password</button>
                    <button type="button" className="button button--ghost no-margin" onClick={() => this.resetPassword(this.state.contact.atts.user_email)}><i className="far fa-paper-plane"></i> Email password reset</button>
                  </div>
              </form>
            </Modal>
          </td>
          <td className="td-editing-close" onClick={this.toggleEdit}><i className="far fa-times"></i></td>
        </tr>
      );
    }else{

      return (
        <tr>
          <td><span dangerouslySetInnerHTML={{ __html: (this.state.contact.atts.first_name || '') }} /></td>
          <td><span dangerouslySetInnerHTML={{ __html: (this.state.contact.atts.last_name || '') }} /></td>
          <td>{this.state.contact.atts.position}</td>
          <td>{this.state.contact.atts.phone_mobile}</td>
          <td>{this.state.contact.atts.phone_landline}</td>
          <td>{this.state.contact.atts.user_email}</td>
          <td className="td-icons">
            <div data-tip="Default Contact" className={`set-default`} onClick={() => this.props.contactSetDefault(this.state.contact)}>
              {this.props.defaultContact && (this.props.defaultContact.ID === this.state.contact.ID) ? <i className="fas fa-check-circle"></i> : <i className="fal fa-circle"></i>}
            </div>

            {this.context.isAdmin() && <div data-tip="Full access" className={`set-role`} onClick={() => this.setRole()}>
              {this.state.contact.atts.role === 'manager' ? <i className="fas fa-check-square"></i> : <i className="fal fa-square"></i>}
            </div>}
            
            <div onClick={this.toggleEdit} data-tip="Edit">
              <i className="fas fa-pencil"></i>
            </div>
          </td>
        </tr>
      );
    }

  }  

  resetPassword(login) {
    spinnerService.show("ug");

    this.context.passwordResetRequest(login, (response) => {
        spinnerService.hide("ug");

        if (response.data?.status) {
            const { status, message } = response.data;

            notify.show(message, status, 3000);
            this.setState({ showResetModal: false });
        } else {
          notify.show('There was an errror sending the password reset link', 'error', 3000);
        }
    });
  }

  delete(e){
    if (!window.confirm('Are you sure you wish to delete contact ' + this.state.contact.atts.first_name + ' ' + this.state.contact.atts.last_name + '?')){
      return false;
    }

    let _this = this;

    if (!isAccess(acc_contact_delete)) {
      return;
    }

    spinnerService.show('ug');
    axios.post(this.props.config.apiUrl + `/${acc_contact_delete}`, {      
      id: this.state.contact.ID
    }).then(function (response) {
      spinnerService.hide('ug');
      _this.setState((state) => ({
        editing: false
      })); 
      notify.show('Deleted contact', 'success', 3000);
      _this.props.contactDeleted(_this.props.index);
    });
  }

  confirmNew(e){
    let _this = this;
    if (!isAccess(acc_contact_new)) {
      return;
    }

    spinnerService.show('ug');
    
    axios.post(this.props.config.apiUrl + `/${acc_contact_new}`, {      
      data: this.state.contact.atts
    }).then(function (response) {
      spinnerService.hide('ug');

      if (response.data.status === 'success') {
        notify.show('Saved new contact', 'success', 3000);
        _this.props.contactAdded(response.data.new_contact);
      } else {
        notify.show(response?.data?.error || 'Something went wrong', 'error', 3000);
      }
    });
  }

  onBlurValue(e){
    let target = e.target;
    let value = target.value;
    let name = target.name;
    if (this.state.contact.ID === 'new' || this.state.originalContact[name] === value) {
      return;
    }
    if (!isAccess(acc_contact_update)) {
      return;
    }
    axios.post(this.props.config.apiUrl + `${acc_contact_update}`, {
      id: this.state.contact.ID,
      data:{
        [name]: this.state.contact.atts[name]
      }
    }).then((response) => {
      const displayName = name.split('_').join(' ');
      notify.show('Set ' + displayName + ' to ' + value, 'success', 3000);

      this.setState({ originalContact: { ...this.state.originalContact, [name]: value }});
    });
  }


  onChangeValue(e){
    let target = e.target;
    let value = target.value;
    let name = target.name;
    

    let atts = Object.assign({}, this.state.contact);  
    atts.atts[name] = value;

    this.setState({atts});
  }

  onPasswordChange({ target }) {
    const name = target.name === 'new-password' ? 'newPassword' : 'confirmPassword';

    this.setState({ [name]: target.value });
  }

  updatePassword(e) {
    e.preventDefault();
    if (!this.state.newPassword || !this.state.confirmPassword) {
      notify.show('Please enter a password in both fields', 'error', 3000);
      return;
    }

    if (this.state.newPassword !== this.state.confirmPassword) {
      notify.show('Passwords do not match', 'error', 3000);
      return;
    }

    if (!isAccess(acc_contact_update)) {
      return;
    }

    spinnerService.show("ug");

    axios.post(this.props.config.apiUrl + `/${acc_contact_update}`, {
      id: this.state.contact.ID,
      data:{
        'new-password': this.state.newPassword,
        'confirm-password': this.state.confirmPassword
      }
    }).then((response) => {
      if (response?.data?.status && response.data.status === "success") {
        notify.show("Password successfully updated", "success", 3000);

        this.setState({ showResetModal: false });
      } else {
        notify.show(response?.data?.error ?? "Something went wrong", "error", 3000);
      }
    }).finally(() => {
      spinnerService.hide("ug");
    });
  }

  toggleEdit(){
    this.setState((state) => ({
      editing: !this.state.editing
    })); 
  }

  setRole() {
    const role = this.state.contact.atts.role !== 'manager' ? 'manager' : 'customer';
    let contact = Object.assign({}, this.state.contact);  
    contact.atts.role = role;

    this.setState({ contact });
    if (!isAccess("acc/v1/contact/role")) {
      return;
    }
      axios
        .post(this.props.config.apiUrl + "/acc/v1/contact/role/" + this.state.contact.ID, { role })
        .then((response) => {
          const message = role === 'manager' ? `${this.state.contact.atts.user_email} has been given full access` : `${this.state.contact.atts.user_email} no longer has full access`;

          notify.show(message, 'success', 3000);
      });
  }
 
  constructor(props) {
    super(props);
    
    this.resetPassword = this.resetPassword.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onBlurValue = this.onBlurValue.bind(this);
    this.confirmNew = this.confirmNew.bind(this);
    this.delete = this.delete.bind(this);
    this.setRole = this.setRole.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);

    this.state = {
      editing: (this.props.contact.ID === 'new'),
      contact: this.props.contact,
      originalContact: { ...this.props.contact.atts },
      showResetModal: false,
      newPassword: '',
      confirmPassword: ''
    }
  }
  
}

export default Contact;

