import React, { createContext, useState } from "react";

// Create a new context
export const FuneralHome = createContext();

// Create a provider component
export const FuneralHomeProvider = ({ children }) => {
  const [routes, setRoutes] = useState(null);

  const initialSelectedFuneralHome = JSON.parse(
    localStorage.getItem("selectedFuneralHome")
  );

  const [selectedFuneralHome, setSelectedFuneralHome] = useState(
    initialSelectedFuneralHome || null
  );

  // const setSelectedFuneralHome = (data) => {
  //   localStorage.setItem('selectedFuneralHome',JSON.stringify(data))
  // }
  //
  // const selectedFuneralHome = JSON.parse(localStorage.getItem('selectedFuneralHome'))

  // You can add any functions or state variables here that you want to share
  // with the components consuming this context.

  return (
    <FuneralHome.Provider
      value={{ selectedFuneralHome, setSelectedFuneralHome, routes, setRoutes }}
    >
      {children}
    </FuneralHome.Provider>
  );
};
