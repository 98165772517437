import React from "react";
import CartWidget from "../../shop/components/cart-widget";
import MenuWidget from "../../customer/components/menu-widget";
// import ChatWidget from "../../customer/components/chat-widget";
import { notify } from "react-notify-toast";
import {
  acc_contact_delete,
  acc_contact_new,
  acc_contact_profile,
  acc_contact_update,
  acc_funeral_home,
  acc_funeral_home_logo_upload,
  acc_funeral_home_lookup_lga,
  acc_funeral_homes,
  acc_note,
  acc_note_new,
  acc_options_email,
  acc_products,
  acc_regions,
  acc_routes,
  acc_shop_invoice,
  acc_shop_order_cart,
  acc_shop_order_unapproved,
  acc_shop_products,
  cocart_cart,
  wc_orders,
  wc_products,
  wp_bjm_cat_product,
  wp_resources,
} from "../../config/config";

// Navbar constants for the staff members.
export const staffNavBarList = Object.freeze({
  commonNavList: [
    { label: "Funeral Homes", to: "/funeral-homes", name: "Funeral Homes" },
    { label: "Products", to: "/products", name: "Products" },
    { label: "Mailouts", to: "/customer-mailouts", name: "Mailouts" },
    { label: "Orders", to: "/orders", name: "Orders" },
    { label: <i className="fas fa-user"></i>, to: "/account", name: "Account" },
    {
      label: <i className="fas fa-cog"></i>,
      to: "/options/routes",
      name: "Option",
    },
  ],
  createNewOrder: [
    {
      label: <CartWidget />,
      name: "CartWidget",
    },
  ],
});

// Navbar constants for the customers.
export const customerNavBarList = Object.freeze({
  commonNavList: [
    {
      label: <MenuWidget />,
      to: "/customer",
      name: "MenuWidget",
    },
    // {
    //   label: <ChatWidget />,
    //   name: "ChatWidget",
    // },
  ],
  createNewOrder: [
    {
      label: <CartWidget />,
      name: "CartWidget",
    },
  ],
});

// Footer constants for the customers.
export const customerFooterList = Object.freeze({
  commonFooterList: [
    {
      label: <MenuWidget />,
      to: "/customer",
      name: "MenuWidget",
    },
    // {
    //   label: <ChatWidget />,
    //   name: "ChatWidget",
    // },
  ],
});

// Validation Regex
export const validationRegex = {
  onlyNumer: /^[0-9]+$/,
  onlyAphabet: /^[a-zA-Z]+$/,
  onlyAlphabetWithWhiteSpace: /^[a-zA-Z\s]+$/,
  alphabetAndNumber: /^[a-zA-Z0-9]+$/,
};

export const isAccess = (endpoint) => {
  let access = true;
  const userRole = localStorage.getItem("role");
  Object.keys(endpointsAccess).forEach((role) => {
    if (role === userRole && endpointsAccess[role].indexOf(endpoint) === -1) {
      access = false;
      notify.show("Sorry, you are not allowed to do that.", "error", 3000);
    }
  });
  return access;
};

// Access
export const endpointsAccess = {
  customer: [
    acc_contact_profile,
    acc_contact_new,
    acc_contact_update,
    acc_contact_delete,
    acc_funeral_home,
    acc_funeral_home_logo_upload,
    acc_funeral_home_lookup_lga,
    wp_resources,
    acc_shop_products,
    acc_shop_invoice,
    acc_shop_order_cart,
    acc_shop_order_unapproved,
    wc_products,
    wc_orders,
    cocart_cart
  ],
  acch_production: [
    acc_contact_profile,
    wp_bjm_cat_product,
    acc_funeral_homes,
    acc_funeral_home,
    acc_products,
    acc_regions,
    acc_routes,
    acc_note,
    acc_note_new,
    acc_options_email,
    wp_resources,
    acc_shop_products,
    acc_shop_order_cart,
    cocart_cart,
    wc_products,
    wc_orders,
  ],
};

export const decodeHTML = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.documentElement.textContent;
};