import React from "react";

class Headery extends React.Component {
  render() {
    return <React.Fragment></React.Fragment>;
  }

  updateTitles() {
    document.title = this.props.title ? this.props.title + " - ACC Higgins Portal" : 'ACC Higgins Portal';

    let headerTitle = this.props.title;
    if (this.props.briefTitle) {
      headerTitle = this.props.briefTitle;
    }

    let pageTitle = document.getElementById("page-title");

    if (pageTitle) {
      pageTitle.innerHTML = headerTitle;
    }
  }

  componentDidUpdate() {
    this.updateTitles();
  }

  componentDidMount() {
    this.updateTitles();
  }

  constructor(props) {
    super(props);

    this.state = {
      scrolled: false,
    };

    this.updateTitles = this.updateTitles.bind(this);
  }
}

export default Headery;
