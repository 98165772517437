import React, { useState, useContext } from "react";
import { spinnerService } from "@chevtek/react-spinners";
import { AuthContext } from "../../context/auth";

export default function RequestReset({ auth }) {
    const { passwordResetRequest } = useContext(AuthContext);
    const [login, setLogin] = useState("");
    const [message, setMessage] = useState(null);

    const handleResetRequest = (e) => {
        e.preventDefault();

        spinnerService.show('ug');

        passwordResetRequest(login, (response) => {
            spinnerService.hide('ug');

            if (response.data?.status) {
                const { status, message } = response.data;

                setMessage({ status, content: message });
            } else {
                setMessage({
                    status: "error",
                    content: "Something went wrong",
                });
            }
        });
    };

    return (
        <form onSubmit={handleResetRequest} autoComplete="off">
            <label>
                <input
                    name="user_login"
                    type="text"
                    value={login}
                    placeholder="Username or Email"
                    onChange={({ target }) => setLogin(target.value)}
                />
            </label>
            {message && <p className={message.status}>{message.content}</p>}
            <button type="submit">Send Reset Link</button>
        </form>
    );
}
