import React, { useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";
import { getImperialUnit } from "../../helpers";
import { AuthContext } from "../../context/auth";

export default function ProductOptions({ data, handleData }) {
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [data.size]);

    const isSpecialtySizing = data.size === 'specialty';
    const specialtySizing = isSpecialtySizing || auth.options?.display?.specialty_sizing;

    return (
        <div className="product-options">
            <div className="grid-x grid-margin-x">
                <div className="product-options-select cell small-12">
                    <label htmlFor="size">Size</label>
                    <select name="size" id="size" onChange={handleData} value={data.size}>
                        <option value="standard">Standard</option>
                        {specialtySizing && <option value="specialty">Specialty</option>}
                    </select>
                    {(auth.options?.sizing && data.size === 'specialty') && <i className="product-options-select-notice fas fa-info-circle" data-tip={`View our custom sizes specifications document for your region <a href='${auth.options.sizing}' target='_blank'>here</a>.`} data-html={true} data-delay-hide={500}></i>}
                </div>
                {isSpecialtySizing && (
                    <div className="product-options-type cell small-12 flex-container">
                        <label>
                            <input type="radio" name="type" value="internal" checked={data.type === 'internal'} onChange={handleData} /> Internal dimensions
                        </label>
                        <label>
                            <input type="radio" name="type" value="external" checked={data.type === 'external'}  onChange={handleData} /> External dimensions
                        </label>
                    </div>
                )}
                <div className="product-options-input cell small-6 medium-6 large-4">
                    <label htmlFor="length"><span className="capitalize">{data.type}</span> length</label>
                    <div className="product-options-input-wrap">
                        {isSpecialtySizing ? <input type="number" placeholder="Enter length" name="length" onChange={handleData} value={data.length} readOnly={!isSpecialtySizing} /> : <span>{data.length}</span>} <span className="suffix">cm</span>
                    </div>
                </div>
                <div className="product-options-input cell small-6 medium-6 large-4">
                    <label htmlFor="width"><span className="capitalize">{data.type}</span> width</label>
                    <div className="product-options-input-wrap">
                        {isSpecialtySizing ? <input type="number" placeholder="Enter width" name="width" onChange={handleData} value={data.width} /> : <span>{data.width}</span>} <span className="suffix">cm</span>
                    </div>
                </div>
                <div className="product-options-input cell small-6 medium-6 large-4">
                    <label htmlFor="height"><span className="capitalize">{data.type}</span> height</label>
                    <div className="product-options-input-wrap">
                        {isSpecialtySizing ? <input type="number" placeholder="Enter height" name="height" onChange={handleData} value={data.height} /> : <span>{data.height}</span>} <span className="suffix">cm</span>
                    </div>
                </div>
                <div className="product-options-convertion cell small-6 medium-6 large-4">
                    <small>Imperial length</small>
                    <p>{data.length ? getImperialUnit(data.length) : 0} inches</p>
                </div>
                <div className="product-options-convertion cell small-6 medium-6 large-4">
                    <small>Imperial width</small>
                    <p>{data.width ? getImperialUnit(data.width) : 0} inches</p>
                </div>
                <div className="product-options-convertion cell small-6 medium-6 large-4">
                    <small>Imperial height</small>
                    <p>{data.height ? getImperialUnit(data.height) : 0} inches</p>
                </div>
            </div>
            {isSpecialtySizing && <p className="product-options-notice cell small-12"><i className="far fa-info-circle"></i> ACC Higgins will need to approve this item before arranging delivery</p>}
        </div>
    );
}
