import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import ProductTable from "./product-table";
import { CartContext } from "../../context/cart";

export default function ProductCart({ path, items }) {
    const { setShowCart, setEditItem, removeItem } = useContext(CartContext);
    let history = useHistory();

    const handleClose = (path) => {
        history.push(path);
        setShowCart(false);
    }

    return (
        <>
            <h3 className="modal-product-cart-title">Items in your order</h3>
            {items.length ? <ProductTable items={items} edit={setEditItem} remove={(item) => removeItem(item.item_key)} /> : <p>You don't have any products in your cart</p>}

            <div className="modal-product-cart-actions flex-container align-right">
                <button className="button button--ghost" onClick={() => handleClose(path)}>Add more products</button>
                <button className="button" onClick={() => handleClose(`${path}/review`)}>Complete order</button>
            </div>
        </>
    );
}
