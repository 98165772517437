import React from "react";
import AccountForm from "./auth/account-form";
import Navigation from "./dashboard/navigation";

export default function Account() {
  return (
    <>
      <div className="body-white page-content">
        <div className="grid-container">
          <Navigation
            navigator={[{ label: "Dashboard", path: "/" }]}
            heading="My Details"
          />
          <h2 className="heading-underline">User details</h2>
          <AccountForm />
        </div>
      </div>
    </>
  );
}
