import React from 'react';
import styled from '@react-pdf/styled-components';
import Styles from './styles.jsx';

import img_cover from '../../../images/bg-cover-3.png';

class PDFCoverProposed extends React.Component {
    render() {  

        const ACCPage = styled.Page`

            flex-direction: row;    
            background-color:white;
            
            margin:0;
            padding:0;
        
            
        `;

  
        const ImageFill = styled.Image`

            width:100%;
            height:100%;
            left:0;
            right:0;
        
            margin:0;
            position:absolute;
            top:0px;
            
            bottom:0px;
            z-index:0;
        `;
        const ImageLogo = styled.Image`
            width:60%;
            height:auto;
            margin:0 auto;
            margin-top:10px;
            
        `;

        const CenterBox = styled.View`
    
            flex-grow: 0;
            height:366px;
            
            margin: 0 auto;
            margin-top:-40px;
            
            
            text-align: center;
            width:300px;
            
            font-size: 8px;
        
            position:relative;
            z-index:10;
            justify-content:center;
            align-items:center;
            
        `;

        const AllPage = styled.View`
            align-items:center;
            justify-content:center;
            display:flex;
            margin:0px;
            width:100%;
            height:100%;
            color:#00386C; 
            
        `;

        const Footer = styled.Text`
            font-size: 7px;
            
            font-family: 'Helvetica';

            position:absolute;
            bottom:26px;
            left:43px;
            color:white;

            
        `;
        const Heading = styled.View`
            font-size: 24px;
            width:80%;
            margin:0px auto;
            margin-top:60px;
            font-family: 'Helvetica';
            text-align:center;
            
            
        `;
        const HeadingText = styled.Text`
            display:block;
            text-align:center;
            
        `; 
 

        
        
        return (
            <ACCPage size="A4">
                <Styles.ImageFillWrap>
                    <ImageFill src={img_cover}  ></ImageFill>
                </Styles.ImageFillWrap>
                <AllPage>
                    <CenterBox>                        
                        {(this.props.funeralHome.logo) ? (                                    
                            <ImageLogo src={`${this.props.funeralHome.logo}`} ></ImageLogo>
                        ) : (
                            <HeadingText></HeadingText>
                        )}
                        <Heading>
                            <HeadingText>Your suggested</HeadingText>
                            <HeadingText>product catalogue</HeadingText>
                        </Heading>
                    </CenterBox>
                    <Footer>{this.props.funeralHome.atts.funeral_home_name} | Private and Confidential</Footer>
                </AllPage>
            </ACCPage>
            
        );


    }


}
export default PDFCoverProposed;
