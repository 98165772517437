import React from "react";
import { View, Image } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";

import PDFIntroSignoff from "./intro-signoff";
import img_header from "../../../images/pdf-header-1.png";

class PDFIntro extends React.Component {
  render() {
    const ImageHeader = styled.Image`
      width: 100%;
      height: 180px;
      margin: 0;
    `;
    const ViewIntro = styled.View`
      margin: 50px 88px 25px 88px;
      color: #00386c;
      font-size: 12px;
    `;
    const TextIntro = styled.Text`
      margin: 6px 0px;
    `;

    const TextIntroSmall = styled.Text`
      margin: 3px 0px;
      font-size: 10px;
    `;

    const TextIntroSpace = styled.Text`
      margin: 26px 0px 13px 0;
    `;

    const is_intro_new = true;

    return (
      <View>
        <ImageHeader src={img_header}></ImageHeader>
        <ViewIntro>
          {this.props.proposal_atts.output_introductory_text ? (
            <TextIntro>
              {this.props.proposal_atts.output_introductory_text}
            </TextIntro>
          ) : (
            <TextIntro>
              {this.props.proposal_prefs.output_introductory_text}
            </TextIntro>
          )}

          <TextIntroSpace>Kind Regards,</TextIntroSpace>

          {this.props.user.signature ? (
            <Image
              source={{ uri: this.props.user.signature }}
              style={{ width: 150 }}
            />
          ) : null}

          <TextIntroSpace>{this.props.user.user_display_name}</TextIntroSpace>

          <TextIntroSmall>{this.props.user.user_email}</TextIntroSmall>
          <TextIntroSmall>1300 588 480</TextIntroSmall>
          <TextIntroSmall>acchiggins.com.au</TextIntroSmall>
        </ViewIntro>

        <PDFIntroSignoff
          funeralHome={
            this.props.proposal_atts.funeral_home.atts.funeral_home_name
          }
          is_intro_new={is_intro_new}
        />
      </View>
    );
  }
}
export default PDFIntro;
