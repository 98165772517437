import React from 'react';
import {Page, View } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import _ from 'lodash'
import { decodeHTML } from '../../../utilities/constant/navbar.constant';

class PDFPricelist extends React.Component {
    render() {  
        

        const ImageLogo = styled.Image`
            
            height: 50px;
            width: auto;
            margin:0 auto;
            margin-top:20px;
            margin-bottom:10px;
            
        `;


        const ViewHalfHalf = styled.View`
            display: flex;
            flex-direction: row;
        `;
        const CatalogueTable = styled.View`
            
            width:100%;
            
            color: black;
            
            padding: 14px 26px;

            
        `;

        const PageHalf = styled.View`
            
            width:50%;
        `;
  
        
        const CatSection = styled.View`
            margin-bottom:12px;
        `;
        const CatRow = styled.View`
            display: flex;
            flex-direction: row;

        `;

        const CatColumn = styled.View`
            
            display: flex;
            flex-shrink: 0;
            flex-grow: 1;
            padding-left: 10px;
            padding-right: 10px;
            
        `;
        const CatColumnNumber = styled.View`
            
            display: flex;
            flex-shrink: 0;
            flex-grow: 0;
            width:70px;
            text-align: right;
            padding-left: 10px;
            padding-right: 10px;
            
        `;
        const CatEntry = styled.Text`
            
            width: 100%;
            white-space: nowrap;
            color: black;
            font-size:11px;
            padding: 3px 0px;

        `;
        const CatEntryHeader = styled.Text`
            
            width: 100%;
            white-space: nowrap;
            color: white;
            font-size:11px;
            text-align: center;
            padding:7px 10px 8px 10px;
            margin-bottom: 5px;

        `;
        // const Testa = styled.View`
            
        //     height: 480px;
        //     border:1px solid red;
        //     width:10px;
        //     position: absolute;
        //     top:96px;
        //     left:20px;

        // `;
        // const Testa1 = styled.View`
            
        //     height: 30px;
        //     border:1px solid blue;
        //     width:10px;
        //     position: absolute;
        //     top:96px;
        //     left:15px;

        // `;
        // const Testa2 = styled.View`
            
        //     height: 19px;
        //     border:1px solid green;
        //     width:10px;
        //     position: absolute;
        //     top:148px;
        //     left:26px;

        // `;
        // const Testa3 = styled.View`
            
        //     height: 19px;
        //     border:1px solid green;
        //     width:10px;
        //     position: absolute;
        //     top:167px;
        //     left:26px;

        // `;
        // const Testa4 = styled.View`
            
        //     height: 19px;
        //     border:1px solid green;
        //     width:10px;
        //     position: absolute;
        //     top:186px;
        //     left:26px;

        // `;

        let chunked = _.chunk(this.props.cataloguePages, 2);
                

        return (

            
            <React.Fragment>

                
          

                {chunked.map(function(chunk, indexPages){

                    return (

                        <Page size="A4" orientation="landscape" wrap={false} key={indexPages}>
                            {/* <Testa></Testa>
                            <Testa1></Testa1>
                            <Testa2></Testa2>
                            <Testa3></Testa3>
                            <Testa4></Testa4> */}
                          
                            {(this.props.funeralHome.logo) ? (                                    
                                <ImageLogo src={`${this.props.funeralHome.logo}`} ></ImageLogo>
                            ) : (
                                <CatEntry></CatEntry>
                            )}
                            <ViewHalfHalf>

                                {chunk.map(function(page, indexHalfPages){

                                    return (

                                        <PageHalf key={indexHalfPages}>


                            
                                            <CatalogueTable>
                                            

                                                {page.categories.map(function(category, indexCategories){

                                                    if (category.products && category.products.length > 0){

                                                        category.products.sort(function(a, b){
                                                                        
                                                            let y = parseFloat(a.atts.retail_price);
                                                            let x = parseFloat(b.atts.retail_price);            
                
                                                            return (x < y) ? -1 : 1;
                
                                                        });

                                                        return (
                                                            <View key={indexCategories}>
                                                
                                                                <CatSection>
                                                                    <CatRow>
                                                                        <CatEntryHeader style={{ backgroundColor: category.colour }}>{category.text}</CatEntryHeader>
                                                                    </CatRow>

                                                                    {category.products.map(function(product, indexProducts){
                                                                        return (
                                                                            <CatRow key={indexProducts} >
                                                                        
                                                                                <CatColumn>
                                                                                    <CatEntry>{decodeHTML(product.atts.product_name_funeral_home)}</CatEntry>
                                                                                </CatColumn>
                                                                        
                                                                                <CatColumnNumber>
                                                                                    <CatEntry>${product.atts.retail_price}</CatEntry>                        
                                                                                </CatColumnNumber>
                                                                            
                                                                            </CatRow>
                                                                        );

                                                                    }, this)}
                                                                    
                                                                </CatSection>
                                                            </View>
                                                        );
                                                    }else{
                                                        return null;
                                                    }

                                                }, this)}


                                            </CatalogueTable>

                                        </PageHalf>


                                    );

                                }, this)}

                            </ViewHalfHalf>

                        </Page>

                    );

                }, this)}

            </React.Fragment>
            
        );


    }


}
export default PDFPricelist;