import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { AuthContext } from "../context/auth.jsx";

export default function SignIn() {
    const { logout } = useContext(AuthContext);

    useEffect(() => {
        logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return <Redirect to={`/`} />;
}
