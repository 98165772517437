import React from "react";
import { Page } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import OutputStyles from "./styles.jsx";
import moment from "moment";
import canvg from "canvg";

import img_cover from "../../../images/bg-a3-portrait.png";
import img_cover_proposed from "../../../images/bg-a3-portrait-proposed.png";

class PDFDiamond extends React.Component {
  render() {
    let _this = this;

    let is_existing = this.props.catalogue_type === "existing";

    let row_lengths = [];

    let lowestPrice = 9999999;
    let highestPrice = 0;
    //let retailTotal = 0;

    let totalMOF = 0;
    let averageMOF = 0;

    let retailSalesTotal = 0;
    let retailSalesNumber = 0;

    let pricePointsNonEmptyCount = 0;

    for (let product of this.props.catalogue.atts.catalogue_products) {
      if (this.props.notes) {
        for (let note of this.props.notes) {
          if (
            note.linked_product &&
            note.linked_product.ID === product.post.ID
          ) {
            product.atts.pinningIndex = note.linked_product_index;
            break;
          }
        }
      }

      let retailPrice = parseInt(product.atts.retail_price);
      let costPrice = parseInt(product.atts.price);
      //retailTotal += retailPrice;
      if (retailPrice > highestPrice) {
        highestPrice = retailPrice;
      }
      if (retailPrice < lowestPrice) {
        lowestPrice = retailPrice;
      }

      totalMOF += retailPrice / costPrice;

      let numberOfSales = parseInt(product.atts.stats_number_of_sales);

      retailSalesTotal += retailPrice * numberOfSales;
      retailSalesNumber += numberOfSales;
    }
    //let retailAverage = retailTotal / this.props.catalogue.atts.catalogue_products.length;

    averageMOF = totalMOF / this.props.catalogue.atts.catalogue_products.length;

    let lowerValue = parseInt(this.props.price_points_new[0].price) || 0;
    const matching_products =
      this.props.catalogue.atts.catalogue_products.filter((product) => {
        return product.atts.retail_price > lowerValue;
      });

    row_lengths.push(matching_products.length);

    this.props.price_points_new.map(function (price_point, pricePointIndex) {
      let this_price_point = parseInt(price_point.price);
      const matching_products =
        _this.props.catalogue.atts.catalogue_products.filter((product) => {
          let lowerValue = 0;
          if (_this.props.price_points_new[pricePointIndex + 1]) {
            lowerValue =
              parseInt(
                _this.props.price_points_new[pricePointIndex + 1].price
              ) || 0;
          }

          return (
            product.atts.retail_price > lowerValue &&
            product.atts.retail_price <= this_price_point
          );
        });

      row_lengths.push(matching_products.length);

      if (matching_products.length > 0) {
        pricePointsNonEmptyCount++;
      }

      return null;
    });

    let row_count = row_lengths.length;
    let total_height = 1066;
    let row_height = total_height / row_count;

    const Header = styled.View`
      width: 816px;
      height: 72px;
      margin-top: 14px;
      margin-left: 14px;
      display: flex;
      flex-direction: row;
    `;
    const HeaderLeft = styled.View`
      width: 50%;
      padding-left: 30px;
      text-align: left;
      color: white;
      align-items: flex-start;
      justify-content: center;
    `;

    const HeaderRight = styled.View`
      text-align: right;

      width: 50%;
      padding-right: 30px;

      color: white;

      justify-content: center;
    `;

    const PricePoints = styled.View`
      width: 75%;
      margin-left: auto;
      margin-right: auto;

      position: relative;

      padding: 0px 100px;
      margin-top: 5px;

      position: relative;
    `;
    const PricePointsOutline = styled.Image`
      position: absolute;
      top: 90px;
      left: 8%;
      right: 8%;
      bottom: 45px;

      height: 88%;
      width: 84%;
    `;
    const PricePointsInner = styled.View``;
    const PricePointLine = styled.View`
      display: flex;

      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: ${row_height}px;
    `;

    const PricePointEntry = styled.View`
      width: 20%;
      //overflow:hidden;
      text-align: center;
      font-size: 8px;
      padding: 2px 5px;
      display: flex;
      flex-grow: 1;
      max-width: 28%;

      position: relative;

      flex-direction: column;
      text-align: center;
    `;
    const EntryPricing = styled.View`
      display: flex;
      flex-direction: row;
    `;
    const EntryBold = styled.Text`
      font-weight: bold;
      //border:1px solid red;
      width: 100%;
      font-size: 9px;
      //height:24px;
      text-align: center;
    `;

    const EntrySmall = styled.Text`
      font-size: 8px;
      fotn-weight: bold;
    `;

    const EntryBoldLarge = styled.Text`
      font-weight: bold;

      // overflow:hidden;
      font-size: 11px;

      text-align: center;
    `;
    const Stats = styled.View`
      position: absolute;
      top: 110px;
      left: 40px;
      font-size: 11px;
    `;

    const StatsWrap = styled.View`
      margin-bottom: 12px;
      flex-direction: row;
      font-size: 14px;
      align-items: center;
    `;

    const StatsText = styled.Text`
      font-size: 10px;
      padding-top: 3px;
    `;

    const FaIconWrap = styled.View`
      width: 26px;

      margin-right: 5px;

      //align-items:center;
      //justify-content:center;
      text-align: center;
    `;
    const FaIcon = styled.Text`
      font-family: "Font Awesome 5 Pro";
      text-align: center;
      text-align: center;
    `;
    const EntryPinWrapNotes = styled.View`
      width: 16px;
      height: 16px;
      margin-left: 5px;
      margin-right: 9px;

      background: black;
      color: white;
      border-radius: 200px;

      align-items: center;
      justify-content: center;
    `;
    const EntryPinWrap = styled.View`
      width: 16px;
      height: 16px;

      text-align: center;

      background: black;
      color: white;
      border-radius: 200px;
      //align-items:center;
      //justify-content: center;

      position: absolute;
      right: 7px;
      top: -1px;
    `;
    const EntryPin = styled.Text`
      font-size: 10px;

      padding: 0px;
      margin: 0px;
      padding-top: 4px;
    `;

    const Notes = styled.View`
      position: absolute;
      top: 110px;
      right: 40px;
      font-size: 11px;
      width: 160px;
    `;

    const NoteEntryWrap = styled.View`
      margin-bottom: 12px;
      flex-direction: row;
      font-size: 16px;
      align-items: flex-start;
    `;

    const Note = styled.Text`
      font-size: 10px;
      padding-top: 3px;
    `;

    let ar_y_points = [];

    //Calculate each shape edge y point
    for (let [index, price_point] of this.props.price_points_new.entries()) {
      if (
        (is_existing && price_point.shape_edge_point_existing) ||
        (!is_existing && price_point.shape_edge_point_proposed)
      ) {
        let perc = (index + 1) / row_count;
        let y_pos = Math.round(perc * 800);

        ar_y_points.push(y_pos);
      }
    }

    //Add top point
    let path = [{ x: 220, y: 5 }];

    //Add RHS y points
    for (let [, y] of ar_y_points.entries()) {
      path.push({ x: 435, y: y });
    }

    //Add bottom point
    path.push({ x: 220, y: 795 });

    //Add LHS y points
    path.reverse();
    for (let [, y] of ar_y_points.entries()) {
      path.push({ x: 5, y: y });
    }

    let newpathS = this.createRoundedPathString(path);

    let outlineColour = is_existing ? "#94021d" : "#66805c";

    let svg =
      '<svg className="outline" height="2000" width="1200" viewBox="0 0 440 800"  strokeLinejoin="round" preserveAspectRatio="none"> <path d="' +
      newpathS +
      '" transform="translate(0,0)" style="fill:none;stroke:' +
      outlineColour +
      '; stroke-width: 4" class="poly-outline" /></svg>';

    let pngy = this.svg2img(svg);

    return (
      <Page size="A3" wrap={false}>
        <OutputStyles.ImageFillWrap>
          {is_existing ? (
            <OutputStyles.ImageFill src={img_cover}></OutputStyles.ImageFill>
          ) : (
            <OutputStyles.ImageFill
              src={img_cover_proposed}
            ></OutputStyles.ImageFill>
          )}
        </OutputStyles.ImageFillWrap>

        <Header>
          {is_existing ? (
            <HeaderLeft>
              <OutputStyles.Header1>
                Current Catalogue Diamond
              </OutputStyles.Header1>
              <OutputStyles.Header2>
                Your current inventory list, organised by category{" "}
              </OutputStyles.Header2>
            </HeaderLeft>
          ) : (
            <HeaderLeft>
              <OutputStyles.Header1>
                Proposed Catalogue Diamond{" "}
              </OutputStyles.Header1>
              <OutputStyles.Header2>
                Your proposed catalogue, organised by category{" "}
              </OutputStyles.Header2>
            </HeaderLeft>
          )}

          <HeaderRight>
            <OutputStyles.Header3>Data taken between:</OutputStyles.Header3>
            <OutputStyles.Header2>
              {moment(
                this.props.catalogue.atts.start_date,
                "YYYY-MM-DD"
              ).format("DD/MM/Y")}{" "}
              -{" "}
              {moment(this.props.catalogue.atts.end_date, "YYYY-MM-DD").format(
                "DD/MM/Y"
              )}
            </OutputStyles.Header2>
          </HeaderRight>
        </Header>

        <Stats style={{ color: outlineColour }}>
          <StatsWrap>
            <FaIconWrap>
              <FaIcon></FaIcon>
            </FaIconWrap>
            <StatsText>
              {this.props.catalogue.atts.catalogue_products.length} Units
            </StatsText>
          </StatsWrap>
          <StatsWrap>
            <FaIconWrap>
              <FaIcon></FaIcon>
            </FaIconWrap>
            <StatsText>{pricePointsNonEmptyCount} Price Points</StatsText>
          </StatsWrap>
          <StatsWrap>
            <FaIconWrap>
              <FaIcon>|</FaIcon>
            </FaIconWrap>
            <StatsText>
              Highest Price $
              {highestPrice.toLocaleString("en-AU", {
                maximumFractionDigits: 0,
              })}
            </StatsText>
          </StatsWrap>
          <StatsWrap>
            <FaIconWrap>
              <FaIcon>|</FaIcon>
            </FaIconWrap>
            <StatsText>
              Lowest Price $
              {lowestPrice.toLocaleString("en-AU", {
                maximumFractionDigits: 0,
              })}
            </StatsText>
          </StatsWrap>

          {retailSalesNumber > 0 && (
            <StatsWrap>
              <FaIconWrap>
                <FaIcon></FaIcon>
              </FaIconWrap>
              <StatsText>
                $
                {(retailSalesTotal / retailSalesNumber).toLocaleString(
                  "en-AU",
                  { maximumFractionDigits: 0 }
                )}{" "}
                Retail Sales Average
              </StatsText>
            </StatsWrap>
          )}

          <StatsWrap>
            <FaIconWrap>
              <FaIcon></FaIcon>
            </FaIconWrap>
            <StatsText>
              {averageMOF.toFixed(2)} Average MOF on pricing
            </StatsText>
          </StatsWrap>
        </Stats>

        {this.props.notes && (
          <Notes style={{ color: outlineColour }}>
            {this.props.notes.map(function (note, index) {
              let match_catalogue = false;

              if (note.linked_product) {
                for (let [
                  ,
                  product_entry,
                ] of this.props.catalogue.atts.catalogue_products.entries()) {
                  if (
                    product_entry.post.ID === note.linked_product.ID &&
                    product_entry.atts.catalogue ===
                      this.props.catalogue.post.ID
                  ) {
                    match_catalogue = true;
                  }
                }
              } else if (
                note.linked_catalogue &&
                note.linked_catalogue === this.props.catalogue_type
              ) {
                match_catalogue = true;
              }

              if (!match_catalogue) {
                return <React.Fragment key={index}></React.Fragment>;
              } else {
                return (
                  <NoteEntryWrap key={index}>
                    {note.linked_product ? (
                      <EntryPinWrapNotes
                        style={{ backgroundColor: outlineColour }}
                      >
                        <EntryPin>{note.linked_product_index}</EntryPin>
                      </EntryPinWrapNotes>
                    ) : (
                      <FaIconWrap>
                        <FaIcon></FaIcon>
                      </FaIconWrap>
                    )}
                    <Note>{note.note}</Note>
                  </NoteEntryWrap>
                );
              }
            }, this)}
          </Notes>
        )}

        <PricePointsOutline src={pngy}></PricePointsOutline>
        <PricePoints>
          <PricePointsInner>
            {this.props.price_points_new.map(function (
              price_point,
              pricePointIndex
            ) {
              let this_price_point = parseInt(price_point.price);
              const matching_products =
                _this.props.catalogue.atts.catalogue_products.filter(
                  (product) => {
                    let lowerValue = 0;
                    if (_this.props.price_points_new[pricePointIndex + 1]) {
                      lowerValue =
                        parseInt(
                          _this.props.price_points_new[pricePointIndex + 1]
                            .price
                        ) || 0;
                    }

                    return (
                      product.atts.retail_price > lowerValue &&
                      product.atts.retail_price <= this_price_point
                    );
                  }
                );

              let ar_cats = [];
              for (let [, cat] of this.props.product_cats.entries()) {
                ar_cats.push(cat.id);
              }

              //Sort matching_products by category order
              matching_products.sort(function (a, b) {
                let A = a.atts.category;
                let B = b.atts.category;

                if (ar_cats.indexOf(A) > ar_cats.indexOf(B)) {
                  return 1;
                } else if (ar_cats.indexOf(A) < ar_cats.indexOf(B)) {
                  return -1;
                }
                return 0;
              });

              return (
                <PricePointLine key={pricePointIndex}>
                  {matching_products.map(function (product, productIndex) {
                    let colour = "black";
                    //let product_cat = null;
                    for (let cat of this.props.product_cats) {
                      if (cat.id === product.atts.category) {
                        //product_cat = cat;
                        colour = cat.colour;
                      }
                    }

                    return (
                      <PricePointEntry
                        key={productIndex}
                        style={{ borderColor: colour }}
                      >
                        {this.props.outputStyle === "full" ? (
                          <React.Fragment>
                            <EntryBold style={{ color: colour }}>
                              {product.atts.product_name_acc}
                            </EntryBold>
                            <EntryPricing>
                              <EntrySmall style={{ color: colour }}>
                                $
                                {parseInt(
                                  product.atts.retail_price
                                ).toLocaleString("en-AU", {
                                  maximumFractionDigits: 0,
                                })}{" "}
                                ($
                                {parseInt(
                                  product.atts.price
                                ).toLocaleString("en-AU", {
                                  maximumFractionDigits: 0,
                                })}
                                )
                              </EntrySmall>
                            </EntryPricing>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <EntryBoldLarge style={{ color: colour }}>
                              $
                              {parseInt(
                                product.atts.retail_price
                              ).toLocaleString("en-AU", {
                                maximumFractionDigits: 0,
                              })}
                            </EntryBoldLarge>
                            {product.atts.pinningIndex && (
                              <EntryPinWrap style={{ backgroundColor: colour }}>
                                <EntryPin>{product.atts.pinningIndex}</EntryPin>
                              </EntryPinWrap>
                            )}
                          </React.Fragment>
                        )}
                      </PricePointEntry>
                    );
                  }, this)}
                </PricePointLine>
              );
            },
            this)}
          </PricePointsInner>
        </PricePoints>

        <OutputStyles.FooterDark>
          {this.props.funeral_home_name} | Private and Confidential
        </OutputStyles.FooterDark>
      </Page>
    );
  }

  createRoundedPathString(pathCoords) {
    const path = [];
    const curveRadius = 3;

    // Reset indexes, so there are no gaps
    pathCoords = pathCoords.filter(() => true);

    for (let i = 0; i < pathCoords.length; i++) {
      // 1. Get current coord and the next two (startpoint, cornerpoint, endpoint) to calculate rounded curve
      const c2Index =
        i + 1 > pathCoords.length - 1 ? (i + 1) % pathCoords.length : i + 1;
      const c3Index =
        i + 2 > pathCoords.length - 1 ? (i + 2) % pathCoords.length : i + 2;

      const c1 = pathCoords[i],
        c2 = pathCoords[c2Index],
        c3 = pathCoords[c3Index];

      // 2. For each 3 coords, enter two new path commands: Line to start of curve, bezier curve around corner.

      // Calculate curvePoint c1 -> c2
      const c1c2Distance = Math.sqrt(
        Math.pow(c1.x - c2.x, 2) + Math.pow(c1.y - c2.y, 2)
      );
      const c1c2DistanceRatio = (c1c2Distance - curveRadius) / c1c2Distance;
      const c1c2CurvePoint = [
        ((1 - c1c2DistanceRatio) * c1.x + c1c2DistanceRatio * c2.x).toFixed(1),
        ((1 - c1c2DistanceRatio) * c1.y + c1c2DistanceRatio * c2.y).toFixed(1),
      ];

      // Calculate curvePoint c2 -> c3
      const c2c3Distance = Math.sqrt(
        Math.pow(c2.x - c3.x, 2) + Math.pow(c2.y - c3.y, 2)
      );
      const c2c3DistanceRatio = curveRadius / c2c3Distance;
      const c2c3CurvePoint = [
        ((1 - c2c3DistanceRatio) * c2.x + c2c3DistanceRatio * c3.x).toFixed(1),
        ((1 - c2c3DistanceRatio) * c2.y + c2c3DistanceRatio * c3.y).toFixed(1),
      ];

      // If at last coord of polygon, also save that as starting point
      if (i === pathCoords.length - 1) {
        path.unshift("M" + c2c3CurvePoint.join(","));
      }

      // Line to start of curve (L endcoord)
      path.push("L" + c1c2CurvePoint.join(","));
      // Bezier line around curve (Q controlcoord endcoord)
      path.push("Q" + c2.x + "," + c2.y + "," + c2c3CurvePoint.join(","));
    }
    // Logically connect path to starting point again (shouldn't be necessary as path ends there anyway, but seems cleaner)
    path.push("Z");

    return path.join(" ");
  }

  serialize(path) {
    return path.reduce(function (str, seg) {
      return str + seg.x + ", " + seg.y + " ";
    }, "");
  }

  svg2img(svg) {
    canvg("canvas", svg);
    var canvas = document.getElementById("canvas");
    var img = canvas.toDataURL("image/png");

    return img;
  }
}
export default PDFDiamond;
