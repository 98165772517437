import styled from '@react-pdf/styled-components';

class OutputStyles{


    static ACCPage = styled.Page`

        flex-direction: row;    
        background-color:white;
        
        margin:0;
        padding:0;

        
    `;

      
    static ImageFill = styled.Image`
        objectFit: 'cover';
    `;
      
    static ImageFillWrap = styled.View`
        width:100%;
        height:100%;
        margin:0;
        padding: 0;
        left:0;
        right:0;
        position:absolute;
        top:0;
        bottom:0;
        z-index:0;
    `;


    static CenterBox = styled.View`
        
        flex-grow: 0;
        height:366px;
        
        margin: 0 auto;
        margin-top:-40px;
        
        
        text-align: center;
        width:300px;
        
        font-size: 8px;

        position:relative;
        z-index:10;
        justify-content:center;
        align-items:center;
        
    `;

    static AllPage = styled.View`
        align-items:center;
        justify-content:center;
        display:flex;
        margin:0px;
        width:100%;
        color:#00386C; 
        
    `;

    static AllPageBottom = styled.View`
        align-items:center;
        justify-content:flex-end;
        display:flex;
        margin:0px;
        width:100%;
        height: 100%;
        color:#00386C; 
        
    `;


    static HeaderText = styled.View`
        color: white;
        
        width:100%;
        padding-left: 40px;
        padding-top:30px;
        height:90px;
    `;
    static Header1 = styled.Text`
        font-size: 22px;
        margin-bottom:3px;
    `;
    static Header2 = styled.Text`
        font-size: 11px;
    `;

    static Header3 = styled.Text`
        font-size: 7px;
        margin-bottom:5px;
    `;


 
    static Footer = styled.Text`
        font-size: 7px;
        
        font-family: 'Helvetica';

        position:absolute;
        bottom:26px;
        left:43px;
        color:white;

        
    `;
    static FooterDark = styled.Text`
        font-size: 7px;
        
        font-family: 'Helvetica';

        position:absolute;
        bottom:26px;
        left:43px;
        color:black;

        
    `;


}

export default OutputStyles;
