import React, { useEffect, useState } from "react";
import Modal from "../widgets/modal";
import Route from "./components/route";
import { spinnerService } from "@chevtek/react-spinners";
import { notify } from "react-notify-toast";
import axios from "axios";
import { useFetch } from "../hooks";
import { acc_routes, apiUrl } from "../config/config";
import { isAccess } from "../utilities/constant/navbar.constant";

export default function Routes() {
  let [routes, setRoutes] = useState(null);
  let [options, setOptions] = useState({});
  let [edit, setEdit] = useState(null);
  let [route, setRoute] = useState({
    name: "",
    frequency: "",
    day: "",
    date: "",
  });
  let [response, refresh] = useFetch(`${apiUrl}/${acc_routes}`);

  useEffect(() => {
    if (response) {
      setRoutes(response.routes);
      setOptions(response.options);
    }
  }, [response]);

  const handleUpdate = ({ target }) => {
    setRoute({ ...route, [target.name]: target.value });
  };

  // Actually save changes
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isAccess('acc/v1/routes/new')) {
      return;
    }
    spinnerService.show("ug");
    axios
      .post(`${apiUrl}/acc/v1/routes`, route)
      .then(function (response) {
        if (response?.data?.status && response.data.status === "success") {
          notify.show("Route saved", "success", 3000);
          setRoute({ name: "", frequency: "", day: "", date: "" });
          refresh();
        } else {
          notify.show(
            response?.data?.error ?? "Something went wrong",
            "error",
            3000
          );
        }
      })
      .finally(() => {
        spinnerService.hide("ug");
      });
  };

  return (
    <div className="routes">
      <h2 className="heading-underline">Delivery routes</h2>

      {routes && (
        <>
          <table className="options-table">
            <thead>
              <tr>
                <th>Delivery route name</th>
                <th>Delivery frequency</th>
                <th>Deliver day/date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {routes.map((route) => (
                <tr key={route.id}>
                  <td>{route.name}</td>
                  <td>{route.frequency["label"]}</td>
                  <td>
                    {route.frequency["value"] === "monthly"
                      ? route.date["label"]
                      : route.day["label"]}
                  </td>
                  <td className="text-right">
                    <button
                      type="button"
                      className="button button--reset"
                      aria-label="Edit delivery route"
                      data-tip="Edit delivery route"
                      onClick={() => setEdit(route)}
                    >
                      <i className="far fa-pencil"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="form-section grid-x grid-margin-x">
            <div className="cell small-12 medium-12 large-5">
              <label htmlFor="route_name">Route name</label>
              <input
                type="text"
                name="name"
                id="route_name"
                onChange={handleUpdate}
                placeholder="Delivery route name"
                value={route.name}
                required
              />
            </div>
            <div className="cell small-12 medium-6 large-4">
              <label htmlFor="frequency">Frequency</label>
              <select
                name="frequency"
                id="frequency"
                onChange={handleUpdate}
                value={route.frequency}
                required
              >
                <option value="">Select frequency</option>
                {options.frequency.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="cell small-12 medium-6 large-2">
              <label htmlFor="day_date">Day or Date</label>
              {route.frequency !== "monthly" ? (
                <select
                  name="day"
                  id="day_date"
                  disabled={!route.frequency}
                  onChange={handleUpdate}
                  value={route.day}
                  required
                >
                  <option value="">Select day</option>
                  {options.day.map(({ value, label }) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  name="date"
                  id="day_date"
                  disabled={!route.frequency}
                  onChange={handleUpdate}
                  value={route.date}
                  required
                >
                  <option value="">Select date</option>
                  {options.date.map(({ value, label }) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="cell small-12 medium-6 large-1 align-end">
              <button
                type="button"
                className="button add-button-height button--ghost"
                disabled={
                  !(route?.name && route.frequency && (route.day || route.date))
                }
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </>
      )}

      <Modal open={Boolean(edit)} classes="modal-option" width="780">
        <button onClick={() => setEdit(null)} className="btn-close-options">
          <i className="far fa-times"></i>
        </button>
        <Route
          item={edit}
          options={options}
          refresh={refresh}
          close={() => setEdit(null)}
        />
      </Modal>
    </div>
  );
}
