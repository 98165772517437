import React, { useEffect, useState } from "react";
import { spinnerService } from "@chevtek/react-spinners";
import { notify } from "react-notify-toast";
import axios from "axios";
import { useFetch } from "../hooks";
import { apiUrl } from "../config/config";
import { isAccess } from "../utilities/constant/navbar.constant";

export default function Display() {
    let [display, setDisplay] = useState(null);
    let [response] = useFetch(`${apiUrl}/acc/v1/options/display`);

    useEffect(() => {
        if (response) {
            setDisplay(response);
        }
    }, [response]);

    const handleUpdate = ({ target }) => {
        let { type, name, checked, value } = target;

        if (type === 'checkbox') {
            value = checked;
        }

        setDisplay({ [name]: value });
    }

    const saveUpdates = () => {
        if (!isAccess('acc/v1/options/display')) {
            return;
          }
        spinnerService.show("ug");

        axios
            .post(`${apiUrl}/acc/v1/options/display`, { display })
            .then(function (response) {
                if (response?.data?.status && response.data.status === "success") {
                    notify.show("Display options saved", "success", 3000);
                } else {
                    notify.show(response?.data?.error ?? "Something went wrong", "error", 3000);
                }
            })
            .finally(() => {
                spinnerService.hide("ug");
            });
    }

    return (
        <div className="emails">
            <h2 className="heading-underline">Display options</h2>

            {display && <>
                <table className="options-table">
                    <tbody>
                        {Object.keys(display).map(key => <tr key={key}>
                            <td className="options-table-small capitalize">
                                <div className="fancy-checkbox">
                                    <input type="checkbox" id={key} name={key} checked={display[key]} onChange={handleUpdate} />
                                    <label htmlFor={key}>{key.replaceAll('_', ' ')}</label>
                                </div>
                                
                            </td>
                        </tr>)}
                    </tbody>
                </table>
                <button type="button" className="button" onClick={saveUpdates}>
                    Save changes
                </button>
            </>}
        </div>
    );
}
