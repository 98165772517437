import React from 'react';
import styled from '@react-pdf/styled-components';
import moment from 'moment';
import Styles from './styles.jsx';

import img_cover from '../../../images/bg-cover-2.png';

class PDFCoverCurrentSales extends React.Component {
    render() {  

        const ACCPage = styled.Page`

            flex-direction: row;    
            background-color:white;
            
            margin:0;
            padding:0;
        
            
        `;

  
        const ImageFill = styled.Image`

            width:100%;
            height:100%;
            left:0;
            right:0;
        
            margin:0;
            position:absolute;
            top:0px;
            
            bottom:0px;
            z-index:0;
        `;
        const ImageLogo = styled.Image`
            width:60%;
            height:auto;
            margin:0 auto;
            margin-top:10px;
            
        `;

        const CenterBox = styled.View`
    
            flex-grow: 0;
            height:366px;
            
            margin: 0 auto;
            margin-top:-40px;
            
            
            text-align: center;
            width:300px;
            
            font-size: 8px;
        
            position:relative;
            z-index:10;
            justify-content:center;
            align-items: center;
            
        `;

        const AllPage = styled.View`
            align-items:center;
            justify-content:center;
            display:flex;
            margin:0px;
            width:100%;
            height:100%;
            color:#00386C; 
            
        `;

        const Footer = styled.Text`
            font-size: 7px;
            
            font-family: 'Helvetica';

            position:absolute;
            bottom:26px;
            left:43px;
            color:white;

            
        `;
        const Heading = styled.Text`
            font-size: 24px;
            width:80%;
            margin-top: 30px;
            margin-bottom: 30px; 
            font-family: 'Helvetica';
            
            text-align: center;

             
        `;
        const MetaWrap = styled.View`
            
            flex-direction:column;
            display:flex;
            justify-content:center;
            width:200px;
        `;
        const Small = styled.Text`
            font-size: 9px;
            text-align: center;
            margin-bottom:3px;
            
            
        `;
        
        const Dates = styled.Text`
            font-size: 14px;
            margin-top:8px;
            text-align: center;
            
            
            
        `;
 

        
        
        return (
            <ACCPage size="A4">
                <Styles.ImageFillWrap>
                    <ImageFill src={img_cover} ></ImageFill>
                </Styles.ImageFillWrap>
                <AllPage>
                    <CenterBox>                        
                        {(this.props.funeralHome.logo) ? (                                    
                            <ImageLogo src={`${this.props.funeralHome.logo}`} ></ImageLogo>
                        ) : (
                            <Small></Small>
                        )}
                        <Heading>Your current sales &amp; product catalogue</Heading>
                        <MetaWrap>
                            <Small>Data taken between:</Small>
                            <Dates>{ moment(this.props.existingCatalogue.atts.start_date, "YYYY-MM-DD").format('DD/MM/Y') } to {moment(this.props.existingCatalogue.atts.end_date, "YYYY-MM-DD").format('DD/MM/Y') } </Dates>
                        </MetaWrap>

                    </CenterBox>
                    <Footer>{this.props.funeralHome.atts.funeral_home_name} | Private and Confidential</Footer>
                </AllPage>
            </ACCPage>
            
        );


    }


}
export default PDFCoverCurrentSales;
