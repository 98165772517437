import React from "react";
import { NavLink, Link } from "react-router-dom";
import moment from "moment";
import ProposalTile from "./proposal-tile.jsx";
import ChangeLog from "./change-log";
import DataTile from "./data-tile.jsx";
import Modal from "../widgets/modal";
import { getRouteById } from "../helpers";
import Navigation from "../dashboard/navigation.jsx";
import { FuneralHome } from "../context/funeral-home.jsx";
import ReactPaginate from "react-paginate";

const itemPerPage = 9;
class FuneralHomeOverview extends React.Component {
  render() {
    let _this = this;

    return (
      <div className="body-funeral-home-page" id="main-scroller">
        <Navigation
          navigator={[{ label: "Funeral Homes", path: "/funeral-homes" }]}
          heading={this.props.atts.funeral_home_name}
        />

        <div className="grid-x grid-padding-x">
          <div className="small-12 cell-creates flex-container flex-wrap align-justify">
            <div>
              <button
                onClick={this.props.createProposal}
                className="button btn-new-proposal right-pad"
              >
                <i className="fas fa-file-alt"></i> New Proposal
              </button>
              <button
                onClick={this.props.createData}
                className="button btn-new-proposal right-pad"
              >
                <i className="fas fa-tag"></i> New Data Set
              </button>
              <Link
                to={`/new-order/${this.props.post.ID}?name=${this.props.atts.funeral_home_name}`}
                className="button right-pad"
              >
                <i className="fas fa-plus"></i> New Order
              </Link>
              <Link
                to={{
                  pathname: "/orders",
                  state: {
                    searchValue: this.props.atts.funeral_home_name,
                    funeralHomeName: this.props?.atts?.main_contact?.atts?.funeral_home?.funeral_home_name,
                    funeralHomeId: this?.props?.atts?.main_contact?.atts?.funeral_home?.id,
                  },
                }}
                className="button right-pad"
              >
                <i className="fas fa-truck"></i> View Orders
              </Link>
              {this.findApprovedProposal(this.props.proposals) && <Link
                to={`/proposals/${this.findApprovedProposal(this.props.proposals).ID}`}
                className="button right-pad"
                exacts
              >
                <i className="fas fa-book-open"></i> Catalogue
              </Link>}
            </div>
            <div>
              <button
                onClick={() => this.showChangeLog(true)}
                className="button button--ghost right-pad"
              >
                <i className="fas fa-list"></i>
              </button>
              {this.props.post.post_status !== "trash" && (
                <button
                  className="button button--ghost"
                  onClick={this.props.archive}
                >
                  <i className="fas fa-archive"></i>
                </button>
              )}
            </div>
          </div>
          <div className="small-12 large-4 cell-funeral-home-overview">
            <NavLink
              className="funeral-home-overview-inner"
              to={`/funeral-home/${this.props.post.ID}/profile`}
            >
              <div className="hover">
                <i className="far fa-pencil"></i>
                EDIT
              </div>
              <div className="content">
                <div className="proposal-overview">
                  <div>
                    <strong>{this.props.atts.funeral_home_name}</strong>
                  </div>

                  {!this.props.atts.main_contact ||
                    !this.props.atts.main_contact.atts ? (
                    <div className="warning">
                      <i className="fas fa-exclamation-triangle"></i>Please
                      select a default contact.
                    </div>
                  ) : (
                    <React.Fragment>
                      <div>
                        <i className="far fa-user-circle"></i>
                        <div>
                          <span dangerouslySetInnerHTML={{ __html: (this.props.atts.main_contact.atts.first_name || '') + " " + (this.props.atts.main_contact.atts.last_name || '') }} />
                        </div>
                      </div>
                      <div>
                        <i className="far fa-phone"></i>
                        <div>
                          {this.props.atts.main_contact.atts.phone_mobile}
                        </div>
                      </div>
                      <div>
                        <i className="far fa-envelope"></i>
                        <div>
                          {this.props.atts.main_contact.atts.user_email}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div>
                    <i className="far fa-map-pin"></i>
                    <div>
                      {this.props.atts.address_line_1}
                      <br />
                      {this.props.atts.address_line_2 && (
                        <span>
                          {this.props.atts.address_line_2}
                          <br />
                        </span>
                      )}
                      {this.props.atts.address_city}
                      <br />
                      {this.props.atts.address_state && (
                        <React.Fragment>
                          {this.props.atts.address_state.toUpperCase()}
                        </React.Fragment>
                      )}{" "}
                      {this.props.atts.address_postcode}
                    </div>
                  </div>
                  {this.state.selectedRoute?.id && (
                    <div className="funeral-home-overview-route">
                      <span>
                        <i className="far fa-box"></i>{" "}
                        {this.state.selectedRoute.name}
                      </span>
                      <span>
                        <i className="far fa-truck"></i>{" "}
                        {this.state.selectedRoute.frequency["value"] ===
                          "monthly"
                          ? this.state.selectedRoute.date["label"]
                          : this.state.selectedRoute.day["label"]}
                        , {this.state.selectedRoute.frequency["label"]}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </NavLink>
          </div>

          <div className="small-12 large-8 cell-funeral-home-notes">
            <NavLink
              className="funeral-home-overview-inner"
              to={`/funeral-home/${this.props.post.ID}/notes`}
            >
              <div className="hover">
                <i className="far fa-pencil"></i>
                VIEW / ADD NOTE
              </div>
              <div className="content">
                <div className="proposal-overview">
                  {this.props.notes && (
                    <ul className="ul-notes">
                      {this.props.notes.slice(0, 5).map(function (note, index) {
                        return (
                          <li key={index} style={{ color: note.actioned ? '#B89653' : '' }}>
                            <div className="note-date">
                              {note.author_name},{" "}
                              {moment(
                                note.post_date,
                                "YYYY-MM-DD hh:mm:ss"
                              ).format("DD/MM/YYYY h:mma")}
                            </div>
                            <div className="note-content">
                              {_this.truncate(note.post_content, 170)}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </NavLink>
          </div>

          <div className="small-12">
            <div className="funeral-home-filters filters">
              <div className="">
                <label htmlFor="filter-state">Type</label>
                <div className="flex-container">
                  <button
                    className={`button button--ghost funeral-home-type ${this.state.filterByGroup === "" && "active-button-color"
                      }`}
                    onClick={() => this.filterByGroup("")}
                  >
                    All
                  </button>
                  <button
                    className={`button button--ghost funeral-home-type ${this.state.filterByGroup === "bjm_catalogue" &&
                      "active-button-color"
                      }`}
                    onClick={() => this.filterByGroup("bjm_catalogue")}
                  >
                    Data Set
                  </button>
                  <button
                    className={`button button--ghost funeral-home-type ${this.state.filterByGroup === "bjm_proposal" &&
                      "active-button-color"
                      }`}
                    onClick={() => this.filterByGroup("bjm_proposal")}
                  >
                    Proposals
                  </button>
                </div>
              </div>

              <div className="cell-search">
                <label htmlFor="search-keywords">Keywords</label>
                <div className="flex-container">
                  <input
                    name="search-keywords"
                    id="search-keywords"
                    onChange={this.filterByName}
                    type="search"
                    placeholder="Enter keywords"
                  />
                </div>
              </div>
              <div className="cell-filter">
                <label htmlFor="filter-status">Status</label>
                <select
                  name="filter-status"
                  id="filter-status"
                  value={this.state.filterByStatus}
                  onChange={({ target }) => this.filterByStatus(target.value)}
                  disabled={this.state.filterByGroup === "bjm_catalogue"}
                >
                  <option value="">All</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Won">Won</option>
                  <option value="Lost">Lost</option>
                </select>
              </div>
              <div className="flex-container align-center">
                <div className="fancy-checkbox">
                  <input
                    name="filter-archived"
                    id="filter-archived"
                    onChange={this.toggleArchived}
                    type="checkbox"
                  />
                  <label htmlFor="filter-archived">Show archived</label>
                </div>
              </div>
            </div>
          </div>

          <div className="small-12 cell-proposals proposals-list">
            {this.state.filteredItems && this.state.listView && (
              <table cellSpacing="0" className="data-table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Title</th>
                    <th>Date Range</th>
                    <th>Author</th>
                    <th>Created</th>
                    <th>Approved</th>
                    <th>Archive</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.filteredItems.slice(
                    (this.state.currentPage - 1) * itemPerPage,
                    this.state.currentPage * itemPerPage
                  )
                    .map(function (item, index) {
                      let content = null;
                      if (item === null) {
                      } else if (item.post_type === "bjm_proposal") {
                        content = (
                          <ProposalTile
                            key={index}
                            prop={item}
                            config={_this.props.config}
                            showArchived={_this.state.showArchived}
                            listView={_this.state.listView}
                            user={_this.props.user}
                          />
                        );
                      } else {
                        content = (
                          <DataTile
                            key={index}
                            prop={item}
                            funeralHomeId={_this.props.post.ID}
                            config={_this.props.config}
                            showArchived={_this.state.showArchived}
                            listView={_this.state.listView}
                          />
                        );
                      }
                      return content;
                    })}
                </tbody>
              </table>
            )}

            {this.state.items && !this.state.listView && (
              <div className="grid-x grid-margin-x grid-margin-y small-up-1 mediunm-up-2 large-up-4 grid-proposals">
                {this.state.items.map(function (item, index) {
                  let content = null;
                  if (item === null) {
                  } else if (item.post_type === "bjm_proposal") {
                    content = (
                      <ProposalTile
                        key={index}
                        prop={item}
                        config={_this.props.config}
                        showArchived={_this.state.showArchived}
                        listView={_this.state.listView}
                      />
                    );
                  } else {
                    content = (
                      <DataTile
                        key={index}
                        prop={item}
                        funeralHomeId={_this.props.post.ID}
                        config={_this.props.config}
                        showArchived={_this.state.showArchived}
                        listView={_this.state.listView}
                      />
                    );
                  }
                  return content;
                })}
              </div>
            )}

            {this.state.filteredItems?.length > 0 && (
              <ReactPaginate
                previousLabel={<i className="fal fa-chevron-left" />}
                breakLabel="..."
                nextLabel={<i className="fal fa-chevron-right" />}
                breakClassName={"break-me"}
                forcePage={this.state.currentPage - 1}
                pageCount={Math.ceil(this.state.filteredItems.length / itemPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(selectedPage) =>
                  this.setState({ currentPage: selectedPage.selected + 1 })
                }
                containerClassName={"pagination"}
              />
            )}
          </div>
        </div>

        <Modal
          open={this.state.showChangeLog}
          classes="modal-option"
          width="780"
          style={{ overflow: 'auto' }}
        >
          <button
            onClick={() => this.showChangeLog(false)}
            className="btn-close"
          >
            <i className="far fa-times"></i>
          </button>
          <ChangeLog
            home={this.props.post.ID}
            name={this.props.atts.funeral_home_name}
          />
        </Modal>
      </div>
    );
  }

  findApprovedProposal(items) {
    let approvedCatalogue = null
    if (items?.length) {
      approvedCatalogue = items.filter(item => item.approved)
    }
    return approvedCatalogue?.length ? approvedCatalogue[0] : false
  }

  toggleListView() {
    let listView = !this.state.listView;
    this.setState({
      listView,
    });
  }

  filter() {
    let filteredData = [];
    this.state.items.forEach(item => {
      if ((!this.state.filterByGroup ||
        item.post_type === this.state.filterByGroup) && (!this.state.filterByStatus ||
          item.status === this.state.filterByStatus) && (!this.state.filterByName || item.title.includes(this.state.filterByName.toLowerCase())) && (this.state.showArchived || !item.archived)) {
        filteredData.push({ ...item, show: true })
      }
    })

    this.setState({ currentPage: 1, filteredItems: filteredData });
  }

  filterByName(event) {
    const value = event.target.value;

    this.setState(
      (state) => ({
        filterByName: value,
      }),
      () => this.filter()
    );
  }

  filterByGroup(group) {
    this.setState(
      (state) => ({
        filterByGroup: group,
        filterByStatus: group === "bjm_catalogue" ? "" : state.status,
      }),
      () => this.filter()
    );
  }

  filterByStatus(status) {
    this.setState(
      (state) => ({
        filterByStatus: status,
      }),
      () => this.filter()
    );
  }

  toggleArchived() {
    this.setState(
      (state) => ({
        showArchived: !state.showArchived,
      }),
      () => this.filter()
    );
  }

  showChangeLog(value) {
    this.setState({ showChangeLog: value });
  }

  constructor(props) {
    super(props);

    this.props.setBreadcrumbs({
      funeralHomeName: this.props.atts.funeral_home_name,
      funeralHomeID: this.props.post.ID,
      pageTitle: "Overview",
    });

    this.state = {
      currentPage: 1,
      items: [...this.props.combinedItems.filter(item => item.approved), ...this.props.combinedItems.filter(item => !item.approved)],
      filteredItems: [...this.props.combinedItems.filter(item => item.approved), ...this.props.combinedItems.filter(item => !item.approved)],
      filterByGroup: "",
      filterByName: null,
      showArchived: false,
      listView: true,
      showChangeLog: false,
      selectedRoute: getRouteById(
        this.props.atts?.funeral_home_route,
        this.props.routes
      ),
    };

    this.filterByGroup = this.filterByGroup.bind(this);
    this.filterByName = this.filterByName.bind(this);
    this.toggleArchived = this.toggleArchived.bind(this);
    this.showChangeLog = this.showChangeLog.bind(this);
    this.filter = this.filter.bind(this);
    this.findApprovedProposal = this.findApprovedProposal.bind(this);
  }

  componentDidMount() {
    this.filter();
    const { setSelectedFuneralHome, setRoutes } = this.context;

    setRoutes(this.props.routes);
    setSelectedFuneralHome(this?.props?.atts?.main_contact?.atts);
  }

  truncate(mystring, n) {
    let str = mystring;
    if (mystring.length > n) {
      str = mystring.replace(mystring.slice(n), "....");
    }
    return str;
  }
}

// Assign the context type to the class
FuneralHomeOverview.contextType = FuneralHome;

export default FuneralHomeOverview;
