import React from 'react';
import Dropzone from 'react-dropzone';

class HomeImage extends React.Component {
    render() {  
      return (
        <div className="home-image">
            <div className="home-existing-logo">
                {this.props.logo &&
                    
                    <React.Fragment>
                        <img alt="Funeral Home Logo" src={this.props.logo} />
                        <button onClick={() => {
                            this.props.onRemove({
                                target: {
                                    name: 'logo',
                                    value: null
                                }
                            });
                        }}><i className="far fa-times"></i> Remove Image</button>
                    </React.Fragment>
                }
                {!this.props.logo &&
                    <div>Funeral Home Logo</div>
                }
            </div>
            <div className="dropzone-wrap">
                <Dropzone onDrop={this.onDrop}>
                    {({ getRootProps, isDragActive, getInputProps }) => (
                        <div {...getRootProps()} className={isDragActive ? 'drag-active' : ''}>
                            <input {...getInputProps()} />
                            <i className="fas fa-camera"></i>
                            <p>drop an image here to upload</p>
                        </div>
                    )}
                </Dropzone>
            </div> 



        </div>
      );
    }  

    onDrop(files) {
        this.setState({
            files
        });

        this.props.onDrop(files);        

    }
    
 
    constructor(props) {
        super(props);

        this.state = { files: [] };

        this.onDrop = this.onDrop.bind(this);
    }
    
  }

  export default HomeImage;
