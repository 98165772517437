import React, { useContext, useState, useEffect, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./auth/private-route";
import ReactTooltip from "react-tooltip";

// Auth views
import SignIn from "./auth/sign-in";
import Logout from "./auth/logout";
import PasswordReset from "./auth/password-reset/password-reset";

// Admin views
import Home from "./home";
import FourOFour from "./404";
import Account from "./account";
import Options from "./options/options";
import Proposal from "./proposals/proposal";
import SalesEntry from "./data/sales-entry";
import FuneralHome from "./funeral-home/funeral-home";
import Products from "./products/products";
import Product from "./products/product";
import CustomerMailout from "./customer-mailouts/customer-mailout";
import CustomerMailouts from "./customer-mailouts/customer-mailouts";
import CustomerMailoutAudience from "./customer-mailouts/customer-mailout-audience";
import CustomerMailoutAudiences from "./customer-mailouts/customer-mailout-audiences";
import Orders from "./orders/orders";
import Order from "./orders/order";
import NewOrder from "./orders/new-order";
import OrderApproved from "./orders/order-approved";
import OrderCompleted from "./orders/order-completed";

// Customer views
import Dashboard from "./customer/dashboard";
import CustomerProfile from "./customer/profile";
import CustomerAccount from "./customer/account";
import CustomerOrders from "./customer/orders";
import CustomerOrder from "./customer/order";
import CustomerNewOrder from "./customer/new-order";
import CustomerOrderConfirmation from "./customer/order-confimation";
import CustomerResources from "./customer/resources";
import { Header } from "./layout-components/header";

// Extras
import { AuthContext } from "./context/auth";
import { apiUrl } from "./config/config";
import DisplayError from "./widgets/display-error";
import Footer from "./layout-components/footer";
import StaffDashboard from "./dashboard/StaffDashboard";

const config = { apiUrl };

export default function Main({ error }) {
  const { ready, auth, authed, logout } = useContext(AuthContext);

  const [breadcrumbs, setBreadcrumbs] = useState({
    funeralHomeName: "",
    proposalID: null,
    pageTitle: "",
  });

  useEffect(() => ReactTooltip.rebuild());

  const childFuneralHome = useRef();

  return (
    <div className="ug">
      {authed() && <Header pageName="commonNavList" />}
      <Route exact path={`/sign-in`} render={() => <SignIn />} />
      <Route exact path={`/password-reset`} render={() => <PasswordReset />} />
      <Route exact path={`/logout`} render={() => <Logout />} />

      <div className="scroll-div">
        {ready && (
          <main className="proposal-body-content">
            <Switch>
              {error && <DisplayError error={error} />}
              <PrivateRoute
                admin
                auth={auth}
                config={config}
                exact
                path={"/funeral-homes"}
                render={(props) => <Home config={config} {...props} />}
              />

              <PrivateRoute
                admin
                auth={auth}
                config={config}
                exact
                path={"/"}
                render={(props) => <StaffDashboard {...props} />}
              />

              <PrivateRoute
                exact
                admin
                path={`/account`}
                render={(props) => <Account {...props} />}
              />

              <PrivateRoute
                admin
                path={`/options`}
                render={(props) => (
                  <Options {...props} setBreadcrumbs={setBreadcrumbs} />
                )}
              />

              <PrivateRoute
                admin
                auth={auth}
                config={config}
                path={`/products`}
                render={(props) => (
                  <Products
                    user={auth}
                    setBreadcrumbs={setBreadcrumbs}
                    config={config}
                    {...props}
                  ></Products>
                )}
              />

              <PrivateRoute
                admin
                auth={auth}
                config={config}
                path={`/product/:id`}
                render={(props) => (
                  <Product
                    user={auth}
                    setBreadcrumbs={setBreadcrumbs}
                    config={config}
                    {...props}
                  ></Product>
                )}
              />

              <PrivateRoute
                admin
                auth={auth}
                config={config}
                path={`/funeral-home/:id`}
                render={(props) => (
                  <FuneralHome
                    user={auth}
                    handleLogout={logout}
                    setBreadcrumbs={setBreadcrumbs}
                    ref={childFuneralHome}
                    config={config}
                    {...props}
                  ></FuneralHome>
                )}
              />

              <PrivateRoute
                admin
                auth={auth}
                config={config}
                path={`/proposals/:id`}
                render={(props) => (
                  <Proposal
                    user={auth}
                    handleLogout={logout}
                    setBreadcrumbs={setBreadcrumbs}
                    config={config}
                    {...props}
                  ></Proposal>
                )}
              />

              <PrivateRoute
                admin
                auth={auth}
                config={config}
                path={`/data/:id`}
                render={(props) => (
                  <SalesEntry
                    user={auth}
                    setBreadcrumbs={setBreadcrumbs}
                    config={config}
                    {...props}
                  ></SalesEntry>
                )}
              />

              <PrivateRoute
                admin
                auth={auth}
                config={config}
                path={`/customer-mailouts`}
                render={(props) => (
                  <CustomerMailouts
                    user={auth}
                    setBreadcrumbs={setBreadcrumbs}
                    config={config}
                    {...props}
                  ></CustomerMailouts>
                )}
              />

              <PrivateRoute
                admin
                auth={auth}
                config={config}
                path={`/customer-mailout-audiences`}
                render={(props) => (
                  <CustomerMailoutAudiences
                    user={auth}
                    setBreadcrumbs={setBreadcrumbs}
                    config={config}
                    {...props}
                  ></CustomerMailoutAudiences>
                )}
              />

              <PrivateRoute
                admin
                auth={auth}
                config={config}
                path={`/customer-mailout-audience/:id?`}
                render={(props) => (
                  <CustomerMailoutAudience
                    user={auth}
                    setBreadcrumbs={setBreadcrumbs}
                    config={config}
                    {...props}
                  ></CustomerMailoutAudience>
                )}
              />

              <PrivateRoute
                admin
                auth={auth}
                config={config}
                path={`/customer-mailout/:id?`}
                render={(props) => (
                  <CustomerMailout
                    user={auth}
                    setBreadcrumbs={setBreadcrumbs}
                    config={config}
                    {...props}
                  ></CustomerMailout>
                )}
              />

              <PrivateRoute
                admin
                exact
                path={`/orders`}
                render={(props) => <Orders {...props} />}
              />

              <PrivateRoute
                admin
                exact
                path={`/orders/:id`}
                render={(props) => <Order {...props} />}
              />

              <PrivateRoute
                admin
                path={`/new-order/:home`}
                render={(props) => (
                  <NewOrder {...props} setBreadcrumbs={setBreadcrumbs} />
                )}
              />

              <PrivateRoute
                admin
                exact
                path={`/order-approved/:id`}
                render={(props) => <OrderApproved {...props} />}
              />

              <PrivateRoute
                admin
                exact
                path={`/order-completed/:id`}
                render={(props) => <OrderCompleted {...props} />}
              />

              <PrivateRoute
                exact
                path={`/customer/profile`}
                render={(props) => <CustomerProfile {...props} />}
              />

              <PrivateRoute
                exact
                manager
              path={`/customer/account`}
                render={(props) => <CustomerAccount {...props} />}
              />

              <PrivateRoute
                exact
                path={`/customer/orders`}
                render={(props) => <CustomerOrders {...props} />}
              />

              <PrivateRoute
                exact
                path={`/customer/orders/:id`}
                render={(props) => <CustomerOrder {...props} />}
              />

              <PrivateRoute
                path={`/customer/new-order`}
                render={(props) => <CustomerNewOrder {...props} />}
              />

              <PrivateRoute
                exact
                path={`/customer/resources`}
                render={(props) => <CustomerResources {...props} />}
              />

              <PrivateRoute
                exact
                path={`/customer/order-confirmation/:id`}
                render={(props) => <CustomerOrderConfirmation {...props} />}
              />

              <PrivateRoute
                exact
                customer
                path={`/customer`}
                render={(props) => <Dashboard {...props} />}
              />

              <Route render={() => <FourOFour />} />
            </Switch>
          </main>
        )}

        {authed() && <Footer />}
      </div>
      <ReactTooltip effect="solid" />
    </div>
  );
}
