import React from 'react';
import Note from './note.jsx';
import Navigation from '../../dashboard/navigation.jsx';

class Notes extends React.Component {
    render() {   
      return (
        <div className="body-notes" id="main-scroller">
            
            <Navigation
            navigator={[{ label: "Funeral Homes", path: "/funeral-homes" },
          { label: `${this.props.home.name}`, path: `/funeral-home/${this.props.home.ID}` }]}
            heading={`${this.props.home.name} Notes`}
          />
            <table className="table-notes">
                <thead>
                    <tr>
                        <th className="th-date">Date</th>
                        <th className="th-staff">Staff Member</th>
                        <th className="th-note">Note</th>
                        <th className="th-actioned">Actioned</th>
                        
                        <th className="th-icons">Edit</th>
                    </tr>

                </thead>
                

                    
                {this.props.notes.map(function(note, index){

                    return (
                        
                        <tbody key={note.ID}  className="home-note-entry">
                           
                            <Note 
                                config={this.props.config}                                
                                note={note}  
                                noteUpdated={this.props.noteUpdated}                              
                                index={index}
                                noteDeleted={this.props.noteDeleted}
                                
                            />
                            
                        </tbody>
                    );


                }, this)}

                {this.state.adding &&

                        <tbody className="home-note-entry note-entry-new">
                            
                            <Note 
                                config={this.props.config}                                
                                note={this.state.new_note}
                                noteAdded={this.noteAdded}
                                
                                funeralHomeID={this.props.home.ID}
                                
                            />
                            
                        </tbody>

                }


            

            </table>

            <p>
                <input className="btn-add-note" type="submit" value={this.state.adding ? 'Cancel Adding Note' : 'Add New Note'} onClick={this.toggleAdd} />
            </p> 


        </div>
      );
    }  

   
    
 
    constructor(props) {
        super(props);        

        this.state = {
            adding: false,            
        }

        this.toggleAdd = this.toggleAdd.bind(this);
        this.noteAdded = this.noteAdded.bind(this);   

        this.props.setBreadcrumbs({
            funeralHomeName: this.props.home.name,
            funeralHomeID: this.props.home.ID,
            pageTitle: 'Notes'
        });

        
    }


    noteAdded(new_note){

        this.toggleAdd();

        this.props.noteAdded(new_note);
    }

    toggleAdd(e){
        
        this.setState((state) => ({
            adding: !this.state.adding,
            new_note:{
                ID: 'new',
                post_content: '',
                post_title: 'New Note',
            }
        })); 

        
    }
    
    
  }

  export default Notes;

