import React, { useState, useEffect } from "react";

export default function Email({ item, update, close }) {
  const [email, setEmail] = useState({ key: "", content: "" });

  useEffect(() => {
    if (item) {
      setEmail(item);
    }
  }, [item]);

  const handleUpdate = ({ target }) => {
    setEmail({ ...email, content: target.value });
  };

  // Send changes up
  const handleSubmit = (e) => {
    e.preventDefault();

    update(email);
  };

  return (
    <>
      <form
        className="form options-form cell small-12 flex-container flex-dir-column"
        onSubmit={handleSubmit}
      >
        <div className="modal-header">
          Edit `{email.key.replace("_", " ")}` email
        </div>
        <p>
          <small>
            Note: you can insert details using the following shortcodes:
            [manager_name] [contact_name] [funeral_home]
          </small>
        </p>

        <div className="form-section grid-x grid-margin-x">
          <div className="cell small-12">
            <textarea
              name="email"
              id="email"
              rows="10"
              value={email.content}
              onChange={handleUpdate}
            />
          </div>
        </div>

        <div className="grid-x grid-margin-x align-middle">
          <div className="cell small-6">
            <button
              type="button"
              className="button button--reset"
              onClick={close}
            >
              <i className="far fa-chevron-left"></i> Cancel
            </button>
          </div>
          <div className="cell small-6 flex-container align-right">
            <button type="submit" className="button">
              Save changes
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
