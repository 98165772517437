import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import Headery from "../auth/header";
import { useQuery } from "../hooks"

export default function OrderConfirmation() {
    let { id } = useParams();
    const query = useQuery();
    const home = query.get("home");

    useEffect(() => spinnerService.hide("ug"), []);

    return (
        <div className="page-content body-white order-update" id="main-scroller">
            <Headery title="Order Confirmation" />
            <div className="grid-container">
                <div className="grid-x">
                    <div className="cell cell-component small-12 medium-offset-2 medium-8">
                        <i className="order-update-icon fal fa-check-circle fadeUp"></i>
                        <p className="no-margin"><strong>Order number: {id}</strong></p>
                        <h1 className="order-update-title">This order has been confirmed and {home ? home : 'the funeral home'} has been informed.</h1>
                        <Link to="/orders" className="button button--ghost right-pad">Back to orders</Link>
                        <Link to="/" className="button">Go to homepage</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
