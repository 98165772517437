import React, { useContext } from "react";
import { AuthContext } from "../context/auth";
import { NavLink } from "react-router-dom";
import { customerFooterList } from "../utilities/constant/navbar.constant";

const Footer = () => {
  const { isCustomer } = useContext(AuthContext);
  const isUserCustomer = isCustomer();
  let additionalFooterForCustomer = null;

  // if user type is customer.
  if (isUserCustomer) {
    const footerListItems = customerFooterList["commonFooterList"];

    additionalFooterForCustomer = (
      <>
        <div>ACC Higgins Online Ordering @ 2023</div>
        <div className="footer-items-listings">
          <ul className="menu align-right">
            {footerListItems.map((navData) => {
              return (
                <li key={navData.name}>
                  {navData?.to ? (
                    <NavLink exact to={navData.to}>
                      {navData.label}
                    </NavLink>
                  ) : (
                    navData.label
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }

  return (
    <footer className="footer">
      <div className="footer-wrapper grid-container">
        {additionalFooterForCustomer}
      </div>
    </footer>
  );
};

export default Footer;
