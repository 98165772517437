import React from 'react';

import { Redirect } from "react-router-dom";
import PrivateRoute from '../auth/private-route.jsx';
import axios from 'axios';

import { spinnerService } from '@chevtek/react-spinners';
import { notify } from 'react-notify-toast';

import Headery  from "../auth/header.jsx";
import FuneralHomeOverview  from "./overview.jsx";
import FuneralHomeProfile  from "./profile/profile.jsx";
import FuneralHomeNotes  from "./notes/notes.jsx";
import FuneralHomeCatalogue  from "./catalogue/catalogue.jsx";
import ItemClone from './item-clone.jsx';
import { acc_funeral_home } from '../config/config.js';
import { isAccess } from '../utilities/constant/navbar.constant.js';

class FuneralHome extends React.Component {

    render() {  
      return (
        <div className="react-main">
        
          {this.state.proposalRedirect &&
            <Redirect to={this.state.proposalRedirect} /> 
          }

          <Headery  title={this.state.pageTitle}></Headery>

            {this.state.error_go_back && 
              <Redirect to={`/`} /> 
            }

            {this.state.post &&
           
                
              <div className="cell auto funeral-home-body body-white page-content">
                <div className="grid-container">
                  <div className="grid-x">
                    

                    {this.state.atts &&
                 
                        <div className="cell funeral-home-body-content">

                          {this.state.add && this.state.add.type === 'data' &&
                            <ItemClone 
                              config={this.props.config}   
                              db={this.state.post.db_connection}
                              item={this.state.add}
                              items={this.state.datasets}
                              closeItemClone={this.closeItemClone}
                              confirmNew={this.confirmNewData}                              
                            />
                          }
                          {this.state.add && this.state.add.type === 'proposal' &&
                            <ItemClone 
                              config={this.props.config}   
                              item={this.state.add}
                              items={null}          
                              db={this.state.post.db_connection}                    
                              closeItemClone={this.closeItemClone}
                              confirmNew={this.confirmNewProposal}                              
                            />
                          }
                        
                          <PrivateRoute
                              exact
                              config={this.props.config}      
                              path={`/funeral-home/${this.props.match.params.id}/profile`}
                              
                              render={(props) => 
                                <FuneralHomeProfile   
                                  config={this.props.config}                        
                                  home={{
                                    ID: this.state.post.ID,
                                    atts: this.state.atts,
                                    lga_postcode_maps: this.state.lga_postcode_maps,
                                    contacts: this.state.contacts,
                                    regions: this.state.regions,
                                    routes: this.state.routes
                                  }}
                                  funeral_home_groups={this.state.funeral_home_groups}
                                  setBreadcrumbs={this.props.setBreadcrumbs}
                                  setHomeAttrs={this.setHomeAttrs}
                                ></FuneralHomeProfile>
                              }               
                            />
                          <PrivateRoute
                              exact
                              config={this.props.config}      
                              path={`/funeral-home/${this.props.match.params.id}/notes`}
                              
                              render={(props) => 
                                <FuneralHomeNotes   
                                  config={this.props.config}                        
                                  home={{
                                    ID: this.state.post.ID,      
                                    name: this.state.atts.funeral_home_name                              
                                  }}
                                  setBreadcrumbs={this.props.setBreadcrumbs}
                                  notes={this.state.notes}
                                  noteAdded={this.handleNoteAdded}
                                  noteUpdated={this.handleNoteUpdated}

                                ></FuneralHomeNotes>
                              }               
                            />
                            <PrivateRoute
                              exact
                              path={`/funeral-home/${this.props.match.params.id}/catalogue`}
                              render={(props) => 
                                <FuneralHomeCatalogue 
                                  home={{
                                    ID: this.state.post.ID,      
                                    name: this.state.atts.funeral_home_name                      
                                  }} 
                                  setBreadcrumbs={this.props.setBreadcrumbs}
                                />
                              }               
                            />
                            <PrivateRoute
                              exact
                              config={this.props.config}      
                              path={`/funeral-home/${this.props.match.params.id}`}
                              
                              render={(props) => 
                                <FuneralHomeOverview                           
                                  atts={this.state.atts}
                                  post={this.state.post}
                                  notes={this.state.notes}
                                  datasets={this.state.datasets}
                                  proposals={this.state.proposals}
                                  combinedItems={this.state.combinedItems}
                                  setBreadcrumbs={this.props.setBreadcrumbs}
                                  archive={this.handleArchive}
                                  createData={this.createData}
                                  createProposal={this.createProposal}
                                  config={this.props.config}
                                  routes={this.state.routes}
                                  user={this.props.user}
                                ></FuneralHomeOverview>
                              }               
                            />
                          
                          </div>
    
                    }
                    
                  </div>
                </div>
              </div>
  
            }
        </div>

      );
    }  

    constructor(props) {
      super(props);

      let _this = this;            

      _this.state = {
        pageTitle: `Funeral Home ${this.props.match.params.id}`,
        proposalRedirect: null,
        add: null
      };

      this.createProposal = this.createProposal.bind(this);
      this.createData = this.createData.bind(this);
      this.handleNoteAdded = this.handleNoteAdded.bind(this);
      this.handleNoteUpdated = this.handleNoteUpdated.bind(this);
      
      this.closeItemClone = this.closeItemClone.bind(this);
      this.confirmNewData = this.confirmNewData.bind(this);
      this.confirmNewProposal = this.confirmNewProposal.bind(this);

      this.handleArchive = this.handleArchive.bind(this);
      this.setHomeAttrs = this.setHomeAttrs.bind(this);

      if(localStorage.getItem('role') === 'customer' || localStorage.getItem('role') === 'manager'){
        return
      }

      spinnerService.show('ug');

      
      let funeral_home_id = this.props.match.params.id;

      
      axios.get(this.props.config.apiUrl + `/${acc_funeral_home}/` + funeral_home_id, {
        
      
      }).then(function (response) {
      
        spinnerService.hide('ug');

        if (response){
          if (response.data.error || !response.data.funeral_home){
              alert('An error occured whilst retrieving this funeral home. Returning you to homepage.')

              _this.setState((state) => ({ 
                  error_go_back: true,
                  
              })); 

            return;
          }        

          let arCombined = response.data.funeral_home.proposals;
          if (response.data.funeral_home.catalogues){
            arCombined = response.data.funeral_home.proposals.concat(response.data.funeral_home.catalogues);
          }

          _this.setState((state) => ({           
            post: response.data.funeral_home.post,
            atts: response.data.funeral_home.atts,
            lga_postcode_maps: response.data.funeral_home.lga_postcode_maps,         
            contacts: response.data.funeral_home.contacts,         
            proposals: response.data.funeral_home.proposals,
            pageTitle: response.data.funeral_home.atts.funeral_home_name ,
            notes: response.data.funeral_home.notes,
            datasets: response.data.funeral_home.catalogues,
            funeral_home_groups: response.data.funeral_home.funeral_home_groups,
            regions: response.data.funeral_home.regions,
            routes: response.data.funeral_home.routes,
            combinedItems: arCombined,
          })); 
        }

      });
    }

    handleArchive(){
      let _this = this;
      if (!window.confirm('Are you sure you wish to archive this funeral home? It will no longer appear in the list of homes.')){
        return;
      }
      if (!isAccess('acc/v1/funeral-home/archive')) {
        return;
      }
      spinnerService.show('ug');      

      axios.post(this.props.config.apiUrl + '/acc/v1/funeral-home/archive', {
        id: this.state.post.ID,        
      }).then(function (response) {
        spinnerService.hide('ug');
        notify.show('Funeral Home archived - returning to portal home', 'success', 3000);

        _this.setState((state) => ({ 
          error_go_back: true,
          
        })); 

      });

    }

    closeItemClone(){
      this.setState((state) => ({ 
        add: null
      })); 
    }

    compareDates(a, b) {
      
      if (a.post_date  < b.post_date){
        return -1;
      }else if (a.post_date > b.post_date){
        return 1;
      }else{
        return 0;
      }
    }


    confirmNewProposal(itemToClone){
 
      let _this = this;
      if (!isAccess('acc/v1/proposal/create')) {
        return;
      }
      spinnerService.show('ug');

      axios.post(this.props.config.apiUrl + '/acc/v1/proposal/create', {
          funeral_home_id: _this.state.post.ID,
          itemToClone: itemToClone,
      }).then(response=> {
        spinnerService.hide('ug');
        if (response?.data?.status === 'success'){
          notify.show('Created proposal. Redirecting you to the proposal edit screen.', 'success', 3000);
          _this.setState((state) => ({ 
              proposalRedirect: '/proposals/' + response?.data.id
          })); 


        }

          
      });

    }
    confirmNewData(itemToClone){
      let _this = this;
      if (!isAccess('acc/v1/catalogue/create')) {
        return;
      }
      spinnerService.show('ug');

      axios.post(this.props.config.apiUrl + '/acc/v1/catalogue/create', {
          funeral_home_id: _this.state.post.ID,
          type: 'data',
          itemToClone: itemToClone,
      }).then(function (response) {
          
        let data = response.data;
      
        spinnerService.hide('ug');
  
        if (data.status === 'success'){
          notify.show('Created data set. Redirecting you to the data entry screen.', 'success', 3000);
          

          _this.setState((state) => ({ 
              proposalRedirect: '/data/' + data.id
          })); 


        }

          
      });
    }

    createProposal(){
      
      this.setState((state) => ({ 
          add: {
            type: 'proposal',
            title: 'Proposal'
          }
      })); 

    }
    createData(){
      
      this.setState((state) => ({ 
          add: {
            type: 'data',
            title: 'Data Set'
          }
      })); 

    }

    handleNoteAdded(new_note){

      let notes = Object.assign([], this.state.notes);  
      notes.unshift(new_note);

      this.setState({notes});
      
    }
    handleNoteUpdated(noteContent, index){


      if (this.state.notes[index]){
        let notes = Object.assign([], this.state.notes);  
        notes[index].post_content = noteContent;
        this.setState({notes});
      }
    }

    setHomeAttrs(attrs) {
      this.setState({ atts: { ...this.state.atts, ...attrs } });
    }

  }



       
export default FuneralHome
