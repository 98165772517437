import React from 'react';


class Sorter extends React.Component {
    render() {  
                
      return (
        <span className="sorter"> 
            {this.props.sorting.column !== this.props.column &&
                <i className="none far fa-sort"></i>
            }
            {this.props.sorting.column === this.props.column && this.props.sorting.sort === 'asc' &&
                <i className="asc fas fa-sort-up"></i>
            }
            {this.props.sorting.column === this.props.column && this.props.sorting.sort === 'desc' &&
                <i className="desc fas fa-sort-down"></i>
            }
        </span>
            
      );
    }   

  }

  export default Sorter;
