import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getMetaDate, getMetaData, hasLineMeta } from "../../helpers";

export default function OrderTable({ items }) {
  let history = useHistory();

  return (
    <table className="shop-table order-table">
      <thead className="table-header">
        <tr>
          <th>Funeral Home</th>
          <th>Order number</th>
          <th>Delivery date</th>
          <th>Created</th>
          <th className="text-center">QTY</th>
          <th className="text-center">Status</th>
          <th className="no-padding"></th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const ref = getMetaData("order_reference", item.meta_data);

          return (
            <tr
              key={item.id}
              data-status={item.status}
              onClick={() =>
                history.push(
                  `/orders/${item.id}?home=${item.funeral_home?.id ?? null}`
                )
              }
            >
              <td>
                {item.funeral_home?.logo && (
                  <img
                    src={item.funeral_home?.logo}
                    className="order-table-image"
                    alt={item.funeral_home.funeral_home_name}
                  />
                )}
                <span className="order-table-home funural-home-text">
                  {item.funeral_home?.funeral_home_name &&
                    item.funeral_home.funeral_home_name}
                  {ref && <small>REF: {ref}</small>}
                </span>
              </td>
              <td data-title="Order number">#{item.id}</td>
              <td data-title="Delivery date">
                {getMetaDate("order_delivery_date", item.meta_data)}{" "}
                {getMetaDate("is_order_fast_track", item.meta_data) && (
                  <i className="fas fa-shipping-fast"></i>
                )}
              </td>
              <td data-title="Order created">
                {moment(item.date_created, "YYYY-MM-DD hh:mm:ss").format(
                  "DD/MM/YYYY"
                )}
              </td>
              <td className="text-center bold-text" data-title="QTY">
                {item.line_items.reduce((a, i) => a + i.quantity, 0)}
              </td>
              <td className="text-center" data-title="Status">
                <button
                  type="button"
                  className={`button ${
                    item.status === "new" ? " button--red" : item.status === "approved" ? "button--black":"button--blue"
                  } capitalize`}
                >
                  {item.status === 'processing' ? 'In-progress' :item.status}
                </button>
              </td>
              <td className="shop-table-notice">
                {hasLineMeta("size", "specialty", item.line_items) && (
                  <i
                    className="fas fa-info-circle"
                    data-tip={"Order contains custom dimensions"}
                  ></i>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
