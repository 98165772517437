import React from "react";
import Notifications, { notify } from "react-notify-toast";
import { Spinner } from "@chevtek/react-spinners";
import axios from "axios";
import Main from "./main.jsx";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/auth.jsx";
import { CartProvider } from "./context/cart.jsx";
import img_spin from "./images/spin.svg";
import { FuneralHomeProvider } from "./context/funeral-home.jsx";

class App extends React.Component {
  render() {
    return (
      <>
        <AuthProvider>
          <CartProvider>
            <FuneralHomeProvider>
              <Router>
                <Main error={this.state.error} />
              </Router>
            </FuneralHomeProvider>
          </CartProvider>
        </AuthProvider>
        <Spinner name="ug" show={true}>
          <div className="global-loading">
            <img alt="Loading..." src={img_spin} />
          </div>
        </Spinner>

        <Notifications />
      </>
    );
  }

  constructor(props) {
    super(props);

    this.state = { error: null };

    // apply interceptor on response
    axios.interceptors.response.use(
      (response) => response,
      this.axiosErrorHandler
    );
  }

  axiosErrorHandler = (error) => {
    // check for errorHandle config
    if (
      error.config &&
      error.config.hasOwnProperty("errorHandle") &&
      error.config.errorHandle === false
    ) {
      return Promise.reject(error);
    }

    // if has response show the error
    if (error && error.response) {
      console.log("Axios Global Error", error.response.data.code);

      switch (error.response.data.code) {
        case "jwt_auth_invalid_token":
          notify.show(
            "You're session has expired, you will now be logged out.",
            "error",
            3000
          );

          setTimeout(() => (window.location = "/logout"), 3000);
          break;

        default:
          notify.show("Error: " + error.response.data.message, "error", 5000);
          break;
      }
    } else if (error.message) {
      let errorText = "";

      switch (error.message) {
        case "timeout":
          errorText = "The network request timed out.";
          break;

        case "Network Error":
          errorText =
            "A general network error occured whilst retrieving information.";
          break;

        default:
          errorText = "An error has occured: " + error.message;
          break;
      }

      this.setState((state) => ({
        error: errorText,
      }));
    }
  };
}

export default App;
