import React from "react";
import ReactModal from "react-modal";
import { spinnerService } from "@chevtek/react-spinners";
import axios from "axios";
import Select from "react-select";
import { isAccess } from "../utilities/constant/navbar.constant";

class ItemClone extends React.Component {
  render() {
    return (
      <div className={`wrapper-clone`}>
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="Add Options"
          style={{
            content: {
              overflow: "initial",
            },
          }}
          portalClassName="modal-clone"
        >
          <h2>Add New {this.props.item.title}</h2>

          {this.state.items && (
            <p>
              <label>Existing {this.props.item.title} </label>
              <Select
                name="address_state"
                id="address_state"
                placeholder="Select existing..."
                value={this.state.selectedItem}
                onChange={(target) => this.setState({ selectedItem: target })}
                isClearable
                options={this.state.items.map((item) =>
                  this.props.item.type === "data"
                    ? {
                        value: item.ID,
                        label: `${item.start_date_display} - ${item.end_date_display} ${item.post_title}`,
                      }
                    : this.props.item.type === "proposal"
                    ? {
                        value: item.ID, // Replace with the appropriate property for proposal ID
                        label: `${item.post_title}`, // Replace with the appropriate property for proposal title
                      }
                    : {}
                )}
              />
              <button
                onClick={() => this.confirmNew(true)}
                className={`create-new-from-existing-button disabled-${
                  this.state.selectedItem === ""
                }`}
                disabled={!this.state.selectedItem}
              >
                Create New from Existing
              </button>
            </p>
          )}
          <p>
            <button onClick={() => this.confirmNew(false)} disabled={!!this.state.selectedItem}>
              Create Blank New
            </button>
          </p>
          <button onClick={this.props.closeItemClone} className="btn-close">
            <i className="far fa-times"></i>
          </button>
        </ReactModal>
      </div>
    );
  }

  confirmNew(clone) {
    let _this = this;
    this.setState({ showModal: false }, () => {
      if (clone) {
        _this.props.confirmNew(_this.state.selectedItem ? _this.state.selectedItem?.value : _this.state.selectedItem);
      } else {
        _this.props.confirmNew(null);
      }
    });
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  constructor(props) {
    super(props);

    let _this = this;

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    if (this.props.item.type === "proposal") {
      this.state = {
        showModal: true,
        selectedItem: "",
      };
      if (!isAccess("acc/v1/proposals")) {
        return;
      }
      spinnerService.show("ug");
      axios
        .get(this.props.config.apiUrl + "/acc/v1/proposals/", {})
        .then(function (response) {
          spinnerService.hide("ug");

          if (response && response.data) {
            _this.setState({
              items: _this.props.db
                ? response.data.proposals.filter(
                    (proposal) =>
                      proposal.db_connection === _this.props.db &&
                      !proposal.archived
                  )
                : response.data.proposals,
            });
          }
        });
    } else {
      this.state = {
        showModal: true,
        selectedItem: "",
        items: this.props.items,
      };
    }
  }
}

export default ItemClone;
