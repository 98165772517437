import React from "react";
import { spinnerService } from "@chevtek/react-spinners";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import Navigation from "../dashboard/navigation.jsx";
import { isAccess } from "../utilities/constant/navbar.constant.js";
import { acc_products, apiUrl, wc_products } from "../config/config.js";

class Products extends React.Component {
  render() {
    const colors = {
      Rosewood: "#65000B",
      Teak: "#B1946C",
      Walnut: "#5D4C3A",
      Sapele: "#59261F",
      White: "#FFFFFF",
      Oak: "#806517",
      Maple: "#DEB887",
      Raw: "#E0D0B7",
      Redgum: "#B3272C",
      Cedar: "#3B4249",
    };

    return (
      <div className="cell auto products-body body-white">
        <div className="grid-container">
          <div className="grid-x">
            <Navigation heading="Manage Products" />
            <div className="cell small-12">
              <div className="div-division product-filters filters">
                <div className="left-side-column">
                  <label htmlFor="filter-material">Keywords</label>
                  <input
                    onChange={this.filterByName}
                    type="search"
                    placeholder="Enter Product Title, SAP Code or other keywords"
                    className="search-products"
                  />
                </div>
                <div className="right-side-column">
                  <label htmlFor="filter-material">Category</label>
                  <select
                    name="filter-material"
                    id="filter-material"
                    placeholder="Select a category"
                    value={this.state.filterByMaterial}
                    className="filter"
                    onChange={({ target }) => this.filterByMaterial(target)}
                  >
                    {this.state.categories?.length !== 0 &&
                      this.state.categories.map((category) => {
                        return (
                          <option
                            key={category}
                            className="button btn-create-product mr-2 align-left"
                            value={category}
                          >
                            {category}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              {this.state.redirectProduct && (
                <Redirect
                  to={`/product/${this.state.redirectProduct}`}
                ></Redirect>
              )}

              {this.state.filterProducts && (
                <div className="grid-x grid-margin-x shop-products-list">
                  {this.state.filterProducts.map((item) => {
                    return (
                      <div className="shop-products-card cell small-12 medium-4 large-3 flex-container flex-dir-column">
                        <div
                          className="shop-products-card-media"
                          style={{ backgroundColor: colors[item.colour] }}
                        >
                          {item.images[0].woocommerce_thumbnail ? (
                            <img
                              src={item.images[0].woocommerce_thumbnail}
                              alt={item.name}
                              className="shop-products-card-image"
                            />
                          ) : (
                            <div className="shop-products-card-no-image" />
                          )}
                        </div>
                        <div className="shop-products-card-content flex-child-grow align-justify">
                          <div className="details-section">
                            <div className="id-text">
                              <span dangerouslySetInnerHTML={{ __html: item.sku }} />
                            </div>
                            <div className="custom-text" title={item.label}>
                              <span dangerouslySetInnerHTML={{ __html: item.label }} />
                            </div>
                            <div
                              className="original-text"
                              dangerouslySetInnerHTML={{ __html: item.name }}
                            />
                          </div>
                          <Link to={`/product/${item.id}`}>
                            <button type="button" className="button">
                              View
                            </button>
                          </Link>
                        </div>
                      </div>
                    );
                  }, this)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  addProduct() {
    if (!isAccess(`${wc_products}/new`)) {
      return;
    }
    spinnerService.show("ug");

    axios
      .post(this.props.config.apiUrl + `/${wc_products}/`, {
        type: "simple",
        name: "New Product",
      })
      .then(
        function (response) {
          spinnerService.hide("ug");
          if (response && response.data.id) {
            this.setState({ redirectProduct: response.data.id });
          }
        }.bind(this)
      );
  }

  getCategories() {
    spinnerService.show("ug");
    axios
      .get(apiUrl + "/wc/v3/products/categories?per_page=100", {})
      .then((response) => {
        spinnerService.hide("ug");
        if (response && response?.data) {
          this.setState({
            categories: response.data.map((cat) => cat.name),
          });
        }
      });
  }

  filter() {
    let products = [];
  
    if (this.state.products.length !== 0) {
      this.state.products.forEach((product) => {
        const isMaterialMatch =
          this.state.filterByMaterial &&
          product.category_names.indexOf(this.state.filterByMaterial) !== -1;
  
        const isNameMatch =
          !this.state.filterByName || // Check if filterByName is empty or falsy
          (product.name.toLowerCase().includes(this.state.filterByName.toLowerCase()) ||
            product.sku.toLowerCase().includes(this.state.filterByName.toLowerCase()));
  
        if (product.show_on_frontend && isMaterialMatch && isNameMatch) {
          products.push(product);
        }
      });
    }
    this.setState({ filterProducts: products });
  }
  
  filterByName(event) {
    const value = event.target.value;

    this.setState(
      () => ({
        filterByName: value,
      }),
      () => this.filter()
    );
  }

  filterByMaterial(target) {
    const value = target.value;

    this.setState(
      () => ({
        filterByMaterial: value,
      }),
      () => this.filter()
    );
  }

  constructor(props) {
    super(props);

    let _this = this;

    this.state = {
      redirectProduct: false,
      filterByName: "",
      filterByMaterial: "",
      filterProducts: [],
      products: [],
      categories: [],
    };

    this.addProduct = this.addProduct.bind(this);

    this.filterByName = this.filterByName.bind(this);
    this.filterByMaterial = this.filterByMaterial.bind(this);
    this.filter = this.filter.bind(this);
    this.getCategories = this.getCategories.bind(this);

    if (!isAccess(acc_products)) {
      return;
    }
    spinnerService.show("ug");

    axios
      .get(this.props.config.apiUrl + `/${acc_products}/`, {})
      .then(function (response) {
        spinnerService.hide("ug");

        if (response && response.data) {
          let products = response.data.products;
          // Ticket:P22075-11 Filter out the products listing based on the show_on_frontend property.
          products = products.filter((product) => {
            return (
              !product.hasOwnProperty("show_on_frontend") ||
              product?.show_on_frontend
            );
          });
          for (let product of products) {
            product.show = true;
          }
          // Find the first non-empty category_names value
          let firstNonEmptyCategoryName = '';
          for (const product of products) {
            if (product.category_names && product.category_names.length > 0) {
              firstNonEmptyCategoryName = product.category_names[0];
              break; // Break out of the loop as soon as a non-empty category_names value is found
            }
          }

          _this.setState({ filterProducts: products, products: products, filterByMaterial: firstNonEmptyCategoryName});
        }
      });
  }

  componentDidMount() {
    this.filter();
    this.getCategories();
  }
}

export default Products;
