import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import { AuthContext } from "../../context/auth";
import { useQuery } from "../../hooks"

export default function UpdatePassword({ code }) {
    let { passwordResetUpdate } = useContext(AuthContext);
    let [password, setPassword] = useState('');
    let [confirmation, setConfirmation] = useState('');
    let [message, setMessage] = useState(null);
    let query = useQuery();
    let login = query.get("login");

    const handlePasswordUpdate = (e) => {
        e.preventDefault();

        spinnerService.show('ug');

        passwordResetUpdate({ key: code, password, confirmation, login }, (response) => {
            spinnerService.hide('ug');

            if (response.data?.status) {
                const { status, message } = response.data;

                setMessage({ status, content: message });
            } else {
                setMessage({
                    status: "error",
                    content: "Something went wrong",
                });
            }
        });
    };

    return (
        <form onSubmit={handlePasswordUpdate} autoComplete="off">
            <label>
                <input
                    name="password"
                    type="password"
                    value={password}
                    placeholder="New Password"
                    onChange={({ target }) => setPassword(target.value)}
                />
            </label>
            <label>
                <input
                    name="password-confirm"
                    type="password"
                    value={confirmation}
                    placeholder="Confirm Password"
                    onChange={({ target }) => setConfirmation(target.value)}
                />
            </label>
            {message && <p className={message.status}>{message.content}</p>}
            {message?.status && message.status === "success" ? (
                <Link to={"/sign-in"}>Login here</Link>
            ) : (
                <button type="submit">Update password</button>
            )}
        </form>
    );
}
