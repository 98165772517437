import React from 'react';
import { NavLink } from "react-router-dom";
import axios from "axios";
import { notify } from 'react-notify-toast';
import { isAccess } from '../utilities/constant/navbar.constant';

class DataTile extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      archiving: false,
    };

    this.archive = this.archive.bind(this);
  }

    archive(event){
      event.preventDefault();
      event.stopPropagation();
      
      if (!window.confirm('Are you sure you wish to archive this? It will no longer appear against this funeral home.')){
        return;
      }
      
      this.setState({
        archiving: true
      });

      let _this = this;
      if (!isAccess('acc/v1/data')) {
        return;
      }
      axios.get(this.props.config.apiUrl + '/acc/v1/data/' + this.props.prop.ID + '/archive', {})
      .then(function (response) {
        if(response.data?.success && response.data.success) {
          window.location.href = `/funeral-home/${_this.props.funeralHomeId}`
          notify.show('Successfully archived', 'success', 3000);
          
          _this.setState({
            show: false,
            archiving: false
          });
        } else {
          notify.show('There was an error archiving this item', 'error', 3000);

          _this.setState({
            archiving: false
          });
        }
      });

      return false;

    }

    render() {  
      let post_date = this.props.prop.post_date_gmt.replace(/-/g,'/');
      let date = new Date(post_date + ' GMT');
      
      return (
         !this.props.listView ? (
        <div className={`cell cell-data-set cell-home cell-home-data ${(this.props.prop.show) ? 'show' : 'hide'} ${(this.props.prop.archived) ? 'is-archived' : ''} ${(this.state.archiving) ? ' archiving' : ''}`} >
            <NavLink to={`/data/${this.props.prop.ID}`} exact>
                <div className="cell cell-data-set-inner cell-home-inner">
                    <div className="cell-home-inner-inner">
                        
                        <p><i className="far fa-tag"></i></p>
                        <div className="title">DATA SET {this.props.prop.ID}</div>

                        {this.props.prop.proposal_id &&
                          <p className="created proposal">EXISTING CATALOGUE FOR<br/>PROPOSAL {this.props.prop.proposal_id}</p>
                        }
                        <p className="created">{this.props.prop.start_date_display} to {this.props.prop.end_date_display}</p>
                       
                        
                    </div>
                </div>

            </NavLink>
         </div> ) : (
           <tr className={`${(this.props.prop.show) ? 'show' : 'hide'} ${(this.props.prop.archived) ? 'is-archived' : ''} ${
            (this.props.prop?.approved) ? 'approved-row' : ''
          }`}>
             <td><NavLink to={`/data/${this.props.prop.ID}`} exact><i className="far fa-tag"></i></NavLink></td>
             <td className="title"><NavLink to={`/data/${this.props.prop.ID}`} exact>DATA SET {this.props.prop.ID}</NavLink></td>
             <td><NavLink to={`/data/${this.props.prop.ID}`} exact>{this.props.prop.start_date_display} to {this.props.prop.end_date_display}</NavLink></td>
             <td><NavLink to={`/data/${this.props.prop.ID}`} exact>{this.props.prop.author && ( this.props.prop.author.data.display_name)}</NavLink></td>
             <td><NavLink to={`/data/${this.props.prop.ID}`} exact>{date.toLocaleString('en-AU', { timeZone: 'Australia/Melbourne' })}</NavLink></td>
              <td></td>
            <td className={`${(this.state.archiving) ? ' archiving' : ''}`}>{ !this.props.prop.archived && <NavLink to={`/data/${this.props.prop.ID}`} exact><button className="dataset-archive" onClick={this.archive}><i className="fal fa-archive"></i></button></NavLink> }</td>
           </tr>
           
         )
        
      );
    }  


    

   
  }

  export default DataTile;
