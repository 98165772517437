import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";

export default function AddressForm({ fields, save }) {
  let [address, setAddress] = useState({});

  useEffect(() => {
    setAddress({ ...fields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    address_1: Yup.string().required("Address line 1 is required"),
    address_2: Yup.string(),
    city: Yup.string().required("City is required"),
    postcode: Yup.string()
      .required("Postcode is required")
      .matches(/^[0-9]+$/, "Postcode must be numeric value."),
    state: Yup.string().required("State is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address_1: address?.address_1,
      address_2: address.address_2,
      city: address.city,
      postcode: address.postcode,
      state: address.state,
    },
    validationSchema,
    onSubmit: (values) => {
      save(values);
    },
  });

  return (
    <>
      <h3 className="modal-address-form-title">Update delivery address</h3>
      {address && (
        <form onSubmit={formik.handleSubmit}>
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="address_1">Address line 1*</label>
                <input
                  name="address_1"
                  type="text"
                  placeholder="Address line 1*"
                  value={formik.values.address_1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.address_1 ? "error-input" : ""}
                />
                {formik.errors.address_1 && (
                  <span className="error-message">
                    {formik.errors.address_1}
                  </span>
                )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="address_2">Address line 2</label>
                <input
                  name="address_2"
                  type="text"
                  placeholder="Address line 2"
                  value={formik.values.address_2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.address_2 ? "error-input" : ""}
                />
                {formik.errors.address_2 && (
                  <span className="error-message">
                    {formik.errors.address_2}
                  </span>
                )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="city">City*</label>
                <input
                  name="city"
                  type="text"
                  placeholder="City*"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.city ? "error-input" : ""}
                />
                {formik.errors.city && (
                  <span className="error-message">{formik.errors.city}</span>
                )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="postcode">Postcode*</label>
                <input
                  name="postcode"
                  type="text"
                  placeholder="Postcode*"
                  value={formik.values.postcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.postcode ? "error-input" : ""}
                />
                {formik.errors.postcode && (
                  <span className="error-message">
                    {formik.errors.postcode}
                  </span>
                )}
              </p>
            </div>
            <div className="cell small-12 large-6">
              <p>
                <label htmlFor="state">State*</label>
                <select
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={formik.errors.state ? "error-input" : ""}
                >
                  <option value="">Select</option>
                  <option value="ACT">ACT</option>
                  <option value="NSW">NSW</option>
                  <option value="NT">NT</option>
                  <option value="SA">SA</option>
                  <option value="QLD">QLD</option>
                  <option value="VIC">VIC</option>
                  <option value="WA">WA</option>
                </select>
                {formik.errors.state && (
                  <span className="error-message">{formik.errors.state}</span>
                )}
              </p>
            </div>
          </div>
          <div className="grid-x grid-margin-x">
            <div className="cell small-12 flex-container align-right">
              <button
                type="submit"
                disabled={!!Object.values(formik.errors)?.length}
                className="button"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
