import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../context/auth";

export default function PrivateRoute(props) {
  const { authed, isAdmin, isManager, isProductionStaff, isCustomer } =
    useContext(AuthContext);

  if (!authed()) {
    return (
      <Route path={props.path} render={() => <Redirect to={`/sign-in/`} />} />
    );
  }

  if (props.admin && !(isAdmin() || isProductionStaff())) {
    return <Redirect to="/customer" />;
  }

  if (props.manager && !isManager()) {
    return <Redirect to="/customer" />;
  }

  if (props.customer && !isCustomer() && !isManager()) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
}
