import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";

export default function Modal({ open, classes, style, width = 1040, children }) {
    const modalRef = useRef(null);
    const [top, setTop] = useState(500);
    const [translateY, setTranslateY] = useState(500);

    useEffect(() => {
        ReactModal.setAppElement('#root');

        window.addEventListener('resize', getContentStyles);

        return () => window.removeEventListener('resize', getContentStyles)
    }, []);

    const getContentStyles = () => {
        if (modalRef.current) {
            const offscreen = modalRef.current.getBoundingClientRect().height > window.innerHeight;

            setTop(offscreen ? '2.5rem' : '50%');
            setTranslateY(offscreen ? '0' : '-50%');
        }
    };

    const content = {
        top,
        width: `${width}px`,
        transform: `translate(-50%, ${translateY})`,
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        border: 'none',
        borderRadius: '5px',
        position: 'relative',
        margin: 0,
        height: 'auto',
        overflow: 'none',
        maxWidth: 'calc(100% - 2.5rem)',
        maxHeight: 'calc(100vh - 2.5rem)',
        ...(style && style)
    };

    return (
        <ReactModal isOpen={open} portalClassName={classes} closeTimeoutMS={250} style={{ content }} onAfterOpen={getContentStyles}>
            <div ref={modalRef} className="modal-content-wrapper">{children}</div>
        </ReactModal>
    );
}
