import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { spinnerService } from "@chevtek/react-spinners";
import moment from 'moment';
import axios from "axios";
import { notify } from "react-notify-toast";
import Headery from "../auth/header.jsx";
import ProductTable from "../shop/components/product-table";
import FeesTable from "../shop/components/fees-table";
import { AuthContext } from "../context/auth.jsx";
import { useFetch } from "../hooks";
import { getMetaDate, getMetaData } from "../helpers";
import { acc_shop_invoice, acc_shop_order_cart, apiUrl, wc_orders } from "../config/config";
import { isAccess } from "../utilities/constant/navbar.constant.js";
import { CartContext } from "../context/cart.jsx";

export default function Order() {
    let { id } = useParams();
    const { getCart, addItem, clearCart  } = useContext(CartContext);
    let [order, setOrder] = useState(null);
    let [requested, setRequested] = useState(false);
    let history = useHistory();
    let [response] = useFetch(`${apiUrl}/${wc_orders}/${id}`);
    const { isManager } = useContext(AuthContext);

    useEffect(() => {
        if (response) {
            setOrder(response);
        }
    }, [response]);

    const handleReorder = async () => {
        if (!isAccess(acc_shop_order_cart)) {
          return;
        }
      
        if (order.line_items?.length) {
          await clearCart(); // Clear the cart before processing items
          for (const item of order.line_items) {
            await addItem(item.product_id, item.quantity);
          }
          history.push('/customer/new-order/review');
          getCart()
        }
      };

    const handleRequest = () => {
        if (!isAccess(acc_shop_invoice)) {
          return;
        }
        spinnerService.show("ug");

        axios
            .post(apiUrl + `/${acc_shop_invoice}`, {
                id: order.id
            })
            .then(function (response) {
                if (response?.data?.status && response.data.status === "success") {
                    setRequested(true);
                    notify.show("Invoice request sent", "success", 3000);
                } else {
                    notify.show(response?.data?.error ?? "Something went wrong", "error", 3000);
                }
            })
            .finally(() => {
                spinnerService.hide("ug");
            });
    }

    const deliveryDate = order ? getMetaDate('order_delivery_date', order.meta_data) : null;
    const fastTrack = order ? getMetaData('is_order_fast_track', order.meta_data) : null;
    const fastTrackRequestName = order ? getMetaData('order_fast_track_approved_by', order.meta_data) : null;
    const freightID = order ? getMetaData('order_freight_id', order.meta_data) : null;

    return (
        <div className="page-content customer-order" id="main-scroller">
            <Headery title="Order Details" />
            <div className="grid-container">
                <Link to="/customer/orders" className="customer-order-back-top button--reset"><i className="far fa-chevron-left"></i> Back to orders</Link>

                {order && (
                    <section>
                        <header className="customer-order-heading grid-x">
                            <div className="cell small-12 large-4">
                                <h1 className="no-margin">Order #{order.id}</h1>
                                <em className="customer-order-updated">Last updated on {moment(order.date_modified, "YYYY-MM-DD hh:mm:ss").format('DD/MM/YYYY hh:mmA')} {order?.last_edit_by ? `by ${order.last_edit_by}` : ''}</em>
                            </div>
                            <div className="cell small-12 large-8">
                                <div className="customer-order-heading-details grid-x align-middle">
                                    {isManager() && 
                                        <div className="cell">
                                            <button type="button" className="button button--ghost" onClick={handleRequest}>{requested ? 'Invoice requested' : 'Request invoice'}</button>
                                        </div>
                                    }
                                    <div className="cell">
                                        <button type="button" className="button" onClick={handleReorder}><i className="fas fa-redo-alt"></i> Reorder</button>
                                    </div>
                                    <div className="cell">
                                        <small>Status</small>
                                        <strong className="capitalize">{order.status}</strong>
                                    </div>
                                    {deliveryDate && <div className="cell">
                                        <small>Delivery Date</small>
                                        <strong className="capitalize">{deliveryDate}</strong>
                                    </div>}
                                    {fastTrack && <div className="cell">
                                        <small>{freightID ? 'Fast track freight ID' : 'Fast track requested'}</small>
                                        <strong className="capitalize"><i className="shop-review-fast-track-icon fas fa-shipping-fast"></i> {freightID ? freightID : fastTrackRequestName}</strong>
                                    </div>}
                                </div>
                            </div>
                        </header>
                        <div className="cell small-12">
                            <ProductTable items={order.line_items} approved={['approved', 'completed'].includes(order.status)} />
                            <FeesTable items={order.fee_lines} />

                            {order.customer_note && <div className="cell small-12 customer-order-additional">
                                <h2>Additional information <small>{getMetaData('order_reference', order.meta_data)}</small></h2>
                                <p className="customer-order-notes">{order.customer_note}</p>
                            </div>}
                        </div>
                        
                        <Link to="/customer/orders" className="button--reset"><i className="far fa-chevron-left"></i> Back</Link>
                    </section>
                )}

            </div>
        </div>
    );
}
