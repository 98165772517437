import React from 'react';
import { Link } from "react-router-dom";
import { spinnerService } from '@chevtek/react-spinners';
import Headery  from "./auth/header.jsx";


class FourOFour extends React.Component {
    render() {  
      
        
      return (
        

        <div className="body-white">
          <Headery briefTitle={`404`}></Headery>
          <div className="grid-container">
            <div className="grid-x">
              <div className="cell cell-404">
                <h1>Page not found</h1>
                <p>Please return to the homepage and follow the links from there.</p>
                <p><Link to={`/`}>Home</Link></p>
                </div>
              </div>
            </div>
        </div>
      );
   
    }

    constructor(props){
      super(props);

      //Don't get the need for this but hey ho
      window.setTimeout(function(){ spinnerService.hide('ug'); }, 500);
    }

   
  }

  export default FourOFour;
