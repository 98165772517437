import React from 'react';
import Catalogue from '../proposals/catalogue/catalogue.jsx';
import ioCatalogue from '../proposals/catalogue/io-catalogue.jsx';
import Output from '../proposals/output/output.jsx';
import axios from 'axios';
//import axiosErrorHandler from '../widgets/axios-error-handler.jsx';
import { spinnerService } from '@chevtek/react-spinners';
import { NavLink } from "react-router-dom";
import PrivateRoute from '../auth/private-route.jsx';
import { isAccess } from '../utilities/constant/navbar.constant.js';

class SalesEntry extends React.Component {
    render() {  


        

        return (
        
            <div className="react-main holder-sales">
                <div className="grid-x">
                    <div className="cell left-nav">
                    {this.state.loaded &&
                        <ul>                  
                            <li>
                                <NavLink exact to={`/data/${this.state.atts.catalogues.data.post.ID}`} data-tip={`Data Entry`} data-place="right"><i className="far fa-tag"></i></NavLink>
                            </li>                        
                            <li>
                                <NavLink to={`/data/${this.state.atts.catalogues.data.post.ID}/output`} data-tip={`Output Documents`} data-place="right"><i className="far fa-arrow-alt-to-bottom"></i></NavLink>
                            </li> 
                        </ul>
                    }
                    </div>
                    

                    {this.state.loaded &&             
                        <div className="cell auto proposal-right-body-content body-white">

                            <PrivateRoute
                                exact
                                config={this.props.config}      
                                path={`/data/${this.state.atts.catalogues.data.post.ID}`}
                                
                                render={(props) => 
                                
                                    <Catalogue    
                                        onChangeValue={this.ioCatalogue.handleInputChangeCatalogue}                              
                                        onChangeValueLine={this.ioCatalogue.handleInputChangeCatalogueProduct}
                                        onChangeValueAddLine={this.ioCatalogue.handleInputChangeAddCatalogueProduct}
                                        onSubmitAddLine={this.ioCatalogue.handleSubmitAddCatalogueProduct}
                                        onDeleteLine={this.ioCatalogue.handleDeleteCatalogueProduct}
                                        onProductSelect={this.ioCatalogue.handleProductSelect}                                    
                                        proposal={this.state.post}
                                        funeralHome={this.state.funeral_home}
                                        add={this.state.atts.catalogues.data.atts.new_product}
                                        catalogue={this.state.atts.catalogues.data}
                                        product_cats={this.state.product_cats}
                                        products={this.state.products}
                                        catalogue_type="data"

                                        {...props}
                            
                                    ></Catalogue>

                                }
                            />
                                        
                            <PrivateRoute
                                exact
                                config={this.props.config}      
                                path={`/data/${this.state.atts.catalogues.data.post.ID}/output`}
                                
                                render={(props) => 
                                    <Output 
                                    proposal={this.state.post}
                                        funeralHome={this.state.funeral_home}
                                        catalogues={{
                                            existing: this.state.atts.catalogues.data
                                        }}
                                        
                                        config={this.props.config}                                        
                                        product_cats={this.state.product_cats}
                                        user={this.props.user}
                                    ></Output>
                                }
                            />
                            
                                
                        </div>        
                    }
                    
                </div>


            </div>
        );
    }  

  

    constructor(props) {
        super(props);

        let _this = this;
        

        this.state = {
            redirect_to_funeral_home: false,
            loaded: false
        }

        
        this.ioCatalogue = new ioCatalogue(this.props.config);
        
        let data_id = this.props.match.params.id;
        
        this.ioCatalogue.handleInputChangeCatalogue = this.ioCatalogue.handleInputChangeCatalogue.bind(this);      
        this.ioCatalogue.handleInputChangeCatalogueProduct = this.ioCatalogue.handleInputChangeCatalogueProduct.bind(this);
        this.ioCatalogue.handleInputChangeAddCatalogueProduct = this.ioCatalogue.handleInputChangeAddCatalogueProduct.bind(this);      
        this.ioCatalogue.handleSubmitAddCatalogueProduct = this.ioCatalogue.handleSubmitAddCatalogueProduct.bind(this);
        this.ioCatalogue.handleDeleteCatalogueProduct = this.ioCatalogue.handleDeleteCatalogueProduct.bind(this);
        this.ioCatalogue.handleProductSelect = this.ioCatalogue.handleProductSelect.bind(this);
        
        if (!isAccess('acc/v1/data')) {
            return;
        }
        spinnerService.show('ug');
        axios.get(this.props.config.apiUrl + '/acc/v1/data/' + data_id, {
      
        }).then(function (response) {
            spinnerService.hide('ug'); 

            if (response && response.data && response.data.atts){

                _this.setState((state) => ({
                    // Data structure below so identical to proposal data structure
                    atts:{
                        catalogues:{
                            data:{
                                post: response.data.post,
                                atts: response.data.atts,
                            }
                        }
                    },
                    product_cats: response.data.product_cats,
                    products: response.data.products,
                    post: response.data.post,
                    redirect_to_funeral_home: false,                    
                    loaded: true,
                    funeral_home: response.data.funeral_home,
                
                })); 

                _this.props.setBreadcrumbs({
                    funeralHomeName: response.data.funeral_home.atts.funeral_home_name,
                    funeralHomeID: response.data.funeral_home.ID,
                    pageTitle: 'Data Set',
                    dataID: data_id,
                });

            }
            
        });
    }

   
  }

  export default SalesEntry;
